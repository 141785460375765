<div [style.width]="width" style="display: flex; align-items: center; justify-content: center">
  <mat-form-field floatLabel="never">
    <input
      class="t-field t-select"
      style="height: 2em; padding: 0 0 0 1em"
      type="text"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button
    (click)="openOptionsManager()"
    class="options-manager-icon"
    [ngClass]="disabled ? 'disabled' : ''"
    matTooltip="Manage Options"
  >
    <mat-icon style="color: #428592">edit_note</mat-icon>
  </button>
</div>
