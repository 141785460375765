
    
<div class="t-popup popup-graphs" style='display: block;height:100%;z-index: 990'>

    <div class="popup-close"></div>
    
    <div class="popup-frame">
        <div class="popup-body">
            <h2>Dicom Inbox</h2>
            <div class="graphs-container">
                <div class="l-flex l-flex-w t-fieldmargin patient-details" style="margin-top: -3em;">
                   

                    <div class="l-flex l-flex-c t-field t-field-pad" style="width: 12em; margin-right: 2em;margin-left: calc(50vw - 35em);">

                        <label>
                            From
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input [(ngModel)]="From" (click)="frompicker.open()" (dateChange)="getList()" readonly [matDatepicker]="frompicker"  class="t-field-text w100 t-field--date"/>
                            <mat-datepicker #frompicker [startAt]="startb"></mat-datepicker>
                        </span>
                        
                    </div>
                    
                

                    <div class="l-flex l-flex-c t-field t-field-pad" style="width: 12em">

                        <label>
                            Until
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input [(ngModel)]="To" (click)="topicker.open()" (dateChange)="getList()" readonly [matDatepicker]="topicker"   class="t-field-text w100 t-field--date"/>
                            <mat-datepicker #topicker [startAt]="start"></mat-datepicker>
                        </span>
                        
                    </div>
                        
                    
                </div>
                <main class="l-deashboard" style="margin-top: 1em;">
    
                    <div class="l-flex patient-item" *ngFor="let box of inbox.InboxData">
                
                        <button class="w100 patient-item-link" (click)="showBox(box.AutoIndex)">
                
                            <div class="l-table h100">
                
                                <span class="l-td-m patient-item-cell patient-id">
                                    Date: <strong>{{box.DicomDate | date: 'dd-MM-yyyy'}}</strong>
                                </span>
                
                                <span class="l-td-m patient-item-cell patient-name">
                                    Name: <strong>{{box.DicomPatName}}</strong>
                                </span>
                
                                <span class="l-td-m patient-item-cell patient-name-last">
                                    ID: <strong>{{box.DicomPatID}}</strong>
                                </span>
                                <span class="l-td-m patient-item-cell">
                                    Images: <strong>{{box.jpg ? box.jpg : '0'}}</strong>
                                    Clips: <strong>{{box.mp4 ? box.mp4: '0'}}</strong>
                                    Full: <strong>{{box['f.mp4'] ? box['f.mp4']: '0'}}</strong>
                                    SRs: <strong>{{box.html ? box.html : '0'}}</strong>  
                                </span>
                
                            </div>			
                
                        </button>
                        
                
                    </div>
                
                </main>
            </div>
            <button type="button" class="popup-close-button" (click)="closed.emit()">Close</button>
        </div>
    </div>
</div>



