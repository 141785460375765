import { PregnancyService } from "./../pregnancy.service";
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FollowUpService } from "../follow-up.service";
import { PatientsService } from "../patients.service";
import { FetusDetailsComponent } from "../fetus-details/fetus-details.component";
import { MatDialog } from "@angular/material/dialog";

import {
  familyHistoryOriginalData,
  pregnanciesOriginalData,
  diseasesOriginalData,
  surgeriesOriginalData,
  medicationsOriginalData,
  sensitivitiesOriginalData,
  habitsOriginalData,
  testsOriginalData,
} from "./data/data-storage";
import { Patient } from "../patients/patient";
import { LoginService } from "../login.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-follow-up-history",
  templateUrl: "./follow-up-history.component.html",
  styleUrls: ["./follow-up-history.component.scss"],
})
export class FollowUpHistoryComponent implements OnInit {
  console = console;
  public showPatientDetailsForm: boolean = false;
  public patient: Patient;

  // Data structures: (For Resetting):
  public familyHistoryOriginalData = { ...familyHistoryOriginalData };
  public pregnanciesOriginalData = { ...pregnanciesOriginalData };
  public diseasesOriginalData = { ...diseasesOriginalData };
  public surgeriesOriginalData = { ...surgeriesOriginalData };
  public medicationsOriginalData = { ...medicationsOriginalData };
  public sensitivitiesOriginalData = { ...sensitivitiesOriginalData };
  public habitsOriginalData = { ...habitsOriginalData };
  public testsOriginalData = { ...testsOriginalData };

  // Data:
  public familyHistoryData = [];
  public pregnanciesData = [];
  public diseasesData = [];
  public surgeriesData = [];
  public medicationsData = [];
  public sensitivitiesData = [];
  public habitsData = [];
  public testsData = [];

  // Is selected row in edit mode:
  public isEditing: boolean = false;

  private pregID: number;
  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;

  constructor(
    public followUpService: FollowUpService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
    this.followUpService.initializeNewData();

    this.patient = this.patientsService.getPat();
    this.pregID = this.pregnancyService.pregID;

    // Get the number of fetuses for the latest pregnancy:
    this.fetusNumberSub = this.followUpService.getFetusesNumber(this.pregID).subscribe((data) => {
      this.fetusesNumber = data;
    });

    // Get the obstetrical formula for the latest pregnancy:
    this.obsFormulaSub = this.followUpService.getObstetricalFormula(this.pregID).subscribe((data) => {
      this.obstetricalFormula = data;
    });

    this.getData("fuFamilyHistory", "familyHistoryData");
    this.getData("fuPregnancies", "pregnanciesData");
    this.getData("fuDiseases", "diseasesData");
    this.getData("fuSurgeries", "surgeriesData");
    this.getData("fuMedications", "medicationsData");
    this.getData("fuSensitivities", "sensitivitiesData");
    this.getData("fuHabits", "habitsData");
    this.getData("fuTests", "testsData");
  }

  ngOnDestroy() {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }

    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  async getData(table: string, placementVariable: string, pregIndex: number = this.pregID) {
    const data = await this.followUpService.getData(table, pregIndex);
    this[placementVariable] = data["data"];
  }

  editRow(rowData: any) {
    rowData.isEditing = true;
  }

  async updateRow(table: string, rowData: any, columnsToUpdate: string[]) {
    try {
      const dataToUpdate = columnsToUpdate.reduce((obj, col) => {
        obj[col] = rowData[col];
        return obj;
      }, {});

      const result = await this.followUpService.updateData(table, dataToUpdate, rowData.AutoIndex);
      if (result["status"] === "ok") {
        rowData.isEditing = false;
      }
    } catch (error) {
      console.error("Error updating the row", error);
    }
  }

  async deleteRow(table: string, rowData: any) {
    try {
      const result = await this.followUpService.deleteData(table, rowData.AutoIndex);
      if (result["status"] === "ok") {
        switch (table) {
          case "fuFamilyHistory":
            this.familyHistoryData = this.familyHistoryData.filter((history) => history !== rowData);
            break;
          case "fuPregnancies":
            this.pregnanciesData = this.pregnanciesData.filter((pregnancy) => pregnancy !== rowData);
            break;
          case "fuDiseases":
            this.diseasesData = this.diseasesData.filter((disease) => disease !== rowData);
            break;
          case "fuSurgeries":
            this.surgeriesData = this.surgeriesData.filter((surgery) => surgery !== rowData);
            break;
          case "fuMedications":
            this.medicationsData = this.medicationsData.filter((medication) => medication !== rowData);
            break;
          case "fuSensitivities":
            this.sensitivitiesData = this.sensitivitiesData.filter((sensitivity) => sensitivity !== rowData);
            break;
          case "fuHabits":
            this.habitsData = this.habitsData.filter((habit) => habit !== rowData);
            break;
          case "fuTests":
            this.testsData = this.testsData.filter((test) => test !== rowData);
            break;
          default:
            console.error(`Table ${table} not handled.`);
            break;
        }
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  }

  async saveNewRow(table: string = "", rowData: any, originalData: any = null) {
    try {
      const result = await this.followUpService.saveData(table, rowData);

      if (result["status"] === "ok") {
        this.resetNewData(rowData, originalData);
        await this.handleDataFetch(table);
      }
    } catch (error) {
      console.error("Error during the save new row", error);
    }
  }

  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];

    for (let year = currentYear; year >= 1980; year--) {
      years.push(year);
    }

    return years;
  }

  getDeliveryWeek(): number[] {
    const weeks: number[] = [];

    for (let week = 42; week >= 1; week--) {
      weeks.push(week);
    }

    return weeks;
  }

  resetNewData(newData: any, originalData: any) {
    const fieldsToPreserve = ["PID", "PregIndex", "ID"];

    for (const key in newData) {
      if (!fieldsToPreserve.includes(key)) {
        // Reset to originalData value or empty string
        newData[key] = originalData && originalData[key] !== undefined ? originalData[key] : "";
      }
    }
  }

  async handleDataFetch(table: string) {
    switch (table) {
      case "fuFamilyHistory":
        await this.getData("fuFamilyHistory", "familyHistoryData");
        break;
      case "fuPregnancies":
        await this.getData("fuPregnancies", "pregnanciesData");
        break;
      case "fuDiseases":
        await this.getData("fuDiseases", "diseasesData");
        break;
      case "fuSurgeries":
        await this.getData("fuSurgeries", "surgeriesData");
        break;
      case "fuMedications":
        await this.getData("fuMedications", "medicationsData");
        break;
      case "fuSensitivities":
        await this.getData("fuSensitivities", "sensitivitiesData");
        break;
      case "fuHabits":
        await this.getData("fuHabits", "habitsData");
        break;
      case "fuTests":
        await this.getData("fuTests", "testsData");
        break;
      default:
        console.error("Unknown table:", table);
    }
  }

  openFetusDetails(fetusesNumber: any, pregnancy: any) {
    const dialogRef = this.dialog.open(FetusDetailsComponent, {
      width: "560px",
      data: {
        fetusesNumber,
        fetusData: {
          Fetus1Weight: pregnancy.Fetus1Weight,
          Fetus1Gender: pregnancy.Fetus1Gender,
          Fetus1Comments: pregnancy.Fetus1Comments,
          Fetus2Weight: pregnancy.Fetus2Weight,
          Fetus2Gender: pregnancy.Fetus2Gender,
          Fetus2Comments: pregnancy.Fetus2Comments,
          Fetus3Weight: pregnancy.Fetus3Weight,
          Fetus3Gender: pregnancy.Fetus3Gender,
          Fetus3Comments: pregnancy.Fetus3Comments,
          Fetus4Weight: pregnancy.Fetus4Weight,
          Fetus4Gender: pregnancy.Fetus4Gender,
          Fetus4Comments: pregnancy.Fetus4Comments,
        },
      },
    });

    dialogRef.afterClosed().subscribe((updatedFetusData) => {
      console.log("Data returned from dialog:", updatedFetusData);

      if (updatedFetusData) {
        pregnancy.Fetus1Weight = updatedFetusData.Fetus1Weight;
        pregnancy.Fetus1Gender = updatedFetusData.Fetus1Gender;
        pregnancy.Fetus1Comments = updatedFetusData.Fetus1Comments;

        pregnancy.Fetus2Weight = updatedFetusData.Fetus2Weight;
        pregnancy.Fetus2Gender = updatedFetusData.Fetus2Gender;
        pregnancy.Fetus2Comments = updatedFetusData.Fetus2Comments;

        pregnancy.Fetus3Weight = updatedFetusData.Fetus3Weight;
        pregnancy.Fetus3Gender = updatedFetusData.Fetus3Gender;
        pregnancy.Fetus3Comments = updatedFetusData.Fetus3Comments;

        pregnancy.Fetus4Weight = updatedFetusData.Fetus4Weight;
        pregnancy.Fetus4Gender = updatedFetusData.Fetus4Gender;
        pregnancy.Fetus4Comments = updatedFetusData.Fetus4Comments;
      }
    });
  }
}
