import { Component, OnInit,OnDestroy,Input,Output,EventEmitter } from '@angular/core';
import {ExamsService} from '../exams.service';
import { LightboxConfig,Lightbox,LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import {GalleryService} from '../gallery.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, FormControl } from '@angular/forms';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit,OnDestroy {
  src:string;
  imgct:number;
  clipct:number;
  fullct: number;
  timelinect: number;
  dwonloadImageCt: number;
  view = 'Groups';
  _albums = [];
  _clips = [];
  _fulls= [];
  _timeline= [];
  currentclip="";
  showVid=false;
  @Input('forprint') forPrint: boolean;
  @Input('inbox') inbox: number;
  @Output() closed = new EventEmitter<null>();
  private _subscription: Subscription;
  private meadiaSub: Subscription;
  invalidi = '';
  download=false;
  image;
  
  constructor(public ls:LoginService,public es: ExamsService,private lbox: Lightbox,private config: LightboxConfig, public gs: GalleryService,private _lightboxEvent: LightboxEvent, public fb:UntypedFormBuilder) { 
    this.config.showImageNumberLabel=true;
    this.config.fadeDuration=0.3;
    // if(this.ls.isCardio)
    //   this.view = 'Timeline';
    
    //this.config.showZoom=true;
    try{
      this.close();
    }
    catch{}
    this.resetImage();
  }

  resetImage(){
    this.image = this.fb.group({
      AutoIndex:[''],
      text:['']
    })
  }

  ngOnInit() {
    this.src="";
    this.imgct=0;
    this.clipct=0;
    this.fullct=0;
    this.timelinect=0;
    this.dwonloadImageCt=0;
    if(this.gs.HasDemos){
      switch(this.es.ExamType){
        case 'NT':
          this.src='NT';
          this.imgct=7;
          break;
        case 'Early':
          this.src='Early';
          this.imgct=31;
          this.clipct=5;
          break;
        case 'Late':
          this.src='Late';
          this.imgct=23;
          this.clipct=5;
      }

      for (let i = 1; i <= this.imgct; i++) {
        const src = '../../assets/demo/'+this.src+'/'+i+'.jpg'
        const caption = 'Image ' + i;
        const thumb = '../../assets/demo/'+this.src+'/thumbs/'+i+'.jpg'
        const album = {
          src: src,
          //caption: caption,
          thumb: thumb
        };
  
        this._albums.push(album);
      }

      for (let i = 1; i <= this.clipct; i++) {
        const src = '../../assets/demo/'+this.src+'/clips/'+i+'.mp4'
        const caption = 'Image ' + i;
        const thumb = '../../assets/demo/'+this.src+'/clips/thumbs/'+i+'.jpg'
        const album = {
          src: src,
          //caption: caption,
          thumb: thumb
        };
  
        this._clips.push(album);
      }
    }
    else{
      this.loadGallery();
    }

    if(this.es.ExamType == 'Emergency Room')
      this.es.getSRs();
    if(! this.es.isClosed){
      this.meadiaSub = this.es.mediaArrived$.subscribe((mtype)=>{
        if(mtype != 'html')
          this.loadGallery();
      });
    }

  }

  ngOnDestroy(): void {
    if(this.meadiaSub)
      this.meadiaSub.unsubscribe();
  }

  loadGallery(){
    this.gs.getGallery(()=>{
      for (let i = 0; i < this.gs.Images.length; i++) {
        const src = '/api/getmedia/'+this.gs.Images[i][0]+'/n';
        const thumb = `/api/getmedia/${this.gs.Images[i][0]}/y`;
        const text = this.gs.Images[i][2]
        const album = {
          src: src,
          //caption: caption,
          thumb: thumb,
          text:  text
        };
  
        this._albums.push(album);
      }
      this.imgct = this.gs.Images.length;
      this.dwonloadImageCt = this.gs.imageSelection.value.filter((i) => i === true).length;
      this.gs.imageSelection.valueChanges.subscribe((res) => {
        this.dwonloadImageCt =res.filter((i) => i === true).length
      })

      for (let i = 0; i < this.gs.Clips.length; i++) {
        const src = '/api/getmedia/'+this.gs.Clips[i]+'/n';
        const caption = 'Image ' + i;
        const thumb =  '/api/getmedia/'+this.gs.Clips[i]+'/y';
        const album = {
          src: src,
          //caption: caption,
          thumb: thumb
        };
  
        this._clips.push(album);
      }
      this.clipct = this.gs.Clips.length;


      for (let i = 0; i < this.gs.Fulls.length; i++) {
        const src = '/api/getmedia/'+this.gs.Fulls[i]+'/n';
        const caption = 'Image ' + i;
        const thumb =  '/api/getmedia/'+this.gs.Fulls[i]+'/y';
        const album = {
          src: src,
          //caption: caption,
          thumb: thumb
        };
  
        this._fulls.push(album);
      }
      this.fullct = this.gs.Fulls.length;

      for (let i = 0; i < this.gs.Timeline.length; i++) {
        if( Array.isArray(this.gs.Timeline[i])){
          const src = '/api/getmedia/'+this.gs.Timeline[i][0]+'/n';
          const thumb = `/api/getmedia/${this.gs.Timeline[i][0]}/y`;
          const text = this.gs.Timeline[i][2]
          const album = {
            src: src,
            //caption: caption,
            thumb: thumb,
            text:  text
          };
    
          this._timeline.push(album);
        }else{

          const src = '/api/getmedia/'+this.gs.Timeline[i]+'/n';
          const caption = 'Image ' + i;
          const thumb =  '/api/getmedia/'+this.gs.Timeline[i]+'/y';
          const album = {
            src: src,
            //caption: caption,
            thumb: thumb
          };
          this._timeline.push(album);
        }
        
      }
      this.timelinect = this.gs.Timeline.length;
    }, this.inbox);
  }


  open(index: number): void {
    // open lightbox
    // this.lbox.open(this._albums, index);
    //this.lbox.close();
    if(this.view =='Timeline'){
      this.lbox.open(this._timeline, index);
    }else{
      this.lbox.open(this._albums, index);
    }
    this.gs.currentImg = index;
    this.gs.isOpen=true;
    this.gs.mode='img';
    this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe((event) => {
        if (event["id"] === LIGHTBOX_EVENT.CLOSE) {
          // event CLOSED is fired
          this.gs.isOpen=false;
          this._subscription.unsubscribe();
        }
     
        if (event["id"] === LIGHTBOX_EVENT.OPEN) {
          // event OPEN is fired
        }
     
        if (event["id"] === LIGHTBOX_EVENT.CHANGE_PAGE) {
          // event change page is fired
          this.gs.currentImg = event["data"]; // -> image index that lightbox is switched to
        }
      });
  }

  openClip(index: number, full=false): void {
    if(this.view == 'Timeline'){
      this.currentclip = this._timeline[index].src;
      this.gs.mode= 'clip';

    }else{ 
      this.currentclip= full ? this._fulls[index].src : this._clips[index].src;
      this.gs.mode= full ? 'full':'clip';
    }
    this.showVid=true;
    this.gs.isOpen=true;
    this.gs.currentImg=index;
  }
 
  close(): void {
    // close lightbox programmatically
    this.lbox.close();
    this.gs.isOpen=false;
    this.gs.showSelect =false;
  }

  assign(){
    this.gs.assignInbox(this.inbox);
    this.closed.emit();
  }

  checkArray(item){
    return Array.isArray(item)
  }

}
