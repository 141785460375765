import { Component, OnInit, Input } from "@angular/core";
import { PrintService } from "../print.service";
import { Anatomies } from "../anatomy/anatomy";
import { Subscription } from "rxjs";
import { FetalechoService } from "../fetalecho.service";

@Component({
  selector: "app-systemprint",
  templateUrl: "./systemprint.component.html",
  styleUrls: ["./systemprint.component.scss"],
})
export class SystemprintComponent implements OnInit {
  @Input("System") System: string;
  @Input("name") Name: string;
  @Input("fetus") fetus: number;
  @Input("dir") dir: string;
  anatomies: Anatomies;
  show = false;
  sub: Subscription;
  alist;
  fetalMeas;
  ivsStatus;
  ivs;
  showIVS;
  Situs = ["Situs", "AVC", "VAC", "PVR", "SVR", "PE"];
  SitusItems = [];
  Atria = [
    "Atria",
    "RightAtrium",
    "LeftAtrium",
    "InteratrialSeptum",
    "ForamenOvale",
    "CoronarySinus",
  ];
  AtriaItems = [];
  Ventricles = [
    "Ventricles",
    "RightVentricle",
    "LeftVentricle",
    "IVS",
    "IVSStatus",
  ];
  VentriclesItems = [];
  Valves = [
    "Valves",
    "PulmonaryValve",
    "AorticValve",
    "TricupsidValve",
    "MitralValve",
  ];
  ValvesItems = [];
  Vessels = [
    "Vessels",
    "PulmonaryArtery",
    "DuctusArteriosus",
    "AorticArch",
    "IsthmicFlow",
  ];
  VesselsItems = [];
  comments: any;

  constructor(public print: PrintService, public fes: FetalechoService) {
    this.anatomies = new Anatomies();
  }

  ngOnInit() {
    if (this.print.loaded) {
      if (this.print[this.System] && this.print[this.System][this.fetus]) {
        this.setList();
        this.show = true;
        this.comments = this.print[this.System][this.fetus].Comments;
      }
    } else {
      this.sub = this.print.loadMsg.subscribe(() => {
        this.sub.unsubscribe();
        if (this.print[this.System] && this.print[this.System][this.fetus]) {
          this.setList();
          this.show = true;
        }
      });
    }
  }

  setList() {
    const newVersionDate = new Date(this.print.env.doubleOrgans);
    const examDate = new Date(this.print.exam.CreationTime);
    this.alist = [];
    for (let i = 0; i < this.anatomies[this.System].length; i++) {
      const hasDouble = this.anatomies[this.System][i]["double"];
      let anatomyName =
        this.anatomies[this.System][i]["db"] ||
        this.anatomies[this.System][i]["name"];
      const value = this.print[this.System][this.fetus][anatomyName];
      const noteValue =
        this.print[this.System][this.fetus][anatomyName + "Note"];

      // new version
      if (examDate >= newVersionDate) {
        if (hasDouble) {
          const anatomyNameRight = this.anatomies[this.System][i]["dbr"];
          const anatomyNameLeft = this.anatomies[this.System][i]["dbl"];
          const noteName = this.anatomies[this.System][i]["name"] + "Note";
          const valueRight =
            this.print[this.System][this.fetus][anatomyNameRight];
          const valueLeft =
            this.print[this.System][this.fetus][anatomyNameLeft];
          const noteDoubleValue = this.print[this.System][this.fetus][noteName];

          if (valueRight > -1) {
            this.pushToAlist(anatomyNameRight, valueRight, noteDoubleValue);
          }

          if (valueLeft > -1) {
            this.pushToAlist(anatomyNameLeft, valueLeft, noteDoubleValue);
          }
        }

        if (value > -1 && !hasDouble) {
          this.pushToAlist(anatomyName, value, noteValue);
        }
        // old version
      } else {
        if (value > -1) {
          this.pushToAlist(anatomyName, value, noteValue);
        }
      }
    }
    if (
      this.ivs ||
      this.ivsStatus ||
      this.print[this.System][this.fetus]["IVSNote"]
    ) {
      this.showIVS = true;
      if (this.ivsStatus) {
        this.ivsStatus.com = this.print[this.System][this.fetus]["IVSNote"];
      }
    }
    this.alist.forEach((i) => {
      if (i.type === "Situs") this.SitusItems.push(i);
      if (i.type === "Atria") this.AtriaItems.push(i);
      if (i.type === "Ventricles") this.VentriclesItems.push(i);
      if (i.type === "Valves") this.ValvesItems.push(i);
      if (i.type === "Vessels") this.VesselsItems.push(i);
    });
  }

  pushToAlist(name: string, value: string, note: string) {
    if (name == "IVS") {
      this.ivs = {
        nm: name,
        val: value,
        type: this.getType(name),
        com: note,
      };
    } else if (name == "IVSStatus") {
      this.ivsStatus = {
        nm: name,
        val: value,
        type: this.getType(name),
        com: note,
      };
    } else {
      this.alist.push({
        nm: name,
        val: value,
        type: this.getType(name),
        com: note,
      });
    }
  }

  getClass(item: any) {
    if (
      item.nm === "IVS" ||
      item.nm === "IVSStatus" ||
      item.nm === "Situs" ||
      item.nm === "PE" ||
      item.nm === "AVC" ||
      item.nm === "VAC" ||
      item.nm === "PVR" ||
      item.nm === "SVR"
    ) {
      return "op";
    }
    return item.val === 0
      ? "seen"
      : item.val === 1
      ? "unseen"
      : this.System === "chestFetal"
      ? ""
      : "comments";
  }

  getType(item) {
    if (this.Situs.includes(item)) return "Situs";
    if (this.Atria.includes(item)) return "Atria";
    if (this.Ventricles.includes(item)) return "Ventricles";
    if (this.Valves.includes(item)) return "Valves";
    if (this.Vessels.includes(item)) return "Vessels";
  }

  getAnswer(item: any) {
    if (item.nm === "VAC" || item.nm === "AVC") {
      return item.val === 0 ? "No" : "Yes";
    }

    if (item.nm === "PVR" || item.nm === "SVR") {
      return item.val === 0
        ? "Abnormal"
        : item.nm === "PVR"
        ? "toLeftAtrium"
        : "toRightAtrium";
    }

    if (item.nm === "Situs") {
      return item.val === 0
        ? "Solitus"
        : item.val === 1
        ? "Ambiguous"
        : "Inversus";
    }

    if (item.nm === "PE") {
      return item.val === 0 ? "Seen" : "Unseen";
    }

    return item.val === 0 ? "normal" : item.val === 1 ? "Unseen" : "Abnormal";
  }

  getPVR(a) {
    return `systems.${this.System}.${a.name}.return ${
      this.fes.fetalEchoForm.get("PVRStatus").value
    } systems.${this.System}.${a.name}.title `;
  }

  hasValue(systemItems: any[], propertyName: string): boolean {
    return systemItems.some(
      (item) => item[propertyName] !== null && item[propertyName] !== -1
    );
  }
}
