export class Patient {
    AutoIndex
    ID: string;
    First: string;
    Last: string;
    FirstEng: string;
    LastEng: string;
    IsID: number = 1;
    IsPreganant: boolean = true;
    LMP: string;
    Birth: string;
    Height: number
    Weight: number
    Email: string
    Mobile: string
    Phone: string
    Address: string
    City: string
    Zip: string
    BloodType: string
    HMO: string
    RefferDoc: string
    Comments: string
    DBIndex: number 
    PID: number//for fetching purposes
    UID: number
    //for awl
    Status: string;
    AWLID:number;
    ExamLMP;
    ExamType: string;
    Reason:string;
    PRN: string;
    Code: string;
    Sex: string;
    BSAus: number;
    ATDNotify: Date;
    SpouseID:string;
    SpouseFirst: string;
    SpouseLast:string;
    SpouseBirth: string
    SpouseOrigin: string
    SpouseGrandmother: string
    SpouseGrandfather: string
    SpouseBloodType: string;

    constructor(id: string = null, fname: string = null, lname: string = null, phone: string = null, HMO:string = null){
        this.ID=id
        this.First=fname
        this.Last=lname
        this.Phone=phone
        this.HMO = HMO
    }
    


    getGA(): string {
        if (!this.LMP) return "";
        let now = new Date();
        now.setHours(0, 0, 0, 0);
    
        let days = Math.floor(
          (Number(now) - Date.parse(this.LMP)) / (1000 * 60 * 60 * 24)
        );
    
        if (Math.floor(days / 7) < 1 || Math.floor(days / 7) > 50) {
          return null;
        }
        return Math.floor(days / 7) + "w " + (Math.floor(days) % 7) + "d";
      }

    getAge(atDate:Date = new Date()):string{
        if(! this.Birth)
            return "";
        let days = Math.round((atDate.valueOf() - Date.parse(this.Birth))/(1000 * 60 * 60 *24));
        return Math.floor(days/36.525) / 10 + " yrs";
    }

    getAgeMo(atDate:Date = new Date()){
        if(! this.Birth)
            return "";
        let days = Math.round((atDate.valueOf() - Date.parse(this.Birth))/(1000 * 60 * 60 *24));
        let years = Math.floor(days/365.25);
        let part = days - years * 365.25;
        return Math.floor(days/365.25) + "y" + Math.floor(part/30)+"m";
    }

    asignVals(pat){
        for (let key in pat) {
            try{
                this[key] = pat[key]
            }
            catch{}
            // Use `key` and `value`
        }
        this.IsPreganant = (pat["IsPreganant"] == 1)
    }
}