export const createScansNewData = (PID: number, PregIndex: number, ID: string, GA: string = "", Date: Date = null) => ({
  PID,
  PregIndex,
  ID,
  Name: "",
  Date,
  GA,
  Sonographer: "",
  Status: "",
  Comments: "",
  InstanceNumber: null,
});

export const scansOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Name: "",
  Date: null,
  GA: "",
  Sonographer: "",
  Status: "",
  Comments: "",
  InstanceNumber: null,
};

export const createMeasurementsNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  BPD: "",
  HC: "",
  AC: "",
  FL: "",
  InstanceNumber: null,
});

export const measurementsOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: "",
  BPD: "",
  HC: "",
  AC: "",
  FL: "",
  InstanceNumber: null,
};
