import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { GastroService } from 'src/app/gastro.service';

@Component({
  selector: 'app-detail-finding',
  templateUrl: './detail-finding.component.html',
  styleUrls: ['./detail-finding.component.scss']
})
export class DetailFindingComponent implements OnInit {
  @Input('data') item;
  @Input('id') id;
  @Input('dbName') dbName:string;

  form:UntypedFormControl;
  constructor(public gs:GastroService, public es:ExamsService, public atS:AutotextService) { }

  ngOnInit() {
  }

}
