import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutotextService } from '../autotext.service';
import { ExamsService } from '../exams.service';
import { PregnancyService } from '../pregnancy.service';

@Component({
  selector: 'app-medicalhistory',
  templateUrl: './medicalhistory.component.html',
  styleUrls: ['./medicalhistory.component.scss']
})
export class MedicalhistoryComponent implements OnInit {
  @Input('isWeightAss') isWeightAss;
  @Input('typeOfUser') typeOfUser;
  @Input('modality') modality;
  showHistory = false;
  @Output() newItemEvent = new EventEmitter<boolean>();
  constructor(public ps:PregnancyService, public es:ExamsService, public atS: AutotextService) { }

  ngOnInit() {
    if(this.es.ExamType == 'Fetal Echo'){
      this.showHistory =true;
      this.newItemEvent.emit(this.showHistory)
    }
  }
  setShowHistory(){
    this.showHistory = !this.showHistory
    this.newItemEvent.emit(this.showHistory);
  }

}
