import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { start } from 'repl';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/login.service';
import { StatsService } from 'src/app/stats.service';

@Component({
  selector: 'app-printstats',
  templateUrl: './printstats.component.html',
  styleUrls: ['./printstats.component.scss']
})
export class PrintstatsComponent implements OnInit, OnDestroy {
  now:Date;
  Direction='ltr';
  typeOfPrint = 'simple'
  showCharts = false;
  loaded=false;
  hasImages = false;
  imPerPage = 2;
  mct = 0;
  altHeader:SafeHtml;
  showHeader = true;
  showHistory = true;
  sub: Subscription;
  form;
  constructor(public sts:StatsService,private route: ActivatedRoute, public translate: TranslateService, public ls:LoginService) {
    this.now=new Date();
   }

  ngOnInit() {
    
    this.translate.use(this.route.snapshot.params.LANG);
    if(this.route.snapshot.params.LANG == 'he-IL' || this.route.snapshot.params.LANG == 'ar-JO')
    this.Direction='rtl';
    this.typeOfPrint = this.route.snapshot.params.style
    let code = this.route.snapshot.params['code'];
    var params;
    if(this.route.snapshot.params.dates == 'months'){
      params = {
        type: 'date',
        date: this.route.snapshot.params.start,
        searchByType:this.route.snapshot.params.searchByType
      }
    }else{
      params = {
        type: 'date',
        start: this.route.snapshot.params.start,
        end: this.route.snapshot.params.end,
        searchByType:this.route.snapshot.params.searchByType
      }
    }
    this.sts.search(params, code)
    this.sub = this.sts.statsLoaded$.subscribe(() => {
      this.loaded = true;
      this.form = this.sts.statsForm.value;
      // console.log(this.sts.usersStats,this.form);
    })
    
    

  }
  precntage(data, type, total = false){
    var temp = 0
    if(total){
      if(data[type] && data['All']){
        temp = (data[type].length  / data['All'].length ) *100
      }
    }else{
      if(data.exams[type] && data.exams['All'])
        temp = (data.exams[type].length / data.exams['All'].length ) *100
    }
    return Number(temp)
    // return (value / user.exams[type].length ) *100
  }
  getDbName(exam){
    if(exam == '1st')
      return 'first'
    if(exam == '2nd/3rd')
      return 'second'
    return exam.replace(' ', '').replace('-', '')
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
