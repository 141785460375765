import { Component, OnInit,Input } from '@angular/core';
import {Measure} from '../measurements/meaure';
import {Subject,Subscription} from "rxjs";
import {ExamsService} from '../exams.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { FormBuilder,FormGroup,UntypedFormControl } from '@angular/forms';
import {DopplerService} from '../doppler.service';
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";
import {LoginService} from '../login.service';
import { PregnancyService } from '../pregnancy.service';

@Component({
  selector: 'app-doppleritem',
  templateUrl: './doppleritem.component.html',
  styleUrls: ['./doppleritem.component.scss']
})
export class DoppleritemComponent implements OnInit {
  @Input('Type') Mname: string;
  @Input('Artery') Artery: string;
  @Input('side') Side: string;
  @Input('GG') GGopts: string[];
  @Input('OK') OK: string;
  @Input('fg') fg: UntypedFormControl[];
  MyMeasure: Measure;
  ggwarn: string;
  mvClass: string = "is-stat3";
  Editing: boolean;
  chartData:ChartDataSets[];
  showChart: boolean = false;
  valueChanged: Subject<number> = new Subject<number>();
  Sub: Subscription;

  lineChartOptions: ChartOptions  = { responsive: true,scales: {
    xAxes: [{
        type: 'linear',
        position: 'bottom',
        scaleLabel: { display: true, labelString: 'weeks' }
    }],
    yAxes: [{
      type: 'linear',
     // scaleLabel: { display: true, labelString: 'mm' }
    }],
    
  },
  aspectRatio: 1,
  };
  
  constructor(
    public es: ExamsService,
    private dops: DopplerService,
    private ls: LoginService,
    public pregnancyService: PregnancyService,
  ) {
    this.Sub=dops.loaded$.subscribe(()=>{//load value when value loads
      //this.MyMeasure.value=this.dops.getValue(this.OK.replace('_OK',''));
      this.checkNum(false);
    });

    this.valueChanged.pipe(
      debounceTime(400)
      )
      .subscribe(model => {
        this.dops.saveMeas(this.OK.replace('_OK',''),this.MyMeasure.value);
      });
    
  }

  ngOnInit() {
    //this.MyMeasure = this.ms.getMeas(this.Artery+this.Mname,[],this.GGopts);
    this.MyMeasure = this.dops.Items[this.OK.replace('_OK','')];//new Measure(this.Artery+this.Mname,[],this.GGopts?this.GGopts:[],0,1000);
    if(this.Mname=='CPR')
      this.dops.setCPR(this);

    const LMP = this.pregnancyService.pregForm.get('LMP').value;
    const LMP_US = this.pregnancyService.pregForm.get('LMP_US').value;

    if (LMP || LMP_US) {
      this.es.isEitherLMPDataMissing = false;
    } else {
      this.es.isEitherLMPDataMissing = true;
    }
  }

  ngOnDestroy(){
    this.Sub.unsubscribe();
  }

  checkNum(save=true){
    //this.ms.calcGGTable(this.MyMeasure.name,this.MyMeasure.GGfig);
    if(save)
      this.valueChanged.next(this.MyMeasure.value);
    this.MyMeasure.calcGGTable(this.es.examGA());
    this.SDClass();

  }

  getRange():string{
    return !this.es.isEitherLMPDataMissing ? this.MyMeasure.getRange(this.es.examGA()) : null;
  }

  setGG(gfig: string):void{
    this.MyMeasure.GGfig = gfig;
    if(gfig == 'none'){
      this.MyMeasure.GG = null;
      this.ggwarn = null;
      return;
    }
    try{//incase the table doesn't exist
      var isdeci = this.MyMeasure.GG_tables[gfig].hasOwnProperty("weeks") && this.MyMeasure.GG_tables[gfig].weeks=="decimal"
      if(Measure.WeekToDays(this.es.examGA()) < (isdeci ? this.MyMeasure.GG_tables[gfig].data[0][0] * 7 :Measure.WeekToDays(this.MyMeasure.GG_tables[gfig].data[0][0]) ) || 
      Measure.WeekToDays(this.es.examGA()) > (isdeci ? this.MyMeasure.GG_tables[gfig].data[this.MyMeasure.GG_tables[gfig].data.length -1][0] * 7 : Measure.WeekToDays(this.MyMeasure.GG_tables[gfig].data[this.MyMeasure.GG_tables[gfig].data.length -1][0]) ) ){
        this.ggwarn="warning";

        this.MyMeasure.GG=null;
      }
      else{
        this.ggwarn=null;
        this.MyMeasure.calcGGTable(this.es.examGA());
        this.SDClass();
      }
      this.es.saveVals(this.Artery.substr(0,1) + this.Mname+'_GGfig',gfig,true,"doppler");
    }
    catch(e){
      console.log(e);
    }
  }

  finishEdit(){
    this.Editing=false;
    if(this.MyMeasure.GG && this.ls.dopplerAutoOk){
      if(this.MyMeasure.GG > 5 && this.MyMeasure.GG < 95)
        this.fg[this.OK].setValue(0);//mark as normal
      else
      this.fg[this.OK].setValue(1);
    }
  }

  SDClass(): void{
    const LMP = this.pregnancyService.pregForm.get('LMP').value;
    const LMP_US = this.pregnancyService.pregForm.get('LMP_US').value;
    let isLMPMissing: boolean;
    if (LMP || LMP_US) {
      isLMPMissing = false;
    } else {
      isLMPMissing = true;
    }

    if(! this.MyMeasure.value){
      this.mvClass="is-stat3"
      return
    }
  
    if (!this.MyMeasure.GG && isLMPMissing) {
      return;
    }
  
    if (this.MyMeasure.GG && !isLMPMissing) {
      if(this.MyMeasure.GG> 96){
        this.mvClass="is-stat5";
        return;
      }
      if(this.MyMeasure.GG> 68){
        this.mvClass="is-stat4";
        return;
      }
      if(this.MyMeasure.GG> 32){
        this.mvClass="is-stat3";
        return;
      }
      if(this.MyMeasure.GG> 6){
        this.mvClass="is-stat2";
        return;
      }
      this.mvClass="is-stat1";
  }
}

resetyn(){
  this.fg[this.OK].setValue(-1);
}

toggleChart(){
  if(! this.MyMeasure.GG)
    return;
    
    let myValues: ChartDataSets = {data:[{
      x: Math.round((Measure.WeekToDays(this.es.examGA())/7)*10)/10,
      y: this.MyMeasure.value
    }]
    ,
      pointStyle:'crossRot',
      hitRadius: 4,
      pointRadius: 10,
      borderWidth: 2,
      pointBorderColor:'#015B6F',
      pointHoverBorderColor: '#015B6F',
      backgroundColor:'#015B6F',
      borderColor: '#015B6F',
      label: 'Measured'
    };

    this.chartData = this.MyMeasure.getBoundaries(this.MyMeasure.GGfig,myValues);
    this.showChart = true;
  }

}
