
<div class="t-popup popup-patientDetails">

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame"  [ngClass]="gfs.typeForm == 'Admission Form' ? 'maxw' : 'w41em'">
        <app-admission  *ngIf="gfs.typeForm == 'Admission Form'" (closed)="close(true)" (print)="showprint=true;showprintopts=true"></app-admission>
        <app-timeout *ngIf="gfs.typeForm == 'Time Out Form'" (closed)="close(true)" (print)="showprint=true;showprintopts=true"></app-timeout>
    </div>
</div>

<app-gastro-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts"></app-gastro-printopts>
