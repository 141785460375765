<section class="l-flex l-column l-deashboard--gallery">
    <button class="save_patieont" (click)="assign()" *ngIf="inbox" style="margin-top: -2em;">
        Assign To this Exam
    </button>
    <ul class="t-menu" style="justify-content: space-between;">
        <div style="display: inline-flex;">
        <li *ngIf="! forPrint && ! gs.showSelect">
            <button class="t-menu-item is-active">
                All ({{imgct+clipct}})
            </button>
        </li>
        <li *ngIf="view !='Timeline'">
            <button class="t-menu-item">
                Images ({{imgct}})
            </button>
        </li>
        <li *ngIf="! forPrint && ! gs.showSelect && view !='Timeline'">
            <button class="t-menu-item">
                Clips ({{clipct}})
            </button>
        </li>
        <li *ngIf="! forPrint && ! gs.showSelect && view !='Timeline' && !ls.isCardio">
            <button class="t-menu-item">
                Full length recordings ({{fullct}})
            </button>
        </li>
        <li *ngIf="! forPrint && ! gs.showSelect && view !='Timeline'">
            <button class="t-menu-item" (click)="gs.showSelect = !gs.showSelect">
                Select For Download
            </button>
        </li>
        <li *ngIf="forPrint || gs.showSelect">
            <button class="t-menu-item" (click)="gs.selectAll()">
                Select All
            </button>
        </li>
        <li *ngIf="forPrint || gs.showSelect">
            <button class="t-menu-item" (click)="gs.selectAll(false)">
                Deselect All
            </button>
        </li>
        <li *ngIf="! forPrint && (gs.showSelect && (gs.imageGroup.get('imageSelection').value.includes(true)))">

            <div class="t-validation-wrap">
                <button class="t-menu-item" (click)="invalidi='is-invalid'" [ngClass]="invalidi"> Download
                    ({{dwonloadImageCt}})</button>

                <div class="t-validation-message" style="width:30vw;left:-13vw;font-size: 1.87em;top:+2vw">
                    <span>בחרת להוציא חומר הכולל מידע רפואי רגיש ממערכת מאובטחת , מרגע הורדה החומר אינו מוגן יותר אנא
                        הקפד שלא להשאירו בכונן או במיקום רשת נגיש לאנשים שאינם מורשים לצפות בחומר
                        <br>
                        <div style="display: flex; justify-content: space-between; margin-left: 1vw;margin-right: 1vw;">
                            <button (click)="gs.downloadImages();download=true;invalidi=''"
                                class="t-menu-item">אישור</button>
                            <button (click)="invalidi=''" class="t-menu-item">בטל</button>
                        </div>

                    </span>

                </div>
            </div>
        </li>
        <li *ngIf="! forPrint && gs.showSelect">
            <button class="t-menu-item" (click)="gs.showSelect = !gs.showSelect">
                Cancel
            </button>
        </li>
        </div>
        <li *ngIf="! forPrint && ! gs.showSelect && this.ls.isCardio">
            <label style="margin-inline-end: 1em;">Gallary view</label>
            <select class="t-select t-field" style="background-color: aliceblue;" [(ngModel)]="view">
                <option value="Timeline">Timeline</option>
                <option value="Groups">By Type</option>
            </select>
        </li>
    </ul>
    <div class="h100 l-flex t-row t-boxMargin--spacebyPadding gallery-table" *ngIf="view =='Timeline'">
        <div class="l-fg1">
            <div class="h100 m-box l-fg1 gallery-still">
                <h2>
                    <i class="gallery-title-icon  icon-camera-still"></i>
                    Timeline
                </h2>
                <div class="scroller">

                    <div class="l-flex l-flex-w gallery-item-list" style="padding-bottom: 1.87em;">

                        <div class="gallery-item" *ngFor="let n of ' '.repeat(timelinect).split('');index as i">

                            <div class="gallery-item-in" style="width: 100%;" *ngIf="checkArray(gs.Timeline[i])">

                                <span (click)="open(i)" class="lb gallery-item-frame">

                                    <img [src]="_timeline[i].thumb" alt="" />

                                </span>
                                <textarea *ngIf="es.isGastro"
                                    style="background: none;border: none;margin: 3px;width: 95%;max-height: 1.5em;"
                                    [(ngModel)]="_timeline[i].text" (ngModelChange)="gs.saveText(i, _timeline[i].text)"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                                <label *ngIf="forPrint || gs.showSelect" class="l-flex"
                                    style="justify-content: center;margin-bottom: 1%; margin-bottom: 2%;">
                                    <input type="checkbox" name="i" class="custom-check -sq" [value]="true"
                                        [formControlName]="i" />
                                    <i class="customInput-display"></i>
                                </label>

                            </div>
                            <ng-container *ngIf="! checkArray(gs.Timeline[i])">
                                <div class="h100 w100 gallery-videos">
                                    <div class="h100 frame">
                                        <div class="videoPlay">
                                            <div class="gallery-item" style="width: 100%;">
    
                                                <div class="gallery-item-in">
    
                                                    <span>
    
                                                        <img [src]="_timeline[i].thumb" alt="" />
                                                        <span (click)="openClip(i)">
                                                            <img src="../../assets/icons/play.svg" style="max-height: 2em ;padding-block-start: 0.5em;"/>
                                                        </span>
    
                                                    </span>
    
                                                </div>
    
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </ng-container>

                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>



    <div class="h100 l-flex t-row t-boxMargin--spacebyPadding gallery-table" *ngIf="view =='Groups'">

        <div class="l-fg1">

            <div class="h100 m-box l-fg1 gallery-still" [formGroup]="gs.imageGroup">

                <h2>
                    <i class="gallery-title-icon icon-camera-still"></i>
                    Images
                </h2>

                <div class="scroller">

                    <div class="l-flex l-flex-w gallery-item-list" formArrayName="imageSelection"
                        style="padding-bottom: 1.87em;">

                        <div class="gallery-item" *ngFor="let n of ' '.repeat(imgct).split('');index as i">

                            <div class="gallery-item-in">

                                <span (click)="open(i)" class="lb gallery-item-frame">

                                    <img [src]="_albums[i].thumb" alt="" />

                                </span>
                                <textarea *ngIf="es.isGastro"
                                    style="background: none;border: none;margin: 3px;width: 95%;max-height: 1.5em;"
                                    [(ngModel)]="_albums[i].text" (ngModelChange)="gs.saveText(i, _albums[i].text)"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                                <label *ngIf="forPrint || gs.showSelect" class="l-flex"
                                    style="justify-content: center;margin-bottom: 1%; margin-bottom: 2%;">
                                    <input type="checkbox" name="i" class="custom-check -sq" [value]="true"
                                        [formControlName]="i" />
                                    <i class="customInput-display"></i>
                                </label>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="h100 gallery-videos" *ngIf="! forPrint && ! gs.showSelect" [ngClass]="ls.isCardio ? 'w45': ''">

            <div class="h100 m-box">

                <h2>
                    <i class="gallery-title-icon icon-camera-video"></i>
                    Clips
                </h2>

                <div>
                    <!--class="scroller"-->

                    <div class="l-flex l-flex-w gallery-item-list gallery-videos-holder">
                        <div class="gallery-item" *ngFor="let n of ' '.repeat(clipct).split('');index as i" [ngClass]="ls.isCardio ? 'w33' :''">

                            <div class="gallery-item-in" [ngClass]="ls.isCardio ? 'cardioSizeImage' : ''">

                                <!-- <span (click)="openClip(i)" class="lb gallery-item-frame">

                                    <img [src]="_clips[i].thumb" alt="" />

                                </span> -->
                                <span (click)="openClip(i)">
                                    <img [src]="_clips[i].thumb" alt="" />
                                    <span>
                                        <img src="../../assets/icons/play.svg" style="max-height: 2em ;padding-block: 0.3em;"/>
                                    </span>
                                </span>


                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div class="h100 m-box" *ngIf="! gs.showSelect && !ls.isCardio">

                <h2>
                    <i class="gallery-title-icon icon-camera-full"></i>
                    Full length recordings
                </h2>

                <div>
                    <!--class="scroller"-->

                    <div class="l-flex l-flex-w gallery-item-list gallery-videos-holder">
                        <div class="gallery-item" *ngFor="let n of ' '.repeat(fullct).split('');index as i">

                            <div class="gallery-item-in" (click)="openClip(i,true)">

                                <!-- <span (click)="openClip(i,true)" class="lb gallery-item-frame">

                                    <img alt="" />

                                </span> -->
                                <span >
                                    <img [src]="_fulls[i].thumb" alt="" />
                                    <span>
                                        <img src="../../assets/icons/play.svg" style="max-height: 2em ;padding-block: 0.3em;"/>
                                    </span>
                                </span>


                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>



    </div>
</section>
<div [ngClass]="ls.isCardio && view == 'Groups' ? 'videos-cardio' : 'videos'" *ngIf="showVid">
    <span (click)="showVid=false" style='float:right'><img src="../../assets/icons/x.svg" style='height:2em'></span>
    <video [src]="currentclip" autoplay="true" controls="true" loop="true" style="max-height:80vh;width: 45em;"></video>
</div>
<div class="modalback" *ngIf="showVid" (click)="showVid=false"></div>