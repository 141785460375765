export const createGeneticsMotherNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  ID,
  Name: "",
  Carrier: "",
  Mutation: "",
});

export const geneticsMotherOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  Name: "",
  Carrier: "",
  Mutation: "",
};

export const createGeneticsSpouseNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  ID,
  Name: "",
  Carrier: "",
  Mutation: "",
});

export const geneticsSpouseOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  Name: "",
  Carrier: "",
  Mutation: "",
};
