<div class="no-scroll patient-ob">

    <div class="wrapper">

        <!-- ========================== header (start) ========================== -->
        <header class="l-header">

            <div class="on-mobile header-logo">
                <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
            </div>
            <div class="l-header-panel">

                <i class="off-mobile header-icon icon-fetus" *ngIf="false"></i>

                <div class="header-pagi" *ngIf="false">
                    <a href="#" class="header-pagi-item">1</a>
                    <a href="#" class="header-pagi-item is-active">2</a>
                    <a href="#" class="header-pagi-item">3</a>
                </div>

                <nav class="header-menu">
                    <span (click)="showprintopts=true;showprint=true" *ngIf="! ls.ishospital" class="header-menu-link icon-print"
                        aria-label="Print" matTooltip="Print"></span>

                    <a routerLink="/patsearch" class="header-menu-link icon-confirm" matTooltip="Exit Stats"></a>
                    <!-- <a href="#" class="header-menu-link icon-file" aria-label="file"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-chart" aria-label="chart"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-image" aria-label="image"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-searchdoc" aria-label="image"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-save" aria-label="save"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-chat" aria-label="chat"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-confirm" aria-label="confirm"></a> -->

                </nav>

                <nav class="on-mobile header-mobilePanel">
                    <button class="btn-mobileMenu" id="open-headerMenu" data-show=".header-menu"
                        aria-label="open mobile menu"></button>
                    <button class="btn-mobileMenu" id="open-mainNav" data-show=".l-sidebar"
                        aria-label="open mobile sidebar"></button>
                </nav>

            </div>

        </header>
        <!-- ========================== header (end) ========================== -->


        <!-- ========================== intro (start) ========================== -->

        <main class="l-deashboard" style="padding-top: 0;"  [ngClass]="!loaded ? 'curs' : ''">

            <div class="h100 l-flex l-flex-w">

                <!-- history (start) -->
                <div class="w100 patient-ob-box" style="padding-bottom: 0;">
                    <div class="h100 m-box">
                        <h2>Search Parameters</h2>

                        <div class="w100 h100 l-flex-w">
                            <label style="align-self: center; margin-inline-end: 0.5em;">Search: </label>
                            <select class="t-select t-field" [(ngModel)]="type" name="type"
                                (ngModelChange)="onSetType()" style="width: fit-content;margin-inline-end: 3em;">
                                <option></option>
                                <ng-container>
                                    <option value="date">Date</option>
                                    <!-- <option value="name" *ngIf="ls.isAdmin">User name</option> -->
                                    <!-- <option value="exam">Exam Type</option> -->
                                </ng-container>
                            </select>
                            <div class="h100 l-flex-w" style="margin-inline-end: 4em;" *ngIf="type =='date'">
                                <div class="l-flex-c l-flex-w indicative-fieldset h100"
                                    style="justify-content: space-evenly">
                                    <label class="t-label-top">

                                        <input type="radio" [(ngModel)]="dateType" value="months" (ngModelChange)="onSearch()"
                                            class="custom-check -sq" />
                                        <i class="customInput-display -sq"></i>
                                        <span>
                                            Months
                                        </span>

                                    </label>
                                    <div *ngIf="dateType == 'months'">
                                        <select class="t-select t-field" [(ngModel)]="date" (ngModelChange)="onSearch()"
                                            name="date" style="width: fit-content;">
                                            <option></option>
                                            <ng-container>
                                                <option value="1">1 month</option>
                                                <option value="3">3 months</option>
                                                <option value="6">6 months</option>
                                            </ng-container>
                                        </select>
                                    </div>

                                    <label class="t-label-top">
                                        <input type="radio" [(ngModel)]="dateType" value="dates" 
                                            class="custom-check -sq" />
                                        <i class="customInput-display -sq"></i>
                                        <span>
                                            Date
                                        </span>
                                    </label>
                                </div>

                            </div>
                            <div class="h100 l-flex-w" style="margin-inline-end: 4em;align-items: center;"
                                *ngIf="type == 'date' && dateType == 'dates'">
                                <strong style="margin-inline-end: 5px;">From </strong> <input [matDatepicker]="startDatepicker" [(ngModel)]="startdate"
                                    class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP"
                                    (click)="startDatepicker.open()">
                                <mat-datepicker #startDatepicker></mat-datepicker>
                                <strong style="margin: 0 5px;">Until </strong>
                                <input [matDatepicker]="endDatepicker" [(ngModel)]="enddate"
                                    (click)="endDatepicker.open()" 
                                    class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP">
                                <mat-datepicker #endDatepicker></mat-datepicker>

                            </div>
                            <div style="margin-inline-end: 4em">
                                <label style="align-self: center;margin-inline-end: 0.5em;">Search By:</label>
                                <select class="t-select t-field" [(ngModel)]="searchByType" 
                                    name="date" style="width: fit-content;">
                                    <option></option>
                                    <ng-container>
                                        <option value="TerminationRemarks">Doctors</option>
                                        <option *ngIf="ls.ishospital" value="Opener">Techs</option>
                                    </ng-container>
                                </select>
                            </div>
                            <!-- <div class="h100 l-flex-w" *ngIf="sts.usersStats && sts.usersStats.length && type != 'exam' &&  ls.isAdmin && false">
                                <label style="align-self: center;margin-inline-end: 0.5em;">User name:</label>
                                <input class="t-field t-field-text" type="text" [(ngModel)]="name"
                                    (ngModelChange)="filterByName(name)" />
                            </div> -->
                            <!-- <div>
                                <select class="t-select t-field" [(ngModel)]="searchByType" 
                                    name="date" style="width: fit-content;">
                                    <option></option>
                                    <ng-container>
                                        <option value="TerminationRemarks">Doctors</option>
                                        <option value="Opener">Techs</option>
                                    </ng-container>
                                </select>
                            </div> -->
                            <div class="h100 l-flex-w" style="width: 50%;" *ngIf="type =='exam'">
                                <label style="align-self: center;margin-inline-end: 0.5em;">Exam Type</label>
                                <div>
                                    <select class="t-select t-field" [(ngModel)]="ExamType" 
                                        name="date" style="width: fit-content;">
                                        <option></option>
                                        <ng-container>
                                            <option *ngFor="let exam of examsArray">{{exam}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <button class="search"   matTooltipPosition="get stats" (click)="onSearch()" matTooltip="Open New Patient">Search</button>  
                        </div>
                    </div>
                </div>
                <!-- history (end) -->

                <!-- new exam (start) -->
                <div class="w100" *ngIf="loaded">
                    <div style="height: fit-content;">
                        <h4 style="width: 100%;margin: 0;" *ngIf="type == 'date'">Total</h4>
                        <div class="w100" style="display: inline-flex;justify-content: space-between;">
                            <div class="w50 patient-ob-box l-flex-w" [formGroup]="sts.statsForm" *ngIf="!selecteduser && loaded">
                                <div class="w100 m-box" *ngIf="type == 'date' && (date || enddate || startdate)"
                                    style="margin: 1em;">
                                    <h2>Total Exams</h2>
                                    <div class="w100 l-flex-w" style="justify-content: center;">
                                        <div style="margin-inline-end: 2em;"><strong>From</strong>: {{sts.statsForm.get('start').value |
                                            date:'dd-MM-yyyy'}}</div>
                                        <div><strong>Until</strong>: {{sts.statsForm.get('end').value | date:'dd-MM-yyyy'}}</div>
                                    </div>
                                    <div class="w100 h100 l-flex l-column">
                                        <div style="min-height: 50%;margin-bottom: 10px;">
                                            <table class="w100">
                                                <tr>
                                                    <td style="width: 5%;font-weight: 700;"></td>
                                                    <td style="width: 30%;font-weight: 700;">Exam Type</td>
                                                    <td style="width: 15%;font-weight: 700;">#</td>
                                                    <td style="width: 15%;font-weight: 700;">%</td>
                                                </tr>
                                                <tr *ngFor="let exam of examsArray;index as i">
                                                    <td>{{i+1}}</td>
                                                    <td>{{exam}}</td>
                                                    <td>{{sts.statsForm.get(getDb(exam)) && sts.statsForm.get(getDb(exam)).value ? sts.statsForm.get(getDb(exam)).value.length : 0}}</td>
                                                    <td>{{(sts.statsForm.get(getDb(exam)) && sts.statsForm.get(getDb(exam)).value ? getPrecent(sts.statsForm.get(getDb(exam)).value.length,sts.statsForm.get('All').value) : 0) | number: '1.0-2'}}</td>
                                                </tr>
    
                                            </table>
                                        </div>
                                        <div>
                                            <table class="w100"> 
                                                <tr>
                                                    <td style="width: 5%;font-weight: 700;"></td>
                                                    <td style="width: 30%;font-weight: 700;">HMO</td>
                                                    <td style="width: 15%;font-weight: 700;">#</td>
                                                    <td style="width: 15%;font-weight: 700;">%</td>
                                                </tr>
                                                <tr *ngFor="let hmo of sts.HMO;index as i">
                                                    <td>{{i+1}}</td>
                                                    <td>{{hmo}}</td>
                                                    <td>{{sts.filterByHMO(sts.statsForm.get('All').value,hmo).length}}</td>
                                                    <td>{{getPrecent(sts.filterByHMO(sts.statsForm.get('All').value,hmo).length, sts.statsForm.get('All').value) | number: '1.0-2'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{sts.HMO.length+1}}</td>
                                                    <td>HMO Not assigned</td>
                                                    <td>{{getNotAssigned(sts.statsForm.get('All').value)}}</td>
                                                    <td>{{getPrecent(getNotAssigned(sts.statsForm.get('All').value), sts.statsForm.get('All').value) | number: '1.0-2'}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w50 patient-ob-box l-flex-w"  *ngIf="date && selecteduser">
                                <div class="w100 m-box" style="margin: 1em;">
                                    <h2>{{ls.isCardio ? selecteduser.user[searchByType] : selecteduser.user.UserName}} Exams</h2>
                                    <div class="w100 l-flex-w" style="justify-content: center;">
                                        <div style="margin-inline-end: 2em;"><strong>From</strong>: {{sts.statsForm.get('start').value |
                                            date:'dd-MM-yyyy'}}</div>
                                        <div><strong>Until</strong>: {{sts.statsForm.get('end').value | date:'dd-MM-yyyy'}}</div>
                                    </div>                    
                                    <div *ngIf="selecteduser.exams" style="margin-bottom: 10px;">
                                        <!-- <h4 style="margin: 0;">Exams</h4> -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td style="width: 5%;font-weight: 700;"></td>
                                                <td style="width: 30%;font-weight: 700;">Exam Type</td>
                                                <td style="width: 15%;font-weight: 700">#</td>
                                                <td style="width: 15%;font-weight: 700">%</td>
                                            </tr>
                                            <tr *ngFor="let exam of examsArray;index as i">
                                                <td>{{i+1}}</td>
                                                <td>{{exam}}</td>
                                                <td>{{selecteduser.exams[getDb(exam)] ? selecteduser.exams[getDb(exam)].length : 0}}</td>
                                                <td>{{(selecteduser.exams[getDb(exam)] ? getPrecent(selecteduser.exams[getDb(exam)].length, selecteduser.exams['All']) :0) | number: '1.0-2'}}</td>
                                            </tr>
                                        </table>
                                         <!-- <div *ngFor="let exam of examsArray" style="padding-top: 0.5em;">{{exam}}: {{user.exams[getDb(exam)] ? user.exams[getDb(exam)].length : 0}}</div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="w50 patient-ob-box l-flex-w" *ngIf="date">
                                <div class="w100 m-box" style="margin: 1em;" *ngIf="!(this.selecteduser && !this.selecteduser.exams.All) || !this.selecteduser">
                                    <h2>Charts</h2>
                                    <div class="chart-wrapper" *ngIf="chartType == 'pie'" style="width: 100%;margin-bottom: 1em;">
                                        <canvas baseChart
                                            [data]="ChartDataSingle" 
                                            [labels]="ChartLabels"
                                            [chartType]="ChartType"
                                            [options]="ChartOptions"
                                            [colors]="ChartColor"
                                            [plugins]="ChartPlugins"
                                            [legend]="ChartLegend">
                                        </canvas>
                                    </div>
                                    <div class="chart-wrapper" *ngIf="chartType == 'bar'" style="width: 100%;margin-bottom: 1em;">
                                        <canvas baseChart
                                            [datasets]="barChartData" 
                                            [labels]="ChartLabels"
                                            [chartType]="ChartType"
                                            [options]="ChartOptions"
                                            [colors]="ChartColor"
                                            [plugins]="ChartPlugins"
                                            [legend]="ChartLegend">
                                        </canvas>
                                    </div>
                                    <div class="chart-wrapper" *ngIf="chartType == 'doughnut'" style="width: 100%;margin-bottom: 1em;">
                                        <canvas baseChart 
                                            [datasets]="barChartData" 
                                            [labels]="ChartLabels" 
                                            [chartType]="ChartType"
                                            [options]="ChartOptions"
                                            [colors]="ChartColor"
                                            [plugins]="ChartPlugins"
                                            [legend]="ChartLegend">
                                        </canvas>
                                    </div>
                                    <div class="w100" style="display: flex;">
                                        <div style="width: 33.3%;">
                                            <label>Chart type</label>
                                            <select [(ngModel)]="chartType" [ngModelOptions]="{standalone: true}" (ngModelChange)="chageChart()" class="t-field t-select">
                                                <option value="pie">Pie</option>
                                                <option value="doughnut">Doughnut</option>
                                                <option value="bar">Bars</option>

                                            </select>
                                        </div>
                                        <div style="width: 33.3%;" *ngIf="!selecteduser ">
                                            <label>Data type</label>
                                            <select [(ngModel)]="dataType" [ngModelOptions]="{standalone: true}" (ngModelChange)="chageData()" class="t-field t-select">
                                                <option value="exams">Exams</option>
                                                <option *ngIf="ls.isAdmin" value="doctors">Doctors</option>
                                            </select>
                                        </div>
                                        <div style="width: 33.3%;" *ngIf="!selecteduser && false">
                                            <label>Hmo type</label>
                                            <select [(ngModel)]="hmoType" [ngModelOptions]="{standalone: true}" (ngModelChange)="chageData()" class="t-field t-select">
                                                <option value="">All</option>
                                                <option *ngFor="let name of sts.HMO" value="name">{{name}}</option>
                                                <!-- <option value="exams">Exams</option>
                                                <option value="doctors">Doctors</option> -->
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ls.isAdmin">
                        <h4 style="width: 100%;margin:0 0 1em 0;">Users</h4>
                        <div class="w100 patient-ob-box l-flex-w" *ngIf="filterdUsers && filterdUsers.length > 0" style="padding: 0;">
                            <div class="l-flex-w" *ngFor="let user of filterdUsers" style="margin: 1em;width: 21%;cursor: pointer;" [ngClass]="user.exams.All && user.exams.All.length > 0 ? '' : 'dsp-none'">
                                <div class="m-box w100" (click)="selectUser(user)" (dblclick)="selectUser(null)"  *ngIf="user.exams.All && user.exams.All.length > 0"  [ngClass]="selecteduser == user ? 'selectuser' : ''" style="padding: 1.31em 0.3em;">
                                    <h2 style="margin-left:1em">{{user.user.UserName}}</h2>
                                    <div *ngIf="user.exams">
                                        <h4>Exams</h4>
                                        <table class="w100 stats-table">
                                            <tr>
                                                <td style="width: 5%;font-weight: 700;"></td>
                                                <td style="width: 40%;font-weight: 700;">Exam Type</td>
                                                <td style="width: 10%;font-weight: 700;">#</td>
                                                <td style="width: 10%;font-weight: 700;">%</td>
                                            </tr>
                                            <tr class="stats-tr" *ngFor="let exam of examsArray;index as i">
                                                <td>{{i+1}}</td>
                                                <td>{{exam}}</td>
                                                <td>{{user.exams[getDb(exam)] ? user.exams[getDb(exam)].length : 0}}</td>
                                                <td>{{(user.exams[getDb(exam)] ? getPrecent(user.exams[getDb(exam)].length,user.exams['All']) :0) | number: '1.0-2'}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts" [stats]="true">
            </app-printopts>

        </main>

        <!-- ========================== main sidebar (start) ========================== -->

        <aside class="l-sidebar l-flex l-column">

            <img class="off-mobile sidebar-logo" src="assets/icons/logo.svg" alt="Artisan Sonics" />

            <!-- user panel (start) -->

            <!-- user panel (end) -->

            <!-- main nav (start) -->
            <nav class="mainNav l-fg1 scroller">

            </nav>
            <!-- main nav (end) -->

            <app-docpanel></app-docpanel>

        </aside>

        <!-- ========================== main sidebar (end) ========================== -->

        <app-bottombar></app-bottombar>

    </div><!-- end wrapper -->
</div>