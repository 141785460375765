import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Patient } from "../patients/patient";
import { Router } from "@angular/router";
import { LoginService } from "../login.service";
import { PregnancyService } from "../pregnancy.service";
import { PatientsService } from "../patients.service";
import { ExamsService } from "../exams.service";
import { FollowUpService } from "../follow-up.service";

@Component({
  selector: "app-follow-up-bar",
  templateUrl: "./follow-up-bar.component.html",
  styleUrls: ["./follow-up-bar.component.scss"],
})
export class FollowUpBarComponent implements OnInit, OnDestroy {
  @Output() showPatDetails = new EventEmitter();
  @Input() fetusesNumber: number;
  @Input() obstetricalFormula: any;

  public patient: Patient;
  public patientEDD: any;

  constructor(
    public patientsService: PatientsService,
    private pregnancyService: PregnancyService,
    public examsService: ExamsService,
    private router: Router,
    public loginService: LoginService,
    public followUpService: FollowUpService
  ) {}

  async ngOnInit() {
    this.patient = this.patientsService.getPat();
    this.patientEDD = this.pregnancyService.edd(true, this.patient.LMP);
  }

  showPatientDetails() {
    this.showPatDetails.emit();
  }

  go(place: string) {
    this.router.navigateByUrl(place);
    this.examsService.exitExam();
  }

  ngOnDestroy() {}
}
