<div class="m-box" [ngClass]="getClass(name)" style="height: 100%; position: relative;" [formGroup]="fes.fetalEchoForm">
    <h2 style="display: flex; width: max-content;padding: 0;">{{noShow ? name : DisplayName }}
        <span style="font-size: medium;place-self: flex-end;" *ngIf="name =='SVR'">(SVC + IVC)</span>
    </h2>

    <div class="anatomy-twin-radios anatomy-twin-radios--is_tripple" style="margin-top: 0;"
        (dblclick)="clear(Group,name)" *ngIf="noShow && name !=='AVC' && name !=='VAC' && name !=='PE'">

        <label class="observe">

            <input type="radio" class="custom-check customInput" [name]="dbName" [formControlName]="dbName"
                [value]="0"><i class="customInput-display"></i>

            <span>
                {{name === 'Situs' ? 'Solitus' : 'Normal'}}
            </span>

        </label>

        <label class="attention">

            <input type="radio" class="custom-check customInput" [name]="dbName" [formControlName]="dbName"
                [value]="2"><i class="customInput-display"></i>

            <span>
                {{name === 'Situs' ? 'Inversus' : 'Abnormal'}}

            </span>

        </label>

        <label class="not-observe">

            <input type="radio" class="custom-check customInput" [name]="dbName" [formControlName]="dbName"
                [value]="1"><i class="customInput-display"></i>
            <span>
                {{name === 'Situs' ? 'Ambiguous' : 'Not Observed'}}
            </span>

        </label>
    </div>
    <div style="height: 100%;" *ngIf="! noShow && name !='VAC' && name !='AVC'" (dblclick)="clear(Group,name)">
        <span style="margin: 1em;">
            <strong style="vertical-align: -webkit-baseline-middle;">Draining into</strong>
        </span>
        <div class="anatomy-twin-radios" style="margin-block-start:1em" *ngIf="name !=='PI'">

            <label class="observe">

                <input type="radio" class="custom-check customInput" [formControlName]="dbName" [value]="1" />
                <i class="customInput-display"></i>
                <span>
                    {{ name === 'PVR' ? 'Left Atrium' : 'Right Atrium'}}
                </span>

            </label>

            <label class="not-observe">

                <input type="radio" class="custom-check customInput" [formControlName]="dbName" [value]="0" />
                <i class="customInput-display"></i>
                <span>
                    Abnormal
                </span>

            </label>

        </div>
        <!-- //class="t-label-top" -->
        <div>
            <select *ngIf="name==='PVR'" class="t-select t-field" style="margin-block-start: 1em;"
                formControlName="PVRVessels">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
            <span class="anatomy-show-more" [ngClass]="name === 'PVR' ? 'bottom25' : ''"
                style="cursor: pointer;bottom: -2.3em;" (click)="showComment = true;"></span>
        </div>
    </div>
    <div *ngIf="name =='PE'" style="padding-bottom: 0;">
        <section style="margin:0.5em;display: flex;">
            <div class="t-set-title" style="width: 100%;" (dblclick)="fes.fetalEchoForm.get('PE').setValue(-1)">

                <div class="anatomy-twin-radios">

                    <label class="observe">

                        <input type="radio" name="PE" class="custom-check customInput" formControlName="PE"
                            [value]="0" />
                        <i class="customInput-display"></i>
                        <span>
                            Observed
                        </span>

                    </label>

                    <label class="not-observe">

                        <input type="radio" name="PE" formControlName="PE" class="custom-check customInput"
                            [value]="1" />
                        <i class="customInput-display"></i>
                        <span>
                            Not Observed
                        </span>

                    </label>

                </div>

            </div>
        </section>
    </div>
    <div *ngIf="name === 'AVC' || name === 'VAC'">
        <section style="margin:0.5em;display: flex;width: 95%;" *ngIf="name === 'AVC'">
            <!-- <span style="margin-right: 0.5em;align-self: center;width: 50%;">
                AV Concordance
            </span> -->
            <div class="t-set-title" style="width: inherit;" (dblclick)="fes.fetalEchoForm.get('AVC').setValue(-1)">

                <div class="anatomy-twin-radios">

                    <label class="observe">

                        <input type="radio" name="AVC" class="custom-check customInput observe" formControlName="AVC"
                            [value]="1" />
                        <i class="customInput-display"></i>
                        <span>
                            Yes
                        </span>

                    </label>

                    <label class="not-observe">

                        <input type="radio" name="AVC" formControlName="AVC"
                            class="custom-check customInput not-observe" [value]="0" />
                        <i class="customInput-display"></i>
                        <span>
                            No
                        </span>

                    </label>

                </div>

            </div>
        </section>
        <section style="margin:0.5em;display: flex;width:95% " *ngIf="name === 'VAC'">
            <!-- <span style="margin-right: 0.5em;align-self: center;width: 50%;">
                VA Concordance
            </span> -->
            <div class="t-set-title" style="width: inherit;" (dblclick)="fes.fetalEchoForm.get('VAC').setValue(-1)">

                <div class="anatomy-twin-radios">

                    <label class="observe">

                        <input type="radio" name="VAC" class="custom-check customInput" formControlName="VAC"
                            [value]="1" />
                        <i class="customInput-display"></i>
                        <span>
                            Yes
                        </span>

                    </label>

                    <label class="not-observe">

                        <input type="radio" name="VAC" formControlName="VAC" class="custom-check customInput"
                            [value]="0" />
                        <i class="customInput-display"></i>
                        <span>
                            No
                        </span>

                    </label>

                </div>

            </div>
        </section>
        <div>
            <span class="anatomy-show-more" style="bottom: auto;cursor: pointer;" (click)="showComment = true;"></span>
        </div>
    </div>
    <span class="anatomy-show-more" style="right: 1em;" *ngIf="noShow && name !=='AVC' && name !=='VAC'">
        <!--form elemnts not working when empty so using hack-->
        <div class="on-hoverPanel">

            <div class="on-hoverPanel-body">

                <div class="anatomy-item-details">

                    <h3 class="anatomy-item-details-title">
                        {{noShow ? name : DisplayName }}
                    </h3>

                    <div class="anatomy-items-desc">

                        <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                            contenteditable="true">
                            <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" tabindex="0"
                                style="max-height: none;">
                                <div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                    style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                <div id="mCSB_2_scrollbar_vertical"
                                    class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                    style="display: none;">
                                    <div class="mCSB_draggerContainer">
                                        <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                            style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                            <div class="mCSB_dragger_bar" style="line-height: 30px;"></div>
                                        </div>
                                        <div class="mCSB_draggerRail"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <textarea class="anatomy-items-edit" [name]="dbName+'Note'"
                            [formControlName]="dbName+'Note'"></textarea>


                    </div>

                </div>

                <!--<button class="anatomy-details-save">Save Comments</button> -->

            </div>

        </div>

    </span>
</div>

<!--

//////////////////////////////////////////////////////////////////////////////////////////////

-->

<div *ngIf="showComment" [formGroup]="fes.fetalEchoForm">
    <div class="t-popup popup-patientDetails summary" style='display: block;top: 5em;'>

        <div class="t-popup-close">
        </div>

        <div class="t-popup-frame" style="max-width: 65em;">
            <h3 class="anatomy-item-details-title">
                {{name}} Comments
            </h3>
            <div class="t-popup-body" style="display: flex;flex-direction: column;height: 100%;">
                <div style="place-self: flex-end;">
                    <button class="t-btn-popup-close summary" style="top: 0;right: 0;position: relative;"
                        aria-label="close popup" (click)="showComment = false"></button>
                </div>
                <div class="m-box" style="height: 100%;">
                    <textarea class="fulltext" style="min-height: 20em;height: 100%;" [formControlName]="name+'Note'"
                        [name]="name+'Note'"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(fes.fetalEchoForm.get(name+'Note'),name+' Comments',ATiD)">Auto
                        Text</button>
                </div>
            </div>
        </div>

    </div>
</div>