<div class="w100 summary-anamnesis summary" [formGroup]="ps.pregForm" style="margin-bottom: 0;">
    <div class="m-box" style="padding: 1.31em 1.43em;">
        <h2>
            Medical History
        </h2>
        <div></div>
        <div *ngIf="!showHistory">
            <textarea class="fulltext"
                style="min-height: 10em;height: 100%;padding: 0.5em;"
                #CommentsExtra formControlName="Remarks"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(ps.pregForm.get('Remarks'),'Medial History',100)">AutoText</button>
        </div>
        <button class="t-btn-edit" [ngClass]="showHistory ? '' : 'marg'" (click)="setShowHistory()" *ngIf="es.ExamType != 'Fetal Echo'">{{ showHistory ? 'Text':'Structured'}} Format</button>
        <h4 style="margin: 0;" *ngIf="!showHistory">Obsetrical Formula</h4>
        <div style="display: flex;padding: 1em 0;justify-content: space-between;">
            <div style="width: 100%;display: flex" *ngIf="!showHistory  && modality!='Ultrasound'">
                <section style="margin-inline-end: 1em;">
                    Gravida
                    <select class="t-select t-field" formControlName="Gravida">
                        <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                            <option value="{{i}}">{{i}}</option>
                        </ng-container>
                    </select>
                </section>
                <section style="margin-inline-end: 1em;">
                    Para
                    <select class="t-select t-field" formControlName="Para">
                        <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                            <option value="{{i}}">{{i}}</option>
                        </ng-container>
                    </select>
                </section>
                <section style="margin-inline-end: 1em;">
                    Abortion
                    <select class="t-select t-field" formControlName="Abortion">
                        <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                            <option value="{{i}}">{{i}}</option>
                        </ng-container>
                    </select>
                </section>
            </div>
            <div style="width: 100%;display: flex" *ngIf="!showHistory && modality=='Ultrasound'">
                <section style="margin-inline-end: 1em;">
                    Gravida
                    <select class="t-select t-field" formControlName="Gravida_US" >
                        <option [value]="ps.pregForm.get('Gravida_US').value">{{ps.pregForm.get('Gravida_US').value}}</option>
                    </select>
                </section>
                <section style="margin-inline-end: 1em;">
                    Para
                    <select class="t-select t-field" formControlName="Para_US" >
                        <option [value]="ps.pregForm.get('Para_US').value">{{ps.pregForm.get('Para_US').value}}</option>
                    </select>
                </section>
                <section style="margin-inline-end: 1em;">
                    Abortion
                    <select class="t-select t-field" formControlName="Abortion_US" >
                        <option [value]="ps.pregForm.get('Abortion_US').value">{{ps.pregForm.get('Abortion_US').value}}</option>
                    </select>
                </section>
            </div>
            <div *ngIf="showHistory" style="width: 100%;">
                <div style="display: flex;">
                    <section class="w50" style="margin: 0 1em 0 0;">
                        <app-medicalhistoryitem type="OBSFormula" id="95"  [modality]="modality" [control]="ps.pregForm.controls['OBSFormula']" title="Obsetrical Formula"></app-medicalhistoryitem>
                    </section>
                    <section class="w50" style="margin: 0 1em 0 0;">
                        <app-medicalhistoryitem type="PreMedical" id="96" [control]="ps.pregForm.controls['PreMedical']"title="Medical status"></app-medicalhistoryitem>
                    </section>
                </div>
                <div style="display: flex;">
                    <section class="w50" style="margin: 0 1em 0 0;">
                        <app-medicalhistoryitem type="Drugs" id="99" [control]="ps.pregForm.controls['Drugs']" title="Drugs usage"></app-medicalhistoryitem>
                    </section>
                    <section class="w50" style="margin: 0 1em 0 0;">
                            <app-medicalhistoryitem type="SurgBack"id="98" [control]="ps.pregForm.controls['SurgBack']" title="Surgical background"></app-medicalhistoryitem>
                    </section>
                </div>
                <div style="display: flex;" *ngIf="es.ExamType =='Fetal Echo'">
                    <section class="w50" style="margin: 0 1em 0 0;">
                        <app-medicalhistoryitem type="CVS" id="97" [control]="ps.pregForm.controls['CVS']" title="Congenital Heart Defects."></app-medicalhistoryitem>
                    </section>
                    <section class="w50" style="margin: 0 1em 0 0;">
                        <app-medicalhistoryitem type="Remarks" id="100" [control]="ps.pregForm.controls['Remarks']" title="Other medical history"></app-medicalhistoryitem>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>