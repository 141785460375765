import { Component, OnDestroy, OnInit,ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../login.service';
import { PatientsService } from '../patients.service';
import { Patient } from './patient';
import { ExamsService } from '../exams.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GastroFormsService } from '../gastro-forms.service';
import { GastroService } from '../gastro.service';
import * as XLSX from 'xlsx';
import { writeFile } from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { PregnancyService } from '../pregnancy.service';




@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit, OnDestroy {
  @ViewChild('swipeField') swipeField: ElementRef;
  showIcons = false
  showprintopts=false;
  showprint=false;
  inform = false;
  id: string;
  first: string
  last: string;
  phone: string
  idp = 1;
  showhelp: boolean = false;
  selectedPatient: Patient
  formSelected: any;
  invalidi: string = ""
  invalidf: string = ""
  invalidl: string = "";
  patList: Patient[];
  isAWL = false;
  AWLStatus = "Waiting";
  AWLExam="All"
  AWLlist: Patient[];
  examList;
  patientsearch = true;
  fromd: Date;
  tod: Date;
  openEsearch = false;
  isReports = false;
  hmostype;
  exams;
  typeForm
  examMode = 'All';
  examNames = ['1st', 'NT', 'Early', '2nd/3rd', 'Late','Third Scan', 'Specialized', 'Fetal Echo', 'Gynecology', 'Weight Assessment','Tech Exam','Emergency Room', 'Amniocentesis', 'CVS', 'Follicles'];
  examNamesGastro = ['Gastroscopy', 'Colonoscopy', 'Sigmoidoscopy'];
  cardioTodayListExams=['אקו', 'אקו לב', 'אקו מכבי', 'אקו לאומית', 'אקו מאוחדת', 'אקו לב במאמץ', 'אקו במאמץ מכבי', 'אקו במאמץ לאומית', 'אקו במאמץ מאוחדת', 'אקו במאמץ פרטי', 'ארגומטריה', 'ארגומטריה מכבי','ארגומטריה לאומית', 'ארגומטריה מאוחדת', 'הולטר קצב לב', 'בדיקת אקג', 'קרוטיד US עורקי צואר', 'ייעוץ קרדיולוגי', 'יעוץ רפואי מכבי', 'יעוץ רפואי לאומית', 'יעוץ רפואי מאוחדת', 'יעוץ רפואי כללית מושלם']
  reportsNames = ["statisticsForLeumit", "missingPrn","sentToLeumit"]
  examVals = {};
  chosenAWL: number;
  awlTime: string;
  awlExamType: string;
  awlLMP;
  awlComment: string;
  prn: string;
  code: string;
  swiping=false;
  card: string;
  idcode:string;
  carderror:string;
  expcode:string;
  GA;
  GaArray: any[] = [];
  patExams: any[] = [];
  visitPat: any[] = [];
  showpayment: string;
  cardtzsearch: string;
  goEx = { preg: null, exam: null, etype: null }
  showLogin = false;
  startClinic: number;
  private destroy$ = new Subject();
  sub = new Subscription();
  startdate;
  enddate;
  changedExamCode;
  reportName=null;
  sentMacabi = false;
  openConcentForm;
  concentForm;
  last_search_time;
  toreassign;
  show_surrogate = false;
  reportList=null;
  visitsList=null;
  statisticsForDoctors=null;
  examTypeCounts: { [key: string]: number } = {};
  reportType: string;
  withoutPrn:boolean=false
  currentPage: number = 1;
  pageSize: number = 25;
  hasMore: boolean = false;
  showPaginationControls: boolean = false;

  console = console;
  constructor(
    public gs: GastroService,
    public loginService: LoginService,
    public patservice: PatientsService,
    public exServ: ExamsService,
    private rt: Router,
    public gfs: GastroFormsService,
    private http: HttpClient,
    public pregnancyService: PregnancyService,
  ) {
    this.loginService.loginMissing();
    this.id = ""
    this.first = ""
    this.last = ""
    this.phone = ""

    for (let e of this.examNames)
      this.examVals[e] = true;
    
  }

  clear() {
    this.id = ""
    this.first = ""
    this.last = ""
    this.phone = ""
    if (this.isAWL)
      this.search();
  }

  ngOnInit() {
    let now = new Date();
    let ndate = now.getFullYear() + '-' + (now.getMonth() + 1 < 10 ? '0' : '') + (now.getMonth() + 1) + '-' + (now.getDate() < 10 ? '0' : '') + now.getDate();
    now = new Date(ndate);
    this.tod = now;
    this.fromd = now;
    if (this.loginService.showAWL()) {
      if (this.loginService.lastAwl) {
        this.AWLStatus = this.loginService.lastAwl
      }
      if(this.loginService.lastAwlExam)
        this.AWLExam = this.loginService.lastAwlExam
      if(this.loginService.lastAwlStartDate && this.loginService.lastAwlEnDate){
        this.startdate = this.loginService.lastAwlStartDate;
        this.enddate = this.loginService.lastAwlEnDate;

      }

      if(!this.exServ.searchReport)
        this.AWL();
    }
    this.loginService.onLogin.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if ((this.goEx.preg && this.goEx.exam && this.goEx.etype) || (this.goEx &&(this.loginService.isGastro || this.loginService.isCardio))) {
        this.exServ.goExam(this.goEx.preg, this.goEx.exam, this.goEx.etype);
      }

    });
    this.patservice.exitFromGastroExam.subscribe(() => {
      this.AWLStatus = this.patservice.AWLStatus;
      this.loginService.lastAwl = "All";
    });
    this.patservice.awlIncomming.pipe(takeUntil(this.destroy$)).subscribe(()=>{
      if(this.isAWL)
        this.AWL();
    });
    if(this.exServ.searchReport){
      this.fromd=this.exServ.searchReport.start;
      this.tod=this.exServ.searchReport.end;
      this.reportName=this.exServ.searchReport.type;
      this.hmostype=this.exServ.searchReport.hmo;
      this.isReports = true;
      this.AWLlist = null;
      this.searchClosed([this.fromd, this.tod, [], this.reportName, this.hmostype])
      // this.examSearch(this.reportName, this.hmostype);

    }
    
    this.patservice.getZimunfeedID();

  }

  addPatient() {
    this.invalidi = "";
    if (this.id.length < 7) {
      this.invalidi = "is-invalid";
      return;
    }
    this.invalidf = ""
    if (this.first == "") {
      this.invalidf = "is-invalid";
      return
    }
    this.invalidl = "";
    if (this.last == "") {
      this.invalidl = "is-invalid";
      return
    }
    this.selectedPatient = new Patient(this.id, this.first, this.last, this.phone);

  }

  onClosed(closed: string) {
    if (closed)
      this.selectedPatient = null
    if (closed == 'awl') {
      this.clear();
      this.AWL();
    }
    else
      if (this.patList)
        this.search();//to refresh details
    this.awlExamType = null;
    this.awlLMP = null;
    this.awlTime = null;
    this.chosenAWL = null;
    this.prn = null;
    this.code = null;
    this.typeForm = null;
    this.showIcons = false;
    

  }

  search(noawl = false) {
    this.examList = null;
    this.reportList=null
    this.visitsList=null;
    this.statisticsForDoctors=null
    this.showIcons=false
    this.exServ.searchReport = null;
    this.carderror = null;
    // Resets to the first page on new search
    this.currentPage = 1; 
    // Resets the pagination control buttons
    this.showPaginationControls = false; 

      if (this.isAWL && !noawl) {
        this.patList = null;
        if(this.startdate, this.enddate){
          var start = (this.startdate._i.year)+'-' + (this.startdate._i.month +1) + '-' +this.startdate._i.date + ' 00:00:00.000' 
          var end = (this.enddate._i.year)+'-' + (this.enddate._i.month +1) + '-' + this.enddate._i.date  + ' 23:59:59.000'
        }
        this.patservice.getAWL(this.AWLStatus, this.AWLExam,{ 'id': this.id, first: this.first, last: this.last, start:start, end: end, uid:this.loginService.doctor }).subscribe((patients: Patient[]) => {
          if (patients.length > 0) {
            this.GaArray = [];
            patients.forEach((i) => {
              var GAitem = this.lmpGA(i.ExamLMP);
              this.GaArray.push(GAitem);
              if(i.UID)
                i['nUID'] = i.UID;
            })
          }
          this.AWLlist = patients;
          if(this.cardtzsearch){
            this.swiping = false;
            for(let pat of this.AWLlist){
              let zeros = pat.ID
              while(zeros.length < 9)
                zeros = '0'+ zeros;
              if(pat.ID == this.cardtzsearch || zeros == this.cardtzsearch){
                this.setAWL(pat);
                this.selectedPatient=pat;
                if(this.selectedPatient.HMO != "Macabi"){//now for macabi only
                  this.selectedPatient.HMO = 'Macabi';
                  this.patservice.savePatient(this.selectedPatient,this.selectedPatient.PID).subscribe((data) => {},(error)=>{
                    console.log(error);
                  });
                }
                this.showpayment = this.cardtzsearch;
                this.cardtzsearch = null;
              }
            }
            if(this.cardtzsearch){
              this.carderror = this.cardtzsearch;
              this.cardtzsearch = null;
            }
           
          }
          
        }, (error) => {
          console.log(error)
        });
      }
      else {
        this.AWLlist = null;
        // this.visitPat = null;
        this.patservice.getPatients(this.id, this.first, this.last, this.phone, this.loginService.doctor, (patients: Patient[]) => {
          this.patList = [];
          this.isAWL = false;
          for (let i = 0; i < patients.length; i++) {
            let pat = new Patient()
            pat.asignVals(patients[i])
            this.patList[i] = pat
          }
          // Determine if there are more results
          this.hasMore = patients.length === this.pageSize; 
          // Show pagination controls if there are 25 or more results
          this.showPaginationControls = patients.length >= this.pageSize; 
        }, this.currentPage, this.pageSize);
      }
  }

  nextPage() {
    this.currentPage++;
    this.loadPatients();
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadPatients();
    }
  }

  loadPatients() {
    this.patservice.getPatients(this.id, this.first, this.last, this.phone, this.loginService.doctor, (patients: Patient[]) => {
      this.patList = [];
      for (let i = 0; i < patients.length; i++) {
        let pat = new Patient();
        pat.asignVals(patients[i]);
        this.patList[i] = pat;
      }
      // Determine if there are more results
      this.hasMore = patients.length === this.pageSize; 
    }, this.currentPage, this.pageSize);
  }

  trysearch() {
    this.id = this.id.replace(/\-/g, '_').replace(/\W/g, '').replace(/_/g, '-');
    if (this.id.length > 2 || this.first.length > 2 || this.last.length > 2 || this.phone.length > 2)
      this.search(!this.loginService.ishospital);//for clinics search works on patients - not awl
    if (this.isAWL && this.id.length == 0 && this.first.length == 0 && this.last.length == 0)//when cleared
      this.search();
  }

  AWL(savestatus=true, type=null) {
    if(! this.enddate && this.startdate){
      this.enddate = this.startdate
    }else if(! this.startdate && this.enddate){
      this.startdate = this.enddate 
    }
    if(savestatus){
      this.loginService.lastAwlExam = this.AWLExam;
      this.loginService.lastAwl = this.AWLStatus;
      this.loginService.lastAwlStartDate = this.startdate;
      this.loginService.lastAwlEnDate = this.enddate;

    }
    this.isAWL = true;
    this.examList = null;
    this.reportList=null
    this.visitsList=null
    this.statisticsForDoctors=null
    this.exServ.searchReport = null;
    this.search();
    this.patservice.openWebSocket(! this.loginService.ishospital? this.loginService.ClinicID: null);
  }

  switchSearch() {
    this.patientsearch = !this.patientsearch;
    if (!this.patientsearch)
      this.examSearch();
    else
      this.examList = [];
      this.reportList=[];
      this.visitsList=[];
      this.statisticsForDoctors=[];
  }


  async vistsSearch (fromDate: Date, toDate: Date) {
    this.exServ.visitSearch(fromDate, toDate).subscribe((data) => {
      this.visitsList= data;
      this.examList = null;
      this.reportList=null
      this.showIcons=false
      this.patList = null;
      this.statisticsForDoctors=null
      this.examTypeCounts={}
    })
  }
  examSearch(reportInfo=[]) {
    const [format,filter,from,until,reportName]=reportInfo
    this.reportType=reportName
    this.isAWL = false;
    this.AWLlist = null;
    this.visitPat = null
    this.patList = null;
    if(reportInfo.length){
      this.isReports = true;
      this.reportName = reportName;
      this.hmostype = "Leumit";
      this.exServ.searchReport = {start:from, end:until, type:reportName, hmo:"Leumit"}
      this.exServ.reportSearch(format, filter, from,until, this.loginService.doctor).subscribe((data) => {
        if(filter==="missing"){
          this.withoutPrn=true
        }
        if(filter==="sent"){
          this.withoutPrn=false
        }
        this.examList=null
    if(format==='statistics'){
      this.statisticsForDoctors=data;
      this.reportList =null
      this.showIcons=true
      this.examTypeCounts={};
      return
    }
        this.reportList = data;
      if(this.reportList.length>0) {
        this.countExamTypes();
       this.showIcons=true
       this.statisticsForDoctors=null
      }
      else{
        this.showIcons=false
        this.examTypeCounts={}
        this.reportList=[];
        this.statisticsForDoctors=[]
      }
      
      
    })
      
      
    }else{
      this.exServ.searchReport = null;
      this.exServ.examSearch(this.fromd, this.tod, this.examVals, this.loginService.doctor).subscribe((data) => {
        this.examList = data;
        this.reportList=null
        this.showIcons=false
        this.statisticsForDoctors=null
        this.examTypeCounts={}
      }, (error) => {
        console.log(error);
      })
    }
  }

  
  countExamTypes() {
    for (let exam of this.reportList) {
      if (exam.ExamType in this.examTypeCounts) {
        this.examTypeCounts[exam.ExamType]++;
      } else {
        this.examTypeCounts[exam.ExamType] = 1;
      }
    }
    return this.examTypeCounts;
  }

  goPatient(pid: number) {
    this.patservice.getPatient(pid, (s) => {
      if (s)
        this.rt.navigate(['/exams'], { state: { data: this.patservice.currentPatient } });
    });

  }

  goPatientFollowUp(pid: number) {
    this.patservice.getPatient(pid, (s) => {
      if (s)
        this.rt.navigate(['/follow-up'], { state: { data: this.patservice.currentPatient } });
    });
  }

  getLocked(exam) {
    var now = new Date();
    var lockedTime = new Date(exam.Locked);
    var lock = true;
    if (now.getDate() !=  lockedTime.getDate() ||  now.getMonth() != lockedTime.getMonth() || now.getFullYear() != lockedTime.getFullYear() || (now.getHours() - lockedTime.getHours()) > 1) {
     lock = false;
    }
    if ((exam.Locked || lock) && exam.LockedBy && !this.loginService.isAdmin)
      return 'locked';
    return 'unlocked';
  }

  viewonly() {
    this.exServ.goExam(this.goEx.preg, this.goEx.exam, this.goEx.etype, true);
  }

  goExam(exam/*PID: number, examID: number, PregIndex: number, etype: string,examtime: string*/) {
    let preg = exam.PregIndex ? { AutoIndex: exam.PregIndex } : null;
    let id = exam.AutoIndex;
    this.patservice.getPatient(exam.PID, (s) => {
      if (s) {
        if (exam.Locked && exam.LockedBy && !this.loginService.isAdmin)
          return;
        let etime = new Date(exam.CreationTime);
        let now = new Date();
        if (exam.ExamType != 'Emergency Room' && ((! this.exServ.examClosed(etime) && !this.loginService.isCardio) || (this.loginService.isCardio && !exam.ClosedDate)) && (this.loginService.userType == 'doctor' || this.loginService.userType == 'visitingdoc' || this.loginService.userType == 'tech')) {
          this.showLogin = true;

          this.goEx = { preg: preg, exam: exam, etype: exam.ExamType };
          if (this.loginService.ClinicID)
            this.startClinic = this.loginService.ClinicID;
        }
        else

          this.exServ.goExam(preg, exam, exam.ExamType, s.PID);
      }
    });
  }

  goVisit(PID, PregIndex) {
    this.patservice.getPatient(PID, (s) => {
      if (s) { 
        this.pregnancyService.pregID = PregIndex
        this.rt.navigate(['/follow-up/visit'], { state: { data: this.patservice.currentPatient } });
      }
    });
  }

  // ************************************************************************************************
  searchClosed($event: any[]) {
    this.examTypeCounts={}

    if($event && $event.length >= 3 &&$event[2] == 'Travel Report'){
      let fromd = new Date($event[0]);
      fromd.setHours(fromd.getHours() + 5);
      let froms = fromd.toISOString().slice(0, 10);
      let untild = new Date($event[1]);
      untild.setHours(untild.getHours() + 29);
      let untils = untild.toISOString().slice(0, 10);
      window.open('/api/stats/travelreport/'+froms+'/'+untils);
      return;
    }
    
    if(!$event){
      this.openEsearch=false
    } else {
      if(this.reportsNames.includes($event[4])){
        this.examSearch($event);
      } else if ($event && $event.length > 3 && $event[2] !== "PregnancyFollowUp") {
        this.fromd = $event[0];
        this.tod = $event[1];
        this.examVals = $event[2];
        this.examMode = $event[3];
        this.examSearch();
      } else if  ($event[2] === "PregnancyFollowUp") {
        let fromDate = new Date($event[0]);
        let toDate = new Date($event[1])
        this.vistsSearch(fromDate, toDate);
      }
    }
    this.showIcons = true;
    this.openEsearch = false;
  }




  setAWL(pat) {
    if ((!this.loginService.ishospital || this.loginService.ishospital == 'belinson') && pat.Status == 'Waiting') {
      try {
        let edate = new Date(pat.ExamDate)
        this.awlTime = edate.getHours() + ':' + (edate.getMinutes() < 10 ? '0' : '') + edate.getMinutes();
      }
      catch {
        this.awlTime = null;
      }
      this.awlExamType = pat.ExamCode;
      
      this.awlLMP = pat.ExamLMP;
      this.awlComment = pat.Reason ? pat.Reason : null;
    }
    this.chosenAWL = pat.AWLID;
  }
  lmpGA(lmp) {
    if (!lmp)
      return "";
    let dt = new Date();
    let days = Math.floor((dt.getTime() - Date.parse(lmp)) / (1000 * 60 * 60 * 24));
    return Math.floor(days / 7) + "w " + (Math.floor(days) % 7) + "d"
  }

  saveComment(comment: string, AWLID: number) {
    this.patservice.saveAWLComment(comment, AWLID);
  }

  getcard(){
    this.card='';
    setTimeout(()=> {this.swipeField.nativeElement.focus();},100);
  }

  proccesscard(evt){
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) 
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    else 
        isEscape = (evt.keyCode === 27);
    
    if (isEscape)
      this.swiping=false;
    var card = this.card;
    if(this.card && this.card.length > 26 && this.card.includes('8=47'))//8=47 is the start of a macabi card
      card = this.card.substring(this.card.indexOf('8=47'));
    if(card && card.length > 26 && ! this.cardtzsearch && ! this.showpayment){
      this.cardtzsearch = card.substring(7,16);
      this.idcode = card.substring(6,7);
      this.expcode = card.substring(16,20);
      if(this.AWLStatus != 'All')
        this.AWLStatus = 'All';
      this.AWL(false);
    }
  }

  barscan(evt){
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) 
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    else 
        isEscape = (evt.keyCode === 27);
    
    if (isEscape)
      this.swiping=false;
    
    if(this.card.trim().length >= 12 && (! this.last_search_time || Date.now() > this.last_search_time + 5000)){//belinson addmission code
      this.last_search_time = Date.now();
       this.patservice.findPatBelinson(this.card.trim()).subscribe( (patient: Patient) => {
         if(patient){
          this.patList = [];
           this.isAWL = false;
           let pat = new Patient()
           pat.asignVals(patient)
           this.patList[0] = pat;
            this.setAWL(pat);
           this.selectedPatient=pat;
           this.swiping = false;
           this.card = '';
           this.awlTime = new Date().toTimeString().substring(0,5);
         }
        
       },(err)=> {
        console.log(err)
      });
    }
  }

  paymentNoCard(){
    this.showpayment = this.selectedPatient.ID;
    while(this.showpayment.length < 9)
      this.showpayment = '0' + this.showpayment.toString();
    this.idcode = '0';
    this.expcode = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sub.unsubscribe();
    this.patservice.closeWebSocket();
  }

  getStatus(status: string):string{//for cardio
    if(this.loginService.isCardio){
      switch(status){
        case 'Completed':
          return 'Performed';
        case 'Waiting':
          return 'Scheduled';
        case 'Received':
          return 'Waiting';
        default:
          return status;
      }
    }
    else
      return status;
  }

  setExamCode(pat){
    var saveTitle = this.patservice.setExamCode(this.changedExamCode, pat.AWLID);
    saveTitle.subscribe((res) =>{
      console.log(res);
      this.changedExamCode = null;
      this.AWL()
    })
  }

  setPatAwlid(pat){
    if(this.loginService.isGastro){
      this.gfs.paitentLoaded$.next(pat);
    }
  }

  filterExams(exams, type, hmo = null){
    if(!exams)
      return []
    if(hmo)
      return exams.filter((i) => i.ExamType == type && (i.HMO == hmo && i.PRN))
    return exams.filter((i) => i.ExamType == type)
  }

  export(){
    var table;
    var strTable;
    var items =['CreationTime','ID', 'First', 'Last', 'HMO', 'ExamType']
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      strTable = '<tr>'
      strTable += '<td> חיפוש לפי</td><td>'+this.getType(this.exServ.searchReport.type)+'</td><td>'+this.getHMO(this.exServ.searchReport.hmo)+'</td>'
      strTable +='</tr><tr></tr>'
      strTable +='<tr><td>תאריכים</td><td>'+this.getDate(this.exServ.searchReport.start)+'</td><td>'+this.getDate(this.exServ.searchReport.end)+'</td></tr>'

      if(this.examList.length > 0){
        for(let name of this.examNames){
          var examTable ='';
          examTable +='<tr>';
          table = document.createElement('table');
          examTable +=`<td>${name}</td>`
          examTable +='</tr>'
          examTable +='<tr>';
          for(let item of items){
            examTable +=`<td><strong>${item}</strong></td>`
          }
          examTable +='</tr>'
          examTable +='<tr>';
          for(let exam of this.filterExams(this.examList, name)){

            for(let item of items){
              var date;
              if(item == 'CreationTime'){
                date = exam[item].replace('T', ' ').replace('Z', '')

                examTable +=`<td>${date}</td>`
              }else{
                examTable +=`<td>${exam[item]}</td>`
              }

            }
            examTable +='</tr>'

          }
          table.innerHTML = strTable +examTable;
          if(this.filterExams(this.examList,name).length > 0){
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
            XLSX.utils.book_append_sheet(wb, ws, name);
          }
        }
        XLSX.writeFile(wb, new Date().toISOString().split('T')[0]+'.xlsx');
        this.loginService.exportLoading = false;
        this.showLogin = false;
        this.sub.unsubscribe();
      }
  }

  sendMacabi(){
      this.patservice.sendMacabi(this.fromd['_i'].year + '-' + (this.fromd['_i'].month + 1 < 10 ? '0': '') + (this.fromd['_i'].month + 1) +'-' + (this.fromd['_i'].date < 0 ? '0': '') + this.fromd['_i'].date,
      this.tod['_i'].year + '-' + (this.tod['_i'].month + 1 < 10 ? '0': '') + (this.tod['_i'].month + 1) + '-'+ (this.tod['_i'].date < 0 ? '0': '') + this.tod['_i'].date).subscribe((data)=>{
        this.sentMacabi = true;
      },(err)=>{
        console.log(err);
      });
    
  }

  getHMO(hmo){
    switch (hmo) {
      case 'Macabi':
        return 'מכבי';
      case 'Leumit':
        return 'לאומית';
      case 'Meuhedet':
        return 'מאוחדת';
      case 'Clalit':
        return 'כללית';
      case 'Private':
        return 'פרטי';
      case 'IDF':
        return 'צהל';
      case 'null':
        return 'לא שוייכו';
      default:
        return "הכל"
    }
  }
  getType(type){
    
    switch (type) {
      case 'Macabi':
        return 'בדיקות שנשלחו למכבי'
      case 'HMO':
        return 'קופת חולים'
      case 'Refferelnum':
        return "מס' הפניה חסר"
      default:
        return "הכל"
    }
  }
  getDate(date){
    var temp = new Date(date)
    var str = temp.getDate()+'/'+(temp.getMonth()+1)+'/'+temp.getFullYear()
    return str
  }

  startForm(pat) {
    this.exServ.patServ.setPat(pat);
    this.exServ.concentForm = null;
    var res = this.exServ.getConcentForms(pat.PID);
    res.subscribe((data:any) =>{
      console.log(data);
      if(!data.hasOwnProperty("empty")){

        var temp = data.filter((i) => i.ExamType == pat.ExamCode && !i.ExamID)
        if(pat.ExamCode.split(" ")[1] == 'Anatomy' || pat.ExamCode.split(" ")[1] == 'Trimester' )
        var temp = data.filter((i) => i.ExamType == pat.ExamCode.split(" ")[0] && !i.ExamID)
        if(temp.length > 0){
          this.concentForm = temp[0]
        }else{
          this.exServ.concentForm = null
          this.exServ.examID = null;
          this.exServ.pregId = null;
          this.exServ.pregnancies = null;
          this.concentForm = null;
        }
      }else{
        this.exServ.examID = null;
        this.exServ.pregId = null;
        this.exServ.pregnancies = null;
        this.exServ.concentForm = null
        this.concentForm = null;
      }
      this.inform =true;
    })
  }
  getDoctors(){
    return this.loginService.clinicUsers
  }

  /*reassign(){//move pat and todaylist to another doctor
    this.toreassign.UID = this.toreassign.nUID;
    this.toreassign.nUid = this.toreassign.UID;
    this.patservice.reassign(this.toreassign).subscribe((data)=>{
      this.toreassign = null;
      this.AWL();
    },(error)=>{
      this.toreassign = null;
      this.AWL();
      console.log(error)
    })
  } *///geting rid of this


  closCalForm(event){
    this.inform = false;
  }

  getDbName(i,str){
    var t = i.replace("-", "").split(" ")[0]

    return str+t
  }

  exportExcel(){
   if(this.reportList?.length > 0) {
    return this.exportExcelExams()
  }
  if (this.statisticsForDoctors?.length > 0) {
    
    return this.exportExcelStatistics()
  }
  return
}

private exportExcelStatistics (){
  const statisticForLeumit=this.statisticsForDoctors.map((doctorStatistic)=>{
  
    return {
      'Leumit with PRN':doctorStatistic.leumitWithoutPrn,
      'Leumit without PRN':doctorStatistic.leumitWithPrn,
      'Leumit Total':doctorStatistic.totalExamsForLeumit,
      'By other HMO’s':doctorStatistic.otherHmo,
      'No HMO indication':doctorStatistic.notRecognizeHmo,
      'Total exams performed':doctorStatistic.totalExams,
      'Doctor':doctorStatistic.englishName

    }

  })
  const sheetName ='statisticForLeumitDoctor';
  const dateEnd=this.statisticsForDoctors[0].until.substring(0,10)
  const fileName =`Exams Distribution by HMO’s for the period starting on  ${this.statisticsForDoctors[0].from}  and ending on  ${dateEnd}.xlsx`;
  const worksheet = XLSX.utils.json_to_sheet(statisticForLeumit);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, fileName);


}

private exportExcelExams(){
  const examsToExport=this.reportList.map((exam)=>{
  const CreationTime=exam.CreationTime.substring(0,10)

  return {
    ExamType:exam.ExamType,
    Name: `${exam.First} ${exam.Last} `,
    CreationTime,
    patientId:exam.ID,
    Doctor:exam.Name
  }


  })
  const sheetName ='Exams';
  const fileName =`${this.reportType}.xlsx`;
  const worksheet = XLSX.utils.json_to_sheet(examsToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, fileName);

}

genaratePdf(){

}

getPDF(){
  
  }

  
  redirectAdministration(type) {
    this.http.post('/api/administration', {type}).subscribe((data: string) => {
      if(this.loginService.userType == "secretary" ){
        window.location.href = data;

      }
      else{
        window.open(data, '_blank');
      }
    })
  }

  isSearchDisabled(): boolean {
    return !(this.id.trim() || this.first.trim() || this.last.trim() || this.phone.trim());
  }
}
