export const createLabSerologyTestNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  GA,
  Name: "",
  Date,
  IgM: "",
  IgG: "",
  Avidity: "",
  Comments: "",
});

export const labSerologyTestOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  GA: "",
  Name: "",
  Date: null,
  IgM: "",
  IgG: "",
  Avidity: "",
  Comments: "",
};

export const createLabCoombsNewData = (PID: number, PregIndex: number, ID: string, Date: Date = null) => ({
  PID,
  PregIndex,
  ID,
  Date,
  Comments: "",
});

export const labCoombsOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  Comments: "",
};

export const createLabAntiDNewData = (PID: number, PregIndex: number, ID: string, Date: Date = null) => ({
  PID,
  PregIndex,
  ID,
  Date,
  Comments: "",
});

export const labAntiDOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  Comments: "",
};

export const createLabGlucose50NewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  GCT60_140: "",
  Comments: "",
});

export const labGlucose50OriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: null,
  GCT60_140: "",
  Comments: "",
};

export const createLabGlucose75NewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  GTT0_110: "",
  GTT120_140: "",
  Comments: "",
});

export const labGlucose75OriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: null,
  GTT0_110: "",
  GTT120_140: "",
  Comments: "",
};

export const createLabGlucose100NewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  GTT0_95: "",
  GTT60_180: "",
  GTT120_150: "",
  GTT180_140: "",
  Comments: "",
});

export const labGlucose100OriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: null,
  GTT0_95: "",
  GTT60_180: "",
  GTT120_150: "",
  GTT180_140: "",
  Comments: "",
};

export const createLabHBA1CNewData = (PID: number, PregIndex: number, ID: string, Date: Date = null) => ({
  PID,
  PregIndex,
  ID,
  Date,
  Value: "",
  Comments: "",
});

export const labHBA1COriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  Value: "",
  Comments: "",
};

export const createLabPPGNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  Meal: "",
  Value: "",
  Hours: "",
  Comments: "",
});

export const labPPGOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: null,
  Meal: "",
  Value: "",
  Hours: "",
  Comments: "",
};

export const createLabKaryotypeNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  Type: "",
  Status: "",
});

export const labKaryotypeOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  GA: null,
  Meal: "",
  Type: "",
  Status: "",
};
