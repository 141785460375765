<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up" aria-label="Back to History" matTooltip="Back to History">Follow Up</button> /
        US Scans
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close US Scans"
          matTooltip="Close US Scans"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->

  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <div style="display: flex; gap: 1em" class="fetus-selector">
        <button
          *ngFor="let num of getFetusesToRender()"
          (click)="selectedInstanceNumber = num"
          [class.selected]="selectedInstanceNumber === num"
        >
          Fetus {{ num }}
        </button>
      </div>

      <!-- ================ Scans Details (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Scans Details</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> Sonographer </span>
              </th>
              <th class="th-clear">
                <span> Status </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let scan of getFilteredScans()" [ngClass]="scan.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="scan.Name"
                  [disabled]="!scan.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuScans'"
                  [isEditing]="scan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="scanDatePicker"
                      [min]="today"
                      [(ngModel)]="scan.Date"
                      [disabled]="!scan.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #scanDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!scan.isEditing ? 'disabled' : ''"
                      (click)="scanDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="scan.GA"
                  [disabled]="!scan.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuScans'"
                  [isEditing]="scan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="scan.Sonographer"
                  [disabled]="!scan.isEditing"
                  [inputName]="'Sonographer'"
                  [tableName]="'fuScans'"
                  [isEditing]="scan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="scan.Status"
                  [disabled]="!scan.isEditing"
                  [inputName]="'Status'"
                  [tableName]="'fuScans'"
                  [isEditing]="scan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="scan.Comments"
                  [disabled]="!scan.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuScans'"
                  [isEditing]="scan.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!scan.isEditing" (click)="editRow(scan)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="scan.isEditing"
                    (click)="updateRow('fuScans', scan, ['Name', 'Date', 'GA', 'Sonographer', 'Status', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuScans', scan)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.scansNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuScans'"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.scansNewData.Date"
                      [matDatepicker]="scanNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #scanNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="scanNewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.scansNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuScans'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.scansNewData.Sonographer"
                  [inputName]="'Sonographer'"
                  [tableName]="'fuScans'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.scansNewData.Status"
                  [inputName]="'Status'"
                  [tableName]="'fuScans'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.scansNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuScans'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.scansNewData.Name ||
                    !followUpService.scansNewData.Date ||
                    !followUpService.scansNewData.GA ||
                    !followUpService.scansNewData.Sonographer ||
                    !followUpService.scansNewData.Status ||
                    !followUpService.scansNewData.Comments
                  "
                  (click)="saveNewRow('fuScans', followUpService.scansNewData, scansOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================  Scans Details (End) ================ -->

      <!-- ================ Measurements (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Measurements</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> BPD </span>
              </th>
              <th class="th-clear">
                <span> HC </span>
              </th>
              <th class="th-clear">
                <span> AC </span>
              </th>
              <th class="th-clear">
                <span> FL </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr
              *ngFor="let measurement of getFilteredMeasurements()"
              [ngClass]="measurement.isEditing ? 'tr-isActive' : ''"
            >
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="measDatePicker"
                      [(ngModel)]="measurement.Date"
                      [min]="today"
                      [disabled]="!measurement.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #measDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!measurement.isEditing ? 'disabled' : ''"
                      (click)="measDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="measurement.GA"
                  [disabled]="!measurement.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuMeasurements'"
                  [isEditing]="measurement.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="measurement.BPD"
                  [disabled]="!measurement.isEditing"
                  [inputName]="'BPD'"
                  [tableName]="'fuMeasurements'"
                  [isEditing]="measurement.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="measurement.HC"
                  [disabled]="!measurement.isEditing"
                  [inputName]="'HC'"
                  [tableName]="'fuMeasurements'"
                  [isEditing]="measurement.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="measurement.AC"
                  [disabled]="!measurement.isEditing"
                  [inputName]="'AC'"
                  [tableName]="'fuMeasurements'"
                  [isEditing]="measurement.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="measurement.FL"
                  [disabled]="!measurement.isEditing"
                  [inputName]="'FL'"
                  [tableName]="'fuMeasurements'"
                  [isEditing]="measurement.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!measurement.isEditing" (click)="editRow(measurement)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="measurement.isEditing"
                    (click)="updateRow('fuMeasurements', measurement, ['Date', 'GA', 'BPD', 'HC', 'AC', 'FL'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuMeasurements', measurement)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.measurementsNewData.Date"
                      [matDatepicker]="measNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #measNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="measNewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.measurementsNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuMeasurements'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.measurementsNewData.BPD"
                  [inputName]="'BPD'"
                  [tableName]="'fuMeasurements'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.measurementsNewData.HC"
                  [inputName]="'HC'"
                  [tableName]="'fuMeasurements'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.measurementsNewData.AC"
                  [inputName]="'AC'"
                  [tableName]="'fuMeasurements'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.measurementsNewData.FL"
                  [inputName]="'FL'"
                  [tableName]="'fuMeasurements'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.measurementsNewData.Date ||
                    !followUpService.measurementsNewData.GA ||
                    !followUpService.measurementsNewData.BPD ||
                    !followUpService.measurementsNewData.HC ||
                    !followUpService.measurementsNewData.AC ||
                    !followUpService.measurementsNewData.FL
                  "
                  (click)="saveNewRow('fuMeasurements', followUpService.measurementsNewData, measurementsOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Measurements (End) ================ -->
    </div>
  </main>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
