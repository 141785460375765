import { Component, OnInit } from "@angular/core";
import { Patient } from "../patients/patient";
import { Subscription } from "rxjs";
import {
  labSerologyTestOriginalData,
  labCoombsOriginalData,
  labAntiDOriginalData,
  labGlucose50OriginalData,
  labGlucose75OriginalData,
  labGlucose100OriginalData,
  labHBA1COriginalData,
  labPPGOriginalData,
  labKaryotypeOriginalData,
} from "./data/data-storage";
import { FollowUpService } from "../follow-up.service";
import { PregnancyService } from "../pregnancy.service";
import { PatientsService } from "../patients.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "../login.service";

@Component({
  selector: "app-follow-up-lab",
  templateUrl: "./follow-up-lab.component.html",
  styleUrls: ["./follow-up-lab.component.scss"],
})
export class FollowUpLabComponent implements OnInit {
  public showPatientDetailsForm: boolean = false;
  public patient: Patient;
  // Is selected row in edit mode:
  public isEditing: boolean = false;

  private pregID: number;

  // Data structures: (For Resetting):
  public labSerologyTestOriginalData = { ...labSerologyTestOriginalData };
  public labCoombsOriginalData = { ...labCoombsOriginalData };
  public labAntiDOriginalData = { ...labAntiDOriginalData };
  public labGlucose50OriginalData = { ...labGlucose50OriginalData };
  public labGlucose75OriginalData = { ...labGlucose75OriginalData };
  public labGlucose100OriginalData = { ...labGlucose100OriginalData };
  public labHBA1COriginalData = { ...labHBA1COriginalData };
  public labPPGOriginalData = { ...labPPGOriginalData };
  public labKaryotypeOriginalData = { ...labKaryotypeOriginalData };

  // Data:
  public labSerologyTestData = [];
  public labCoombsData = [];
  public labAntiDData = [];
  public labGlucose50Data = [];
  public labGlucose75Data = [];
  public labGlucose100Data = [];
  public labHBA1CData = [];
  public labPPGData = [];
  public labKaryotypeData = [];

  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;

  today: Date = new Date();

  constructor(
    public followUpService: FollowUpService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
    this.followUpService.initializeNewData();

    this.patient = this.patientsService.getPat();
    this.pregID = this.pregnancyService.pregID;

    // Get the number of fetuses for the latest pregnancy:
    this.fetusNumberSub = this.followUpService.getFetusesNumber(this.pregID).subscribe((data) => {
      this.fetusesNumber = data;
    });

    // Get the obstetrical formula for the latest pregnancy:
    this.obsFormulaSub = this.followUpService.getObstetricalFormula(this.pregID).subscribe((data) => {
      this.obstetricalFormula = data;
    });

    this.getData("fuLabSerologyTest", "labSerologyTestData");
    this.getData("fuLabCoombs", "labCoombsData");
    this.getData("fuLabAntiD", "labAntiDData");
    this.getData("fuLabGlucose50", "labGlucose50Data");
    this.getData("fuLabGlucose75", "labGlucose75Data");
    this.getData("fuLabGlucose100", "labGlucose100Data");
    this.getData("fuLabHBA1C", "labHBA1CData");
    this.getData("fuLabPPG", "labPPGData");
    this.getData("fuLabKaryotype", "labKaryotypeData");
  }

  ngOnDestroy() {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }

    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  async getData(table: string, placementVariable: string, pregIndex: number = this.pregID) {
    const data = await this.followUpService.getData(table, pregIndex);
    this[placementVariable] = data["data"];
  }

  editRow(rowData: any) {
    rowData.isEditing = true;
  }

  resetNewData(newData: any, originalData: any) {
    const fieldsToPreserve = ["PID", "PregIndex", "ID"];

    for (const key in newData) {
      if (!fieldsToPreserve.includes(key)) {
        if (key === "Date") {
          newData[key] = new Date();
        } else if (key === "GA") {
          newData[key] = this.patient?.getGA() || "";
        } else {
          newData[key] = originalData && originalData[key] !== undefined ? originalData[key] : "";
        }
      }
    }
  }

  async updateRow(table: string, rowData: any, columnsToUpdate: string[]) {
    try {
      const dataToUpdate = columnsToUpdate.reduce((obj, col) => {
        obj[col] = rowData[col];
        return obj;
      }, {});

      const result = await this.followUpService.updateData(table, dataToUpdate, rowData.AutoIndex);
      if (result["status"] === "ok") {
        rowData.isEditing = false;
      }
    } catch (error) {
      console.error("Error updating the row", error);
    }
  }

  async deleteRow(table: string, rowData: any) {
    try {
      const result = await this.followUpService.deleteData(table, rowData.AutoIndex);
      if (result["status"] === "ok") {
        switch (table) {
          case "fuLabSerologyTest":
            this.labSerologyTestData = this.labSerologyTestData.filter((labTest) => labTest !== rowData);
            break;
          case "fuLabCoombs":
            this.labCoombsData = this.labCoombsData.filter((coomb) => coomb !== rowData);
            break;
          case "fuLabAntiD":
            this.labAntiDData = this.labAntiDData.filter((antid) => antid !== rowData);
            break;
          case "fuLabGlucose50":
            this.labGlucose50Data = this.labGlucose50Data.filter((glucose50) => glucose50 !== rowData);
            break;
          case "fuLabGlucose75":
            this.labGlucose75Data = this.labGlucose75Data.filter((glucose75) => glucose75 !== rowData);
            break;
          case "fuLabGlucose100":
            this.labGlucose100Data = this.labGlucose100Data.filter((glucose100) => glucose100 !== rowData);
            break;
          case "fuLabHBA1C":
            this.labHBA1CData = this.labHBA1CData.filter((hba1c) => hba1c !== rowData);
            break;
          case "fuLabPPG":
            this.labPPGData = this.labPPGData.filter((ppg) => ppg !== rowData);
            break;
          case "fuLabKaryotype":
            this.labKaryotypeData = this.labKaryotypeData.filter((karyotype) => karyotype !== rowData);
            break;
          default:
            console.error(`Table ${table} not handled.`);
            break;
        }
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  }

  async saveNewRow(table: string = "", rowData: any, originalData: any = null) {
    try {
      const result = await this.followUpService.saveData(table, rowData);

      if (result["status"] === "ok") {
        this.resetNewData(rowData, originalData);
        await this.handleDataFetch(table);
      }
    } catch (error) {
      console.error("Error during the save new row", error);
    }
  }

  async handleDataFetch(table: string) {
    switch (table) {
      case "fuLabSerologyTest":
        await this.getData("fuLabSerologyTest", "labSerologyTestData");
        break;
      case "fuLabCoombs":
        await this.getData("fuLabCoombs", "labCoombsData");
        break;
      case "fuLabAntiD":
        await this.getData("fuLabAntiD", "labAntiDData");
        break;
      case "fuLabGlucose50":
        await this.getData("fuLabGlucose50", "labGlucose50Data");
        break;
      case "fuLabGlucose75":
        await this.getData("fuLabGlucose75", "labGlucose75Data");
        break;
      case "fuLabGlucose100":
        await this.getData("fuLabGlucose100", "labGlucose100Data");
        break;
      case "fuLabHBA1C":
        await this.getData("fuLabHBA1C", "labHBA1CData");
        break;
      case "fuLabPPG":
        await this.getData("fuLabPPG", "labPPGData");
        break;
      case "fuLabKaryotype":
        await this.getData("fuLabKaryotype", "labKaryotypeData");
        break;
      default:
        console.error("Unknown table:", table);
    }
  }
}
