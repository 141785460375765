import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { OptionService } from "../option.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { OptionsManagerComponent } from "../options-manager/options-manager.component";

@Component({
  selector: "app-free-text-select",
  templateUrl: "./free-text-select.component.html",
  styleUrls: ["./free-text-select.component.scss"],
})
export class FreeTextSelectComponent implements OnInit, OnChanges {
  @Output() inputValueChange = new EventEmitter<string>();
  @Input() inputValue: string = "";
  @Input() disabled: boolean = false;
  @Input() inputName: string = "";
  @Input() tableName: string = "";
  @Input() isEditing: boolean = false;
  @Input() width: string = "100%";

  myControl!: FormControl;
  options: string[] = [];
  filteredOptions!: Observable<string[]>;

  constructor(private dialog: MatDialog, private optionService: OptionService) {}

  ngOnInit() {
    this.myControl = new FormControl({ value: this.inputValue, disabled: this.disabled });

    this.inputValueChange.emit(this.inputValue);

    if (!this.disabled) {
      this.fetchOptions();
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(this.inputValue),
      map((value) => {
        this.inputValueChange.emit(value);
        return this._filter(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue && !changes.inputValue.firstChange) {
      this.myControl.setValue(changes.inputValue.currentValue, { emitEvent: false });
    }

    if (changes.isEditing && this.isEditing && !this.disabled) {
      this.fetchOptions();
    }

    if (this.myControl) {
      if (this.disabled) {
        this.myControl.disable();
      } else {
        this.myControl.enable();
      }
    }
  }

  private fetchOptions(): Promise<void> {
    return new Promise((resolve) => {
      this.optionService.getOptions(this.tableName, this.inputName).subscribe((data) => {
        this.options = data;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(this.inputValue),
          map((value) => {
            this.inputValueChange.emit(value);
            return this._filter(value);
          })
        );

        resolve();
      });
    });
  }

  private _filter(value: string | number | null): string[] {
    if (value === null) {
      return [];
    }

    const filterValue = value.toString().toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  openOptionsManager() {
    this.fetchOptions().then(() => {
      const dialogRef = this.dialog.open(OptionsManagerComponent, {
        width: "400px",
        data: {
          options: [...this.options],
          tableName: this.tableName,
          fieldName: this.inputName,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.options = result;
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(this.inputValue),
            map((value) => {
              this.inputValueChange.emit(value);
              return this._filter(value);
            })
          );
        }
      });
    });
  }
}
