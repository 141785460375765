import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SearchtextService {
  text='';
  exams=[];
  filteredExams=[];

  constructor(private http: HttpClient) {}

  search(search) {
    return this.http.post("/api/stats/searchtext", { search: search });
  }


  
  clearSearch(){
    this.exams=[];
    this.text=""
    this.filteredExams=[];
  }
}
