<div class="wrapper">
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel" style="justify-content: space-between">
      <h3>AWEB Data Center</h3>
      <ng-container
        *ngFor="let examType of AdvancedSearch.examTypeCounts | keyvalue"
      >
        <h4>{{ examType.key }}: {{ examType.value }}</h4>
      </ng-container>
      <h4 *ngIf="AdvancedSearch.matchExams.length">
        Number of Exams: {{ AdvancedSearch.matchExams.length }}
      </h4>
      <div class="buttons-container">
        <a
          class="header-menu-link icon-confirm"
          style="cursor: pointer; margin-left: 0em"
          aria-label="Confirm"
          (click)="exit()"
          matTooltip="Close Data Center and Open AWL"
        ></a>
        <img
          style="cursor: pointer"
          matTooltip="Download CVS"
          *ngIf="AdvancedSearch.matchExams.length"
          (click)="generateExcelFile()"
          height="31em"
          width="31em"
          src="../../assets/icons/file.svg"
        />
        <button
          style="border: 2px solid white"
          matTooltip="Clear"
          (click)="clearSearchSelect()"
          class="clear-btn"
          type="button"
        >
          Clear
        </button>
      </div>
    </div>

    <!-- ========================== Private Clinics (Start) ========================== -->
    <!-- <div *ngIf="!loginService.isSheba" class="search-wrapper">
      <select
        class="t-select t-field"
        [(ngModel)]="selectedCategory"
        (ngModelChange)="
          selectedCategory2 = null;
          selectedCategory3 = null;
          selectedCategory4 = null;
          showButton = false;
          msgNoResults = false
        "
        style="
          margin-block: 0.3em;
          text-align-last: left;
          direction: ltr;
          width: fit-content;
        "
      >
        <ng-container *ngFor="let option of AdvancedSearch.searchCategory">
          <option [ngValue]="option">{{ option.name }}</option>
        </ng-container>
      </select>
      <select
        *ngIf="selectedCategory"
        class="t-select t-field"
        [(ngModel)]="selectedCategory2"
        (ngModelChange)="
          toggleButton($event);
          selectedCategory3 = null;
          selectedCategory4 = null;
          showButton = false;
          msgNoResults = false
        "
        style="
          margin-block: 0.3em;
          text-align-last: left;
          direction: ltr;
          width: fit-content;
        "
      >
        <ng-container
          *ngFor="let option of bottomService.getList(selectedCategory.values)"
        >
          <option [ngValue]="option[0]">{{ option[2] }}</option>
        </ng-container>
      </select>
      <select
        *ngIf="selectedCategory2"
        class="t-select t-field"
        [(ngModel)]="selectedCategory3"
        (ngModelChange)="
          toggleButton($event); selectedCategory4 = null; msgNoResults = false
        "
        style="
          margin-block: 0.3em;
          text-align-last: left;
          direction: ltr;
          width: fit-content;
        "
      >
        <ng-container
          *ngFor="let option of diagnosisService.getList(selectedCategory2)"
        >
          <option [ngValue]="option[0]">{{ option[2] }}</option>
        </ng-container>
      </select>
      <select
        *ngIf="diagnosisService.getList(selectedCategory3).length"
        class="t-select t-field"
        [(ngModel)]="selectedCategory4"
        (ngModelChange)="toggleButton($event); msgNoResults = false"
        style="
          margin-block: 0.3em;
          text-align-last: left;
          direction: ltr;
          width: fit-content;
        "
      >
        <ng-container
          *ngFor="let option of diagnosisService.getList(selectedCategory3)"
        >
          <option [ngValue]="option[0]">{{ option[2] }}</option>
        </ng-container>
      </select>
    </div> -->
    <!-- ========================== Private Clinics (End) ========================== -->

    <!-- ========================== Sheba Hospital (Start) ========================== -->
    <div *ngIf="loginService.isSheba" class="search-wrapper">
      <mat-form-field class="border-red" style="width: 90%">
        <mat-label> What you are looking for </mat-label>
        <input
          class="search-input"
          type="text"
          matInput
          [matAutocomplete]="auto"
          [(ngModel)]="AdvancedSearch.searchText"
          (ngModelChange)="filterOptions()"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptions"
            [value]="option[5]"
          >
            {{ option[5] }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button
        matTooltip="Search"
        *ngIf="isOptionSelected"
        class="advanced-search-btn"
        type="button"
        (click)="searchExamsByFreeText()"
      >
        Search
      </button>
    </div>

    <div *ngIf="loginService.isSheba" class="search-wrapper">
      <div>
        <span>Structured Search: &nbsp; </span>

        <select
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory"
          (ngModelChange)="
            AdvancedSearch.selectedCategory2 = null;
            AdvancedSearch.selectedCategory3 = null;
            AdvancedSearch.selectedCategory4 = null;
            AdvancedSearch.selectedCategory5 = null;
            AdvancedSearch.selectedCategory6 = null;
            showButton = false;
            msgNoResults = false
          "
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <ng-container *ngFor="let option of AdvancedSearch.searchCategory">
            <option [ngValue]="option">{{ option.name }}</option>
          </ng-container>
        </select>

        <select
          *ngIf="AdvancedSearch.selectedCategory"
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory2"
          (ngModelChange)="
            toggleButton($event);
            AdvancedSearch.selectedCategory3 = null;
            AdvancedSearch.selectedCategory4 = null;
            AdvancedSearch.selectedCategory5 = null;
            AdvancedSearch.selectedCategory6 = null;
            msgNoResults = false
          "
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <option></option>
          <ng-container
            *ngFor="
              let option of AdvancedSearch.getList(
                AdvancedSearch.selectedCategory.values
              )
            "
          >
            <option [ngValue]="option[0]">{{ option[2] }}</option>
          </ng-container>
        </select>

        <select
          *ngIf="
            diagnosisService.getList(AdvancedSearch.selectedCategory2).length
          "
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory3"
          (ngModelChange)="
            toggleButton($event);
            AdvancedSearch.selectedCategory4 = null;
            AdvancedSearch.selectedCategory5 = null;
            AdvancedSearch.selectedCategory6 = null;
            msgNoResults = false
          "
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <option></option>
          <ng-container
            *ngFor="
              let option of diagnosisService.getList(
                AdvancedSearch.selectedCategory2
              )
            "
          >
            <option [ngValue]="option[0]">{{ option[2] }}</option>
          </ng-container>
        </select>

        <select
          *ngIf="
            diagnosisService.getList(AdvancedSearch.selectedCategory3).length
          "
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory4"
          (ngModelChange)="
            toggleButton($event);
            AdvancedSearch.selectedCategory6 = null;
            AdvancedSearch.selectedCategory5 = null;
            msgNoResults = false
          "
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <option></option>
          <ng-container
            *ngFor="
              let option of diagnosisService.getList(
                AdvancedSearch.selectedCategory3
              )
            "
          >
            <option [ngValue]="option[0]">{{ option[2] }}</option>
          </ng-container>
        </select>

        <select
          *ngIf="
            diagnosisService.getList(AdvancedSearch.selectedCategory4).length
          "
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory5"
          (ngModelChange)="
            toggleButton($event);
            AdvancedSearch.selectedCategory6 = null;
            msgNoResults = false
          "
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <option></option>
          <ng-container
            *ngFor="
              let option of diagnosisService.getList(
                AdvancedSearch.selectedCategory4
              )
            "
          >
            <option [ngValue]="option[0]">{{ option[2] }}</option>
          </ng-container>
        </select>

        <select
          *ngIf="
            diagnosisService.getList(AdvancedSearch.selectedCategory5).length
          "
          class="t-select t-field"
          [(ngModel)]="AdvancedSearch.selectedCategory6"
          (ngModelChange)="toggleButton($event); msgNoResults = false"
          style="
            margin-block: 0.3em;
            text-align-last: left;
            direction: ltr;
            width: fit-content;
          "
        >
          <option></option>
          <ng-container
            *ngFor="
              let option of diagnosisService.getList(
                AdvancedSearch.selectedCategory5
              )
            "
          >
            <option [ngValue]="option[0]">{{ option[2] }}</option>
          </ng-container>
        </select>
      </div>
      <!-- ========================== Search Button & CVS (Start) ========================== -->
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        "
      >
        <button
          matTooltip="Search"
          (click)="searchExams()"
          class="advanced-search-btn"
          *ngIf="showButton"
          type="button"
        >
          Search
        </button>
      </div>
      <!-- ========================== Search Button & CVS (End) ========================== -->
    </div>

    <!-- ========================== Sheba Hospital (End) ========================== -->

    <!-- ========================== Search Results (Start) ========================== -->
    <main #main class="search-results" style="padding: 1em">
      <ng-container
        *ngFor="
          let examType of AdvancedSearch.matchExams
            | map : 'ExamType'
            | set
            | toArray
        "
      >
        <h4>{{ examType }}</h4>
        <div
          *ngFor="
            let item of AdvancedSearch.matchExams
              | filter : { ExamType: examType }
          "
          class="l-flex patient-item"
          (click)="
            patientsService.setPat(item); examService.goExamAdvancedSearch(item)
          "
        >
          <div
            class="w100 patient-item-link"
            matTooltip="Click to View Patient History"
          >
            <div class="l-table h100">
              <span class="l-td-m patient-item-cell patient-name-firsts">
                <strong> {{ item.First }} </strong>
              </span>
              <span class="l-td-m patient-item-cell patient-name-firsts">
                <strong> {{ item.Last }} </strong>
              </span>
              <span class="l-td-m patient-item-cell patient-id">
                Exam Type:
                <strong>{{ item.ExamType }}</strong>
              </span>
              <span class="l-td-m patient-item-cell patient-id">
                Exam Time:
                <strong>{{
                  item.CreationTime | date : "d/M/yy, h:mm a"
                }}</strong>
              </span>
              <span class="l-td-m patient-item-cell patient-id">
                Done By:
                <strong>{{ item.OpenerName }}</strong>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="msgNoResults" class="not-found-error">
        <h3 style="text-align: center">
          We couldn't find results with your search criteria please change them
          and try again.
        </h3>
      </div>
    </main>
    <!-- ========================== Search Results (End) ========================== -->
  </header>

  <aside class="l-sidebar l-flex l-column">
    <img
      class="off-mobile sidebar-logo"
      src="../../assets/icons/logo.svg"
      alt="Artisan Sonics"
    />

    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>

    <app-docpanel></app-docpanel>
  </aside>

  <app-bottombar></app-bottombar>
</div>
