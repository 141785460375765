import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-customheader',
  templateUrl: './customheader.component.html',
  styleUrls: ['./customheader.component.scss']
})
export class CustomheaderComponent implements OnInit, OnDestroy {
  fileInputLabel: any;
  fileUploadForm =new UntypedFormControl();
  msg="";
  canSave = false;
  fileSaved$ = new Subject();
  sub = new Subscription()
  file;
  constructor(public ls:LoginService) {
    if(this.ls.ishospital){
      if(this.ls.CustomHeader.value)
        this.ls.headerToShow = `../../assets/images/${this.ls.UID}.jpg`
      else{
        this.ls.headerToShow = `../../assets/images/clinic.jpg`
      }
    }
   }

  ngOnInit() {
    this.ls.getHeader();
    // this.ls.getImageFromService();
    this.sub = this.fileSaved$.subscribe(() => {
      this.ls.getHeader();
    })
  }

  onFileSelect(event: any) {
    this.file = event.target.files[0];
    this.fileInputLabel = this.file.name;
    this.fileUploadForm.setValue(this.file);

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.ls.headerToShow = reader.result;
    }
    this.canSave = true;
  }

  onFormSubmit(){
    const formData = new FormData();
    var str ='';
    if(this.ls.CustomHeader.value){
      str = `${this.ls.UID}_${this.ls.ClinicID}.jpg`;
    }else{
      str = `${this.ls.ClinicID}.jpg`;
    }
    formData.append('uploadedImage', this.fileUploadForm.value, str);
    this.ls.saveHeader(formData);
    this.canSave = false;
    // this.fileUploadForm.reset()
    this.fileSaved$.next();
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }

}
