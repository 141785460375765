<div class="no-scroll patient-ob">

    <div class="wrapper">

        <!-- ========================== header (start) ========================== -->
        <header class="l-header">

            <div class="on-mobile header-logo">
                <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
            </div>
            <div class="l-header-panel">
                <label style="font-size: 20px;">Search By Text</label>

                <i class="off-mobile header-icon icon-fetus" *ngIf="false"></i>

                <div class="header-pagi" *ngIf="false">
                    <a href="#" class="header-pagi-item">1</a>
                    <a href="#" class="header-pagi-item is-active">2</a>
                    <a href="#" class="header-pagi-item">3</a>
                </div>

                <nav class="header-menu">
                    <!-- <span (click)="showprintopts=true;showprint=true" *ngIf="! ls.ishospital" class="header-menu-link icon-print"
                        aria-label="Print" matTooltip="Print"></span> -->
                        <img
                        style="cursor: pointer"
                        matTooltip="Download CVS"
                        *ngIf="st.filteredExams?.length"
                        (click)="exportExcel()"
                       height="31em"
                        width="31em"
                        src="../../assets/icons/file.svg"
                          />
                    <a routerLink="/patsearch" class="header-menu-link icon-confirm" matTooltip="Exit Search"></a>
                    <!-- <a href="#" class="header-menu-link icon-file" aria-label="file"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-chart" aria-label="chart"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-image" aria-label="image"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-searchdoc" aria-label="image"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-save" aria-label="save"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-chat" aria-label="chat"></a> -->
                    <!-- <a href="#" class="header-menu-link icon-confirm" aria-label="confirm"></a> -->

                </nav>

                <nav class="on-mobile header-mobilePanel">
                    <button class="btn-mobileMenu" id="open-headerMenu" data-show=".header-menu"
                        aria-label="open mobile menu"></button>
                    <button class="btn-mobileMenu" id="open-mainNav" data-show=".l-sidebar"
                        aria-label="open mobile sidebar"></button>
                </nav>

            </div>

        </header>
        <!-- ========================== header (end) ========================== -->


        <!-- ========================== intro (start) ========================== -->

        <main class="l-deashboard">

            <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding" style="padding-bottom: 1em;">
                <div class="w60 patient-ob-box">
                    <div class="m-box w100" [formGroup]="searchForm">
                        <h2>Search</h2>
                        <textarea (focus)="displayValidationPopup=false"  #searchInput class="scroller" formControlName="text"></textarea>
                        <!-- <button class="search-btn"  [disabled]="searchForm.get('text').value.length < 5 || isLoading" (click)="searchText(searchInput.value)">Search</button> -->
                        <!-- <textarea class="scroller" [disabled]="isLoading" [(ngModel)]="search" [ngModelOptions]="{  debounce: 500}" (ngModelChange)="searchText()"></textarea> -->
                    
                    <!-- popup for validation -->
                    <div *ngIf="displayValidationPopup"  class="t-validation-wrap">
                        <button class="t-menu-item is-invalid"></button>
                  
                        <div class="t-validation-message" style="width: 20em; left: 1em; font-size: 1.5em; margin: -1.3em 1em">
                          <span style="border-radius: 8px">
                            <p *ngIf="searchForm.get('text').value.length < 5" style="margin-bottom: 1em">
                             needs at least 5 characters
                            </p>
                            <p *ngIf="searchForm.get('text').value.length > 15" style="margin-bottom: 1em">
                                Search phrase can have up to 15 characters 
                               </p>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="w20 patient-ob-box"  style="height: auto;" >
                    <div class="m-box w100 h100" style="text-align: center;">
                        <button style="font-size: 23px;font-weight: 700;"  [disabled]="isLoading" (click)="searchText(searchInput.value)">Start Search</button>
                    </div>
                </div>
                <div class="w20 patient-ob-box"  style="height: auto;" >
                    <div class="m-box w100 h100" style="text-align: center;">
                        <button style="font-size: 23px;font-weight: 700;" [disabled]="isLoading" (click)="clearSearch()"  > Clear Search</button>
                    </div>
                </div>
                <div class="w20  patient-ob-box" style="height: auto;">
                    <div class="m-box w100 h100" >
                        <h2>Exams Count</h2>
                        <div style="text-align: center;" *ngIf="!isLoading">
                            <label style="font-size: 33px;font-weight: 700;"> {{st.filteredExams ? st.filteredExams.length : 0}}</label>
                        </div>
                        <div style="text-align: center;" *ngIf="isLoading">
                            <label style="font-size: 33px;font-weight: 700;">  Loading...</label>
                            <img src="../../assets/images/Wedges.svg"/>
                        </div>
                        <!-- <div style="text-align: center; height: 10px; width: 10px" *ngIf="true">
                        <mat-spinner></mat-spinner>
                    </div> -->
                    </div>
                </div>
                <!-- Search Box (start) -->
                <!-- Search Box (end) -->
                
            </div>
            <div class="l-flex patient-item"  *ngFor="let exam of exams | uniqueExams ">
                <button class="w100 patient-item-link" >
    
                    <div class="l-table h100">
                        <span class="l-td-m patient-item-cell patient-name-first">
                            Exam Time: <strong>{{exam.CreationTime | date:"dd/MM/yyyy h:mm a"}}</strong>
                        </span>
    
                        <span class="l-td-m patient-item-cell patient-name-first">
                            Exam Type: <strong>{{exam.ExamType}} <span *ngIf="exam.Changed">({{exam.ChangedExamTitle}})</span></strong>
                        </span>
    
                    </div>			
    
                </button>
                <a class="l-td-m patient-item-exit" [ngClass]="getLocked(exam)" (click)="goExam(exam)" [matTooltip]="(getLocked(exam) === 'locked' ) ? 'Locked By: ' +exam.LockedBy : 'View Exam'"></a>
                
            </div>

            <app-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts" [stats]="true">
            </app-printopts>

        </main>

        <!-- ========================== main sidebar (start) ========================== -->

        <aside class="l-sidebar l-flex l-column">

            <img class="off-mobile sidebar-logo" src="assets/icons/logo.svg" alt="Artisan Sonics" />

            <!-- user panel (start) -->

            <!-- user panel (end) -->

            <!-- main nav (start) -->
            <nav class="mainNav l-fg1 scroller">

            </nav>
            <!-- main nav (end) -->

            <app-docpanel></app-docpanel>

        </aside>

        <!-- ========================== main sidebar (end) ========================== -->

        <app-bottombar></app-bottombar>

    </div><!-- end wrapper -->
</div>
<app-loginbox *ngIf="showLogin" (closed)="showLogin=false;" (viewonly)="viewonly()" [search]="true"></app-loginbox>
