<div class="wrapper">
        <header class="l-header">

                <div class="on-mobile header-logo">
                    <img src="assets/icons/logo.svg" alt="Artisan Sonics"/>
                </div>
                <div class="l-header-panel">
                  
                    <h1 class="t-title-main" style="margin-right: 32px;"> {{es.ExamType}} <span *ngIf="es.changedTitle">({{es.newTitle}})</span> {{pat.ID != 'default' || es.examID ? (es.CreationTime| date:"dd/MM/yyyy") : "Defaults"}}</h1>
                    <i class="off-mobile header-icon icon-fetus" *ngIf="ps.fetusCt() > 1 && es.ExamType != 'Gynecology' && es.ExamType != 'Follicles'"></i>
        
                    <div class="header-pagi" *ngIf="ps.fetusCt() > 1 && es.ExamType != 'Gynecology' && es.ExamType != 'Follicles' && pat.ID != 'default' && ! ls.isCardio && ! ls.isGastro">
                        <span class="header-pagi-item" [ngClass]="es.getFetus() == 1? 'is-active':''" (click)="es.setFetus(1)">1</span>
                        <span class="header-pagi-item" [ngClass]="es.getFetus() == 2? 'is-active':''" (click)="es.setFetus(2)">2</span>
                        <span *ngIf="ps.fetusCt() > 2" class="header-pagi-item" [ngClass]="es.getFetus() == 3? 'is-active':''" (click)="es.setFetus(3)">3</span>
                        <span *ngIf="ps.fetusCt() > 3" class="header-pagi-item" [ngClass]="es.getFetus() == 4? 'is-active':''" (click)="es.setFetus(4)">4</span>
                    </div>
                    <div *ngIf="!this.ls.ishospital && this.ls.premissions?.Consent && pat.ID != 'default'&& es.ExamType !== 'Consultation Letter' && es.ExamType != 'Gynecology' && es.ExamType != 'Pediatric Echo'" [ngClass]="checkApprovedConcent(linkedConcent) ? 'signed-consent' : 'not-signed-consent'" [matTooltip]="checkApprovedConcent(linkedConcent) ? 'Consent Signed' : 'Consent Not Signed'"
                    ></div>

            
                    <nav class="header-menu" *ngIf="pat.ID != 'default' || es.examID">
                        {{messages}}
                        <a class="header-menu-link" [ngClass]="isRecording()? 'icon-stop': 'icon-record'" aria-label="Record" (click)="record()" *ngIf="! es.isClosed && ! es.isForm && Stream && (ls.ishospital || (ls.premissions && ls.premissions.VideoRecording))" matTooltip="Start/Stop Recording"></a> 
                        <span (click)="showprintopts=true;showprint=true" class="header-menu-link icon-print" aria-label="Print" matTooltip="Print" *ngIf="es.ExamType != 'Emergency Room' &&  (!ls.isCardio || es.signed)"></span>
                        <div *ngIf="(ls.premissions && ls.premissions.Links && ! ls.isCardio) || (ls.isCardio && es.signed)">
                            <span class="header-menu-link" [ngClass]="sendonce ? 'icon-link-sent' : 'icon-link'" aria-label="Link" (click)="showlink=true" matTooltip="Send Weblink to patient" *ngIf="(ls.premissions && ls.premissions.Links && ! ls.isCardio) || (ls.isCardio && es.signed)"></span>
                            <span *ngIf="ls.premissions.LinkLimit" style="align-self:center;margin-inline-start: 2.31em;" [ngClass]="ls.linksent >=ls.premissions.LinkLimit ? 'error': '' ">{{ls.linksent}}/{{ls.premissions.LinkLimit}}</span>
                        </div>
                        <ng-container *ngIf="! es.isForm">
                            <a class="header-menu-link"  [ngClass]="messages !='Creating Disk ...'? 'icon-save': 'icon-busy'" aria-label="Disk" (click)="getDisk()" matTooltip="Burn CD"></a>
                            <button mat-button [matMenuTriggerFor]="menu" style="margin-bottom: auto;"><a class="header-menu-link icon-chat" *ngIf="! ls.isCardio && (es.ExamType =='1st' || es.ExamType == 'Early')"></a></button>
                            <mat-menu #menu="matMenu">
                                <button style="padding: 8px;" *ngIf="!ls.ishospital" mat-menu-item (click)="es.switchTONTExam()" aria-label="Add NT to exam" matTooltip="{{es.addNT || es.switchNT ? 'Remove NT from exam' : 'Add NT to exam'}}">{{es.addNT || es.switchNT ? 'Remove NT from exam' : 'Add NT to exam'}}</button>
                                <button style="padding: 8px;" *ngIf="ls.ishospital" mat-menu-item (click)="es.addOvaries()" aria-label="Add Ovaries & Uterus to exam" matTooltip="{{es.switchOvaries ? 'Remove Ovaries & Uterus from exam' : 'Add Ovaries & Uterus to exam'}}">{{es.switchOvaries ? 'Remove Ovaries & Uterus from exam' : 'Add Ovaries & Uterus to exam'}}</button>
                            </mat-menu>
                            <ng-container *ngIf="es.ExamType != 'Emergency Room'">
                            <span class="header-menu-link icon-image" aria-label="Images" (click)="showgallery=!showgallery" matTooltip="Open Gallery"></span>
                            <span class="header-menu-link icon-chart" *ngIf="! ls.isCardio" aria-label="Charts" (click)="showcharts=true" matTooltip="Show Charts"></span>
                            <img *ngIf="ls.premissions && ls.premissions?.uploadDocuments" style="cursor: pointer;" class="header-menu-link "
                                matTooltip="{{'GUI.documents.tooltips.Attached Documents' | translate}}" height="35em" width="35em" (click)="openDocumentsAdd=true"
                                src="../../assets/icons/fileupload.svg" />
                            <span class="header-menu-link icon-inbox" aria-label="Inbox" (click)="showInbox=! showInbox" matTooltip="Open Inbox"></span>
                            </ng-container>
                            <a class="header-menu-link icon-searchdoc" aria-label="Search" *ngIf="false"></a>
                            <span *ngIf="! es.isClosed && vidMinified && Stream" (click)="vidMinified=false" style='margin-left:2em'>
                                <video id='minviewer' style='height: 35px;' playsinline autoplay [srcObject]="Stream"  ></video>
                                <meter [high]="0.25" max="1" [value]="audioLevel / 100" class="vertical"></meter>
                                <div style='position: absolute; color: white; font-size: 7pt; margin-top: -4.5em'>{{ts.time}}</div>
                            </span>
                        </ng-container>
                        <a *ngIf="!ls?.premissions?.PregnancyFollowUp" class="header-menu-link icon-confirm" aria-label="Confirm" (click)="exit(true)" matTooltip="Close Exam and Open AWL"></a> 
                        <a *ngIf="ls?.premissions?.PregnancyFollowUp" class="header-menu-link icon-confirm" aria-label="Confirm" (click)="exit(true)" matTooltip="Close Letter and Open Patient History"></a> 
                    </nav>
        
                    <nav class="on-mobile header-mobilePanel">
                        <button class="btn-mobileMenu" id="open-headerMenu" data-show=".header-menu"></button>
                        <button class="btn-mobileMenu" id="open-mainNav" data-show=".l-sidebar"></button>
                    </nav>			
        
                </div>
            
            </header>
        
    
        <div class='exam-element'>
            <ng-container *ngIf="(this.ls.usMode || this.ls.premissions.usMode) && this.es.examsUsMode.includes(es.ExamType)">
            <ng-container  *ngIf="page=='Pregnancy'" >
                <mat-radio-group [(ngModel)]="modality"  (change)="changeModality()" *ngIf="!this.es.isClosed">
                    <mat-radio-button value="Ultrasound">Ultrasound </mat-radio-button>&nbsp;
                    <mat-radio-button value="Computing">Computing</mat-radio-button>
                </mat-radio-group>
                <h2 *ngIf="this.es.isClosed">{{modality?modality:""}}</h2>
            </ng-container>
        </ng-container>
            <div class='topbar' *ngIf="! es.OpenerName || es.ExamType != 'Emergency Room'"></div>
            <div class='topbar' *ngIf="es.OpenerName && es.ExamType == 'Emergency Room'">Examined By: {{es.OpenerName}}</div>
            <main class="l-deashboard">
                <app-pregnancy *ngIf="page=='Pregnancy'" [modality]="modality" (setFetuses)="changeFetuses($event)"></app-pregnancy>
                <app-placenta *ngIf="page=='Placenta'"></app-placenta>
                <app-anamnesis *ngIf="page =='Anamnesis'"></app-anamnesis>
                <app-nt *ngIf="page=='NT'"></app-nt>
                <app-doppler *ngIf="page=='Obs Doppler'"></app-doppler>
                <app-measurements *ngIf="page=='Measurements'" [modality]="modality" [fetus]="this.fetuses"></app-measurements>
                <app-anatomy *ngIf="page=='Anatomy'"></app-anatomy>
                <app-gyngeneral *ngIf="page=='Introduction' && ! ls.isCardio"></app-gyngeneral>
                <app-cardio-general *ngIf="page=='Introduction' && ls.isCardio"></app-cardio-general>
                <app-uterus *ngIf="page=='Uterus'"></app-uterus>
                <app-ovaries *ngIf="page=='Ovaries'"></app-ovaries>
                <app-procedure *ngIf="page=='Procedure'"></app-procedure>
                <app-consultation *ngIf="page=='forms'"></app-consultation>
                <app-summary *ngIf="page=='Summary' && ! ls.isCardio"></app-summary>
                <app-cardio-summary *ngIf="page=='Summary' && ls.isCardio"></app-cardio-summary>
                <app-gallerybox *ngIf="showgallery" (closed)="showgallery=false;CurrentInbox=null" (closeall)="showgallery=false;CurrentInbox=null;showInbox=false" [inbox]="CurrentInbox"></app-gallerybox>
                <app-charts *ngIf="showcharts" (closed)="showcharts=false" [page]="page"></app-charts>
                <app-inbox *ngIf="showInbox" (closed)="showInbox=false" (openg)="openGallery($event)"></app-inbox>
                <app-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts"></app-printopts>
                <app-send-link *ngIf="showlink" (closed)="showlink=false;sendonce=true;ls.countLinks()" [exam]="null"></app-send-link>
                <app-gallery style='width: 100%' [forprint]="false" [inbox]="null" *ngIf="es.ExamType == 'Emergency Room'"></app-gallery>
                <app-twod *ngIf="page=='2D Measurements'"></app-twod>
                <app-cardio-doppler *ngIf="page == 'Doppler'"></app-cardio-doppler>
                <app-cardio-tissue-dop *ngIf="page == 'Tissue Doppler'"></app-cardio-tissue-dop>
                <app-preparation *ngIf="page=='Preparation'"></app-preparation>
                <app-findings *ngIf="page=='Findings' && ls.isGastro"></app-findings>
                <app-pathologies *ngIf="page=='Pathologies'"></app-pathologies>
                <app-cardio-findings *ngIf="page=='Findings' && ls.isCardio"></app-cardio-findings>
                <app-gastro-procedure *ngIf="page == 'ProcedureGastro'"></app-gastro-procedure>
                <app-gastro-summary *ngIf="page=='SummaryGastro'"></app-gastro-summary>
                <app-commerror></app-commerror>
            </main>
            <!-- <main class="l-deashboard" *ngIf="!ls.isGastro">
                <app-preparation *ngIf="page=='Preparation'"></app-preparation>
                <app-findings *ngIf="page=='Findings'"></app-findings>
                <app-pathologies *ngIf="page=='Pathologies'"></app-pathologies>
                <app-gastro-summary *ngIf="page=='GastroSummary'"></app-gastro-summary>
                <app-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts"></app-printopts>
                <app-commerror></app-commerror>
            </main> -->
        </div>
        <app-informconcent *ngIf="inform" (closed)="closeConcent($event)" [form]="concentForm" [ExamType]="es.ExamType"></app-informconcent>

        
        <aside class="l-moreInfo" *ngIf="es.ShowSRs">

                <div class="moreInfo-partialyObscured">
        
                    <section class="m-box">
                        <h2>
                            Structured reports
                        </h2>
                        <p>
                            <ng-container *ngFor="let sr of es.SRList; let i=index">
                                <span (click)="loadSR(sr)" [ngClass]="CurrentSR == sr? 'srselected' : 'sritem'">{{i + 1}}</span>&nbsp; 
                            </ng-container>

                            <iframe *ngIf="CurrentSR" [srcdoc]="SR" style='width: 100%; height: 63vh; padding-bottom: 2em'></iframe>

                        </p>
                        
                    </section>
        
                </div>
        
            </aside>
           
                    <app-examsbar (pageChange)="setPage($event)" (showPatDetails)="showPat=true" [switchpage]="showPage$"  [returnto]="returnTo"></app-examsbar>
                    <app-patdetails *ngIf="showPat" [pat]="pat" (closed)="showPat=false" (show_surrogate)="show_surrogate=true"></app-patdetails> 
                    <app-surrogate *ngIf="show_surrogate" (closed)="show_surrogate=false" [genMom]="pat"></app-surrogate>
                    <app-bottombar></app-bottombar>
                    <app-pat-documents *ngIf="openDocumentsAdd" [showDocuments]="true"  (closed)="openDocumentsAdd = false;" ></app-pat-documents>
                </div>
<app-autotext *ngIf="atS.Display"></app-autotext>


<div style="position: absolute;bottom:0; right: 0; z-index:100;" *ngIf="! es.isClosed && ls.Recording == 'webrtc' &&! vidMinified"  >
    <div style='position: absolute;top:0;left:0'>
        <img src="../../assets/icons/minimize.svg" style='width: 30px' (click)="vidMinified = true" matTooltip="Minimize">
        <img src="../../assets/icons/maximize.svg" style='width: 30px' (click)="maximizeVid()" matTooltip="Full Screen">
    </div>
    <meter style="width: 60%; margin-top: 2em;" [high]="0.25" max="1" [value]="audioLevel / 100"></meter>
    <span #videobox (fullscreenchange)="togglefullscreen()">
        <video playsinline autoplay [srcObject]="Stream" *ngIf="Stream" #video [class]="fullscreen? 'fullscreen':'vscreen'"></video>
        <div style="font-size: 22pt;color: white;position: absolute;margin-top: -5em; z-index: 200;direction: ltr;" *ngIf="isRecording()">{{ts.time}}</div>
    </span>
    <div style="position: absolute;margin-top: -5em; z-index: 200; color: white;direction: ltr;">
        
            <span *ngIf="! isRecording() && ! ls.RemoteRTC">
                <div><select [(ngModel)]="videoSource" (change)="startVideo()">
                    <ng-container *ngFor="let item of videoInputs">
                        <option [value]="item.value">{{item.text}}</option>
                    </ng-container>
                </select>
                </div>
                <select [(ngModel)]="audioSource" (change)="startVideo()">
                    <ng-container *ngFor="let a of audioInputs">
                        <option [value]="a.value">{{a.text}}</option>
                    </ng-container>
                </select>
                Sound Test <input type="checkbox" [(ngModel)]="soundtest" [value]="true" (change)="startVideo()">
            </span>
            
            <span style="color: red; display: none" *ngIf="videowarn">No Video Device found!</span>
        </div>
</div>
<div *ngIf="showgallery && gs.isOpen && ! es.isClosed"  style="z-index:10001;position:fixed;bottom:4em;">
    <img  style='width: 30px;cursor: pointer;' title="Delete this" (click)="showdeleteimg=true" src="../../assets/icons/delete.svg">
    <span style="color: white;" *ngIf="showdeleteimg">Are you sure you want to delete this?  <span (click)="deleteMedia()" style='cursor: pointer;margin-left:1em'>YES</span>&nbsp;<span (click)="showdeleteimg=false" style='cursor: pointer;margin-left:1em'>NO</span></span>
</div>
<div class="t-popup popup-patientDetails" *ngIf="defaultWarn" style='display: block; max-width: 40vw;'>

    <div class="t-popup-close"></div>

    <div class="t-popup-frame">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup"(click)="cancelDefaultQ()"></button>

            <h2 style='color: #D7A95B'>
                Do you wish to continue without setting exam defaults?<br>They will not be available later.
            </h2>
            <!-- box (end) -->
            <div>
                <button (click)="noDefaults()">Yes</button> 
                <button (click)="cancelDefaultQ()" style='margin-left: 2em'>No</button>
            </div>

        </div>
    </div>

</div>
<div class="t-popup popup-patientDetails" *ngIf="missingLMPMsg" style='display: block; max-width: 40vw;'>

    <div class="t-popup-close"></div>

    <div class="t-popup-frame">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup"(click)="missingLMPContinue()"></button>

            <h2 style='color: #D7A95B'>
                The LMP field is empty,
                <br />
                <strong>
                    Please fill it up or continue.</strong>
            </h2>
            <div>
                <button (click)="missingLMPContinue()" class="cancel--btn">Continue</button> 
                <button (click)="backToPregnancy()" class="confirm--btn" style='margin-left: 2em'>
                    {{page === 'Pregnancy' ? 'Fill Up LMP' : 'Go to Pregnancy TAB'}}
                </button>
            </div>

        </div>
    </div>

</div>
<canvas #imgcanvas style="position:absolute; top: 0; left: 0; z-index: 1020;" *ngIf="ls.hasImaging && es.ExamType == 'Gynecology' && ! es.isClosed">

