<div class="t-popup popup-patientDetails">

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="close()"></button>

            <h2>
                Details of Surrogate for {{genMom.FirstEng + ' '+ genMom.LastEng}} ({{genMom.ID}})
            </h2>
            <!-- box (end) -->

            <div class="l-flex l-flex-w t-fieldmargin patient-details">
                
                <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <div class="t-field l-flex t-twin-fieldset">
                            <label>
                              ID
                            </label>
                        </div>

                        <i class="t-sep"></i>

                        <div class="t-validation-wrap">
                            <input type="text" [(ngModel)]="Surrogate.ID" class="t-field-text w100" (change)="idchanged=true"/>
                        </div>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->

                <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            First Name
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input type="text" [(ngModel)]="Surrogate.First" class="t-field-text w100" (change)="idchanged=true"/>
                        </span>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->

                <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            Last Name
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input type="text" [(ngModel)]="Surrogate.Last" class="t-field-text w100" (change)="idchanged=true"/>
                        </span>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->

                <!-- cell ( end ) -->

                <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            Org ID
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input type="text" [(ngModel)]="Surrogate.OrgID" class="t-field-text w100" (change)="idchanged=true"/>
                        </span>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->

                <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            Partner Name
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input type="text" [(ngModel)]="Surrogate.Partner" class="t-field-text w100" (change)="idchanged=true"/>
                        </span>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->
                

                    <!-- cell ( start ) -->
                <div class="w33">

                    <div class="l-flex l-flex-c t-field t-field-pad">

                        <label>
                            Birth Date
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <input [(ngModel)]="Surrogate.Birth"   [matDatepicker]="birthpicker" [max]="maxBD"  class="t-field-text w100"/>
                            <img src="../../assets/icons/date.svg" (click)="birthpicker.open()" style='margin-left: -1.8em;position:absolute'>
                            <mat-datepicker #birthpicker [startAt]="startBD" startView="year"></mat-datepicker>
                        </span>
                        
                    </div>
                    
                </div>
                <!-- cell ( end ) -->
            </div>
            <button class="save_patieont" (click)="save()" *ngIf="Surrogate.ID && Surrogate.OrgID && Surrogate.First && Surrogate.Last">
                Save Surrogate Details and Close
            </button>
            <button class="save_patieont" matTooltip="This will make the current surrogate inactive" (click)="switch()" *ngIf="Surrogate.AI">Switch Surrogate</button>
        </div>
        
    </div>
</div>
                    
