import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-fetus-details",
  templateUrl: "./fetus-details.component.html",
  styleUrls: ["./fetus-details.component.scss"],
})
export class FetusDetailsComponent implements OnInit {
  @Output() inputValueChange = new EventEmitter<any>();
  fetusesNumber: number;
  fetusData: any[] = [];

  constructor(public dialogRef: MatDialogRef<FetusDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  async ngOnInit() {
    this.fetusesNumber = this.data.fetusesNumber;

    // Preload the existing fetus data passed from the parent
    this.fetusData = Array.from({ length: this.fetusesNumber }, (_, index) => ({
      weight: this.data.fetusData[`Fetus${index + 1}Weight`] || "",
      gender: this.data.fetusData[`Fetus${index + 1}Gender`] || "",
      comments: this.data.fetusData[`Fetus${index + 1}Comments`] || "",
    }));
  }

  // Emit changes back to the parent when dialog is closed
  closeDialog() {
    // Map the array structure back to individual properties
    const updatedFetusData = this.fetusData.reduce((acc, fetus, index) => {
      acc[`Fetus${index + 1}Weight`] = fetus.weight;
      acc[`Fetus${index + 1}Gender`] = fetus.gender;
      acc[`Fetus${index + 1}Comments`] = fetus.comments;
      return acc;
    }, {});

    this.inputValueChange.emit(updatedFetusData);
    this.dialogRef.close(updatedFetusData); // Pass the updated data back when closing the dialog
  }

  // Method to handle individual changes to fetus data if needed
  updateFetusData() {
    console.log("Updated Fetus Data:", this.fetusData);
    this.inputValueChange.emit(this.fetusData);
  }
}
