<div [formGroup]="gs.gastroForm">
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
        <div style="width: 50%">
            <section class="m-box" style="height: 100%;margin-top: auto;">
                <h2 style="top: -3.2em;">Diagnosis</h2>
                <label>Diagnosis search: </label>
                <mat-form-field style="width: 50%; margin-left: 2em;margin-top: 0;">
                    <input #inputDiagnosis type="text"
                            id="inputDiagnosis"
                           matInput
                           class="t-field t-select" 
                           style="height: 2em;padding: 0 0 0 1em;"
                           [formControl]="search"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  >
                        <mat-option [value]="search.value" (onSelectionChange)="addToList(search, true);reset();blur();" >{{search.value}}</mat-option>
                      <div *ngFor="let option of filteredOptions | async;" style="display: flex;">                          
                        <!-- <mat-option style="width:100%"  [value]="option.value" (onSelectionChange)="gs.saveDiagnosis(option);reset();blur()"> -->
                        <mat-option style="width:100%"  [value]="option.value" (onSelectionChange)="addToList(option, false);reset();blur()">

                            {{option.value}}
                        </mat-option>
                      </div>
                    </mat-autocomplete>
                </mat-form-field>
                <ng-container *ngIf="gs.diagnosis && gs.diagnosis.length > 0">
                <hr *ngIf="gs.diagnosis && gs.diagnosis.length > 0">
                    <table>
                        <tr *ngFor="let diag of gs.diagnosis;index as i" style="cursor: pointer;" (dblclick)="gs.deleteDiagnosis(i)" matTooltip="Double Click To Remove">
                            <td class="l-td-m">{{i+1}}.</td>
                            <td class="l-td-m">{{diag.value}}</td>
                        </tr>
                    </table>
                </ng-container>
            </section>
        </div>
        <div style="width: 50%; height: auto;">
            <section class="m-box" style="height: 100%;">
                <h2>Diagnosis Comments</h2>
                <textarea class="fulltext" #Diagnosis name="Diagnosis" id="Diagnosis" style="min-height: 70px;height: 100%;" formControlName="Diagnosis"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('Diagnosis'),'Diagnosis',113)">Auto Text</button>
            </section>
        </div>
        <div style="width: 100%;">
            <section class="m-box">
                <h2>Recovery Comments</h2>
                <textarea class="fulltext" style="min-height: 70px;" formControlName="Recovery"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('Recovery'),'Recovery',209)">Auto Text</button>
            </section>
        </div>
        <div style="width: 100%;">
            <section class="m-box">
                <h2>Dissmission Instructions</h2>
                <textarea class="fulltext" style="min-height: 7em;" formControlName="Dissmission"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('Dissmission'),'Dissmission Instructions',204)">Auto Text</button>
            </section>
        </div>
        <div style="width: 100%;">
            <section class="m-box">
                <h2>Recommendations</h2>
                <textarea class="fulltext" style="min-height: 70px;" formControlName="Recomm"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(gs.gastroForm.get('Recomm'),'Recommendations',114)">Auto Text</button>
            </section>
        </div>
        <div style="width: 100%;display: inline-flex;">
            <div class="w50 summary-boxHeight summary-referringDr" style="padding: 0 1em 0 0;">
                <section class="m-box" (dblclick)="gs.gastroForm.get('HMO').setValue(-1)">
    
                    <h2>
                        HMO
                    </h2>
                    <div class="l-flex-c l-flex-w indicative-fieldset h100">
                        <label class="t-label-top">
    
                            <input type="radio" formControlName="HMO" value="Leumit" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Leumit
                            </span>
    
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Clalit" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Clalit
                            </span>
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Macabi" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Macabi
                            </span>
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Meuhedet" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Meuhedet
                            </span>
                        </label>
                        <label class="t-label-top">
                            <input type="radio" formControlName="HMO" value="Private" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                Private
                            </span>
                        </label>
                    </div>
    
                </section>
            </div>
            <div class="w50 summary-boxHeight summary-referringDr" style="padding: 0 0 0 1em;">
                <section class="m-box padd" (dblclick)="gs.gastroForm.get('ReffDoc').setValue(-1)">
                    <h2>Referring Doctor</h2>
                    <textarea class="fulltext minH4" formControlName="ReffDoc" style="height: 100%;"></textarea>
                </section>
            </div>
        </div>
        <div class="w50 summary-boxHeight summary-referringDr" *ngIf="false">
			<section class="m-box">

				<h2>
					Sign Exam
				</h2>
				<div class="l-flex-c l-flex-w indicative-fieldset h100">
					<button  class="save_patieont" *ngIf="ls.userType == 'doctor' && ! es.isClosed && !showlock" (click)="showlock=true">Sign Exam as {{ls.getUserName()}}</button>
					<div *ngIf="showlock && ! es.isClosed">Are you sure this will lock the exam? 
						<span (click)="signClose()" style='margin-left: 2em; cursor: pointer;'>Yes</span>
						<span (click)="showlock=false" style='margin-left: 2em; cursor: pointer;'>Cancel</span>
					</div>
					<span *ngIf="signed">Signed on {{signed | date:'d-M-y H:mm:ss'}} by {{signer}}</span>
				</div>
			</section>
		</div>
    </div>
</div>
