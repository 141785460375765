import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounce, debounceTime} from 'rxjs/operators';
import { LoginService } from '../login.service';



@Component({
  selector: 'app-limits',
  templateUrl: './limits.component.html',
  styleUrls: ['./limits.component.scss']
})
export class LimitsComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<null>();
  limitsForm;
  examsArray = ['1st', 'NT', 'Early', '2nd/3rd', 'Weight Assesments','Late','Third Scan', 'Specialized', 'Fetal Echo', 'Gynecology'];

  hmos = ['Leumit', 'Macabi','Meuhedet','Clalit'];
  loaded = false;
  sub = new Subscription()
  constructor(private http:HttpClient, private fb:UntypedFormBuilder, private ls:LoginService) { }

  ngOnInit(): void {
    if(this.ls.isGastro)
      this.examsArray = ['Gastroscopy', 'Colonoscopy', 'Sigmoidoscopy']
    if(this.ls.isCardio)
      this.examsArray = ['TTE Adults', 'Stress', 'Stress-Echo', 'Duplex Caroutis'];
    this.resetForm();
  }
  resetForm(){
    this.limitsForm = this.fb.group({
      GID:[''],
      LeumitStress:[''],
      LeumitStressEcho:[''],
      LeumitTTE:[''],
      LeumitDuplex:[''],
      MacabiStress:[''],
      MacabiStressEcho:[''],
      MacabiTTE:[''],
      MacabiDuplex:[''],
      MeuhedetStress:[''],
      MeuhedetStressEcho:[''],
      MeuhedetTTE:[''],
      MeuhedetDuplex:[''],
      ClalitStress:[''],
      ClalitStressEcho:[''],
      ClalitTTE:[''],
      ClalitDuplex:[''],
      MilitaryStress:[''],
      MilitaryStressEcho:[''],
      MilitaryTTE:[''],
      MilitaryDuplex:[''],
      LeumitFirst:[''],
      LeumitNT:[''],
      LeumitEarly:[''],
      LeumitLate:[''],
      LeumitSecond:[''],
      LeumitWeight:[''],
      LeumitSpecialized:[''],
      LeumitFetal:[''],
      LeumitGynecology:[''],
      MacabiFirst:[''],
      MacabiNT:[''],
      MacabiEarly:[''],
      MacabiLate:[''],
      MacabiSecond:[''],
      MacabiWeight:[''],
      MacabiSpecialized:[''],
      MacabiFetal:[''],
      MacabiGynecology:[''],
      MeuhedetFirst:[''],
      MeuhedetNT:[''],
      MeuhedetEarly:[''],
      MeuhedetLate:[''],
      MeuhedetSecond:[''],
      MeuhedetWeight:[''],
      MeuhedetSpecialized:[''],
      MeuhedetFetal:[''],
      MeuhedetGynecology:[''],
      ClalitFirst:[''],
      ClalitNT:[''],
      ClalitEarly:[''],
      ClalitLate:[''],
      ClalitSecond:[''],
      ClalitWeight:[''],
      ClalitSpecialized:[''],
      ClalitFetal:[''],
      ClalitGynecology:[''],
      LeumitGastroscopy:[''],
      LeumitColonoscopy:[''],
      LeumitSigmoidoscopy:[''],
      MacabiGastroscopy:[''],
      MacabiColonoscopy:[''],
      MacabiSigmoidoscopy:[''],
      ClalitGastroscopy:[''],
      ClalitColonoscopy:[''],
      ClalitSigmoidoscopy:[''],
      MeuhedetGastroscopy:[''],
      MeuhedetColonoscopy:[''],
      MeuhedetSigmoidoscopy:['']
    })
    this.ls.limits = this.limitsForm.value;
    this.getAll();
    for(let i in this.limitsForm.controls){
      if(i!='GID')
      this.sub = this.limitsForm.controls[i].valueChanges.pipe(debounceTime(500)).subscribe((data) =>{
        this.ls.limits[i] = data;
        this.save(i, data)
      })
    }
  }
  getAll(){
    this.http.get('/api/limits').subscribe((data) =>{
      if(data){
        this.ls.limits = data;
        this.limitsForm.patchValue(data, {emitEvent: false, onlySelf: true})
      }
      this.loaded = true;
    })


  }
  save(index, value){
    this.http.post('/api/limits/save', {index, value}).subscribe((data) =>{
      console.log(data);
      
    })
  }

  getForm(i, str){
    var t = i.replace("-", "").split(" ")[0]
    switch (t) {
      case '1st':
          return str+'First' 
      case '2nd/3rd':
        return str+'Second'
      default:
        break;
    }
    return str+t
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }


}
