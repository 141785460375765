import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ExamsService } from './exams.service';
import { LoginService } from './login.service';
import { PatientsService } from './patients.service';
import { Patient } from './patients/patient';

@Injectable({
  providedIn: 'root'
})
export class GastroFormsService implements OnDestroy{
  typeForm;
  Form;
  table;
  debounce=500;
  public loaded=false;
  pat;
  PID;
  form;
  isClosed = false
  // CreationTime;
  awlIndex;
  FormIndex;
  loadct:number;
  code: string;
  public signers;
  fetchImages: boolean;
  public env;
  public showPercentiles = true;


  public formLoaded$: Subject<void> = new Subject<void>();
  public paitentLoaded$: Subject<string> = new Subject<string>();
  CreationTime =  new Date()
  sub = new Subscription();
  listType=["habits", "preparetion", "diseases", "surgeries", "medications", "status"]
  list =[
    {name:"Smokes", displayName:"מעשן/ת", type:"habits"},
    {name:"Alcohol", displayName:"אלכוהול", type:"habits"},
    {name:"Drugs", displayName:"סמים", type:"habits"},
    {name:"Sleep", displayName:"שינה", type:"habits"},
    {name:"Allergies", displayName:"רגישות לחומרים", type:"habits"},
    {name:"DrugsAllergies", displayName:"רגישות לתרופות", type:"habits"},
    {name:"Nursing", displayName:"סיעודי", type:"preparetion"},
    {name:"OSA", displayName:"דום נשימה בשינה", type:"preparetion"},
    {name:"DAC", displayName:"תרופות נוגדות קרישה", type:"preparetion"},
    {name:"Dentures", displayName:"שיניים תותבות", type:"preparetion"},
    {name:"Escorted", displayName:"האם ישנו מלווה", type:"preparetion"},
    {name:"Guardian", displayName:"אפוטרופוס", type:"preparetion"},
    {name:"Hypertension", displayName:"יתר לחץ דם", type:"diseases"},
    {name:"RespiratoryDiseases", displayName:"מחלות ריאה (בעיקר אסטמה)", type:"diseases"},
    {name:"Arrhythmias", displayName:"הפרעות קצב לב", type:"diseases"},
    {name:"Diabetes", displayName:"סכרת/מחלות אנדוקרניות", type:"diseases"},
    {name:"Hepatitis", displayName:"הפטיטיס", type:"diseases"},
    {name:"Cancer", displayName:"סרטן", type:"diseases"},
    {name:"Radiation", displayName:"הקרנות/כימותרפיה", type:"diseases"},
    {name:"Cramps", displayName:"התכווצויות", type:"diseases"},
    {name:"Psychiatric", displayName:"מחלות פסיכיאטריות", type:"diseases"},
    {name:"Nervous", displayName:"מחלות עצבים", type:"diseases"},
    {name:"Surgeries", displayName:"ניתוחים", type:"surgeries"},
    {name:"PreMedication", displayName:"תרופות קבועות", type:"medications"},
    {name:"Medication", displayName:"האם קיבל תרופות היום", type:"status"},
    {name:"BloodThinners", displayName:"תרופות לדילול דם", type:"status"},
    {name:"Fast", displayName:"האם המטופל בצום", type:"status"},

  ]
  constructor(public es:ExamsService, private fb:UntypedFormBuilder, private http:HttpClient, public patServ:PatientsService, private ls:LoginService) {
    
    this.sub = this.paitentLoaded$.subscribe((data) => {
      if(data['AWLID'])
        this.awlIndex = data['AWLID'];
      else{
        this.awlIndex = data;
      }
      if(this.awlIndex)
        this.loadForm(false,null);
    })
    if(es.isClosed){
      this.Form.disable({emitEvent:false})
    }
  }

  resetAdmissionForm(){
    this.Form = this.fb.group({
      AutoIndex: [''],
      UID:[''],
      PID:[''],
      GID:[''],
      AWLID:[''],
      CreationTime:[''],
      ID: [''],
      InstanceNumber: [''],
      Locked: [''],
      LockedBy: [''],
      Colonoscopy:[''],
      Gastroscopy:[''],
      Sigmoidoscopy:[''],
      Temp:[''],
      Pulse:[''],
      bpSystolic:[''],
      bpDyastolic:[''],
      CheckTime:[''],
      Smokes:[''],
      SmokesNote:[''],
      Alcohol:[''],
      AlcoholNote:[''],
      Drugs:[''],
      DrugsNote:[''],
      Sleep:[''],
      SleepNote:[''],
      Allergies:[''],
      AllergiesNote:[''],
      DrugsAllergies:[''],
      DrugsAllergiesNote:[''],
      Nursing:[''],
      NursingNote:[''],
      OSA:[''], //Obstructive Sleep Apnea
      OSANote:[''],
      DAC:[''],// Drugs Anti-coagulants 
      DACNote:[''],
      Dentures:[''],
      DenturesNote:[''],
      Escorted:[''],
      EscortedNote:[''],
      Guardian:[''],
      GuardianNote:[''],
      Hypertension:[''],
      HypertensionNote:[''],
      RespiratoryDiseases:[''],
      RespiratoryDiseasesNote:[''],
      Arrhythmias:[''],
      ArrhythmiasNote:[''],
      Diabetes:[''],
      DiabetesNote:[''],
      Hepatitis:[''],
      HepatitisNote:[''],
      Cancer:[''],
      CancerNote:[''],
      Radiation:[''],
      RadiationNote:[''],
      Cramps:[''],
      CrampsNote:[''],
      Psychiatric:[''],
      PsychiatricNote:[''],
      Nervous:[''],
      NervousNote:[''],
      Surgeries:[''],
      SurgeriesNote:[''],
      PreMedication:[''],
      PreMedicationNote:[''],
      Medication:[''],
      MedicationNote:[''],
      BloodThinners:[''],
      BloodThinnersNote:[''],
      Fast:[''],
      FastNote:[''],
      Saturation:[''],

    })
  }
  resetTimeOutForm(){
    this.Form = this.fb.group({
      AutoIndex: [''],
      UID:[''],
      PID:[''],
      GID:[''],
      AWLID:[''],
      CreationTime:[''],
      ID: [''],
      InstanceNumber: [''],
      Locked: [''],
      LockedBy: [''],
      Identety:[''],
      IdentetyMatch:[''],
      IdentetyMatchBreclate:[''],
      IdentetyMatchDocumante:[''],
      Guardian:[''],
      GuardianName:[''],
      GuardianID:[''],
      Prosthesis:[''],
      ProcedureType:[''],
      isProc:[''],
      DAC:[''],
      DACName:[''],
      DACSstopped:[''],
      Med:[''],
      MedicationType:[''],
      ConcentForm:[''],
      ProcedureConcentForm:[''],
      MedicConcentForm:[''],
      idType:[''],
      IsIdType:[''],
      medSens:[''],
      eggSens:[''],
      soyaSens:[''],
      Equipment:['']
    })
  }

  setPat(pat:Patient){
    this.pat = pat;
  }
  loadForm(isTimeOut,form, awl =null){
    if(!this.awlIndex){
      this.awlIndex = awl;
    }
    if(!form)
      return;
    if(isTimeOut || (form && form['FormName'] == 'Time Out Form')){
      this.typeForm = 'Time Out Form';
      this.resetAdmissionForm();
      this.resetTimeOutForm();
    }
    else{
      this.typeForm = 'Admission Form';
      this.resetTimeOutForm();
      this.resetAdmissionForm();
    }
    if(this.awlIndex)
      this.fillForm(this.Form, 'GastroForms', [], this.awlIndex)
  }
//----------------------------------------------------Start Print Form----------------------------------------------------------------------//

  getForm(){
    this.loaded=false;

    this.http.get(`/api/gastro/getFormByIndex/${this.FormIndex}`).subscribe((data)=>{
      this.form=data[0];
      this.form['CreationTime'] = data[0]['CreationTime'].replace('T',' ').replace('Z','')
      this.form['data']=[]
      for(var i of this.listType){
        this.form['data'][i] =[]
      }
      for(var key of this.list){
        if(this.form[key.name] >-1){
          this.form['data'][key.type].push({key, value: this.form[key.name], comment:this.form[key.name+'Note']})
        }
      }
      
      this.getSigners();
      this.loaded = true;
    });
  }

  loadExam(id:number,PID: number,doImages: boolean, code:string = null, gastro = false){
    this.loaded=false;
    this.loadct=0;
    this.FormIndex=id;
    this.PID=PID;
    this.code = code;
    this.getPatient();
    this.fetchImages = doImages;
  }

  getPatient(){
    let args = {'PatID':this.PID}
    if(this.code){
      args['Outside']='1'
      args['code'] = this.code.toString()
    }
    this.http.post("api/patient",args).subscribe((data)=>{//need to use a token to open up a new session for pupeteer
      if(! data.hasOwnProperty('empty'))
        this.pat = new Patient('3','','','');
        this.pat.asignVals(data);
        this.patServ.setPat(this.pat);
        this.http.get('/api/getenv').subscribe((data)=>{
          this.env = data;
          if(data["printSkipPercentiles"])
            this.showPercentiles = false;
          this.getForm();
        },(error)=>{
          console.log(error);
        });
            
    },
    (error)=>{
      console.log(error);
    });
  }
//----------------------------------------------------End Print Form----------------------------------------------------------------------//



  fillForm(form:UntypedFormGroup, table: string, nosave = [], AWLID=this.awlIndex){
    this.table = table;
    let params = {'table':table, PatID: this.patServ.getPat().PID.toString(), AWLID: AWLID, ID:this.patServ.getPat().ID.toString(), FormName:this.typeForm};
    let f = this.http.get("/api/gastro/get",{'params':params});
    f.subscribe((data: any)=>{
      if(!data.hasOwnProperty("empty")){
        this.FormIndex = data[0]['AutoIndex']
        form.patchValue(data[0], {emitEvent: false});
      }
      else{
        this.FormIndex = data.data['AutoIndex'];
        form.reset({emitEvent:false});
      }
      
      
      for (let key in form.controls) {
        if(this.es.isClosed || ! this.ls.category.canChange.form){
          form.get(key).disable({emitEvent:false});
        }
        else
          if(! nosave.includes(key))
            form.controls[key].valueChanges.pipe(debounceTime(this.debounce), distinctUntilChanged()).subscribe(data=>{
              if(data != null)
                this.saveVals(key,form.get(key).value, table);
            }); 
      }
      this.formLoaded$.next();
    }, (error)=>{
      console.log(error);
    });
    return f;
  }

  saveVals(key: string, value: any,table=this.table){
    let p = this.patServ.getPat();
    let values = {
      'table': table,
      'PID': p.PID,
      'AutoIndex': this.FormIndex,
      'ID': p.ID,
      'AWLID': p.AWLID,
      'CreationTime': new Date(),
      'field': key,
      'value': value,
    };
    this.http.post('/api/gastro/save',values).subscribe((data)=>{
      console.log(data);
      //later on add observer for saves/errors
    }, (error)=> {// do this part later
      console.log(error);
    });
  }
  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }

  getVisits(PID:number){
    return this.http.get(`/api/gastro/GetVisits/${PID}`)
  }

  getFormByVisit(AWLID){
    // console.log(AWLID);
    return this.http.get(`/api/gastro/GetFormByAWL/${AWLID}`)
  }

  getDisplay(name){
    var index = this.list.filter((i) => {return i.name === name})
    return index[0].displayName;
  }

  getSigners(){
    this.http.get('/api/gastro/signers/' + this.FormIndex).subscribe((data)=> {
      this.signers = data;
    },(error)=> {
      console.log(error);
    });
  }

}
