import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PregnancyService } from "../pregnancy.service";

@Component({
  selector: "app-open-pregnancy",
  templateUrl: "./open-pregnancy.component.html",
  styleUrls: ["./open-pregnancy.component.scss"],
})
export class OpenPregnancyComponent implements OnInit {
  @Output() isClosed = new EventEmitter<null>();
  @Output() examClosed = new EventEmitter<null>();
  @Input("pregtoload") pregIDtoLoad: number;
  @Input("exams") exams: any;
  todayDate: string;
  minLMP: string;
  maxLMP: string;
  weeksArray = [];
  daysArray = [];
  invalidLMP = "";
  dateNow = new Date();

  constructor(public pregnancyService: PregnancyService) {}
  ngOnInit() {
    for (let i = 2; i < 45; i++) {
      this.weeksArray.push(i);
    }
    for (let i = 0; i < 7; i++) {
      this.daysArray.push(i);
    }

    let date = new Date();

    this.todayDate =
      date.getUTCFullYear() +
      "-" +
      OpenPregnancyComponent.add0(date.getMonth() + 1) +
      "-" +
      OpenPregnancyComponent.add0(date.getDate());
    let lmpDate = Date.now() - 2 * 7 * 24 * 60 * 60000; //2 weeks ago
    date.setTime(lmpDate);
    this.maxLMP =
      date.getUTCFullYear() +
      "-" +
      OpenPregnancyComponent.add0(date.getMonth() + 1) +
      "-" +
      OpenPregnancyComponent.add0(date.getDate());

    lmpDate = Date.now() - 44 * 7 * 24 * 60 * 60000; //44 weeks ago
    date.setTime(lmpDate);
    this.minLMP =
      date.getUTCFullYear() +
      "-" +
      OpenPregnancyComponent.add0(date.getMonth() + 1) +
      "-" +
      OpenPregnancyComponent.add0(date.getDate());

    if (this.pregIDtoLoad) {
      this.pregnancyService.loadPreg(this.pregIDtoLoad, true);
    }
  }

  closeMax(): Date {
    let date = new Date();
    let dateParsed = Date.parse(this.pregnancyService.getLMP());
    date.setTime(dateParsed + 48 * 7 * 24 * 60 * 60 * 1000);
    return date;
  }

  closePreg() {
    this.pregnancyService.close();
    this.examClosed.emit();
  }

  static add0(val: number): string {
    if (val > 9) return val.toString();
    return "0" + val.toString();
  }

  checkLMP() {
    if (this.pregnancyService.pregForm.get("LMP").value) {
      let lmp = new Date(this.pregnancyService.pregForm.get("LMP").value);
      if (lmp < new Date(this.minLMP) || lmp > new Date(this.maxLMP)) {
        this.invalidLMP = "is-invalid";
      } else {
        this.invalidLMP = "";
      }
    }
  }

  unselectECTOPIC(): void {
    this.pregnancyService.pregForm.get("CervicalEctop").setValue("");
    this.pregnancyService.pregForm.get("TubalEctop").setValue("");
    this.pregnancyService.pregForm.get("OvarianEctop").setValue("");
    this.pregnancyService.pregForm.get("PeritonealEctop").setValue("");
    this.pregnancyService.pregForm.get("AbdominalEctop").setValue("");
  }

  toggleECTOPIC(selectedValue: string): void {
    const ectopicValues = ["CervicalEctop", "TubalEctop", "OvarianEctop", "PeritonealEctop", "AbdominalEctop"];

    ectopicValues.forEach((ectopicValue) => {
      if (selectedValue !== ectopicValue) {
        this.pregnancyService.pregForm.get(ectopicValue).setValue("");
      }
    });
  }

  ivfToggle(val: string) {
    if (
      this.pregnancyService.pregForm.get("IVFType").value &&
      this.pregnancyService.pregForm.get("IVFType").value.includes(val)
    ) {
      if (this.pregnancyService.pregForm.get("IVFType").value.includes("|" + val))
        this.pregnancyService.pregForm
          .get("IVFType")
          .setValue(this.pregnancyService.pregForm.get("IVFType").value.replace("|" + val, ""));
      else {
        if (this.pregnancyService.pregForm.get("IVFType").value.includes(val + "|"))
          this.pregnancyService.pregForm
            .get("IVFType")
            .setValue(this.pregnancyService.pregForm.get("IVFType").value.replace(val + "|", ""));
        else
          this.pregnancyService.pregForm
            .get("IVFType")
            .setValue(this.pregnancyService.pregForm.get("IVFType").value.replace(val, ""));
      }
    } else {
      if (
        this.pregnancyService.pregForm.get("IVFType").value &&
        this.pregnancyService.pregForm.get("IVFType").value.length > 0
      )
        this.pregnancyService.pregForm
          .get("IVFType")
          .setValue(this.pregnancyService.pregForm.get("IVFType").value + "|" + val);
      else this.pregnancyService.pregForm.get("IVFType").setValue(val);
    }
  }
}
