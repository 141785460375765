import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PdfprintService {

  printImages=true;
  printCharts=true;
  letterhead=false;
  perPage=2;
  newTab=true;
  Lang='he-IL';
  Style="simple"
  returnval:string;
  constructor(private http:HttpClient) { }

  getPDF(exam: number, pat: number, gastro = false): Observable<ArrayBuffer> {
    // if(gastro){
    //   let link =  '/api/gastro/printpdf/'+exam+'/'+pat+'/'+this.Lang+'/'+(this.printCharts? 'y': 'n')+'/'+(this.printImages? 'y':'n')+ '/simple/'+this.perPage+ '/' + this.returnval+'/'+(this.letterhead?'y':'n');//last y is to sendback pdf
    //   return this.http.get(link, { responseType: 'arraybuffer' });
    // }else{
      let link = '/api/printpdf/'+exam+'/'+pat+'/'+this.Lang+'/'+(this.printCharts? 'y': 'n')+'/'+(this.printImages? 'y':'n')+ '/simple/'+this.perPage+ '/' + this.returnval+'/'+(this.letterhead?'y':'n')+'/'+(gastro ?'y':'n');//last y is to sendback pdf
      return this.http.get(link, { responseType: 'arraybuffer' });
    // }
  }
  
  resendChameleon(exam:number,pat:number){
    return this.http.get('/api/printpdf/'+exam+'/'+pat+'/'+this.Lang+'/n/y/simple/all/chameleon');
  }
}
