import { UniqueExamsPipe } from './../pipes/unique-exams.pipe';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExamsService } from '../exams.service';
import { LoginService } from '../login.service';
import { PatientsService } from '../patients.service';
import { SearchtextService } from '../searchtext.service';
import * as XLSX from 'xlsx';
import { writeFile } from 'xlsx';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-searchtext',
  templateUrl: './searchtext.component.html',
  styleUrls: ['./searchtext.component.scss']
})
export class SearchtextComponent implements OnInit, OnDestroy {
  searchForm: UntypedFormGroup;

  exams;
  filterdExams =[];
  showLogin = false;
  startClinic
  goEx;
  showprint=false;
  showprintopts;//added by Hillel so that aot will work - maybe needs something else
  isLoading = false;
  sub = new Subscription()
  displayValidationPopup=false
  constructor(
    public fb: UntypedFormBuilder,
    public st: SearchtextService,
    public ls: LoginService,
    public ps: PatientsService,
    public es: ExamsService,
    public uniq: UniqueExamsPipe,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.resetForm();
    this.searchForm.get('text').setValue(this.st.text)
    this.exams=this.st.exams
    this.st.filteredExams = this.uniq.transform(this.exams);
  }
  
  resetForm(){
    this.searchForm = this.fb.group({
      text:['']
    })
  }
  searchText(text){
   if(this.searchForm.get('text').value.length<5 || this.searchForm.get('text').value.length>15){
    return this.displayValidationPopup=true
   }
    this.st.text=text
    this.isLoading = true;
    this.searchForm.get('text').disable({emitEvent:false})
    if(text != ''){
      var res = this.st.search(text)
      res.subscribe((data) => {
        this.exams = data[0]['data'];
        this.st.exams=this.exams
        this.st.filteredExams = this.uniq.transform(this.exams);
      this.isLoading = false;
        this.searchForm.get('text').enable({emitEvent:false})
  
      },
      (error) => {
        // Error handling code
        console.error('An error occurred:', error);
        this.isLoading = false;
        this.searchForm.get('text').enable({ emitEvent: false });
      })
    }else{
      this.exams =null;
      this.st.exams=null
      this.st.filteredExams = null
      this.isLoading = false;
      this.searchForm.get('text').enable({emitEvent:false})
    }
  }

  goExam(exam/*PID: number, examID: number, PregIndex: number, etype: string,examtime: string*/) {
    let preg = exam.PregIndex ? { AutoIndex: exam.PregIndex } : null;
    let id = exam.AutoIndex;
    this.ps.getPatient(exam.PID, (s) => {
      if (s) {
        if (exam.Locked && exam.LockedBy && exam.LockedBy.toLowerCase() != this.ls.loginname.toLowerCase())
          return;
        let etime = new Date(exam.CreationTime);
        let now = new Date();
        if (exam.ExamType != 'Emergency Room' && ((!this.es.examClosed(etime) && !this.ls.isCardio) || (this.ls.isCardio && !exam.ClosedDate)) && (this.ls.userType == 'doctor' || this.ls.userType == 'visitingdoc' || this.ls.userType == 'tech')) {
          this.showLogin = true;

          this.goEx = { preg: preg, exam: exam, etype: exam.ExamType };
          if (this.ls.ClinicID)
            this.startClinic = this.ls.ClinicID;
        }
        else

          this.es.goExam(preg, exam, exam.ExamType, s.PID);
      }
    });
  }

  getLocked(exam) {
    var now = new Date();
    var lockedTime = new Date(exam.Locked);
    var lock = true;
    if (now.getDate() !=  lockedTime.getDate() ||  now.getMonth() != lockedTime.getMonth() || now.getFullYear() != lockedTime.getFullYear() || (now.getHours() - lockedTime.getHours()) > 1) {
     lock = false;
    }
    if ((exam.Locked || lock) && exam.LockedBy && exam.LockedBy.toLowerCase() != this.ls.loginname.toLowerCase())
      return 'locked';
    return 'unlocked';
  }

  filterData(){
    const NOKEY = ['PregCopied','NumFetuses','NaturalPregnancy','LastPrint','LastUpdate', 'CreationTime', 'Emergency','ExamType','ID']
    this.filterdExams = [];
    this.isLoading = true;
    if(!this.searchForm.get('text').value || this.searchForm.get('text').value == '')
      this.exams = null;
    if(this.exams){
      for(let exam of this.exams){
        var inExam = false
        for(var key of Object.keys(exam)){
          if(key.includes(this.searchForm.get('text').value) && ! NOKEY.includes(key)){
            inExam = true;
          }
        }
        if(inExam)
          this.filterdExams.push(exam)
      }
    }
    this.isLoading = false
  }
  viewonly() {
    this.es.goExam(this.goEx.preg, this.goEx.exam, this.goEx.etype, true);
  }

  
  clearSearch(){
    this.exams=[];
    this.st.exams=[]
    this.searchForm.get('text').setValue("")
    this.st.text=""
    this.st.filteredExams=[];
    this.displayValidationPopup=false
  }

  needToClear(){
    if(this.searchForm.get('text').value){
      return true
    }
    if(this.exams.length){
      return true
    }
    return false
  }

  
  exportExcel(){
  
    let data=[...this.st.filteredExams]
    data=data.map((exam)=>{
      const date=this.datePipe.transform(exam.CreationTime, 'dd/MM/yyyy h:mm a')
      const examType=this.ls.ishospital? exam['ExamType'][0]: exam['ExamType']
      return {"Opener Name":exam['OpenerName'],"Creation Time":date,"Exam Type":examType}
    })
  
  
    const sheetName ='Report';
    const fileName =`searchByText:${this.searchForm.get('text').value}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, fileName);
  
  
    }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
  

}
