<div class="t-popup popup-patientDetails"  style='display: block'>

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame" >
        <div class="t-popup-body" [formGroup]="limitsForm" *ngIf="loaded">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="closed.emit()"></button>
            <div class="t-limits">
                <table style="width: 100%;">
                    <tr>
                        <th></th>
                        <th *ngFor="let i of hmos">{{i}}</th>
                    </tr>
                    <tr *ngFor="let exam of examsArray" style="width: 100%;">
                        <th>{{exam}}</th>
                        <td><input class="t-field t-field--number" [formControlName]="getForm(exam, 'Leumit')" type="number" ></td>
                        <td><input class="t-field t-field--number" [formControlName]="getForm(exam, 'Macabi')" type="number" ></td>
                        <td><input class="t-field t-field--number" [formControlName]="getForm(exam, 'Meuhedet')" type="number" ></td>
                        <td><input class="t-field t-field--number" [formControlName]="getForm(exam, 'Clalit')" type="number" ></td>
                        <!-- <td><input class="t-field t-field--number" [formControlName]="getForm(exam, 'IDF')" type="number" ></td> -->
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>