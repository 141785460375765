import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ExamsService } from "./exams.service";
import { HttpResponse } from "@angular/common/http";
import { PatientsService } from "./patients.service";

@Injectable({
  providedIn: "root",
})
export class MediaTransferService {
  // Time:
  hours: string[] = [];
  minutes: string[] = [];

  // Data:
  availableClinics: any;
  incorrectResults: any;
  correctResults: any;
  clinics: number[];
  movingDataResult: any;

 // <=============== Search Inputs (Start) ===============>:
  // Clinic:
  selectedIncorrectClinic: number;
  selectedCorrectClinic: number;

  // Patient ID:
  selectedIncorrectID: any;
  selectedCorrectID: any;

  // From Exam Date:
  selectedFromIncorrectDate: number;
  selectedFromIncorrectHour: string = "01";
  selectedFromIncorrectMinute: string = "00";
  selectedFromCorrectDate: number;
  selectedFromCorrectHour: string = "01";
  selectedFromCorrectMinute: string = "00";

  // To Exam Date:
  selectedToIncorrectDate: number;
  selectedToIncorrectHour: string = "01";
  selectedToIncorrectMinute: string = "00";
  selectedToCorrectDate: number;
  selectedToCorrectHour: string = "01";
  selectedToCorrectMinute: string = "00";

  // Parsed Dates:
  parsedFromIncorrectDate: string;
  parsedToIncorrectDate: string;
  parsedFromCorrectDate: string;
  parsedToCorrectDate: string;
  // <=============== Search Inputs (End) ===============>:

  // <=============== Move Data Inputs (Start) ===============>:
  // Date to move from:
  dateToMoveFrom: number;
  toMoveFromCorrectHour: string = "01";
  toMoveFromCorrectMinute: string = "00";
  dateToMoveTo: number;
  toMoveToCorrectHour: string = "01";
  toMoveToCorrectMinute: string = "00";

  parsedMoveFromCorrectDate: string;
  parsedMoveToCorrectDate: string;
  // <=============== Move Data Inputs (End) ===============>:

  // Server Messages:
  toggleErrorMessage1: boolean = false;
  errorMessage1: string = "";
  toggleErrorMessage2: boolean = false;
  errorMessage2: string = "";
  toggleDataTransferMessage: boolean = false;
  dataTransferMessage: string = "";
  successMessage: string = "";
  toggleSuccessMessage: boolean = false;

  // Search Buttons:
  toggleSearch1: boolean = false;
  toggleSearch2: boolean = false;

  // Selection:
  selectedIncorrectExam: any;
  selectedCorrectExam: any;
  selectedFileType: string;

  revert: boolean = false;
  documentPath: string = "";
  toggleValidation: boolean = false;

  constructor(
    public examService: ExamsService,
    public patientsService: PatientsService,
    private http: HttpClient
  ) {
    for (let i = 1; i <= 23; i++) {
      this.hours.push(i.toString().padStart(2, "0"));
    }

    for (let i = 0; i <= 59; i++) {
      this.minutes.push(i.toString().padStart(2, "0"));
    }

    this.http.get(`/api/support/clinics`).subscribe((result) => {
      this.availableClinics = result;
    });
  }

  // <=============== Date Pickers Formatting (Start) ===============>:
  updateDateToSelected(event: any) {
    this.dateToMoveFrom = event.CreationTime;
    this.dateToMoveTo = event.CreationTime;
    this.parsedMoveFromCorrectDate = this.examService.parseDate(
      this.dateToMoveFrom
    );
    this.parsedMoveToCorrectDate = this.examService.parseDate(
      this.dateToMoveTo
    );
  }

  onIncorrectFromDateChange(date: Date) {
    this.parsedFromIncorrectDate = this.examService.parseDate(date);
  }
  onIncorrectToDateChange(date: Date) {
    this.parsedToIncorrectDate = this.examService.parseDate(date);
  }

  onCorrectFromDateChange(date: Date) {
    this.parsedFromCorrectDate = this.examService.parseDate(date);
  }
  onCorrectToDateChange(date: Date) {
    this.parsedToCorrectDate = this.examService.parseDate(date);
  }

  onMoveFromDateChange(date: Date) {
    this.parsedMoveFromCorrectDate = this.examService.parseDate(date);
  }
  onMoveToDateChange(date: Date) {
    this.parsedMoveToCorrectDate = this.examService.parseDate(date);
  }

  // <=============== Date Pickers Formatting (End) ===============>:

  // Navigate to the selected exam:
  goToExamDetails(exam) {
    this.patientsService.setPat(exam);
    this.examService.goExamAdvancedSearch(exam, true, true);
  }

  // Stops the parent div's click event:
  handleRadioClick(event) {
    event.stopPropagation();
  }

  searchIncorrectExam() {
    const fromFullTime = `${this.selectedFromIncorrectHour}:${this.selectedFromIncorrectMinute}`;
    const incorrectFromExamFullDate = `${this.parsedFromIncorrectDate} ${fromFullTime}`;

    const toFullTime = `${this.selectedToIncorrectHour}:${this.selectedToIncorrectMinute}`;
    const incorrectToExamFullDate = `${this.parsedToIncorrectDate} ${toFullTime}`;

    const data = {
      patientId: this.selectedIncorrectID,
      GID: this.selectedIncorrectClinic,
      fromCreationDate: incorrectFromExamFullDate,
      toCreationDate: incorrectToExamFullDate,
    };

    this.http.post(`/api/support`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.incorrectResults = result;
        this.errorMessage1 = "";
        this.toggleErrorMessage1 = false;
        this.selectedCorrectExam = null;
        this.selectedIncorrectExam = null;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
      },
      (error) => {
        if (error.status === 404) {
          this.errorMessage1 = error.error.message;
          this.toggleErrorMessage1 = true;
          this.incorrectResults = null;
        } else {
          console.error(error);
        }
      }
    );
  }

  searchCorrectExam() {
    const fromFullTime = `${this.selectedFromCorrectHour}:${this.selectedFromCorrectMinute}`;
    const correctFromExamFullDate = `${this.parsedFromCorrectDate} ${fromFullTime}`;

    const toFullTime = `${this.selectedToCorrectHour}:${this.selectedToCorrectMinute}`;
    const correctToExamFullDate = `${this.parsedToCorrectDate} ${toFullTime}`;

    const data = {
      patientId: this.selectedCorrectID,
      fromCreationDate: correctFromExamFullDate,
      GID: this.selectedCorrectClinic,
      toCreationDate: correctToExamFullDate,
    };

    this.http.post(`/api/support`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.correctResults = result;
        this.toggleErrorMessage2 = false;
        this.errorMessage2 = "";
        this.selectedCorrectExam = null;
        this.selectedIncorrectExam = null;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
      },
      (error) => {
        if (error.status === 404) {
          this.errorMessage2 = error.error.message;
          this.toggleErrorMessage2 = true;
          this.correctResults = null;
        } else {
          this.errorMessage2 = "error.message";
          this.toggleErrorMessage2 = true;
          console.error(error);
        }
      }
    );
  }

  moveDataByTime() {
    const fullTimeMoveFrom = `${this.toMoveFromCorrectHour}:${this.toMoveFromCorrectMinute}`;
    const fullDateMoveFrom = `${this.parsedMoveFromCorrectDate} ${fullTimeMoveFrom}`;

    const fullTimeMoveTo = `${this.toMoveToCorrectHour}:${this.toMoveToCorrectMinute}`;
    const fullDateMoveTo = `${this.parsedMoveToCorrectDate} ${fullTimeMoveTo}`;

    const data = {
      incorrectExamId: this.selectedIncorrectExam?.AutoIndex,
      correctExamId: this.selectedCorrectExam?.AutoIndex,
      GID: this.selectedCorrectClinic,
      dateToMoveFrom: fullDateMoveFrom,
      dateToMoveTo: fullDateMoveTo,
      fileType: this.selectedFileType,
    };

    this.http.put(`/api/support/move-time`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.movingDataResult = result;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Data has successfully been transferred`;
        this.toggleSuccessMessage = true;
        this.revert = false;
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  revertDataMove() {
    const fullTimeMoveFrom = `${this.toMoveFromCorrectHour}:${this.toMoveFromCorrectMinute}`;
    const fullDateMoveFrom = `${this.parsedMoveFromCorrectDate} ${fullTimeMoveFrom}`;

    const fullTimeMoveTo = `${this.toMoveToCorrectHour}:${this.toMoveToCorrectMinute}`;
    const fullDateMoveTo = `${this.parsedMoveToCorrectDate} ${fullTimeMoveTo}`;

    const data = {
      incorrectExamId: this.selectedIncorrectExam?.AutoIndex,
      correctExamId: this.selectedCorrectExam?.AutoIndex,
      GID: this.selectedCorrectClinic,
      dateToMoveFrom: fullDateMoveFrom,
      dateToMoveTo: fullDateMoveTo,
      fileType: this.selectedFileType,
    };

    this.http.put(`/api/support/move-time-back`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.movingDataResult = result;
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Data has successfully been transferred back to original exam`;
        this.toggleSuccessMessage = true;
        this.revert = true;
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  finishTransfer() {
    const oldPatientName = `${
      this.movingDataResult[0]?.old_patient_first +
      " " +
      this.movingDataResult[0]?.old_patient_last
    }`;
    const oldExamId = this.movingDataResult[0]?.old_examId;
    const oldPatientId = this.movingDataResult[0]?.old_patient_id;
    const oldPID = this.movingDataResult[0]?.old_PID;
    const newPatientName = `${
      this.movingDataResult[0]?.new_patient_first +
      " " +
      this.movingDataResult[0]?.new_patient_last
    }`;
    const newExamId = this.movingDataResult[0]?.new_examId;
    const newPatientId = this.movingDataResult[0]?.new_patient_id;
    const newPID = this.movingDataResult[0]?.new_PID;
    const GID = this.selectedCorrectClinic;
    const DocumentPath = this.documentPath;

    const data = {
      oldPatientName,
      oldExamId,
      oldPatientId,
      oldPID,
      newPatientName,
      newExamId,
      newPatientId,
      newPID,
      GID,
      DocumentPath,
      isInobx: false,
    };

    this.http.post(`/api/support/media-finish`, data).subscribe(
      (result: HttpResponse<any>) => {
        this.dataTransferMessage = "";
        this.toggleDataTransferMessage = false;
        this.successMessage = `Transfer Complete`;
        this.toggleSuccessMessage = true;
        this.toggleValidation = false;
        this.clearFields();
      },
      (error) => {
        if (error.status === 404) {
          this.dataTransferMessage = error.error.message;
          this.toggleDataTransferMessage = true;
        } else {
          this.dataTransferMessage = "Finish Transfer Failed";
          this.toggleDataTransferMessage = true;
          console.error(error);
        }
      }
    );
  }

  clearFields() {
    this.selectedIncorrectID = null;
    this.selectedCorrectID = null;
    this.selectedCorrectExam = null;
    this.selectedIncorrectExam = null;
    this.selectedFromIncorrectDate = null;
    this.selectedFromIncorrectHour = "01";
    this.selectedFromIncorrectMinute = "00";
    this.selectedFromCorrectDate = null;
    this.selectedFromCorrectHour = "01";
    this.selectedFromCorrectMinute = "00";
    this.selectedToIncorrectDate = null;
    this.selectedToIncorrectHour = "01";
    this.selectedToIncorrectMinute = "00";
    this.selectedToCorrectDate = null;
    this.selectedToCorrectHour = "01";
    this.selectedToCorrectMinute = "00";
    this.selectedIncorrectClinic = null;
    this.selectedCorrectClinic = null;

    this.correctResults = null;
    this.incorrectResults = null;

    this.selectedFileType = null;

    this.toMoveFromCorrectHour = "01";
    this.toMoveFromCorrectMinute = "00";
    this.toMoveToCorrectHour = "01";
    this.toMoveToCorrectMinute = "00";
    this.dateToMoveFrom = null;
    this.dateToMoveTo = null;
    this.movingDataResult = null;
    this.documentPath = null;
  }

  toggleValidationMsg() {
    if (!this.documentPath) {
      this.toggleValidation = true;
    } else {
      this.toggleValidation = false;
      this.finishTransfer();
    }
  }
}
