<div class="h100 l-flex-w  summary" style="direction: rtl;">

    <div class="m-box" [formGroup]="SignForm">
        <h2>סימנים חיוניים</h2>
        <div>
            <span class="l-flex-w" style="width: 100%;padding: 5px 0;">
                <label class="t-label-top">
                    <span class="f-label-span">לחץ דם</span>
                    <span class="t-field t-field--number" style="direction: ltr;">
                        <input class="t-field-text" type="number" min="0" formControlName="bpSystolic"
                            style='width: 3em;text-align: center;text-align-last: center;' />/<input
                            class="t-field-text" type="number" min="0" formControlName="bpDyastolic"
                            style='width: 3em;text-align: center;text-align-last: center;'>
                        <span class="t-field-desc" style="padding: 0.3em;">
                            mmHg
                        </span>
                    </span>
                </label>
                <label class="t-label-top">
                    <span class="f-label-span">
                        דופק
                    </span>
                    <span class="t-field t-field--number">
                        <span class="t-field-desc">bpm</span>
                        <input type="text" name="placenta_TEMP_name" class="t-field t-field-text"
                            style="margin-inline-start: 10px;" formControlName="Pulse">
                    </span>
                </label>
                <label class="t-label-top">
                    <span class="f-label-span">
                        סטורציה
                    </span>
                    <span class="t-field t-field--number">
                        <span class="t-field-desc">%</span>
                        <input type="text" name="placenta_TEMP_name" class="t-field t-field-text"
                            style="margin-inline-start: 10px;" formControlName="Saturation">
                    </span>
                </label>
                <label class="t-label-top">
                    <span class="f-label-span">
                        חמצן
                    </span>
                    <span class="t-field t-field--number">
                        <span class="t-field-desc">%</span>
                        <input type="text" name="placenta_TEMP_name" class="t-field t-field-text"
                            style="margin-inline-start: 10px;" formControlName="Oxygen">
                    </span>
                </label>
                <button class="btnAdd" (click)="saveSigns()">הוספה</button>
            </span>
        </div>
        <div class="m-box w50" *ngIf="Signs.length > 0">
            <table class="l-table">
                <tr class="l-row header">
                    <td class="l-td center">שעה</td>
                    <td class="l-td center">לחץ דם</td>
                    <td class="l-td center">דופק</td>
                    <td class="l-td center">סטורציה</td>
                    <td class="l-td center">חמצן</td>
                </tr>
                <tr class="l-row" *ngFor="let sign of Signs">
                    <td class="l-td center">{{sign.CreationTime | date: 'HH:mm'}}</td>
                    <td class="l-td center">{{sign.bpSystolic}}/{{sign.bpDyastolic}}mmHg</td>
                    <td class="l-td center">{{sign.Pulse}}bpm</td>
                    <td class="l-td center">{{sign.Saturation}}%</td>
                    <td class="l-td center">{{sign.Oxygen}}%</td>
                </tr>
            </table>
        </div>
    </div>
    <ng-container [formGroup]="ProcedureForm">
        <div class="m-box w100">
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">מדד כאב 0-10: </span>
                    <div class="l-flex-c l-flex-w indicative-fieldset" style="flex-wrap: inherit;direction: rtl;">

                        <label class="t-label-top">
                            <input type="radio" formControlName="PainLevel" value="painless" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                אין כאב בכלל
                            </span>
                        </label>
                        <label class="t-label-top">
                            <input type="radio" formControlName="PainLevel" value="lowPain" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                כאב מועט
                            </span>
                        </label>
        
                        <label class="t-label-top">
                            <input type="radio" formControlName="PainLevel" value="mediumPain" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                כאב בינוני
                            </span>
                        </label>
        
                        <label class="t-label-top">
                            <input type="radio" formControlName="PainLevel" value="strongPain" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                כאב חזק
                            </span>
                        </label>
        
                        <label class="t-label-top">
                            <input type="radio" formControlName="PainLevel" value="fullPain" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                כאב בלתי נסבל
                            </span>
                        </label>
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">סדציה</span>
                    <select class="t-field t-field-select" formControlName="Medication" style="width: 12em;">
                        <option value="ביאטריל">ביאטריל</option>
                        <option value="דורמיקום">דורמיקום</option>
                        <option value="פרופופול">פרופופול</option>
                    </select>
                    <div *ngIf="ProcedureForm.get('Medication').value">
                        <input class="t-field t-field-text" type="text" formControlName="MedicationText" />
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">ביופסיה</span>
                    <div class="anatomy-twin-radios" style="margin-top: 0;">

                        <label class="observe">

                            <input type="radio" class="custom-check customInput" formControlName="Byopsy" [value]="1"><i
                                class="customInput-display"></i>

                            <span>
                                כן
                            </span>
                        </label>

                        <label class="not-observed">

                            <input type="radio" class="custom-check customInput" formControlName="Byopsy" [value]="0"><i
                                class="customInput-display"></i>

                            <span>
                                לא
                            </span>

                        </label>
                    </div>
                </label>

            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">שימוש בפלטה</span>
                    <div class="anatomy-twin-radios" style="margin-top: 0;">

                        <label class="observe">

                            <input type="radio" class="custom-check customInput" formControlName="Plate" [value]="1"><i
                                class="customInput-display"></i>

                            <span>
                                כן
                            </span>
                        </label>

                        <label class="not-observed">

                            <input type="radio" class="custom-check customInput" formControlName="Plate" [value]="0"><i
                                class="customInput-display"></i>

                            <span>
                                לא
                            </span>

                        </label>
                    </div>
                    <div *ngIf="ProcedureForm.get('Plate').value">
                        <span>
                            מיקום הפלטה:
                        </span>
                        <input class="t-field t-field-text" formControlName="PlateText" />
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">מצב העור לפני</span>
                    <div class="anatomy-twin-radios" style="margin-top: 0;">

                        <label class="observe">

                            <input type="radio" class="custom-check customInput" formControlName="SkinBefore"
                                [value]="1"><i class="customInput-display"></i>

                            <span>
                                תקין
                            </span>
                        </label>

                        <label class="not-observed">

                            <input type="radio" class="custom-check customInput" formControlName="SkinBefore"
                                [value]="0"><i class="customInput-display"></i>

                            <span>
                                לא תקין
                            </span>

                        </label>
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">מצב העור אחרי</span>
                    <div class="anatomy-twin-radios" style="margin-top: 0;">

                        <label class="observe">

                            <input type="radio" class="custom-check customInput" formControlName="SkinAfter"
                                [value]="1"><i class="customInput-display"></i>

                            <span>
                                תקין
                            </span>
                        </label>

                        <label class="not-observed">

                            <input type="radio" class="custom-check customInput" formControlName="SkinAfter"
                                [value]="0"><i class="customInput-display"></i>

                            <span>
                                לא תקין
                            </span>

                        </label>
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">מהלך פעולה</span>
                    <div class="anatomy-twin-radios" style="margin-top: 0;">

                        <label class="observe">

                            <input type="radio" class="custom-check customInput" formControlName="DuringProc"
                                [value]="1"><i class="customInput-display"></i>

                            <span>
                                תקין
                            </span>
                        </label>

                        <label class="not-observed">

                            <input type="radio" class="custom-check customInput" formControlName="DuringProc"
                                [value]="0"><i class="customInput-display"></i>

                            <span>
                                לא תקין
                            </span>

                        </label>
                    </div>
                    <div *ngIf="ProcedureForm.get('DuringProc').value">
                        <span>
                           פירוט:
                        </span>
                        <input class="t-field t-field-text" formControlName="DuringProcText" />
                    </div>
                </label>
            </div>
            <div>
                <label class="t-label-top">
                    <span style="width: 8em;">שעת סיום הבדיקה:</span>
                    <input class="t-field" type="time" formControlName="EndTime" />
                </label>
            </div>
        </div>
    </ng-container>

</div>