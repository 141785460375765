import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ExamsService } from './exams.service';
import { PregnancyService } from './pregnancy.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetalechoService {
  fetalEchoForm: UntypedFormGroup;
  fetalMeasureForm: UntypedFormGroup;
  peefSet:any;
  public loaded:Subject<null>;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private ps: PregnancyService,
    private es: ExamsService
  ) {
    this.loaded=new Subject();
    this.resetForm();
    this.resetMeas();
    /*this.getFetalEchoData(this.es.examID);
    this.getFetalMeasure();
    this.es.examLoaded$.subscribe(() => {
      this.resetForm();
      this.getFetalEchoData();
      this.getFetalMeasure();
    })*/
    this.es.fetusChange$.subscribe(() => {
      if (
        this.es.ExamType == "Fetal Echo" ||
        this.es.ExamType === "Pediatric Echo"
      ) {
        this.resetForm();
        this.resetMeas();
        this.getFetalEchoData();
        // this.getFetalMeasure();
      }
    });
  }

  resetForm(){
    this.fetalEchoForm = this.fb.group({
      LeftVentricle: [''],
      LeftVentricleNote: [''],
      RightVentricle: [''],
      RightVentricleNote: [''],
      InterventricularSeptum: [''],
      InterventricularSeptumNote: [''],
      IVS: [''],
      IVSStatus:[''],
      IVSNote: [''],
      LeftAtrium: [''],
      LeftAtriumNote: [''],
      RightAtrium: [''],
      RightAtriumNote: [''],
      InteratrialSeptum: [''],
      InteratrialSeptumNote: [''],
      ForamenOvale: [''],
      ForamenOvaleNote: [''],
      CoronarySinus: [''],
      CoronarySinusNote: [''],
      AorticValve: [''],
      AorticValveNote: [''],
      PulmonaryValve: [''],
      PulmonaryValveNote: [''],
      TricupsidValve: [''],
      TricupsidValveNote: [''],
      MitralValve: [''],
      MitralValveNote: [''],
      AorticArch: [''],
      AorticArchNote: [''],
      IsthmicFlow: [''],
      IsthmicFlowNote: [''],
      PulmonaryArtery: [''],
      PulmonaryArteryNote: [''],
      DuctusArteriosus : [''],
      DuctusArteriosusNote: [''],
      Comments: [''],
      Situs: [''],
      SitusNote: [''],
      AVC:[''],
      VAC: [''],
      AVCNote:[''],
      VACNote:[''],
      PE:[''],
      PENote:[''],
      PVR: [''],
      PVRVessels:[''],
      PVRNote:[''],
      SVR: [''],
      SVRNote:['']
    });
  }

  resetMeas(){
    this.fetalMeasureForm = this.fb.group({
      DuctusVenosus: [''],
      AWave: [''],
      PI: [''],

    })
  }


  getFetalEchoData() {
    this.resetForm();
    this.es.fillForm(this.fetalEchoForm, 'chestFetal', true, false)
  }

  getFetalMeasure(){
    if(this.es.ExamType === 'Pediatric Echo'){
      this.es.fillForm(this.fetalMeasureForm, 'meas', false, true);
    } else {
      this.es.fillForm(this.fetalMeasureForm, 'meas', true,false);
    }
  }

}
