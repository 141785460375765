import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Patient } from "../patients/patient";
import { FollowUpService } from "../follow-up.service";
import { LoginService } from "../login.service";
import { PatientsService } from "../patients.service";
import { PregnancyService } from "../pregnancy.service";
import { screeningNTOriginalData, screening2ndOriginalData, screeningRisksOriginalData } from "./data/data-storage";
import { Subscription } from "rxjs";

@Component({
  selector: "app-follow-up-screening",
  templateUrl: "./follow-up-screening.component.html",
  styleUrls: ["./follow-up-screening.component.scss"],
})
export class FollowUpScreeningComponent implements OnInit {
  public showPatientDetailsForm: boolean = false;
  public patient: Patient;
  // Is selected row in edit mode:
  public isEditing: boolean = false;

  private pregID: number;

  // Data structures: (For Resetting):
  public screeningNTOriginalData = { ...screeningNTOriginalData };
  public screening2ndOriginalData = { ...screening2ndOriginalData };
  public screeningRisksOriginalData = { ...screeningRisksOriginalData };

  // Data:
  public screeningNTData = [];
  public screening2ndData = [];
  public screeningRisksData = [];

  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;

  today: Date = new Date();

  selectedInstanceNumber: number = 1;

  constructor(
    public followUpService: FollowUpService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) {
    this.loginService.loginMissing();
  }
  ngOnInit(): void {
    this.followUpService.initializeNewData();

    this.patient = this.patientsService.getPat();
    this.pregID = this.pregnancyService.pregID;

    // Get the number of fetuses for the latest pregnancy:
    this.fetusNumberSub = this.followUpService.getFetusesNumber(this.pregID).subscribe((data) => {
      this.fetusesNumber = data;
    });

    // Get the obstetrical formula for the latest pregnancy:
    this.obsFormulaSub = this.followUpService.getObstetricalFormula(this.pregID).subscribe((data) => {
      this.obstetricalFormula = data;
    });

    this.getData("fuScreeningNT", "screeningNTData");
    this.getData("fuScreening2nd", "screening2ndData");
    this.getData("fuScreeningRisks", "screeningRisksData");
  }

  ngOnDestroy() {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }

    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  async getData(table: string, placementVariable: string, pregIndex: number = this.pregID) {
    const data = await this.followUpService.getData(table, pregIndex);
    this[placementVariable] = data["data"];
  }

  editRow(rowData: any) {
    rowData.isEditing = true;
  }

  async updateRow(table: string, rowData: any, columnsToUpdate: string[]) {
    try {
      const dataToUpdate = columnsToUpdate.reduce((obj, col) => {
        obj[col] = rowData[col];
        return obj;
      }, {});

      const result = await this.followUpService.updateData(table, dataToUpdate, rowData.AutoIndex);
      if (result["status"] === "ok") {
        rowData.isEditing = false;
      }
    } catch (error) {
      console.error("Error updating the row", error);
    }
  }

  async deleteRow(table: string, rowData: any) {
    try {
      const result = await this.followUpService.deleteData(table, rowData.AutoIndex);
      if (result["status"] === "ok") {
        switch (table) {
          case "fuScreeningNT":
            this.screeningNTData = this.screeningNTData.filter((ntScan) => ntScan !== rowData);
            break;
          case "fuScreening2nd":
            this.screening2ndData = this.screening2ndData.filter((secondScan) => secondScan !== rowData);
            break;
          case "fuScreeningRisks":
            this.screeningRisksData = this.screeningRisksData.filter((risk) => risk !== rowData);
            break;
          default:
            console.error(`Table ${table} not handled.`);
            break;
        }
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  }

  async saveNewRow(table: string = "", rowData: any, originalData: any = null) {
    try {
      rowData.InstanceNumber = this.selectedInstanceNumber;

      const result = await this.followUpService.saveData(table, rowData);

      if (result["status"] === "ok") {
        this.resetNewData(rowData, originalData);
        await this.handleDataFetch(table);
      }
    } catch (error) {
      console.error("Error during the save new row", error);
    }
  }

  resetNewData(newData: any, originalData: any) {
    const fieldsToPreserve = ["PID", "PregIndex", "ID"];

    for (const key in newData) {
      if (!fieldsToPreserve.includes(key)) {
        if (key === "Date") {
          newData[key] = new Date();
        } else if (key === "GA") {
          newData[key] = this.patient?.getGA() || "";
        } else {
          newData[key] = originalData && originalData[key] !== undefined ? originalData[key] : "";
        }
      }
    }
  }

  async handleDataFetch(table: string) {
    switch (table) {
      case "fuScreeningNT":
        await this.getData("fuScreeningNT", "screeningNTData");
        break;
      case "fuScreening2nd":
        await this.getData("fuScreening2nd", "screening2ndData");
        break;
      case "fuScreeningRisks":
        await this.getData("fuScreeningRisks", "screeningRisksData");
        break;
      default:
        console.error("Unknown table:", table);
    }
  }

  getFilteredScreeningNT(): any[] {
    return this.screeningNTData.filter((ntScan) => ntScan.InstanceNumber === this.selectedInstanceNumber);
  }
  getFilteredScreening2nd(): any[] {
    return this.screening2ndData.filter((secondScan) => secondScan.InstanceNumber === this.selectedInstanceNumber);
  }

  getFilteredScreeningRisks(): any[] {
    return this.screeningRisksData.filter((risk) => risk.InstanceNumber === this.selectedInstanceNumber);
  }

  getFetusesToRender(): number[] {
    const instanceNumbersFromData = [
      ...new Set([
        ...this.screeningNTData.map((ntScan) => ntScan.InstanceNumber),
        ...this.screening2ndData.map((secondScan) => secondScan.InstanceNumber),
        ...this.screeningRisksData.map((risk) => risk.InstanceNumber),
      ]),
    ];

    const maxInstanceNumber = Math.max(this.fetusesNumber, ...instanceNumbersFromData);

    return Array.from({ length: maxInstanceNumber }, (_, i) => i + 1);
  }
}
