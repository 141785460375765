import { Component, EventEmitter, Input, OnInit,OnDestroy, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ExamsService } from '../exams.service';
import { PatientsService } from '../patients.service';
import { PregnancyService } from '../pregnancy.service';
import {fromEvent, Subject, Subscription} from 'rxjs';
import { map, tap, switchMap, takeUntil, finalize} from 'rxjs/operators';
import { LoginService } from '../login.service';
import { FormBuilder,FormGroup,FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommerrorService } from '../commerror.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: "app-informconcent",
  templateUrl: "./informconcent.component.html",
  styleUrls: ["./informconcent.component.scss"],
})
export class InformconcentComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<string>();
  @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasPat", { static: true })
  canvasPat: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasApp", { static: false })
  canvasApp: ElementRef<HTMLCanvasElement>;
  @ViewChild("canvasDoc", { static: true })
  canvasDoc: ElementRef<HTMLCanvasElement>;
  @Input("form") form;
  @Input("ExamType") ExamType;
  @Input("pat") pat;
  showlink = false;

  ctx: CanvasRenderingContext2D;
  ctxPat: CanvasRenderingContext2D;
  ctxApp: CanvasRenderingContext2D;
  concentForm;
  typecanvas;
  changeCanvas$ = new Subject();
  destroy$ = new Subject();
  sub = new Subscription();
  type;
  docImg;
  OBEXAMS = [
    "1st",
    "NT",
    "Early",
    "2nd/3rd",
    "Weight Assessment",
    "Late",
    "Third Scan",
    "Specialized",
    "Fetal Echo",
  ];
  sendonce;
  lockedConsent: boolean = false;
  signatureSubscription: Subscription;
  waitingForSignatures = false;
  errorInSignature = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public ce: CommerrorService,
    public http: HttpClient,
    private translate: TranslateService,
    private fb: FormBuilder,
    public ls: LoginService,
    public es: ExamsService,
    public patS: PatientsService,
    public ps: PregnancyService
  ) {}

  async ngOnInit() {
    this.resetForm();
    this.sub = this.ce.saveConcent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.save();
          if (
            this.concentForm.get("ExamID").value == "" &&
            this.concentForm.get("AutoIndex").value == ""
          ) {
            this.closed.emit("1");
          } else if (
            this.concentForm.get("ExamID").value &&
            this.concentForm.get("ExamID").value != "" &&
            this.concentForm.get("AutoIndex").value != ""
          )
            this.closed.emit("1");
          else this.closed.emit("2");
        } else this.closed.emit("1");
        this.form = null;
        this.concentForm.reset();
        this.sub.unsubscribe();
      });

    if (this.ls.userType === "secretary") {
      this.concentForm.get("refferalDoc").setValue(this.es.consentPractitioner);
      this.concentForm.get("UID").setValue(this.es.consentDocUID);

      (await this.es.getPrevDocSign()).subscribe((data: any) => {
        this.concentForm.get("docSign").setValue(data?.docSign);
        this.loadSign("docSign");
      });
    } else if (this.ls.userType === "doctor") {
      this.concentForm.get("refferalDoc").setValue(this.ls.username);
      this.concentForm.get("UID").setValue(this.ls.UID);

      (await this.es.getPrevDocSign()).subscribe((data: any) => {
        this.concentForm.get("docSign").setValue(data?.docSign);
        this.loadSign("docSign");
      });
    }

    if (
      (this.concentForm.get("approved").value &&
        this.concentForm.get("approved").value !== "appotropus" &&
        this.concentForm.get("patSign").value &&
        this.concentForm.get("patSignApproved").value &&
        this.concentForm.get("docSign").value) ||
      this.concentForm.get("printed").value
    ) {
      this.concentForm.disable({ emitEvent: false });
      this.lockedConsent = true;
    } else if (
      (this.concentForm.get("approved").value &&
        this.concentForm.get("approved").value === "appotropus" &&
        this.concentForm.get("patSign").value &&
        this.concentForm.get("docSign").value) ||
      this.concentForm.get("printed").value
    ) {
      this.lockedConsent = true;
    }

    this.toggleSave();
  }

  async save() {
    this.es.concentForm = this.concentForm.value;
    const data = await this.es.saveFormPrint(true);
    return data;
  }
  resetForm() {
    this.concentForm = this.fb.group({
      AutoIndex: [""],
      PID: [""],
      UID: [""],
      GID: [""],
      PregID: [""],
      ExamID: [""],
      Opener: [""],
      Deleted: [""],
      DeletedBy: [""],
      ID: [""],
      First: [""],
      Last: [""],
      Phone: [""],
      CreationTime: [""],
      refferalDoc: [""],
      ExamType: [""],
      weekNum: [""],
      preformed: ["women"],
      docReason: [""],
      approved: ["paitent"],
      translated: [""],
      transName: [""],
      transRel: [""],
      appRel: [""],
      appName: [""],
      patSign: [""],
      patSignApproved: [""],
      appSign: [""],
      docSign: [""],
      printed: [""],
    });
    if (this.form) {
      if (this.es.examID) this.form["ExamID"] = this.es.examID;
      if (this.es.pregId) this.form["PregID"] = this.es.pregId;

      this.form.CreationTime = this.form.CreationTime.replace("T", " ").replace(
        "Z",
        ""
      );
      this.concentForm.setValue(this.form, { emitEvent: false });
      this.es.concentForm = this.form;
    } else {
      if (this.pat) {
        this.concentForm.get("ExamType").setValue(this.pat.ExamCode);
        if (
          this.pat.ExamCode.split(" ")[1] == "Anatomy" ||
          this.pat.ExamCode.split(" ")[1] == "Trimester"
        )
          this.concentForm
            .get("ExamType")
            .setValue(this.pat.ExamCode.split(" ")[0]);
        this.concentForm.get("Last").setValue(this.pat.Last);
        this.concentForm.get("First").setValue(this.pat.First);
        this.concentForm.get("ID").setValue(this.pat.ID);
        if (this.pat.Phone) {
          this.concentForm.get("Phone").setValue(this.pat.Phone);
        }
        if (this.pat.Mobile) {
          this.concentForm.get("Phone").setValue(this.pat.Mobile);
        }
        if (this.es.patServ.getPat().getGA())
          this.concentForm
            .get("weekNum")
            .setValue(this.es.patServ.getPat().getGA().split("w")[0]);
      } else {
        if (this.es.patServ.getPat().getGA())
          this.concentForm
            .get("weekNum")
            .setValue(this.patS.getPat().getGA().split("w")[0]);

        this.concentForm.get("ExamType").setValue(this.ExamType);
        if (this.patS.getPat().Last)
          this.concentForm.get("Last").setValue(this.patS.getPat().Last);
        if (this.patS.getPat().First)
          this.concentForm.get("First").setValue(this.patS.getPat().First);
        if (this.patS.getPat().ID)
          this.concentForm.get("ID").setValue(this.patS.getPat().ID);
        if (this.patS.getPat().Phone)
          this.concentForm.get("Phone").setValue(this.patS.getPat().Phone);
        if (this.patS.getPat().Mobile)
          this.concentForm.get("Phone").setValue(this.patS.getPat().Mobile);
      }

      this.concentForm.get("CreationTime").setValue(this.es.parseConsentDate(new Date()));

      if (this.es.pregId)
        this.concentForm.get("PregID").setValue(this.es.pregId);
    }
    if (!this.es.pregnancies) {
      this.es
        .getExams(this.es.patServ.getPat().PID.toString())
        .subscribe((data) => {
          this.es.pregnancies = data["pregnancies"];
        });
    }
  }

  ngAfterViewInit() {
    if (this.form && this.form.patSign) {
      this.loadSign("patSign");
    }
    if (this.form && this.form.patSignApproved) {
      this.loadSign("patSignApproved");
    }
    if (this.form && this.form.appSign) {
      this.changeDetector.detectChanges();
      this.loadSign("appSign");
    }
    if (this.form && this.form.docSign) {
      this.loadSign("docSign");
    }
    this.sub = this.changeCanvas$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.typecanvas = data.canvas;
        this.ctx = data.ctx;
        const mouseDownStream = fromEvent(
          this.typecanvas.nativeElement,
          "mousedown"
        );
        const mouseMoveStream = fromEvent(
          this.typecanvas.nativeElement,
          "mousemove"
        );
        const mouseUpStream = fromEvent(window, "mouseup");
        mouseDownStream
          .pipe(
            tap((event: MouseEvent) => {
              this.ctx.beginPath();
              this.ctx.strokeStyle = "black";
              this.ctx.lineWidth = 5;
              this.ctx.lineJoin = "round";
              this.ctx.moveTo(event.offsetX, event.offsetY);
            }),
            switchMap(() =>
              mouseMoveStream.pipe(
                tap((event: MouseEvent) => {
                  this.ctx.lineTo(event.offsetX, event.offsetY);
                  this.ctx.stroke();
                }),
                takeUntil(mouseUpStream),
                finalize(() => {
                  // this.ctx.closePath();
                  this.saveSign(this.ctx);
                })
              )
            )
          )
          .subscribe((data) => {
            // console.log(data);
          });
      });
  }

  close(toclose: boolean) {
    if (toclose) {
      this.ce.errorCalendar("Save Consent Form?");
      // this.es.saveForm();
    } else this.closed.emit("1");
  }

  closeFromLinks(toclose: boolean) {
    if (toclose) {
      this.closed.emit("1");
    }
  }

  closeSendLinks(openSocket: boolean) {
    this.showlink = false;
    this.sendonce = true;
    if(openSocket) {
    this.waitingForSignatures = true;
    if (!this.signatureSubscription) {
      this.signatureSubscription = this.patS.signatureIncoming.subscribe(
        (s) => {
          this.es.getUpDateConcent().subscribe((concent) => {
            this.es.concentForm = concent;
            this.waitingForSignatures = false;
            const sign = this.checkApprovedConcent(this.es.concentForm);
            if (sign) {
              this.closed.emit("1");
              this.errorInSignature = false;
            } else {
              this.errorInSignature = true;
              this.waitingForSignatures = false;
            }
          });
        }
      );
    }
  }
  }

  setCanvas(type) {
    if (!this.lockedConsent) {
      switch (type) {
        case "canvas":
          this.type = type;
          this.ctx = this.canvas.nativeElement.getContext("2d");
          this.typecanvas = this.canvas;
          this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

          break;
        case "canvasPat":
          this.type = type;
          this.ctx = this.canvasPat.nativeElement.getContext("2d");
          this.typecanvas = this.canvasPat;
          this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

          break;
        case "canvasApp":
          this.type = type;
          this.ctx = this.canvasApp.nativeElement.getContext("2d");
          this.typecanvas = this.canvasApp;
          this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });

          break;
        case "canvasDoc":
          this.type = type;
          this.ctx = this.canvasDoc.nativeElement.getContext("2d");
          this.typecanvas = this.canvasDoc;
          this.changeCanvas$.next({ canvas: this.typecanvas, ctx: this.ctx });
          break;
      }
    }
  }

  loadSign(type = null) {
    switch (type) {
      case "patSign":
        var ctx = this.canvas.nativeElement.getContext("2d");
        const imgpatSign = document.createElement("img");
        imgpatSign.onload = function () {
          ctx.drawImage(imgpatSign, 0, 0); // Or at whatever offset you like
        };
        imgpatSign.src = this.concentForm.get("patSign").value;
        break;
      case "patSignApproved":
        var ctx = this.canvasPat.nativeElement.getContext("2d");
        const imgpatSignApproved = document.createElement("img");
        imgpatSignApproved.onload = function () {
          ctx.drawImage(imgpatSignApproved, 0, 0); // Or at whatever offset you like
        };
        imgpatSignApproved.src = this.concentForm.get("patSignApproved").value;
        break;
      case "appSign":
        var ctx = this.canvasApp.nativeElement.getContext("2d");
        const imgappSign = document.createElement("img");
        imgappSign.onload = function () {
          ctx.drawImage(imgappSign, 0, 0); // Or at whatever offset you like
        };
        imgappSign.src = this.concentForm.get("appSign").value;
        break;
      case "docSign":
        var ctx = this.canvasDoc.nativeElement.getContext("2d");
        const imgdocSign = document.createElement("img");
        imgdocSign.onload = function () {
          ctx.drawImage(imgdocSign, 0, 0); // Or at whatever offset you like
        };
        imgdocSign.src = this.concentForm.get("docSign").value;
        break;

      default:
        break;
    }
  }
  clear(type) {
    switch (type) {
      case "patSign":
        var ctx = this.canvas.nativeElement.getContext("2d");
        ctx.clearRect(
          0,
          0,
          this.canvas.nativeElement.width,
          this.canvas.nativeElement.height
        );
        this.concentForm.get('patSign').setValue('');
        break;
      case "patSignApproved":
        var ctx = this.canvasPat.nativeElement.getContext("2d");
        ctx.clearRect(
          0,
          0,
          this.canvasPat.nativeElement.width,
          this.canvasPat.nativeElement.height
        );
        this.concentForm.get('patSignApproved').setValue('');
        break;
      case "appSign":
        var ctx = this.canvasApp.nativeElement.getContext("2d");
        ctx.clearRect(
          0,
          0,
          this.canvasApp.nativeElement.width,
          this.canvasApp.nativeElement.height
        );
        this.concentForm.get('appSign').setValue('');
        break;
      case "docSign":
        var ctx = this.canvasDoc.nativeElement.getContext("2d");
        ctx.clearRect(
          0,
          0,
          this.canvasDoc.nativeElement.width,
          this.canvasDoc.nativeElement.height
        );
        this.concentForm.get('docSign').setValue('');
        break;
      default:
        break;
    }
  }

  saveSign(ctx) {
    let canvasUrl;
    switch (this.type) {
      case "canvas":
        canvasUrl = this.canvas.nativeElement.toDataURL("image/png");
        this.concentForm.get("patSign").setValue(canvasUrl);
        break;
      case "canvasPat":
        canvasUrl = this.canvasPat.nativeElement.toDataURL("image/png");
        this.concentForm.get("patSignApproved").setValue(canvasUrl);
        break;
      case "canvasApp":
        canvasUrl = this.canvasApp.nativeElement.toDataURL("image/png");
        this.concentForm.get("appSign").setValue(canvasUrl);
        break;
      case "canvasDoc":
        canvasUrl = this.canvasDoc.nativeElement.toDataURL("image/png");
        this.concentForm.get("docSign").setValue(canvasUrl);
        break;

      default:
        break;
    }
  }

  async printConsent() {
    let printUrl: string;
    let autoIndex;

    if (this.concentForm.get("AutoIndex").value) {
      autoIndex = this.concentForm.get("AutoIndex").value;
      printUrl = `api/newExams/printConsent/${autoIndex}`;
    } else {
      let data: any;
      data = await this.save();
      autoIndex = data?.AutoIndex;
      printUrl = `api/newExams/printConsent/${autoIndex}`;
    }

    this.closed.emit("1");

    const body = {
      AutoIndex: autoIndex,
      value: 1,
    };

    this.http.post(`/api/consent/update-printed`, body).subscribe((data) => {});

    window.open(printUrl);
  }

  toggleSave(): boolean {
    let disable: boolean = false;
    if (
      this.concentForm.get("translated").value == 1 &&
      !(
        this.concentForm.get("transName").value &&
        this.concentForm.get("transRel").value
      )
    ) {
      disable = true;
    } else if (
      this.concentForm.get("approved").value == "appotropus" &&
      !(
        this.concentForm.get("appName").value &&
        this.concentForm.get("appRel").value
      )
    ) {
      disable = true;
    } else if (
      (this.concentForm.get("approved").value === "paitent" &&
        !this.concentForm.get("patSign").value) ||
      !this.concentForm.get("patSignApproved").value ||
      !this.concentForm.get("docSign").value
    ) {
      disable = true;
    } else if (!this.concentForm.get("approved").value) {
      disable = true;
    } else if (
      this.concentForm.get("approved").value === "appotropus" &&
      (!this.concentForm.get("patSign").value ||
        !this.concentForm.get("docSign").value)
    ) {
      disable = true;
    }

    return disable;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sub.unsubscribe();
    if (this.signatureSubscription) {
      this.signatureSubscription.unsubscribe;
    }
  }

  checkApprovedConcent(consent: any) {
    let valid = false;
    if (
      consent.approved &&
      consent.approved !== "appotropus" &&
      consent.patSign &&
      consent.patSignApproved &&
      consent.docSign
    ) {
      valid = true;
    } else if (
      consent.approved &&
      consent.approved === "appotropus" &&
      consent.patSign &&
      consent.docSign
    ) {
      valid = true;
    }
    return valid;
  }

  public handleSaveAndShowLink(): void {
    this.save();
    this.showlink = true;
  }

  linkTooltipMsg(): string {
    if (this.lockedConsent) {
      return ""
    } else if (this.concentForm.get('ExamType').value!=='NT' && !this.concentForm.get('weekNum').value) {
      return "Please fill the week field"
    } else if (!this.concentForm.get('docSign').value) {
      return "Doctor sign is required"
    } else {
      return "Send Consent to Patient"
    }
  }
}
