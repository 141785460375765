<!-- round section (start) -->
<section class="t-fieldset-round doppler-measuremet" [ngClass]="mvClass">

        <div class="t-fieldset-round-in">
        
            <h3>
                {{Mname}} 
                <span *ngIf="Editing" style="font-size: 0.5em;">
                    &nbsp; 
                    {{!this.es.isEitherLMPDataMissing ? "Normal: " + getRange() : "" }}
                </span>

                <span class="measurment-unit-dim">
                    <span>
                            <input *ngIf="!es.isClosed && Artery != ''" type="number" class="nval"  [(ngModel)]="MyMeasure.value" (keyup)="checkNum()" (blur)="finishEdit()" (focus)="Editing=true">
                            {{!es.isClosed && Artery !='' ? '': MyMeasure.value}}
                    </span>
                </span>
                
            </h3>

            <div class="t-field l-flex t-twin-fieldset" (dblclick)="resetyn()">
                <label>
                    <input type="radio" [name]="OK" class="custom-check" [value]="0" [formControl]="fg[OK]">
                    <i class="customInput-display"></i>
                    <span>
                        OK
                    </span>
                </label>
                <label>
                    <input type="radio" [name]="OK" class="custom-check" [value]="1" [formControl]="fg[OK]">
                    <i class="customInput-display"></i>
                    <span>
                        Not OK
                    </span>
                </label>
            </div>

            <footer>
                
                <dl class="l-table measurment-unit-data"  *ngIf="MyMeasure.GG_opts">
                    <div class="l-row measurment-unit-row">
                        <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span [ngClass]="ggwarn" [attr.title]="ggwarn ? 'The GA from LMP is outside of the bounds of this GG table' : undefined">
                                GG by {{MyMeasure.GGfig}}
                            </span>
                            <div [ngClass]="MyMeasure.GG_opts.length > 1? 'measurment-switch' : ''">
                                <div class="on-hoverPanel measurment-listHold">
                                    <div class="on-hoverPanel-body">
                                        <ul class="ul-norm measurment-list">
                                                <li *ngFor="let ggfig of MyMeasure.GG_opts" (click)=setGG(ggfig) class="measurment-list-item" [ngClass]="ggfig==MyMeasure.GGfig?'is-checked':''">
                                                        {{ggfig}}
                                                 </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </dt>
                        <dd class="l-td-m measurment-cell measurment-unit-value"  (mouseover)='toggleChart()'>
                            <div class="measurment-unit-value-bubble" *ngIf="MyMeasure.GG && !es.isEitherLMPDataMissing"><i></i>
                                <span>
                                        {{MyMeasure.GG}}%
                                </span>

                                <div class="on-hoverPanel measurment-chartHold">
                                    <div class="on-hoverPanel-body" width="300" height="300">
                                        <canvas *ngIf="showChart" baseChart [datasets]="chartData"
                                        [options]="lineChartOptions"
                                        [legend]="true"
                                        [chartType]="'scatter'"
                                    ></canvas>
                                    </div>
                                </div>
                                    
                            </div>
                        </dd>
                    </div>
                </dl>
                
            </footer>

        </div>
            
    </section>
    <!-- round section (end) -->
