<div class="body-wrapper" *ngIf="print.loaded" [ngClass]="Direction" style='padding-top: 0;'>
    <header class="header">
        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"></div>
        <div class="justify-between items-center" *ngIf="print.env && print.env['haslogo'] && ! altHeader">
            <img style="width: 100%;" [src]="! print.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo">
        </div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title" style="width: max-content;font-size: 36px;">{{'exam.type.gastro.'+print.exam.ExamType | translate}}</h1>
            <!-- <img [src]="! print.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo"
                class="header-logo" *ngIf="print.env && ! altHeader && print.env['haslogo']"> -->
        </div>
        <div class="header-details" style="margin-bottom: 3em;">
            <div style="display: inline-block; padding-left: 1em;padding-right: 1em;font-size: 12pt;">{{(print.isForm ? 'general.date'
                :'general.ExaminationDate')| translate}}:&nbsp;<strong style='direction: ltr;'>{{print.exam.CreationTime
                    | date:"dd.MM.yyyy"}}</strong></div>
            <div style="display: inline-block;font-size: 12pt">{{'general.Printing_Date' | translate}}:&nbsp;<strong
                    style='direction: ltr;'>{{now| date:"dd.MM.yyyy"}}</strong></div>
        </div>
    </header>
    <main class="main" [ngClass]="typeOfPrint">
        <section class="section" *ngIf="print.Pat">
            <h4 class="text-20 text-gold">{{(print.Pat.Sex ? (print.Pat.Sex == 'M' ?  'gastro.Patient.male' : 'gastro.Patient.female'): 'gastro.Patient.details') | translate}}</h4>
            <div style="width:100%">
                <div>
                    <table>
                        <tr>
                            <td>
                                <dt>{{'general.Patient.first_name' | translate}}</dt>
                                <dd>{{print.Pat.First}}</dd>
                            </td>
                            <td>
                                <dt>{{'general.Patient.last_name' | translate}}</dt>
                                <dd>{{print.Pat.Last}}</dd>
                            </td>
                            <td>
                                <dt>{{'general.Patient.ID' | translate}}</dt>
                                <dd>{{print.Pat.ID}}</dd>
                            </td>
                            <td *ngIf="print.Pat.Mobile">
                                <dt>{{'general.Patient.mobile' | translate}}</dt>
                                <dd>{{print.Pat.Mobile}}</dd>
                            </td>
                            <td *ngIf="print.Pat.Sex">
                                <dt>מגדר</dt>
                                <dd>{{print.Pat.Sex == 'M'? 'גבר': 'אישה' }}</dd>
                            </td>
                        </tr>
                        <tr *ngIf="print.Pat.Height || print.Pat.Weight">
                            <td *ngIf="print.Pat.Birth">
                                <dt>{{'general.Patient.birth_date' | translate}}</dt>
                                <dd>{{print.Pat.Birth| date:"dd.MM.yyyy"}}</dd>
                            </td>
                            <td *ngIf="print.Pat.Birth">
                                <dt>{{'general.Patient.Age' | translate}}</dt>
                                <dd>{{print.Pat.getAgeMo()}}</dd>
                            </td>
                            <td *ngIf="print.Pat.Height">
                                <dt>גובה</dt>
                                <dd>{{print.Pat.Height}}cm</dd>
                            </td>
                            <td *ngIf="print.Pat.Weight">
                                <dt>משקל</dt>
                                <dd>{{print.Pat.Weight}}kg</dd>
                            </td>
                        </tr>
                    </table>

                    
                </div>
            </div>
        </section>
        <section class="section" *ngIf="print.gastro && print.gastro[0] && (print.gastro[0].TimeOut || (print.gastro[0].imag1 || print.gastro[0].imag2 || print.gastro[0].scope1 || print.gastro[0].scope2))">
            <h4>{{'gastro.preparetion.TimeOut' | translate}}</h4>
            <p class="comment">{{print.gastro[0].TimeOut}}</p>
            <hr *ngIf="print.gastro[0] && (print.gastro[0].imag1 || print.gastro[0].imag2 || print.gastro[0].scope1 || print.gastro[0].scope2)" style="margin-top: 0.5em">
            <div style="display: flex;">
                <td class="w-1/3" *ngIf="print.gastro[0] && (print.gastro[0].imag1 || print.gastro[0].imag2)" style="display: flex;"> <div><strong style="margin-inline-end: 1em;"> {{'gastro.preparetion.imagingDevice'| translate}}: </strong></div>  <div style="direction: ltr;display: flex;"> {{print.gastro[0].imag1}} <span *ngIf="print.gastro[0].imag1 && print.gastro[0].imag2"> # </span> {{print.gastro[0].imag2}}</div></td>
                <td class="w-1/3" *ngIf="print.gastro[0] && (print.gastro[0].scope1 || print.gastro[0].scope2)" style="display: flex;"> <div><strong style="margin-inline-end: 1em;"> {{'gastro.preparetion.scope'| translate}}: </strong> </div> <div style="direction: ltr;display: flex;"> {{print.gastro[0].scope1}} <span *ngIf="print.gastro[0].scope1 && print.gastro[0].scope2"> # </span> {{print.gastro[0].scope2}}</div></td>
            </div>
        </section>
        <section class="section" *ngIf="print.gastro && print.gastro[0] && (print.gastro[0].Reasone || print.gastro[0].ASA || print.gastro[0].Mellampeti || print.gastro[0].ReffDoc)">
            <section *ngIf="print.gastro[0] && (print.gastro[0].Reasone || print.gastro[0].ASA || print.gastro[0].Mellampeti)">
                <h4>{{'gastro.preparetion.Reasone' | translate}}</h4>
                <p class="comment">{{print.gastro[0].Reasone}}</p>
                <table *ngIf="print.gastro[0] && (print.gastro[0].ASA || print.gastro[0].Mellampeti)">
                    <tr>
                        <td *ngIf="print.gastro[0].ASA && print.gastro[0].ASA >-1">
                            <dt>דירוג ASA </dt>
                            <dd>{{print.gastro[0].ASA}}</dd>
                        </td>
                        <td *ngIf="print.gastro[0].Mellampeti >-1">
                            <dt>דירוג מלמפטי </dt>
                            <dd>{{print.gastro[0].Mellampeti}}</dd>
                        </td>
                    </tr>
                </table>
            </section>
        </section>
        <section class="section" *ngIf="print.Medication && print.Medication[0]">
            <h4>{{'gastro.preparetion.Medication' | translate}}</h4>
            <table class="l-table-auto medication-table">
                <tr class="l-row medication-thead">
                    <td class="l-td-m" style="width: 10%;">#</td>
                    <td class="l-td-m" style="width: 50%;">{{'gastro.preparetion.Medication_type' | translate}}</td>
                    <td class="l-td-m" style="width: 20%;">{{'gastro.preparetion.Unit' | translate}}</td>
                    <td class="l-td-m" style="width: 20%;">{{'gastro.preparetion.Dose' | translate}}</td>
                </tr>
                <ng-container *ngIf="print.Medication && print.Medication[0]">
                    <tr  class="l-row medication-tbody" *ngFor="let med of print.Medication[0]; index as i">
                        <td class="l-td-m" style="width: 10%;">{{i+1}}</td>
                        <td class="l-td-m" style="width: 50%;direction: ltr;">{{med.Medication}}</td>
                        <td class="l-td-m" style="width: 20%;">{{med.Dose}}</td>
                        <td class="l-td-m" style="width: 20%;">{{med.Unit}}</td>
                    </tr>
                </ng-container>
            </table>
        </section>
        <section class="section" *ngIf=" (print.gastro && print.gastro[0] ) &&(print.gastro[0].Preparetion || print.gastro[0].Comments || print.Medication)">
            <h4 class="text-20 text-gold">{{'gastro.preparetion.title' | translate}}</h4>
                <section class="section" *ngIf="print.gastro[0] && (print.gastro[0].Preparetion || print.gastro[0].BBPS || print.gastro[0].Quality)" style="width: 100%;">
                    <div class="comment">
                        <strong>פרוטוקול הכנה: </strong> {{print.gastro[0].Preparetion}}
                    </div>
                    <table *ngIf="print.es.ExamType !='Gastroscopy' && print.gastro[0] && (print.gastro[0].BBPS >-1)">
                        <tr>
                            <td>
                                <dt>דירוג הכנה לפי בוסטון</dt>
                                <dd>{{print.gastro[0].BBPS}}</dd>
                            </td>

                        </tr>
                    </table>
                    <table *ngIf="print.gastro[0].Quality">
                        <tr>
                            <td>
                                <dt>איכות ההכנה</dt>
                                <dd>{{'gastro.preparetion.Quality.'+print.gastro[0].Quality | translate}}</dd>
                            </td>

                        </tr>
                    </table>
                </section>
                <section class="section" *ngIf="print.gastro[0] && print.gastro[0].Comments">
                    <h4>{{'gastro.preparetion.Comments' | translate}}</h4>
                    <p class="comment">{{print.gastro[0].Comments}}</p>
                </section>
        </section>
        <section class="section" *ngIf="print.gastro && print.gastro[0] && (print.gastro[0].Colonoscopy || print.gastro[0].FindingsComment || (print.gastro[0].DetailsFindings && print.gastro[0].DetailsFindings.length >0) || (print.gastro[0].Esophagus > -1 || print.gastro[0].EsophagusComment) || print.gastro[0].EsoJunction || (print.gastro[0].Stomach > -1 || print.gastro[0].StomachComment) || (print.gastro[0].Duodenum > -1 || print.gastro[0].DuodenumComment) || print.gastro[0].ColoComment || print.gastro[0].ExtentOfExam)">
            <h4 class="text-20 text-gold">{{'gastro.summary.title' | translate }}</h4>
            <section class="section" *ngIf="print.gastro[0] && print.gastro[0].ExtentOfExam">
                <h2>{{'gastro.preparetion.ExtentOfExam' | translate}}</h2>
                <div style="white-space: pre-wrap;">
                    {{print.gastro[0].ExtentOfExam}}
                </div>
            </section>
            <section class="section">
                <h2>ממצאים</h2>
                <table *ngIf="print.exam.ExamType !== 'Gastroscopy'">
                    <tr *ngIf="print.gastro[0].Colonoscopy > -1">
                        <td>
                            {{(print.gastro[0].Colonoscopy == 1  ? '' : 'gastro.Findings.NPD') |
                            translate}}
                        </td>
                        <td *ngIf="print.gastro[0].Colonoscopy == 0 && print.gastro[0].FindingsComment">
                            <dt style="width: fit-content;">{{ 'gastro.preparetion.Comments' | translate}}</dt>
                            <dd>{{print.gastro[0].FindingsComment }}</dd>
                        </td>
                    </tr>
                    <tr *ngIf="print.gastro && print.gastro[0] && print.gastro[0].Annus">
                        <td>
                            <strong>{{'gastro.Findings.Anus' | translate}}</strong> {{print.gastro[0].Annus}}
                        </td>
                    </tr>
                    <ng-container *ngIf="print.gastro[0].Colonoscopy == 1">
                        <div *ngFor="let d of print.gastro[0].DetailsFindings;index as i">
                            <tr style="width: 100%;display: flex;">
                                <td style="width: 30%;display: flex;" *ngIf="d.value || d.value === 0 || d.value === 1 || d.comment">
                                    <div style="width: 50%;">
                                        <strong>{{'gastro.Findings.type.'+d.db | translate}}: </strong>
                                    </div>
                                    <div style="width:50%" *ngIf="d.value === 0 || d.value === 1">
                                        {{(d.value == 1 ? 'gastro.Findings.abnormal' : 'gastro.Findings.normal') | translate}}
                                    </div>
                                </td>
                                <td style="width: 70%;" *ngIf="d.comment">
                                    <strong>{{'gastro.preparetion.Comments' | translate}}:  </strong>
                                    {{d.comment}}
                                </td>
                            </tr>
                        </div>
                    </ng-container>
                </table>
                <table *ngIf="print.exam.ExamType == 'Gastroscopy'">
                    <tr *ngIf="print.gastro[0].Esophagus > -1 || print.gastro[0].EsophagusComment">
                        <td style="width: 10em;">
                            <dt style="width: 5em;">{{'gastro.Findings.Esophagus' | translate}}</dt>
                            <dd *ngIf="print.gastro[0].Esophagus > -1">{{(print.gastro[0].Esophagus == 1  ? 'gastro.Findings.abnormal' : 'gastro.Findings.normal') |
                                translate}}</dd>
                        </td>
                        <td *ngIf="print.gastro[0].EsophagusComment">
                            <dt>{{'gastro.preparetion.Comments' | translate}}</dt>
                            <dd>{{print.gastro[0].EsophagusComment}}</dd>
                        </td>
                    </tr>
                    <tr *ngIf="print.gastro[0].EsoJunction || print.gastro[0].EsoJunctionComment">
                        <td style="width: 10em;">
                            <dt>{{'gastro.Findings.EsoJunction' | translate}}</dt>
                            <dd *ngIf="print.gastro[0].EsoJunction">{{print.gastro[0].EsoJunction}}cm</dd>
                        </td>
                        <td *ngIf="print.gastro[0].EsoJunctionComment">
                            <dt>{{'gastro.preparetion.Comments' | translate}}</dt>
                            <dd>{{print.gastro[0].EsoJunctionComment}}</dd>
                        </td>
                    </tr>
                    <tr *ngIf="print.gastro[0].Stomach > -1 || print.gastro[0].StomachComment">
                        <td style="width: 10em;">
                            <dt style="width: 5em;">{{'gastro.Findings.Stomach' | translate}}</dt>
                            <dd *ngIf="print.gastro[0].Stomach > -1 ">{{(print.gastro[0].Stomach == 1  ? 'gastro.Findings.abnormal' : 'gastro.Findings.normal') |
                                translate}}</dd>
                        </td>
                        <td *ngIf="print.gastro[0].StomachComment">
                            <dt>{{'gastro.preparetion.Comments' | translate}}</dt>
                            <dd>{{print.gastro[0].StomachComment}}</dd>
                        </td>
                    </tr>
                    <tr *ngIf="print.gastro[0].Duodenum > -1 || print.gastro[0].DuodenumComment">
                        <td style="width: 10em;">
                            <dt style="width: 5em;">{{'gastro.Findings.Duodenum' | translate}}</dt>
                            <dd *ngIf="print.gastro[0].Duodenum > -1 ">{{(print.gastro[0].Duodenum == 1  ? 'gastro.Findings.abnormal' : 'gastro.Findings.normal') |
                                translate}}</dd>
                        </td>
                        <td *ngIf="print.gastro[0].DuodenumComment">
                            <dt>{{'gastro.preparetion.Comments' | translate}}</dt>
                            <dd>{{print.gastro[0].DuodenumComment}}</dd>
                        </td>
                    </tr>
                </table>
                <section class="section" *ngIf="print.gastro[0].ColoComment">
                    <h4>{{'gastro.preparetion.Comments' | translate}}</h4>
                    <p class="comment">{{print.gastro[0].ColoComment}}</p>
                </section>
            </section>
            <section class="section" *ngIf="(print.pathologyS.pathologies && print.pathologyS.pathologies.length > 0) ||(print.gastro[0] && print.gastro[0].PathologyComments)">
                <h2>{{'gastro.Pathology.title' | translate}}</h2>
                <table class="l-table-auto weightAss-table" *ngIf="print.pathologyS.pathologies && print.pathologyS.pathologies.length > 0">
                    <tr class="l-row weightAss-thead">
                        <td class="l-td-m" style="width: 10%;">#</td>
                        <td class="l-td-m" style="width: 30%;">{{'gastro.Pathology.Type' | translate}}</td>
                        <td class="l-td-m" style="width: 60%;">{{'gastro.preparetion.Comments' | translate}}</td>
                    </tr>
                    <ng-container *ngFor="let p of print.pathologyS.pathologies;index as i">
                        <tr class="l-row weightAss-tbody">
                            <td class="l-td-m" style="width: 10%;">{{i+1}}</td>
                            <td class="l-td-m" style="width: 30%;">{{p.Type}}</td>
                            <td class="l-td-m" style="width: 60%;">{{p.Comments}}</td>
                        </tr>
                    </ng-container>
                </table>
                <section *ngIf="print.gastro[0] && print.gastro[0].PathologyComments" style="width: 100%;">
                    <div class="comment section-inner" style="width: 100%;">
                        <h4>{{'gastro.preparetion.Comments' | translate}}</h4>
                        {{print.gastro[0].PathologyComments}}
                    </div>
                </section>
            </section>
            <section class="section" *ngIf="print.gastro[0] && (print.gastro[0].DiagnosisList?.length > 0 || print.gastro[0].Diagnosis)">
                <h2>{{'gastro.summary.diagnosisList' | translate}}</h2>
                <ng-container *ngIf="print.gastro[0].DiagnosisList" >
                    <table><tr *ngFor="let d of print.gastro[0].DiagnosisList">
                        <p class="comment" style="text-align: justify;">
                            {{d.value}}
                        </p>
                    </tr></table>
                </ng-container>
                <hr *ngIf="(print.gastro[0].DiagnosisList && print.gastro[0].DiagnosisList?.length > 0) && print.gastro[0].Diagnosis">
                <section *ngIf="print.gastro[0].Diagnosis" style="text-align: justify;">
                    <p class="comment">{{print.gastro[0].Diagnosis}}</p>
                </section>
            </section>
        </section>
        <ng-container>
            <section class="section" *ngIf="print.gastro && ((print.gastro[0] && print.gastro[0].Dissmission) || (print.gastro[0] && print.gastro[0].OtherStaff) ||(print.gastro[0] && print.gastro[0].Recomm) || (print.gastro[0] && print.gastro[0].Recovery)|| (print.gastro[0] && print.gastro[0].ReffDoc)|| (print.gastro[0] && print.gastro[0].HMO) || (print.gastro[0] && (print.gastro[0].imag1 || print.gastro[0].imag2 || print.gastro[0].scope1 || print.gastro[0].scope2)))">
                <h4 class="text-20 text-gold">התאוששות ושחרור</h4>
                <section class="section" *ngIf="print.gastro[0].Recovery">
                    <h4>{{'gastro.Recovery' | translate }}</h4>
                    <p class="comment" style="font-size: small;">{{print.gastro[0].Recovery}}</p>
                </section>
                <section class="section" *ngIf="print.gastro[0].Dissmission">
                    <h4>{{'gastro.Dissmission' | translate}}</h4>
                    <p class="comment" style="font-size: small;">{{print.gastro[0].Dissmission}}</p>
                </section>
                <section class="section" *ngIf="print.gastro[0].Recomm">
                    <h4>{{'gastro.Recomm' | translate }}</h4>
                    <p class="comment">{{print.gastro[0].Recomm}}</p>
                </section>
                <section *ngIf="print.gastro[0] && print.gastro[0].ReffDoc">
                    <strong>רופא מפנה: </strong> {{print.gastro[0].ReffDoc}}
                </section>
                <section *ngIf="print.gastro[0] && print.gastro[0].HMO">
                    <strong>גורם מפנה: </strong> {{print.gastro[0].HMO}}
                </section>
                <section *ngIf="print.gastro[0] && print.gastro[0].OtherStaff">
                    <strong>שם האח\ות: </strong> {{print.gastro[0].OtherStaff}}
                </section>

            </section>
        </ng-container>
        <section class="section">
            <h4>
מסמך זה מהווה הפניה רפואית למיון                    
            </h4>
        </section>
        <section class="section">
            <table>
                <tr *ngFor="let signer of print.signers;let i = index">
                    <td class="w-1/3"><strong>{{(signer['Title'] ? signer['Title']+ ' ': '')+ signer.Name}}</strong>
                    </td>
                    <td class="w-1/3">
                        <ng-container *ngIf="signer.DrNum">מ.ר. <strong>{{signer.DrNum}} </strong></ng-container>
                        <ng-container *ngIf="signer.ExpertNum">מ.ר.מ. <strong>{{signer.ExpertNum}}</strong>
                        </ng-container>
                    </td>
                    <td class="w-1/3" *ngIf="i == 0">{{(print.isForm ? 'general.date' :'general.ExaminationDate') |
                        translate}}: <strong>{{print.exam.CreationTime | date:"dd.MM.yyyy"}}</strong></td>
                </tr>
            </table>
        </section>
        <div *ngIf="hasImages && print.images.length > 0" class="signatures" [ngClass]="'perpage'+imPerPage">
            <ng-container *ngFor="let image of print.images">
                <div style="width: fit-content;display: inline-flex;flex-direction: column;">
                    <img style="padding: 0.5em;" [src]="'/api/getmedia/'+image.src+'/n'">
                    <p *ngIf="image.text" class="section" style="width: 95%;margin-inline-start: 0.5em;" [ngClass]="imPerPage ==2 ? 'w100' :'w95'">{{image.text}}</p>
                </div>
            </ng-container>
        </div>
        <span class="comment" class="finished" *ngIf="loaded"></span>
    </main>
</div>