import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import {AutotextService} from '../autotext.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-autotext',
  templateUrl: './autotext.component.html',
  styleUrls: ['./autotext.component.scss']
})
export class AutotextComponent implements OnInit, OnDestroy {

  rowData;
  Text:string;
  FilterBy;
  sub = new Subscription();
  constructor(public atS: AutotextService, private fb:FormBuilder, public ls:LoginService) {
    
   }

  ngOnInit() {
    this.rowData = null;
    this.atS.filter=1;
    this.reload(1);
    this.Text = this.atS.currentText.value;
    this.resetFilter();

  }
  resetFilter(){
    this.FilterBy = this.fb.group({
      type:[1]
    })
    this.sub = this.FilterBy.get('type').valueChanges.subscribe((data) =>{
      this.atS.filter = data;
      if(data)
        this.reload(data)
      else
        this.reload()
    })
  }


  reload(filter = null){
    if(this.FilterBy?.get('type').value == 1 || !this.ls.isCardio)
      filter = 1;
    this.atS.getTexts(this.atS.currentFieldID.toString(),filter).subscribe((data)=>{
      this.rowData = data;
    },
    (error)=>{
      console.log(error);
    });
  }

  save(){
    this.atS.currentText.setValue(this.Text);
    this.atS.Display=false;
  }

  addText(toadd:string){
    if(this.Text && this.Text != "")
      this.Text += '\n' + toadd;
    else this.Text = toadd;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
