import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "toArray" })
export class ToArrayPipe implements PipeTransform {
  transform(value: any): any {
    if (Array.isArray(value)) {
      return value;
    } else if (value instanceof Set) {
      return [...value];
    } else {
      return [value];
    }
  }
}
