import { Component, OnInit } from "@angular/core";
import { PatientsService } from "../patients.service";
import { PregnancyService } from "../pregnancy.service";
import { ExamsService } from "../exams.service";
import { Router } from "@angular/router";
import { LoginService } from "../login.service";
import { Patient } from "../patients/patient";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FollowUpService } from "../follow-up.service";
import { CalendarService } from "../calendar.service";

@Component({
  selector: "app-follow-up",
  templateUrl: "./follow-up.component.html",
  styleUrls: ["./follow-up.component.scss"],
})
export class FollowUpComponent implements OnInit {
  public showPrint: boolean = false;
  public showPrintOpts: boolean = true;
  public patient: Patient;
  public page: string = "History";
  public showPatientDetailsForm: boolean = false;
  public showFollowUpPage$: Subject<string> = new Subject<string>();
  public Group: string = "Diseases";
  public category: string = "OB STD";
  examname: string;
  public pregnancies: any = null;
  pregExams;
  closePreg: number = null;
  defaults;
  private destroy$ = new Subject();
  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;
  isPregClosed: boolean;
  formsExams: any[] = [];
  otherExams: any;
  FORMS = ["Consultation Letter"];
  goEx;
  startClinic: number;
  openDocumentsAdd: boolean = false;
  calendarForm: boolean = false;
  selectedForm;
  openNewPregnancy: boolean = false;
  newPregIndex: number = null;
  
  constructor(
    public patientsService: PatientsService,
    public pregnancyService: PregnancyService,
    public followUpService: FollowUpService,
    public examsService: ExamsService,
    public loginService: LoginService,
    public router: Router,
    public cf: CalendarService
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
    this.patient = this.patientsService.getPat();

    this.resetPregs();
  }

  ngOnDestroy(): void {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }
    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  setPage(selectedPage: string) {
    this.page = selectedPage;
  }

  setGroup(selectedGroup: string) {
    this.Group = selectedGroup;
  }

  resetPregs(showNewPreg: boolean = false) {
    this.examsService
      .getExams(this.patient?.PID.toString())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.pregnancies = data["pregnancies"];
          for (let item of this.pregnancies) {
            item.calendarForms = [];
            item.Letters = [];
            item.Documents = [];
            item.AdminDocuments = [];
            this.cf
              .getAllCalForms(item.AutoIndex)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                if (data[0].calendar.length > 0) item.calendarForms.push(data[0].calendar[0]);
              });
            this.followUpService
              .getAllLetters(item.AutoIndex, item.ID)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                if (data["letters"] && data["letters"].length > 0) {
                  item.Letters.push(...data["letters"]);
                }
                if (data["Documents"] && data["Documents"].length > 0) {
                  item.Documents.push(...data["Documents"]);
                }
                if (data["AdminDocuments"] && data["AdminDocuments"].length > 0) {
                  item.AdminDocuments.push(...data["AdminDocuments"]);
                }
              });
          }

          this.isPregsOpen(this.pregnancies);

          // Get the number of fetuses for the latest pregnancy:
          this.fetusNumberSub = this.followUpService
            .getFetusesNumber(this.pregnancies[0].AutoIndex)
            .subscribe((data) => {
              this.fetusesNumber = data;
            });

          // Get the obstetrical formula for the latest pregnancy:
          this.obsFormulaSub = this.followUpService
            .getObstetricalFormula(this.pregnancies[0].AutoIndex)
            .subscribe((data) => {
              this.obstetricalFormula = data;
            });

          this.examsService.pregnancies = data["pregnancies"];
          if (this.pregnancies && this.pregnancies.length > 0 && this.pregnancies[0].LMP) {
            this.patient.LMP = this.convertTimeZone(this.pregnancies[0].LMP);
          }
          this.otherExams = data["other"];

          if (this.otherExams.length > 0) {
            this.otherExams.forEach((exam) => {
              if (this.FORMS.includes(exam.ExamType)) {
                this.formsExams.push(exam);
              }
            });
          }
          // Setting the newest pregnancy's AutoIndex:
          this.pregnancyService.pregID = this.pregnancies[0].AutoIndex;
          if (showNewPreg) {
            this.newPregIndex = this.pregnancyService.pregID;
            this.openNewPregnancy = true;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  convertTimeZone(dateToFormat) {
    if (!dateToFormat) return;
    const utcTimestamp = dateToFormat;
    const dateInUTC = new Date(utcTimestamp);

    // Set the time zone to Israel (Asia/Jerusalem)
    const israelTimeZone = "Asia/Jerusalem";
    const options = { timeZone: israelTimeZone, hour12: false };

    // Format the date according to the Israel time zone
    return this.examsService.parseConsentDate(new Date(dateInUTC.toLocaleString("en-US", options)));
  }

  pregClosed() {
    for (let pregnancy of this.pregnancies) {
      if (pregnancy.AutoIndex === this.closePreg) pregnancy.ClosedDate = new Date();
    }
    this.closePreg = null;
    this.isPregsOpen(this.pregnancies);
  }

  isPregsOpen(pregnancies: any[]): boolean {
    const pregnanciesArray = Array.isArray(pregnancies) ? pregnancies : Object.values(pregnancies);
    this.isPregClosed = pregnanciesArray.some((pregnancy) => !pregnancy.ClosedDate);
    return this.isPregClosed;
  }

  newPregnancy() {
    const { PID, ID } = this.patient;

    this.followUpService.newPregnancy(PID, ID).subscribe((data: any) => {
      this.pregnancyService.pregID = data.data.insertId;
      const showNewPreg = true;
      this.resetPregs(showNewPreg);
    });
  }

  newLetter() {
    const { PID, ID } = this.patient;

    this.examsService.startExam("Consultation Letter", PID, ID, null, null, null,this.pregnancyService.pregID,'follow-up');
  }

  newFile() {
    this.openDocumentsAdd = true;
  }

  goLetter(pregid: number, exam, examtype: string, examtime: string) {
    let closed;
    if (exam.ClosedDate) closed = new Date(exam.ClosedDate);

    this.examsService.goExam(pregid, exam, examtype);
  }
  askDelete(
    exam,
    concent = false,
    gynConsent = false,
    unlinkedConsents = false,
    calendarPlanner: boolean = false,
    document = false
  ) {
    // this.plannerToDelete = false;
    // let etime = new Date(exam.CreationTime);
    // if (document) {
    //   this.documentDelete = true;
    //   this.toDelete = exam; /**document */
    // } else if (calendarPlanner) {
    //   this.plannerToDelete = true;
    //   this.toDelete = exam;
    // } else if (this.defaults || !this.examServe.examClosed(etime)) {
    //   this.plannerToDelete = false;
    //   //if the exam is from today allow delete
    //   this.toDelete = {
    //     ...exam,
    //     concent,
    //     gynConsent,
    //     unlinkedConsents,
    //     calendarPlanner,
    //   };
    // }
    // return false;
  }

  openDocument(docId) {
    window.open(`api/patient/getDocument/${docId}`);
  }

  printVisit(visitIndex) {
    const { PID } = this.patient;

    let printUrl = `/api/follow-up/visit/print/${visitIndex}/${PID}/en-US`;
    window.open(printUrl);
  }
  closCalForm(event) {
    this.calendarForm = false;
    this.resetPregs();
  }
  loadCalendarForm(form) {
    this.calendarForm = true;
    this.selectedForm = form;
  }
  navigateToVisit() {
    this.router.navigate(['/follow-up/visit']);
  }
}
