<div class="body-wrapper rtl" *ngIf="loaded" [ngClass]="sts.isGastro ? '' : 'trans'" style='padding-top: 0;max-width: 1500px;'>
    <header class="header">
        <div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader"></div>
        <div class="justify-between items-center" style="margin-bottom: 5px;">
            <h1 class="header-title" style="width: max-content;font-size: 36px;">סיכום בדיקות</h1>
        </div>
        <div style="display: inline-block;font-size: 12pt">{{'general.Printing_Date' | translate}}:&nbsp;<strong
            style='direction: ltr;'>{{now| date:"dd.MM.yyyy HH:mm"}}</strong></div>
    </header>
    <main class="main">
        <table class="formatted" style="text-align-last: center;">
            <tr>
                <td style="width: 20%;">{{'stats.date' | translate}}</td>
                <td style="width: 20%;">
                    <dt>{{'stats.from' | translate}}</dt>
                    <dd>{{form.start | date: "dd-MM-yyyy"}}</dd>
                </td>
                <td style="width: 20%;">
                    <dt>{{'stats.until' | translate}}</dt>
                    <dd>{{form.end | date: "dd-MM-yyyy"}}</dd>
                </td>
                <td style="width: 20%;"></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
        <section class="section" *ngIf="form && sts.examsArray && sts.examsArray.length > 0">
            <div class="text-20 text-gold">
                {{ 'stats.TotalExams' | translate}}
            </div>
            <table>
                <tr *ngFor="let exam of sts.examsArray">
                    <td>
                        <dt>{{(exam == 'All' ? 'stats.All' : 'exam.type.'+(ls.isCardio ? 'Cardio.' : (ls.isGastro? 'gastro.' : 'OBS.'))+exam) | translate}} </dt>
                        <dd> {{form[getDbName(exam)] ? form[getDbName(exam)].length : 0}}</dd>
                    </td>
                </tr>
            </table>
        </section>
        <section class="section" *ngIf="form">
            <section *ngIf="sts.usersStats && sts.usersStats.length > 0 && false" style="direction: ltr;" >
                <div class="text-20 text-gold">
                    {{ 'stats.usersExams' | translate}}
                </div>
                <table class="formatted">
                    <tr class="formatted-title" style="display: initial;">
                        <td style="width: 6em;text-align: center;padding: 0;">
                        <!-- </td> class="w9title"> -->
                            {{ 'stats.name' | translate}}
                        </td>
                        <td style="width: 9em;text-align: center;padding: 0;" *ngFor="let exam of sts.examsArray; index as i">
                            {{(exam == 'All' ? 'stats.All' : 'exam.type.'+(ls.isCardio ? 'Cardio.' : (ls.isGastro ? 'gastro.' : 'OBS.'))+exam) | translate}}
                        </td>
                    </tr>
                    <tr *ngFor="let user of sts.usersStats; index as i" style="border-bottom: solid 1px black;">
                        <td style="width: 6em;text-align: center;padding: 0;">
                        <!-- </td> class="w9"> -->
                           {{ls.isCardio ? user.user.TerminationRemarks : user.user.Name}}
                        </td>
                        <td style="width: 9em;text-align: center;padding: 0;" *ngFor="let exam of sts.examsArray; index as i">
                            {{user.exams[getDbName(exam)] ? user.exams[getDbName(exam)].length  : 0}}
                        </td>
                    </tr>
                </table>
            </section>
            <section>
                <div class="text-20 text-gold">
                    {{ 'stats.examsByHmo' | translate}}
                </div>

                <table class="formatted">
                    <tr *ngFor="let hmo of sts.HMO; index as i">
                        <td>{{i+1}})</td>
                        <td style="width: 8em;padding: 0;">
                        <!-- </td> class="w9title"> -->
                            {{ 'stats.'+hmo | translate}}:
                        </td>
                        <td>
                            {{sts.filterByHMO(sts.statsForm.get('All').value,hmo).length}}
                        </td>
                    </tr>
                    <tr>
                        <td>{{sts.HMO.length + 1 }}) </td>
                        <td style="width: 8em;padding: 0;">
                            {{'stats.notassigned' | translate}}:
                        </td>
                        <td>
                            {{sts.hmoNotAssign.length}}
                        </td>
                    </tr>
                </table>
            </section>
        </section>
    </main>

</div>
