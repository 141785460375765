<ng-container  *ngIf="(MyMeas.value && ! doppler && modality!='Ultrasound') || PeefSet > -1">
    <td class="ps-16" [ngClass]="print.isFetalEcho ? 'wid40' : 'wid20'">{{getName()}}</td>
    <td *ngIf="minmax && PeefSet > -1 && ! (minPEEFF && MyMeas.value)" [ngClass]="minmax ? 'wid10': ''">{{(! minmax ? '' :(PeefSet ? 'general.observed_notobserved.observed' : 'general.observed_notobserved.not_observed') )|translate}}</td>
    <td *ngIf="MyMeas.value" [ngClass]="print.isFetalEcho ? 'wid20 max' : 'wid10' "><strong *ngIf="minmax && PeefSet === 1">{{minmax ? 'Min: ':''}}{{minPEEFF}} cm</strong> {{' '}}<strong *ngIf="(Name === 'PEEFF' && PeefSet === 1) || (Name !== 'PEEFF' && MyMeas.value)">{{minmax ? 'Max: ':''}}{{MyMeas.value}}{{Name.indexOf('EFW')>-1?'g':''}} </strong><strong *ngIf="(print.exam.ExamType ==='Fetal Echo' || print.exam.ExamType==='Pediatric Echo') && Name !== 'IFI'">{{( PeefSet === 0 ? ('general.observed_notobserved.not_observed'):(getDiam())) |translate}}</strong></td>
    <td style="width: 20%;padding-right:0"><strong *ngIf="showGA()">{{MyMeas.GA}}{{weeks}} {{Name.indexOf('EFW')>-1?'Hadlock':MyMeas.GAfig}}</strong></td>
    <td style="width: 20%;padding-right:0" *ngIf="showGG()"><strong >{{inRange() ? MyMeas.GG + (MyMeas.showSD? 'SD':'%') : 'GA outside of'}} {{MyMeas.GGfig}}</strong></td>
    <td class="items-center pe-16" style="width: 20%;padding-right:0" *ngIf="showGG() && inRange()">
        <span class="text-12" *ngIf="false">{{getEnds(1)}}</span>
        <input type="range" min="0" max="100" [value]="MyMeas.GG" disabled>
        <span class="text-12" *ngIf="false">{{getEnds(2)}}</span>
    </td>
</ng-container>

<ng-container *ngIf="doppler && MyMeas.value && MyMeas.value>-1">
    <td class="ps-16" [ngClass]="print.isFetalEcho ? 'wid40' : 'wid20'">{{getName()}}</td>
    <td [ngClass]="print.isFetalEcho ? 'wid30' : 'wid10' " style="padding-right:0"><strong>{{Name === 'PI' ? MyMeas.value : getDoplerValue(MyMeas.value)}}</strong></td>
</ng-container>

<ng-container  *ngIf="(ultrasoundMeasurements['value'] && modality==='Ultrasound' && Name!=='AUA')" style="font-size: 2px;" >
    <td class="ps-16 wid10" >{{getNameForUs()}}</td>
    <td  class='wid10' ><strong>{{ultrasoundMeasurements['value']}}{{Name.indexOf('EFW')>-1?'g':''}} </strong></td>
    <td  style="width: 30%;padding-right:0"> <strong *ngIf="ultrasoundMeasurements['GA']">{{ ultrasoundMeasurements['GA'] | weeksDays }} ({{ (ultrasoundMeasurements['GA'] + 'd') }}) {{ ultrasoundMeasurements['agingCalculatedBy'] }}</strong></td>
    <td  style="width: 20%;padding-right:0"><strong >{{ultrasoundMeasurements['GG']? ultrasoundMeasurements['GG']+'%':""}} {{ultrasoundMeasurements['agingCalculatedBy']}} </strong></td>
    <td *ngIf="ultrasoundMeasurements['GG']" class="items-center pe-16" style="width: 10%;padding-right:0" >
        <input type="range" min="0" max="100" [value]="ultrasoundMeasurements['GG']" disabled>
</ng-container>
 
 
<ng-container  *ngIf="(ultrasoundMeasurements['value'] && modality==='Ultrasound' && Name==='AUA')" style="font-size: 2px;" >
    <td class="ps-16 wid10" >GA (AUA)</td>
    <td  class='wid10' ><strong></strong></td>
    <td  style="width: 30%;padding-right:0"><strong >{{ ultrasoundMeasurements['value'] | weeksDays }} ({{(ultrasoundMeasurements['value'])}}d) </strong></td>
    <td  style="width: 20%;padding-right:0"><strong > </strong></td>
 
</ng-container>
 