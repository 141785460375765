export const createScreeningNTNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  InstanceNumber: null,
  GA,
  NT: "",
  CRL: "",
  Risk: "",
  PAPPA: "",
  FreeBeta: "",
  CombinedRisk: "",
});

export const screeningNTOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  InstanceNumber: null,
  GA: "",
  NT: "",
  CRL: "",
  Risk: "",
  PAPPA: "",
  FreeBeta: "",
  CombinedRisk: "",
};

export const createScreening2ndNewData = (
  PID: number,
  PregIndex: number,
  ID: string,
  GA: string = "",
  Date: Date = null
) => ({
  PID,
  PregIndex,
  ID,
  Date,
  InstanceNumber: null,
  GA,
  AFP: "",
  FreeBeta: "",
  UE3: "",
  Inhibin: "",
  Risk: "",
});

export const screening2ndOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: null,
  InstanceNumber: null,
  GA: "",
  AFP: "",
  FreeBeta: "",
  UE3: "",
  Inhibin: "",
  Risk: "",
};

export const createScreeningRisksNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  ID,
  InstanceNumber: null,
  CalculatedBy: "",
  Risk: "",
  Comments: "",
});

export const screeningRisksOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  InstanceNumber: null,
  CalculatedBy: "",
  Risk: "",
  Comments: "",
};
