<!-- ==================================== POPUP : GRAPHS (STARTS) ============================================== -->

<!-- <div class="t-popup popup-graphs" style='display: block;height:100%'> -->
    <div class="t-popup popup-graphs" style='display: block;height:100%'><!--          this.examservice.isForm && ! pdfToShow ? 't-popup popup-graphs form':'t-popup popup-graphs'"--> 
    

    <div class="popup-close"></div>
    <div class="popup-frame">
        <div class="popup-body">
            <h2>Select Language</h2>
            <div class="graphs-container">
                <div class="l-flex-c t-set" *ngIf="ShowOptions">
                    <div class="t-set-title"><!--    *ngIf="!this.examservice.isForm">-->
                        <div class="l-flex-c">
                            <!-- <label>
                                <input type="checkbox" matTooltip="Change The existing Title"
                                    name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="changeTitle" />
                                <i class="customInput-display"></i>
                                <span>
                                    Change Title
                                </span>
                            </label> -->
                            <!-- <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.printCharts" />
                                <i class="customInput-display"></i>
                                <span>
                                    Print Charts
                                </span>
                            </label> -->
                            <!-- <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    (click)="showGallery=true" [(ngModel)]="pdf.printImages" />
                                <i class="customInput-display"></i>
                                <span>
                                    Print selected Images
                                </span>
                            </label> -->
                            <label *ngIf="pdf.printImages">
                                &nbsp;
                                <select [(ngModel)]="pdf.perPage" class="t-select t-field menstural-field">
                                    <option value="2">2</option>
                                    <option value="6">6</option>
                                </select>
                                <span>
                                    Per Page
                                </span>
                            </label>
                            <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.newTab" />
                                <i class="customInput-display"></i>
                                <span>
                                    Open in new tab
                                </span>
                            </label>
                            <label *ngIf="login.letterhead">
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.letterhead" />
                                <i class="customInput-display"></i>
                                <span>
                                    Use preprinted paper
                                </span>
                            </label>

                        </div>
                    </div>
                    <div *ngIf="! login.printLang">
                        <label for="select_fetus" class="t-select-label" style='margin-left: 1em;'>Language</label>
                        <select id="select_fetus" class="t-field t-select" [(ngModel)]="pdf.Lang">
                            <option value='en-US'>English</option>
                            <option value='he-IL'>עברית</option>
                            <!--<option value='ar-JO'>عربى</option>
                                    <option value='it-IT'>Italiano</option>
                                    <option value='es-ES'>Español</option>-->
                        </select>
                    </div>
                    <div *ngIf="! login.printStyle">
                        <label for="select_fetus" class="t-select-label" style='margin-left: 1em;'>Print Style</label>
                        <select id="select_fetus" class="t-field t-select" [(ngModel)]="pdf.Style">
                            <option value='simple'>Plain</option>
                            <option value='fancy'>Decorated</option>
                        </select>
                    </div>
                    <button class="search-btn" (click)='getPDF()' style='width: 4em; margin-left: 1em'
                        *ngIf="showGo">Go</button>
                </div>
                <app-gallery *ngIf="pdf.printImages && showGallery && ShowOptions" style="width:100%" [forprint]="true">
                </app-gallery>
                <embed *ngIf="!isPDFLoading && pdfToShow != null" [src]="pdfToShow" type="application/pdf"
                    style='width:95vw;height:80vh;z-index:1'>
                <img *ngIf="isPDFLoading" src="../../assets/images/Wedges.svg"
                    style='z-index:0;margin-left: auto;margin-right:auto;position: absolute;left: 0; right: 0;top: 40vh'>
            </div>
            <button type="button" class="popup-close-button" (click)="closed.emit()">Close printout</button>
        </div>
    </div>

</div>

<!-- ===================================== POPUP : GRAPHS (END)  =============================================== -->