import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../app/patients/patient';
import { Observable, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class PatientsService {

  currentPatient: Patient;
  lmp;
  public Consultation: UntypedFormControl;
  letters: any;
  lettersChange = new Subject<any>();
  awlChange =  new Subject<any>();
  exitFromGastroExam =  new Subject<any>()
  awlIncomming = new Subject<string>();
  signatureIncoming = new Subject<string>();
  AWLStatus = "Waiting"
  AWLSock: WebSocket;
  consent_sock: WebSocket;
  zimunFeedID: any = null;

  constructor(private http: HttpClient, public loginService: LoginService) {
    this.Consultation = new UntypedFormControl();
  }

  async getZimunfeedID() {
    if (!this.loginService.ishospital) {
      try {
        const data = await this.http.get("/api/old-web/zimunfeed").toPromise();
        this.zimunFeedID = data;
      } catch (error) {
        console.log(error);
      }
    }
  }

  savePatient(p: Patient, pid: number = null) {
    let req = this.http.put("api/patient", { 'pid': pid, 'data': p })
    return req
  }

  getPatients(
    id: string,
    fname: string,
    lname: string,
    phone: string,
    uid,
    cb,
    page?: number,
    pageSize?: number
  ): Observable<Patient[]> {
    //let req = this.http.post("api/patient",{'ID':id,'First':fname,'Last':lname,'Phone': phone});
    let req = this.http.post<Patient[]>("api/patient", {
      ID: id,
      First: fname,
      Last: lname,
      Phone: phone,
      uid: uid,
      page: page,
      pageSize: pageSize,
    });
    req.subscribe(
      (data) => {
      cb(data);
    }, (error) => {
      console.log(error);
    });
    return req;
  }

  getPatient(pid: number, cb) {
    this.http.post<Patient>("api/patient", { 'PatID': pid }).subscribe((data) => {
      if (data)
        this.setPat(data);
      cb(true);
    }, (error) => {
      console.log(error);
      cb(false);
    });

  }

  getAWL(status: string, exam, search = null) {
    if (!search)
      return this.http.get('/api/awl/' + status +'/' + exam);
    return this.http.get('/api/awl/' + status + '/'+ exam, { params: search, })
  }

  saveAWL(awlid: number, time: string, lmp: string, examname: string, comment: string, pid: number, prn: string, code: string, examCodes: any[] = null, uid = false) {
    
    let d = new Date().toISOString().slice(0, 10) + ' ' + time;
    let lmpd = null;
    if (lmp) {
      lmpd = new Date(lmp);
      lmpd.setTime(lmpd.getTime() + 5 * 60 * 60 * 1000)
    }
    let details = { LMP: lmpd, ExamDate: d, ExamCode: examname, PID: pid, note: comment, PRN: (prn ? prn : null), Code: (code ? code : null), uid: (uid ? uid : null) };
    if (awlid){
      details['AutoIndex'] = awlid;
      this.awlChange.next(awlid)
    }
    if(examCodes)
      details['examCodes'] = examCodes;
    if(lmp){
      this.lmp=lmp;
    }
    
    return this.http.post('/api/awl', details);
  }

  deleteAWL(awlid: number) {
    return this.http.delete('/api/awl/' + awlid)
  }

  saveAWLComment(comment: string, AWLID: number) {
    this.http.post('/api/awlcomment', { Reason: comment, AutoIndex: AWLID }).subscribe((ok) => { },
      (err) => {
        console.log(err);
      })
  }


  setPat(pat: Patient, defaults = false, awl=null) {
    let p = new Patient(pat.ID, pat.First, pat.Last, pat.Phone, pat.HMO);
    for (let key in pat) {
      if (pat[key])
        p[key] = pat[key];
    }
    if (defaults)
      p.PID = 0;
    this.currentPatient = p;
  }

  getPat(): Patient {
    return this.currentPatient;
  }

  ageAtEDD(edd: Date, months = false, toadd = 0) {
    if (months)
      return this.getAgeMo(edd, toadd);
    return this.currentPatient.getAge(edd);
  }

  getAgeMo(atDate: Date = new Date(), toadd = 0) {
    if (!this.currentPatient.Birth)
      return "";
    let months = toadd + this.monthDiff(new Date(Date.parse(this.currentPatient.Birth)), atDate);//months between birthday and edd (for example)
    let yrs = Math.floor(months / 12);
    let mo = months - yrs * 12;
    return yrs + "y" + mo + "m";
  }
  monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    if (d1.getDate() > d2.getDate())
      months--;
    return months <= 0 ? 0 : months;
  }

  getConsultationLetter(patient) {
    this.http.get(`/api/letters/${patient.GID}/${patient.PID}`).subscribe(
      (res) => {
        this.letters = res;
        this.lettersChange.next(res);
      }
    )
  }

  setConsultationLetter(patient, text) {
    this.Consultation.patchValue({ consulationText: text })
    this.http.post('/api/letters', { patient: patient, FormContent: this.Consultation.value.consulationText, FormType: "Consultation" }).subscribe(
      (res) => {
        console.log(res);
      }
    )
  }

  checkPRN(tz:string,prn:string,treatmentcode: string){
    return this.http.get('/api/leumit/checkprn/'+ tz +'/'+ prn+'/' + treatmentcode);
  }

  getPayment(awlid){
    return this.http.get('/api/cardio/payment/'+awlid);
  }

  macabiLookup(patid: string, idcode: string, awlid: number, exp:string){
    return this.http.get('http://localhost:8088/checkmacabi/'+ patid + '/' + idcode + '/' + awlid+'/'+ (exp? exp:'0'));
  }

  savePaymentAWL(awlid: number,payment){
    return this.http.post('/api/cardio/payment',{
      AWLID: awlid,
      HMOcovered: payment["approved"],
      PatPayment: payment['payment'],
      CollectAtClinic: !payment['macabipay'],
      HMOnote: payment["note"]
    });
  }

  receivePat(awlid:number){
    return this.http.get('/api/cardio/receive/'+ awlid);
  }

  ageRange(age){
    if(! age)
     return false
    var year = age.split("y")[0];
    var month = age.split("y")[1];
    // var year = this.getPat().getAge().split(" ")[0].split(".")[0]
    // var month = this.getPat().getAge().split(" ")[0].split(".")[1]
    if((Number(year) < 20) || Number(year) >= 46){
      return false
    }
    return true;
  }
  setExamCode(title,awlid){
    return this.http.post(`/api/cardio/changeAwlExam/${awlid}`, {title: title});
  }

  openConsentSocket(pid = this.getPat().PID){
    if(! this.consent_sock || this.consent_sock.readyState != this.consent_sock.OPEN){
      let path  =  (window.location.protocol == 'https:' ? 'wss://': 'ws://') + window.location.hostname + (window.location.port? ':' + window.location.port: '') + '/api/consentsocket/' + pid;
      this.consent_sock = new WebSocket(path);
      this.consent_sock.onmessage = (e)=>{
        this.signatureIncoming.next(null);
      }
    }
  }

  closeConsentSocket(){
    if(this.consent_sock)
      this.consent_sock.close();
  }
  openWebSocket(gid = null){
    if(! this.AWLSock || this.AWLSock.readyState != this.AWLSock.OPEN){
      let path  =  (window.location.protocol == 'https:' ? 'wss://': 'ws://') + window.location.hostname + (window.location.port? ':' + window.location.port: '') + '/api/awlsocket' + (gid ? '/' + gid: '');
      this.AWLSock = new WebSocket(path);
      this.AWLSock.onmessage = (e)=>{
        this.awlIncomming.next(null);
      }
    }
  }

  closeWebSocket(){
    if(this.AWLSock)
      this.AWLSock.close();
  }

  findPatBelinson(code:string){
    return this.http.get('/api/belinson/patient/' + code);
  }
  
  getPatAWL(){
    try {
      this.http.get('/api/newExams/getAWL/' +this.getPat().PID).subscribe((data:any) =>{
        this.currentPatient.AWLID = data
      })
    } catch (error) {
      console.log(error);
      
    }
  }

  sendMacabi(startdt,enddt){
    return this.http.get('/api/stats/sendmacabi?from='+startdt+'&until='+enddt);
  }

  reassign(pat){//patient and awl to another doctor
    return this.http.get('/api/awlreassign/'+ pat.AWLID + '/' + pat.PID + '/' + pat.UID);
  }

  copyPat(pid:number,uid: number){
    return this.http.get('/api/copypat/'+ pid + '/' + uid);
  }

  saveSurrogate(surrogate){
    return this.http.post('/api/patient/surrogate',surrogate);
  }

  getSurrogate(pid:number, examid:number = null){
    if(! examid)
      return this.http.get('/api/patient/surrogate/' + pid);
    return this.http.get('/api/patient/surrogate/' + pid+ '/' + examid);
  }

  notifyATD(awlid: number){
    return this.http.get('/api/belinson/atd/'+awlid);
  }

  getPreg(pat:Patient){

  }
}
