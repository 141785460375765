import { Component, OnInit } from "@angular/core";
import { PatientsService } from "../patients.service";
import { MediaTransferService } from "../media-transfer.service";
import { ExamsService } from "../exams.service";
import { LoginService } from "../login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-media-transfer",
  templateUrl: "./media-transfer.component.html",
  styleUrls: ["./media-transfer.component.scss"],
})
export class MediaTransferComponent implements OnInit {
  constructor(
    public mts: MediaTransferService,
    public loginService: LoginService,
    public patientsService: PatientsService,
    public examService: ExamsService,
    private router: Router
  ) {  
    this.loginService.loginMissing();
  }


  ngOnInit(): void {
  }

  togglePopup() {
    this.mts.toggleSuccessMessage = !this.mts.toggleSuccessMessage;
  }

  exit() {
    this.router.navigateByUrl("/patsearch");
  }
}
