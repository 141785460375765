import { Injectable } from '@angular/core';
import { Measure } from './measurements/meaure';
import { AllMeasures} from './measurements/allmeasures';
import {Subject} from 'rxjs';
import { PregnancyService} from './pregnancy.service';
import { HttpClient } from '@angular/common/http';
import {ExamsService} from './exams.service';
import {PatientsService} from './patients.service';
import { UntypedFormControl } from '@angular/forms';
import {LoginService} from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MeasgrowthService {
  
  measref;
  GAlmp: string;
  weightUpdated: Subject<null> = new Subject();
  private History;
  public empty = false;
  public loaded:Subject<null>;
  public dataloaded:boolean;
  EFWdefault: string;
  public historyloaded:boolean;
  Comment: string;
  allWeightAssments: any;
  public allWeightAssmentsSub:Subject<boolean> = new Subject();
  public addEFW : Subject<number> = new Subject();



  constructor(private ps: PregnancyService,private http: HttpClient, private es: ExamsService, private patServ: PatientsService,private loginS: LoginService) {
    this.measref = {};
    this.loginS.ENVLoaded.subscribe(()=>{
      this.loadMeas();
    });
    if(this.loginS.hasENV){//if the env loads before the meas service starts
      this.loadMeas();
    }
    
   }

   private loadMeas(){
    if(AllMeasures.AllMeas.length == 0){
      AllMeasures.init(this.loginS.noneAsMeasDefault);
      for(let i:number =0; i < AllMeasures.AllMeas.length; i++ ){
        this.measref[AllMeasures.AllMeas[i].name] = AllMeasures.AllMeas[i];
      }
      this.EFWdefault = "Hadlock1 (AC,FL)";
      this.measref["EFW 2"].WeightType = "Hadlock4 (BPD,HC,AC,FL)";
      this.loaded=new Subject();

      this.http.get('/api/get_defaults').subscribe((data)=>{
        if(data["EFW"])
          this.EFWdefault = data["EFW"];
      },(err)=>{
        if(err)
          console.log(err);
      });
    }
   }

   addNone(){
    for(let index in this.measref){
      this.measref[index].addNoneToFront();
    }
   }

   setGA(){
     this.GAlmp = this.es.examGA();
   }

   getGALMP(){
     return this.GAlmp;
   }
   

  getAll():Measure[]{
    return AllMeasures.AllMeas;
  }

  getMeas(mname:string,displayName:string ="",gao:string[] = [],ggo:string[]=[],min: number = 0, max: number=1000):Measure{
    if(mname == "Weight1"){
      return this.measref["EFW (AC FL)"];
    }
    if(mname == "Weight4")
      return this.measref["EFW (BPD HC AC FL)"];
    if(this.measref.hasOwnProperty(mname)){
      return this.measref[mname];
    }
    //else for doppler, etc
    //for later just take the options from the file - don't reuire beforehane
    this.measref[mname] = new Measure(mname,gao,ggo,min,max,"","",false,displayName);
    if(this.loginS.noneAsMeasDefault)
      this.measref[mname].addNoneToFront();
    return this.measref[mname];
  }

  getMeasMin(mname){
    this.measref[mname]["myComp"]["measService"]["History"].forEach((i) => {
      if(i.ExamID === this.es.examID){
        this.measref[mname].valuemin = i.PEEFFMIN;
      }
    })
    return this.measref[mname];
  }


  calcGGTable(meas: string, figure: string){
    if(meas != "EFW")
      this.measref[meas].calcGGTable(this.GAlmp,figure);
    if(meas == "HC" || meas == "AC")
      this.CalcHCAC();
    if(meas == "HC" || meas == "AC" || meas == "FL" || meas == "BPD" || meas=="EFW" && this.measref["EFW 1"].WeightType != 'none'){
      if(this.measref.AC.value && this.measref.FL.value){
        this.CalcWeight1();
        if(this.measref.HC.value)
          this.CalcWeight3();
        else{
          if(this.measref["EFW 1"].WeightType == "Hadlock3 (HC,AC,FL)" || this.measref["EFW 1"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
            this.clearMeas("EFW 1")
          }
          if(this.measref["EFW 2"].WeightType == "Hadlock3 (HC,AC,FL)" || this.measref["EFW 2"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
            this.clearMeas("EFW 2")
          }
        }
        if(this.measref.BPD.value)
          this.CalcWeight2();
        else{
          if(this.measref["EFW 1"].WeightType == "Hadlock2 (BPD,AC,FL)" || this.measref["EFW 1"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
            this.clearMeas("EFW 1")
          }
          if(this.measref["EFW 2"].WeightType == "Hadlock2 (BPD,AC,FL)" || this.measref["EFW 2"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
            this.clearMeas("EFW 2")
          }
        }
        if(this.measref.HC.value && this.measref.BPD.value)
          this.CalcWeight4();
        
      }
      else{
        this.clearMeas("EFW 1")
        this.clearMeas("EFW 2")
      }
      this.weightUpdated.next();
    }
    
    
  }

  clearMeas(name: string){
    this.measref[name].value=null;
    this.measref[name].GA="";
    this.measref[name].GG=null;
  }


  CalcHCAC(){
    if(this.measref.AC.value && this.measref.HC.value){
      this.measref.HCAC.value = Math.floor(100 * this.measref.HC.value / this.measref.AC.value) / 100;
      this.measref.HCAC.calcGGTable(this.GAlmp,this.measref.HCAC.GGfig);
      try{this.measref.HCAC.myComp.SDClass();}
      catch{};//for print theres no component to set
    }
  }

  static decialToWeeksDays(weeksd:number,efw=false):string{
    if(efw && weeksd == 9)
      return '<10w';
    if(efw && weeksd == 41)
      return '>40w';
    let weeks = Math.floor(weeksd);
    let days = Math.round((weeksd - weeks) * 7);
    if(days == 7){
      weeks++;
      days = 0
    }
    let dsr = weeks+'w';
    if(days > 0)
      dsr += days + 'd';
    return dsr;
  }

  CalcWeight1(ac = this.measref.AC.value,fl = this.measref.FL.value, adjustEFWs = true)
  {
    let sigma = 15.4;
    let f = fl;
    let a = ac
    if (f != null)
    {
      if (a != null)
      {
        f = f / 10.0;
        a = a / 10.0;
        let af = a*f
        
        let val = 0+1.304+(0.05281 * a) +(0.1938 * f) -(0.004 * af);
        val = Math.pow(10, val)
        val = Math.round(val * 100)/100;
        if(!adjustEFWs)
          return val;
        if(this.measref["EFW 1"].WeightType == "Hadlock1 (AC,FL)"){
          this.measref["EFW 1"].value=val;
          if(this.measref["EFW 1"].GAfigure != 'none')
            this.measref["EFW 1"].GA= MeasgrowthService.decialToWeeksDays(MeasgrowthService.CalcWeightWeeks(val),true);
        }
        if(this.measref["EFW 2"].WeightType == "Hadlock1 (AC,FL)"){
          this.measref["EFW 2"].value=val;
          this.measref["EFW 2"].GA= MeasgrowthService.decialToWeeksDays(MeasgrowthService.CalcWeightWeeks(val),true);
        }
        
      }
    }
    return null;
    
  }

  CalcWeight2(fl = this.measref.FL.value, ac = this.measref.AC.value, bpd = this.measref.BPD.value, adjustEFWs=true)
  {
 
      if(!fl || !ac)
        return null;
      let f = fl / 10.0;
      let a = ac / 10.0;
      let b = bpd;
      
      
      if (b )
      {
        b = b / 10.0;
        let af = a * f;
        
        let val = 1.335 - (0.0034 * af)+  (0.0316 * b)+ (0.0457 * a) + (0.1623 * f);
        val = Math.pow(10, val)
        val = Math.round(val * 100)/ 100;
        if(! adjustEFWs)
          return val;
        let weeks = MeasgrowthService.CalcWeightWeeks(val);
        if(this.measref["EFW 1"].WeightType == "Hadlock2 (BPD,AC,FL)"){
          this.measref["EFW 1"].value=val;
          if(this.measref["EFW 1"].GAfigure != 'none')
            this.measref["EFW 1"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
        }
        if(this.measref["EFW 2"].WeightType == "Hadlock2 (BPD,AC,FL)"  ){
          this.measref["EFW 2"].value=val;
          this.measref["EFW 2"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
        }
        
      }
      return null;
  }

  CalcWeight3(fl=this.measref.FL.value,ac=this.measref.AC.value,hc=this.measref.HC.value,adjustEFWs=true)
  {
      if(!fl || !ac)
        return null;
      let f = fl / 10.0;
      let a = ac / 10.0;
      let h = hc;
      
      if (h )
      {
        h = h / 10.0;
        let af = a * f;
        
        let val = 1.326 - (0.00326 * af)+ (0.0107 * h) +  (0.0438 * a) + (0.158 * f);
        val = Math.pow(10, val)
        val = Math.round(val * 100) / 100
        if(!adjustEFWs)
          return val;
        
        let weeks = MeasgrowthService.CalcWeightWeeks(val);
        if(this.measref["EFW 1"].WeightType == "Hadlock3 (HC,AC,FL)"){
          this.measref["EFW 1"].value=val;
          if(this.measref["EFW 1"].GAfigure != 'none')
          this.measref["EFW 1"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
          // this.addEFW.next(this.measref["EFW 1"])
          this.allWeightAssmentsSub.next(true)
        }
        if(this.measref["EFW 2"].WeightType == "Hadlock3 (HC,AC,FL)"){
          this.measref["EFW 2"].value=val;
          this.measref["EFW 2"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
        }
        
      }
      return null;
    }

  CalcWeight4(fl=this.measref.FL.value,ac=this.measref.AC.value,bpd=this.measref.BPD.value,hc=this.measref.HC.value,adjustEFWs=true)
  {
    let sigma = 14.6;

      if(!fl || !ac)
        return null;
      let f = fl / 10.0;
      let a = ac / 10.0;
      let b = bpd;
      let h = hc;
      
      if (b &&h )
      {
        h = h / 10.0;
        b = b / 10.0;
        let af = a * f;
        let ba = b * a;
        
        let val = 0 + 1.3596 - (0.00386 * af)+ (0.0064 * h) + (0.00061 * ba)+ (0.0424 * a) + (0.174 * f);
        val = Math.pow(10, val)
        val = Math.round(val * 100) / 100
        if(! adjustEFWs)
          return val;
        let weeks = MeasgrowthService.CalcWeightWeeks(val);
        if(this.measref["EFW 1"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
          this.measref["EFW 1"].value=val;
          if(this.measref["EFW 1"].GAfig != 'none')
            this.measref["EFW 1"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
        }
        if(this.measref["EFW 2"].WeightType == "Hadlock4 (BPD,HC,AC,FL)"){
          this.measref["EFW 2"].value=val;
          this.measref["EFW 2"].GA=MeasgrowthService.decialToWeeksDays(weeks,true);
        }
      }

      return null;
  }
  calcEFW(meas){
    var data= [];
    let ga = Math.round((Measure.WeekToDays(this.es.examGA(meas.CreationTime))/7)*10)/10;
    switch(meas.Weight1Type){
      case "Hadlock1 (AC,FL)":
        data.push({
          x:ga,
          y: this.CalcWeight1(meas.AC,meas.FL,false)
        });
        break;
      case "Hadlock2 (BPD,AC,FL)": 
      data.push({
        x:ga,
        y: this.CalcWeight2(meas.FL,meas.AC,meas.BPD,false)
      });
        break;
      case "Hadlock3 (HC,AC,FL)":
          data.push({
            x:ga,
            y: this.CalcWeight3(meas.FL,meas.AC,meas.HC,false)
          });
        break;
      case "Hadlock4 (BPD,HC,AC,FL)":
          data.push({
            x:ga,
            y: this.CalcWeight4(meas.FL,meas.AC,meas.BPD,meas.HC,false)
          });
        break;
    }
    return data;
  }

 static percentile(val: number):number
{
	let abs_val = Math.abs(val)
	let ret = Measure.normal_distribution(abs_val) * 100.0;

	if (val < 0)
		ret = 100 - ret;

	ret = Math.round(ret * 10)/10 
	return ret
}

//this one is a mistake use tables only fow EFW
  CalcWeighAhuzun( calc_weight, sigma)
  {
    let lmp = Measure.WeekToDays(this.GAlmp)/7;
    if (lmp < 10||lmp > 40)
      return;

    let start = 10;
    var arr = new Array(35, 45, 58,73,93,117,146,181,223,273,331,399,478,568,670,785,913,1055,1210,1379,1559,1751,1953,2162,2377,2595,2813,3028,3236,3435,3619)		

    let w = Math.floor(lmp);
    let d = lmp - w;
    let per = (arr[w - start + 1] - arr[w - start]) * d;
    let weight = per + arr[w - start];
    sigma = (sigma * weight) / 100.0
    let dist = (calc_weight - weight) / sigma
    let dev_per = MeasgrowthService.percentile(dist);
    
    return dev_per
  }

  static CalcWeightWeeks(weight:number):number
  {
    if (weight == 0)
      return null;
      
    let start = 10;
    var arr = new Array(35, 45, 58,73,93,117,146,181,223,273,331,399,478,568,670,785,913,1055,1210,1379,1559,1751,1953,2162,2377,2595,2813,3028,3236,3435,3619)
    
    for(var i = 0; i < arr.length; i++)
    {
      if (weight <= arr[i])
      {
        if (i == 0)
          return 9;
        
        let a = weight - arr[i-1];
        let b = arr[i] - arr[i-1];
        let days = a / b;
        let weeks = 0 + (i-1) +days + start*1.0;
        return Math.round(weeks*10)/10
      }
    }
    return 41;
  }

  getAllPoints(meas: string){
    let data=[];
    if(meas.indexOf("EFW")< 0 && meas != "HCAC"){
      if(this.measref[meas].Table == "meas"){
        for(let i=0; i < this.History.length; i++){
          if(this.History[i][meas] && this.History[i].ExamID < this.es.examID){
            let ga = Math.round((Measure.WeekToDays(this.es.examGA(this.History[i].CreationTime))/7)*10)/10;
            data.push({x: ga, y: this.History[i][meas]});
          }
        }
      }
      if(this.measref[meas].value){
        data.push({
          x: Math.round((Measure.WeekToDays(this.getGALMP())/7)*10)/10,
          y: this.measref[meas].value
        });
      }
      return data;
    }
    if(meas == "HCAC"){
      for(let i = 0; i < this.History.length; i++){
        if(this.History[i].HC && this.History[i].AC && this.History[i].ExamID != this.es.examID){
          let ga = Math.round((Measure.WeekToDays(this.es.examGA(this.History[i].CreationTime))/7)*10)/10;
          data.push({
            x: ga,
            y: Math.round(this.History[i].HC/ this.History[i].AC * 10)/10
          });
        }
      }
      if(this.measref.HC && this.measref.AC){
        data.push({
          x: Math.round((Measure.WeekToDays(this.getGALMP())/7)*10)/10,
          y: Math.round(this.measref.HC.value/ this.measref.AC.value * 10)/10
        });
      }
      return data;
    }

    //else for EFW
    for(let i=0; i < this.History.length; i++){
      if(this.History[i].ExamID == this.es.examID)
        break;
      let ga = Math.round((Measure.WeekToDays(this.es.examGA(this.History[i].CreationTime))/7)*10)/10;
      switch(this.measref[meas].WeightType){
        case "Hadlock1 (AC,FL)":
          data.push({
            x:ga,
            y: this.CalcWeight1(this.History[i].AC,this.History[i].FL,false)
          });
          break;
        case "Hadlock2 (BPD,AC,FL)": 
        data.push({
          x:ga,
          y: this.CalcWeight2(this.History[i].FL,this.History[i].AC,this.History[i].BPD,false)
        });
          break;
        case "Hadlock3 (HC,AC,FL)":
            data.push({
              x:ga,
              y: this.CalcWeight3(this.History[i].FL,this.History[i].AC,this.History[i].HC,false)
            });
          break;
        case "Hadlock4 (BPD,HC,AC,FL)":
            data.push({
              x:ga,
              y: this.CalcWeight4(this.History[i].FL,this.History[i].AC,this.History[i].BPD,this.History[i].HC,false)
            });
          break;
      }
    }
    //add current
    data.push({
      x: Math.round((Measure.WeekToDays(this.getGALMP())/7)*10)/10,
      y: this.measref[meas].value
    });
    return data; 
  }

  saveGAGGtoDB(){
    const LMP = this.ps.pregForm.get('LMP').value;
    const LMP_US = this.ps.pregForm.get('LMP_US').value;
    if(this.es.ExamType==='NT' || this.es.ExamType==='1st'|| this.es.ExamType=='Fetal Echo' ) return
    const measuresGA=['BPD','AC','HC','FL','CEREB','EFW1','HL','RADIUS','CL','UL','OFD','OOD','TL','FIBULA','CRL'];
    const measuresGG=['BPD','AC','HC','FL','CEREB','EFW1','HCAC','HL','RADIUS','CL','UL','TC','OFD','OOD','IOD','EarLen','CM','Nuchal','TL','OD','FIBULA'];
    const measValues={}
    for(let measureName in this.measref){
      let measure = this.measref[measureName];
      let name= measure.name.replace(" ","")
      if (measuresGA.includes(name)){
        measValues[`${name}_GAcalc`]= measure.GA
      }
      if (measuresGG.includes(name) && (LMP || LMP_US)){
        measValues[`${name}_GGcalc`]= measure.GG
      }
      if(measure.name=='EFW 1' || measure.name=='HCAC'){
        measValues[name]=measure.value
      }
    }

    
  const requestBody = {
    measValues: measValues,
    examID: this.es.examID,
    fetus: this.es.getFetus()
  };

    this.http.post('/api/saveGAGGtoDB',requestBody).subscribe((data)=>{
      console.log(data);
    })
  }

  async fetchValues(comment:UntypedFormControl = null, forprint=false){
    //reset all values
   for(let m in this.measref){
      if(forprint){
        let mo = this.measref[m];
        this.measref[m] = new Measure(this.measref[m].name,this.measref[m].GAo,this.measref[m].GGo,this.measref[m].GAmin,this.measref[m].GAmax,"meas",null,true,this.measref[m].displayName,false,"",this.loginS.noneAsMeasDefault);
        this.measref[m].GA_tables=mo.GA_tables;
        this.measref[m].GA_opts = mo.GA_opts;
        this.measref[m].GAfig = mo.GAfig;
        this.measref[m].GG_tables=mo.GG_tables;
        this.measref[m].GG_opts = mo.GG_opts;
        this.measref[m].GGfig = mo.GGfig;
      }
      else{
        this.measref[m].value=null;
        this.measref[m].GA=null;
        this.measref[m].GG=null;
      }
    }
    //wait 100ms
    setTimeout(()=>{
      let f = this.http.get("/api/get",{params:this.params()});
      this.dataloaded=false;
      this.historyloaded=false;
      f.subscribe((data)=>{
        this.empty=data.hasOwnProperty('empty');
        if(! this.loginS.noneAsMeasDefault)
          this.measref["EFW 1"].GGfig="Hadlock";//these should get set automatically
        else
          this.measref["EFW 1"].GGfig="none";
        this.measref["EFW 2"].GGfig="Hadlock";
        this.setDolberg();
        for(let key in data){
          if(data[key] == null&& key.indexOf('GAfig') == -1 && key.indexOf('GGfig') == -1)
            continue;
          if(this.measref.hasOwnProperty(key)){
            this.measref[key].value = data[key];
            this.measref[key].calcGATable();
            this.measref[key].calcGGTable(this.es.examGA());
          }
          if(data[key] && key.indexOf("GAfig")> -1){
              this.measref[key.replace("_GAfig","").replace('EFW','EFW ')].GAfig = data[key];
              if(data[key] == 'none')
                this.measref[key.replace("_GAfig","").replace('EFW','EFW ')].GA = null;
          }
          if(key.indexOf("GAfig")> -1 && data[key] != 'none'){
            if(this.measref[key.replace("_GAfig","").replace('EFW','EFW ')].GAfig)
              this.measref[key.replace("_GAfig","").replace('EFW','EFW ')].calcGATable();
          }
          if(data[key] && key.indexOf("GGfig")> -1){
              this.measref[key.replace("_GGfig","").replace('EFW','EFW ')].GGfig = data[key];
              if(data[key] == 'none')
              this.measref[key.replace("_GGfig","").replace('EFW','EFW ')].GG = null;
          }
          if(key.indexOf("GGfig")> -1 && data[key] != 'none'){
            if(this.measref[key.replace("_GGfig","").replace('EFW','EFW ')].GGfig){
              this.measref[key.replace("_GGfig","").replace('EFW','EFW ')].calcGGTable(this.es.examGA());
              this.measref[key.replace("_GGfig","").replace('EFW','EFW ')].setSDColor();
            }
          }
          
        }
        
        this.measref["EFW 1"].WeightType = data["Weight1Type"] ? data["Weight1Type"]: this.EFWdefault;
        this.measref["EFW 2"].WeightType = data["Weight2Type"] ? data["Weight2Type"]: "Hadlock4 (BPD,HC,AC,FL)";

        this.calcGGTable("EFW","Hadlock");
        
        if(this.measref["EFW 1"].value)
          this.measref["EFW 1"].calcGGTable(this.getGALMP());
        if(this.measref["EFW 2"].value)
          this.measref["EFW 2"].calcGGTable(this.getGALMP());
        this.CalcHCAC();
        if(comment)
          comment.setValue(data["Comments"],{emitEvent: false});
        this.Comment = data["Comments"];
        this.dataloaded=true;
        if(this.historyloaded)
          this.loaded.next();
        if(forprint)
          this.saveGAGGtoDB();
      },
      (error)=>{
        console.log(error);
      });
      //also get history
      this.http.get("/api/measurehistory", {params: this.params()}).subscribe((data)=>{
        this.History = data;
        this.historyloaded=true;
        if(this.dataloaded)
          this.loaded.next();
      },(error)=>{
        console.log(error);
      });
    },
    forprint?300:0);//if is prinout wait 100ms before getting vals from db
  }

  params() {
    let patient = this.patServ.getPat();
    let params: {};
    if(this.es.ExamType === 'Pediatric Echo'){
      params = {
        examType: this.es.ExamType, 
        ExamID: this.es.examID,
        table: 'meas',
      }
    } else {
      params = {
        pregid: (patient.ID === 'default') ? 0 : this.ps.pregID.toString(),
        fetus: this.es.getFetus().toString(),
        examType: this.es.ExamType,
        ExamID: this.es.examID,
        table: 'meas',
      };
    }
    return params;
  }

  setDolberg(){
    this.measref['EFW 1'].GG_opts = ["Hadlock","Spiegel-Yagel","none"];//for now disable dolbergs
    /*if(this.ps.fetusCt() > 1){
      this.measref["EFW 1"].GG_opts = ["Hadlock","Dolberg_F_Multi","Dolberg_M_Multi"];
      this.measref["EFW 2"].GG_opts = ["Hadlock","Dolberg_F_Multi","Dolberg_M_Multi"];
    }
    else{
      this.measref["EFW 1"].GG_opts = ["Hadlock","Dolberg_Female","Dolberg_Male"];
      this.measref["EFW 2"].GG_opts = ["Hadlock","Dolberg_Female","Dolberg_Male"];
    }
    if(! this.measref["EFW 1"].GG_opts.includes("none")){
      if(this.loginS.noneAsMeasDefault)
        this.measref["EFW 1"].GG_opts.unshift('none');
      else
        this.measref["EFW 1"].GG_opts.push('none');
    }
    
    
    this.http.get("/api/get",{params:{'table':'urology',fetus: this.es.getFetus().toString(),ExamID: this.es.examID.toString()}}).subscribe((data)=> {
      if(! data.hasOwnProperty('empty')){
        if(data['FetalSex'] == 0){//female
          let index =  this.measref["EFW 1"].GG_opts.indexOf("Dolberg_Male");
          if(index > -1){
            this.measref["EFW 1"].GG_opts.splice(index, 1);
            this.measref["EFW 1"].GG_opts.splice(index, 1);
          }
          index = this.measref["EFW 1"].GG_opts.indexOf("Dolberg_M_Multi");
          if(index > -1){
            this.measref["EFW 1"].GG_opts.splice(index, 1);
            this.measref["EFW 1"].GG_opts.splice(index, 1);
          }
          return;
        }
        if(data['FetalSex'] == 1){//male
          let index =  this.measref["EFW 1"].GG_opts.indexOf("Dolberg_Female");
          if(index > -1){
            this.measref["EFW 1"].GG_opts.splice(index, 1);
            this.measref["EFW 2"].GG_opts.splice(index, 1);
          }
          index = this.measref["EFW 1"].GG_opts.indexOf("Dolberg_F_Multi");
          if(index > -1){
            this.measref["EFW 1"].GG_opts.splice(index, 1);
            this.measref["EFW 2"].GG_opts.splice(index, 1);
          }
          return;
        }
      }
    },
    (error)=>{
      console.log(error);
    });*/
  }

  saveMeas(field: string, value,table: string= "meas"){
    let p = this.patServ.getPat();
    let pregId;
    let fetusNum;

    if(this.es.ExamType == 'CVS' || this.es.ExamType == 'Amniocentesis')
      table = 'meas';

    if(this.es.ExamType === 'Pediatric Echo'){
      pregId = null
      fetusNum = null;
    } else {
      pregId = p.PID > 0 ? this.ps.pregID : 0;
      fetusNum = this.es.getFetus()
    }

    this.http.post('/api/save',
      {
        'table': table,
        'indexvalue': this.es.examID,
        'PID': p.PID,
        'ID': p.ID,
        'Preg': pregId,
        'CreationTime': this.es.CreationTime,
        'field': field,
        'value': value,
        'fetus': fetusNum,
        'examType': this.es.ExamType,
      }
    ).subscribe((data)=>{//later on add observer for saves/errors
      var array = [];
      var efwValid: any = false;
      switch (field) {
        case 'BPD': {
          array.push({BPD:true});
        }
        case 'HC': {
          array.push({HC:true})
        }
        case 'AC': {
          array.push({AC:true})
        }
        case 'FL': {
          array.push({FL:true})
        }
        default:
          this.allWeightAssmentsSub.next(false);
          break;
      }
      array.forEach((i) => {
        if(i){
          efwValid = true;
        }else{
          efwValid = false;
        }
      })
      if(efwValid){
        this.allWeightAssmentsSub.next(true);
      }
      console.log(data);
    }, (error)=> {// do this part later
      console.log(error);
    });
  }

  public getNames(){
    let names:string[] = [];  
    for(let key in this.measref){
      names.push(key);
    }
    return names;
  }

  
  fetchDifferent(){
    return new Promise((resolve, reject) => {
      this.http.get("/api/compareMeas",{params:{fetus: this.es.getFetus().toString(),ExamID: this.es.examID.toString()}}).subscribe((data)=>{
        resolve(data); // resolve with this.modality value
      },
      (error)=>{
        console.log(error);
        reject(error); // reject with error value
      });
    });
  }

  


}
