import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ExamsService } from 'src/app/exams.service';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { GastroService } from 'src/app/gastro.service';
import { LoginService } from 'src/app/login.service';
import { PatientsService } from 'src/app/patients.service';
import { PdfprintService } from 'src/app/pdfprint.service';

@Component({
  selector: 'app-gastro-printopts',
  templateUrl: './gastro-printopts.component.html',
  styleUrls: ['./gastro-printopts.component.scss']
})
export class GastroPrintoptsComponent  implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Input('showopts') ShowOptions:boolean
  showlast:boolean;
  lang:string="en-US";
  link='';
  chameleon = false;
  
  pdfToShow:SafeResourceUrl=null;
  isPDFLoading=false;
  showGallery=true;
  
  lastLink: string = "";
  showGo=true;
  tabMemory:boolean;
  changeTitle=false;
  newTitle:any;


  constructor(public gfs: GastroFormsService, private pat: PatientsService,private sanitizer:DomSanitizer, private translate:TranslateService, public pdf:PdfprintService,public login: LoginService) { 
    this.lang=this.translate.currentLang;
    if(this.login.printLang)
      this.pdf.Lang = this.login.printLang;
    if(this.login.printStyle)
      this.pdf.Style = this.login.printStyle;
    // this.getImageFromService();
    // this.link=this.sanitizer.sanitize(SecurityContext.URL,  '/api/printpdf/'+this.gfs.FormIndex+'/'+this.pat.currentPatient.PID+'/'+this.lang);
  }

  ngOnInit() {
    this.pdf.printCharts = false;
    this.pdf.printImages = false;
    if(this.gfs.isClosed && this.login.hasChameleon)
      this.chameleon=true;
    if(! this.login.letterhead)
      this.pdf.letterhead = false;
    if(this.gfs.isClosed && !this.ShowOptions){
      this.ShowOptions = false;
      this.showlast=true;
      this.tabMemory = this.pdf.newTab;
      this.pdf.newTab=false;
      this.getPDF();
    }
    // this.changeTitle = this.examservice.changedTitle;
    // this.newTitle = this.examservice.newTitle;

  }

  getImageFromService() {
    this.showGo=false;
    this.pdf.getPDF(this.gfs.FormIndex,this.pat.currentPatient.PID, true).subscribe(data => {
      this.createPDFFromBlob(data);
      this.isPDFLoading = false;
      this.showGo=true;
    }, error => {
      this.isPDFLoading = false;
      console.log(error);
    });
    if(this.showlast){
      this.showlast=false;
      this.pdf.newTab=this.tabMemory;
      this.showGallery=true;
    }

  }

  getPDF(){
    // if(! this.gfs.isClosed){
    //     this.examservice.changedTitle = this.changeTitle;
    //     this.examservice.changeTitle(this.newTitle);
    // }
    this.pdf.returnval = this.gfs.isClosed? 'view':'y';
    if(this.showlast)
      this.pdf.returnval = 'last';
    this.isPDFLoading = true;
    this.showGallery=false;
    if(this.lastLink == this.gfs.FormIndex+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n') +'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+ '/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' +(this.pdf.letterhead ? 'y': 'n')+ + '/y')//to prevent trying to load mutiple times
      return;
    this.lastLink = this.gfs.FormIndex+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n') +'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+ '/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' +(this.pdf.letterhead ? 'y': 'n') + '/y';
    if(this.pdf.newTab){
      window.open('api/printpdf/'+this.gfs.FormIndex+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n') +'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+ '/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' +(this.pdf.letterhead ? 'y': 'n')+'/y');
      this.isPDFLoading=false;
      this.closed.emit();
    }else{
      this.getImageFromService();
    }
  }

  createPDFFromBlob(pdf: ArrayBuffer) {
    let file = new Blob([pdf], {type: 'application/pdf'});
    this.pdfToShow = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
    
    
 }

  setLink(){
    this.link='';
    this.link=this.sanitizer.sanitize(SecurityContext.URL,  '/api/printpdf/'+this.gfs.FormIndex+'/'+this.pat.currentPatient.PID+'/'+this.lang);
  }

  resendChameleon(){
    this.pdf.resendChameleon(this.gfs.FormIndex,this.pat.currentPatient.PID).subscribe((data)=>{},(error)=>{
      console.log(error);
    });
    this.chameleon = false;
  }

}
