import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    if (isNaN(value)) {
      return '';
    }

    const sizeInMB = value / (1024 * 1024);
    return sizeInMB.toFixed(4) + ' MB';
  }

}
