<div [formGroup]="pathologyS.pathologyForm">
    <div class="h100 l-flex-w summary ">
        <section class="m-box" style="width: 100%;min-height: 7em;">
            <h2>Pathology</h2>
            <div style="display: flex;height: 100%;">
                <div style="width:30%;align-self: center;padding-block-start: 1em;" >
                    <label style="margin-right: 0.5em;">Pathology Type</label>
                    <select class="t-select t-field" formControlName="Type">
                        <option></option>
                        <ng-container>
                            <option value="Biopsy">Biopsy</option>
                            <option value="Hot Biopsy">Hot Biopsy</option>
                            <option value="Polypectomy">Polypectomy</option>
                            <option value="Polypectomy + Hot Biopsy">Polypectomy + Hot Biopsy</option>
                            <option value="Rapid">Rapid Ureas test</option>
                        </ng-container>
                    </select>
                </div>
                <div style="width: 70%;display: flex;justify-content: space-between;padding-block-start: 1em;" *ngIf="pathologyS.pathologyForm.get('Type').value">
                    <label style="align-self: center;margin-right: 0.5em;">Comments:</label>
                    <section class="l-flex-c t-field commText">
                        <textarea class="fulltext pathText" formControlName="Comments"></textarea>
                    </section>
                    <button class="btn" (click)="pathologyS.save()"><label style="color: white;">Add</label></button>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed && pathologyS.pathologyForm.get('Type').value"
            (click)="atS.showAuto(gs.gastroForm.get('Comments'),'Comments',211)">Auto Text</button>
        </section>

        <section class="m-box" style="width: 100%" *ngIf="pathologyS.pathologies && pathologyS.pathologies.length > 0">
            <div class="l-table-auto">
                <div class="l-row weightAss-tbody">
                    <div class="l-td-m" style="width: 5%;"></div>
                    <div class="l-td-m" style="width: 5%;">#</div>
                    <div class="l-td-m" style="width: 20%;">Type</div>
                    <div class="l-td-m" style="width: 70%;">Comments</div>
                </div>
                <ng-container *ngFor="let path of pathologyS.pathologies;index as i">
                    <div class="l-row weightAss-tbody">
                        <div class="l-td-m center"><button class="auto-text-delete-button" (click)="pathologyS.delete(path)"><img src="../../assets/icons/x.svg" style="vertical-align: auto;" width="20" height="20" alt="Delete Icon"></button></div>
                        <div class="l-td-m">{{i+1}}</div>
                        <div class="l-td-m">{{path.Type}}</div>
                        <div class="l-td-m">{{path.Comments}}</div>
                    </div>
                </ng-container>
            </div>
        </section>
        <section class="m-box" [formGroup]="gs.gastroForm" style="width: 100%;">
            <h2>Comment</h2>
            <textarea class="fulltext" style="margin-left: 0;top:0;width: 100%;min-height: 6em;" formControlName="PathologyComments"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(gs.gastroForm.get('PathologyComments'),'Pathology Comments',210)">Auto Text</button>
        </section>
    </div>
</div>
