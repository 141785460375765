<div class="t-popup popup-patientDetails summary" style='display: block;top: 5em;'>

    <div class="t-popup-close">
    </div>

    <div class="t-popup-frame" style="max-width: 65em;">
        <h3 class="anatomy-item-details-title">
            Comments
        </h3>
        <div class="t-popup-body" style="display: flex;flex-direction: column;height: 100%;">
            <div style="place-self: flex-end;">
                <button class="t-btn-popup-close summary" style="top: -1em;right: 0;position: relative;"
                    aria-label="close popup" (click)="close(true)"></button>
            </div>
            <div class="m-box" style="height: 100%; padding: 0;">
                <textarea class="fulltext" style="min-height: 20em;height: 100%; width: 100%;padding-block-start: 1em;"   [formControl]="myField" #CommentsExtra></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(myField,type,ATiD)">Auto
                    Text</button>
            </div>
        </div>
    </div>

</div>