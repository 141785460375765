import { SearchtextService } from './../searchtext.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoginService } from "../login.service";
import { ExamsService } from "../exams.service";
import { PatientsService } from "../patients.service";
import { Patient } from "../patients/patient";
import { Subject, Subscription } from "rxjs";
import * as XLSX from "xlsx";
import { takeUntil } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AdvancedSearch } from "../AdvencedSearch.service";
import {environment} from '../../environments/environment';


@Component({
  selector: "app-docpanel",
  templateUrl: "./docpanel.component.html",
  styleUrls: ["./docpanel.component.scss"],
})
export class DocpanelComponent implements OnInit, OnDestroy {
  showLogin = false;
  sub = new Subscription();
  private destroy$ = new Subject();
  showLimits = false;
  currentApplicationVersion: string;
  currentAppDate: string;
  showAbout=false;

  constructor(
    private http: HttpClient,
    public loginService: LoginService,
    public patS: PatientsService,
    private es: ExamsService,
    private AdvancedSearch: AdvancedSearch,
    private st:SearchtextService
  ) {}

  ngOnInit() {
    this.currentApplicationVersion = environment.appVersion;
    this.currentAppDate = environment.versionDate;
    this.sub = this.loginService.export$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.export(data);
        }
      });
  }

  goDefaults() {
    let defaults = new Patient("default", "default", "defaults", "defaults");
    defaults.PID = 0;
    this.patS.setPat(defaults, true);
  }

  export(res) {
    this.sub = res.subscribe((data) => {
      var element = document.createElement("a");
      // window.open('/api/stats/exportData')
      element.setAttribute("href", "/api/stats/readydata");
      element.setAttribute("download", "./test.csv");
      document.body.appendChild(element);
      element.click();
      setTimeout(() => {
        document.body.removeChild(element);
      }, 4000);

      this.loginService.exportLoading = false;
      this.showLogin = false;
    });
  }

  logout() {
    this.loginService.clearCookies();
    this.AdvancedSearch.clearLogout();
    this.loginService.Logout();
    this.st.clearSearch()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.destroy$.next();
  }
}
