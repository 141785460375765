<div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
    <span class="l-flex-c" *ngIf="typeOfUser != 'doctor' && IsWeightAss">
        <input id="showHistory" type="checkbox" name="showHistory" [(ngModel)]="showHistoryB" (value)="showHistoryB"
            class="custom-check">
        <i class="customInput-display"></i>
        <label for="showHistory">Show History</label>
    </span>
</div>
<div [formGroup]="ps.pregForm">
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
        <div class="w50" style="height:50; " *ngIf="typeOfUser != 'doctor' && IsWeightAss">
            <section class="m-box fulltext" *ngIf="showHistoryB">
                <h2>
                    Medical History
                </h2>
                <section class="m-box" style="padding: 1.31em 1.43em 0;">
                    <h2>CHD</h2>
                    <textarea class="scroller scroller-intro fulltext" formControlName="CHD"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(ps.pregForm.get('CHD'),'Medical History',1)">Auto Text</button>
                </section>
                <section class="m-box" style="padding: 1.31em 1.43em 0;">
                    <h2>Drugs</h2>
                    <textarea class="scroller scroller-intro fulltext" formControlName="Drugs"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(ps.pregForm.get('Drugs'),'Medical History',1)">Auto Text</button>
                </section>
                <section class="m-box" style="padding: 1.31em 1.43em 0;">
                    <h2>Others</h2>
                    <textarea class="scroller scroller-intro fulltext" formControlName="Remarks"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(ps.pregForm.get('Remarks'),'Medical History',1)">Auto Text</button>
                </section>
            </section>
        </div>
        <div class="w100 summary-anamnesis" style="display: flex;">
            <div class="summary-anamnesis" [ngClass]="show ? 'w100' : 'w50'" *ngIf="! IsWeightAss || typeOfUser == 'doctor'">
                <app-medicalhistory [typeOfUser]="typeOfUser" [isWeightAss]="IsWeightAss" [modality]="modality" (newItemEvent)="setShow($event)"></app-medicalhistory>
            </div>
            <div class="w50 summary-recommendations" style="height: 100%;padding: 0;" *ngIf="es.ExamType !== 'Fetal Echo' && !show">

                <section class="m-box fulltext" style="height: 100%;">
                    <h2>
                        Purpose of Exam
                    </h2>
                    <textarea class="scroller scroller-intro fulltext" [formControl]="ps.Purpose" style="min-height: 10em;"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(ps.Purpose,'Purpose',2)">Auto
                        Text</button>
                    <div style="width: fit-content;" *ngIf="es.ExamType =='CVS'">
                        <app-genradio formname="Emergency" [formgroup]="ps.pregForm" [value1]="0" [value2]="1" lname="Elective" rname="Emergency" [resetval]="-1"></app-genradio>
                    </div>
                </section>
            </div>
        </div>

        <div class="w100 summary-recommendations" style="padding:0.87em 0.62em 0;" *ngIf="es.ExamType === 'Fetal Echo'">
            <app-diagnoses group="fetal" [form]="fetalEchoForm.get('IndicationCodes')" inpregnancy="true" [text]="fetalEchoForm.get('AnomaliesIndication')" class="w100 summary-boxHeight summary-extradocs" autoid="51"></app-diagnoses>
            <!-- <app-fetalecho></app-fetalecho> -->
        </div>
        <div class="w100 summary-recommendations" style="height: 100%;" *ngIf="es.ExamType !== 'Fetal Echo' && show">

            <section class="m-box fulltext" style="padding-bottom: 0">
                <h2>
                    Purpose of Exam
                </h2>
                <textarea class="scroller scroller-intro fulltext" [formControl]="ps.Purpose"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(ps.Purpose,'Purpose',2)">Auto
                    Text</button>
            </section>
        </div>


    </div>



    <!-- ========================== intro (end) ========================== -->
    <section class="m-box">

        <h2>
            Last Menstrual
        </h2>

        <div class="pregnancy-menstrual">

            <!-- row (start) -->
            <div class="l-flex-c menstrual-row menstrual-row--LMP">

                <label class="l-fg1 menstural-label" id="menstural_LMP">
                    L.M.P.
                </label>

                <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap" *ngIf="modality!='Ultrasound'">

                    <input #lmpField [autofocus]="lmpAutoFocus" [matDatepicker]="LMPDatepicker" formControlName="LMP" (dateChange)="checklmp()"
                        class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP"
                        [max]="lmpmax" [min]="lmpmin" [ngClass]="invalidlmp">
                    <div class="t-validation-message" style="left:auto;top: 70%;right:12em">
                        <span>Invalid LMP</span>
                    </div>
                    <img src="../../assets/icons/date.svg" (click)="LMPDatepicker.open()"
                        style='margin-left: -3em'>
                    <mat-datepicker #LMPDatepicker></mat-datepicker>
                    <img src='../../assets/icons/x.svg' class='clear-lmp' matTooltip="Clear"
                        (click)="ps.pregForm.get('LMP').setValue(null)" *ngIf="! es.isClosed">
                    <span class="t-field menstural-field menstural-info">{{ps.lmpGA()}}</span>

                </span>
                <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap" *ngIf="modality=='Ultrasound'">

                    <p    
                        class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP">{{ps.pregForm.get('LMP_ULTRASOUND').value |date: 'dd/MM/yyyy'}}</p>
                    <img src="../../assets/icons/date.svg" 
                        style='margin-left: -3em'>
                    
               
                    <span class="t-field menstural-field menstural-info">{{ps.pregForm.get('GA_LMP').value| weeksDays}}</span> 
                    <span class="t-field menstural-field menstural-info">{{ps.pregForm.get('GA_LMP').value}}d</span> 


                </span>


            </div>
            <!-- row (end) -->

            <!-- row (start) -->
            <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">

                <label class="l-fg1 menstural-label" id="menstural_LMP_by_US">
                    L.M.P by US
                </label>

                <span class="l-flex t-boxMargin menstural-fields-set">

                    <select class="t-select t-field menstural-field" aria-labelledby="menstural_LMP_by_US"
                        formControlName="LMPUSweeks">
                        <option *ngFor="let i of weeks" [value]="i">{{i}} W</option>
                    </select>

                    <select class="t-select t-field menstural-field" aria-labelledby="menstural_LMP_by_US"
                        formControlName="LMPUSdays">
                        <option *ngFor="let i of days" [value]="i">{{i}} D</option>
                    </select>

                    <input [matDatepicker]="USDatepicker" class="t-field t-field--date menstural-field"
                        aria-labelledby="menstural_LMP_by_US" max="{{today}}" min="{{lmpmin}}"
                        formControlName="LMP_US">
                    <img src="../../assets/icons/date.svg" (click)="USDatepicker.open()"
                        style='margin-left: -3em'>
                    <mat-datepicker #USDatepicker></mat-datepicker>
                    <img src='../../assets/icons/x.svg' class='clear-lmp' matTooltip="Clear"
                        (click)="ps.pregForm.get('LMP_US').setValue(null);ps.pregForm.get('LMPUSweeks').setValue(null);ps.pregForm.get('LMPUSdays').setValue(null);"
                        *ngIf="! es.isClosed">
                    <span class="t-field menstural-field menstural-info">{{ps.lmpUSGA()}}</span>

                </span>

            </div>
            <!-- row (end) -->

            <!-- row (start) -->
            <div class="l-flex-c menstrual-row menstrual-row--ovulation">

                <label class="l-fg1 menstural-label" id="menstural_ovulation">
                    Ovulation
                </label>

                <span class="l-flex-c t-boxMargin menstural-fields-set">

                    <!--<button class="open_calc" aria-label="Calculator"></button> -->
                    <input [matDatepicker]="OvDatepicker" class="t-field t-field--date menstural-field"
                        aria-labelledby="menstural_ovulation" max="{{today}}" min="{{lmpmin}}"
                        formControlName="Ovulation">
                    <img src="../../assets/icons/date.svg" (click)="OvDatepicker.open()"
                        style='margin-left: -3em'>
                    <mat-datepicker #OvDatepicker></mat-datepicker>
                    <img src='../../assets/icons/x.svg' class='clear-lmp' matTooltip="Clear"
                        (click)="ps.pregForm.get('Ovulation').setValue(null);" *ngIf="! es.isClosed">
                    <span class="t-field menstural-field menstural-info">{{ps.ovulationGA()}}</span>

                </span>

            </div>
            <!-- row (end) -->

            <!-- row (start) -->
            <div class="l-flex-c menstrual-row menstrual-row--EDD">

                <label class="l-fg1 menstural-label" id="menstural_LMP">
                    E.D.D
                </label>

                <span class="l-flex t-boxMargin menstural-fields-set">

                    <span *ngIf="modality!='Ultrasound'" class="t-field menstural-field menstural-info">{{ps.edd()}}</span>
                    <span *ngIf="modality =='Ultrasound'" class="t-field menstural-field menstural-info">{{ps.pregForm.get('EDD_US').value |date: 'dd/MM/yyyy'}}</span>

                </span>

            </div>
            <!-- row (end) -->

        </div>

    </section>
    <!-- ========================== GESTATION SAC (start) ========================== -->
    <div class="l-flex gestation t-row" *ngIf="es.ExamType !=='Fetal Echo' && es.ExamType !='Amniocentesis' && es.ExamType !='CVS' && es.ExamType !='Follicles'">

        <section class="l-fg1 m-box">

            <h2>
                Gestation Sac Location
            </h2>

            <div class="l-flex-c t-set">

                <div class="t-set-title" (dblclick)="ps.pregForm.get('Insitu').setValue(-1)">

                    <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big">

                        <label>

                            <input type="radio" name="Insitu" class="custom-check" formControlName="Insitu"
                                [value]="1" />
                            <i class="customInput-display"></i>
                            <span>
                                IN SITU
                            </span>

                        </label>

                        <label>

                            <input type="radio" name="Insitu" class="custom-check" formControlName="Insitu"
                                [value]="0" />
                            <i class="customInput-display"></i>
                            <span>
                                ECTOPIC
                            </span>

                        </label>

                    </div>

                </div>

      <!-- ========================== ECTOPIC Options (Start) ========================== -->
         <div
         *ngIf="!ps.pregForm.get('Insitu').value" 
         class="l-flex-c l-fg1 t-set-table"
         (dblclick)="unselectECTOPIC()">
         <label class="t-label-top">
           <input
             type="radio"
             formControlName="CervicalEctop"
             [value]="true"
             class="custom-check"
             (click)="toggleECTOPIC('CervicalEctop')"
           />
           <i class="customInput-display"></i>
           <span>
             Cervical
           </span>
         </label>

         <label class="t-label-top">
           <input
             type="radio"
             formControlName="TubalEctop"
             [value]="true"
             class="custom-check"
             (click)="toggleECTOPIC('TubalEctop')"
           />
           <i class="customInput-display"></i>
           <span>
             Tubal
           </span>
         </label>

         <label class="t-label-top">
           <input
             type="radio"
             formControlName="OvarianEctop"
             [value]="true"
             class="custom-check"
             (click)="toggleECTOPIC('OvarianEctop')"
           />
           <i class="customInput-display"></i>
           <span>
             Ovarian
           </span>
         </label>

         <label class="t-label-top">
           <input
             type="radio"
             formControlName="PeritonealEctop"
             [value]="true"
             class="custom-check"
             (click)="toggleECTOPIC('PeritonealEctop')"
           />
           <i class="customInput-display"></i>
           <span>
             Peritoneal
           </span>
         </label>

         <label class="t-label-top">
           <input
             type="radio"
             formControlName="AbdominalEctop"
             [value]="true"
             class="custom-check"
             (click)="toggleECTOPIC('AbdominalEctop')"
           />
           <i class="customInput-display"></i>
           <span>
             Abdominal
           </span>
         </label>
       </div>
       <!-- ========================== ECTOPIC Options (End) ========================== -->
      </div>
    </section>
        
        <button class="close_pregnancy" *ngIf="! ps.pregForm.value.Insitu" (click)="showclose=true">
            {{ ps.pregForm.get('ClosedDate').value ? 'See Closing Remarks' : 'Close Pregnancy'}}
        </button>
       
    </div>


    <!-- ========================== GESTATION SAC (END) ========================== -->

    <div class="l-flex pregnancy-table">

        <!-- PREGNANCY TABLE - WIDE CELL (START) -->
        <div class="l-fg1 pregnancy-table-wide" [ngClass]="(es.ExamType == 'Amniocentesis' || es.ExamType == 'CVS' || es.ExamType =='Follicles') ? 'flexMarg' : ''">

            <!-- box (start) -->
            <section class="m-box pregnancy-type" *ngIf="es.ExamType != 'Amniocentesis' && es.ExamType != 'CVS' && es.ExamType !='Follicles'">
                <h2 style="margin: 0 0.35em 0 1.3em;">
                    Pregnancy Type
                </h2>
                <div class="l-flex-c t-set">

                    <div class="t-set-title" (dblclick)="ps.pregForm.get('NaturalPregnancy').setValue(-1)">

                        <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big">

                            <label>

                                <input type="radio" name="NaturalPregnancy" class="custom-check"
                                    formControlName="NaturalPregnancy" [value]="1"/>
                                <i class="customInput-display"></i>
                                <span>
                                    Natural
                                </span>

                            </label>

                            <label>

                                <input type="radio" name="NaturalPregnancy" formControlName="NaturalPregnancy"
                                    class="custom-check" [value]="0"/>
                                <i class="customInput-display"></i>
                                <span>
                                    Artificial Fertility Aid
                                </span>

                            </label>

                        </div>

                    </div>

                    <div class="l-flex-c t-set-table" *ngIf="ps.pregForm.value.NaturalPregnancy == 0">
                        <label class="t-label-top" style="padding: 0;">

                            <input type="checkbox" class="custom-check" [checked]="ps.pregForm.get('IVFType').value.includes('Insemination')"
                                 (change)="ivftoggle('Insemination')"/>
                            <i class="customInput-display"></i>
                            <span>
                                Insemination
                            </span>

                        </label>

                        <label class="t-label-top" style="padding: 0;">

                            <input type="checkbox" class="custom-check"  [checked]="ps.pregForm.get('IVFType').value.includes('Embrio Donation')"
                             (change)="ivftoggle('Embrio Donation')"/>
                            <i class="customInput-display"></i>
                            <span>
                                Embrio Donation
                            </span>

                        </label>

                        <label class="t-label-top" style="padding: 0;">

                            <input type="checkbox" class="custom-check"  [checked]="ps.pregForm.get('IVFType').value.includes('Egg Donation')"
                           (change)="ivftoggle('Egg Donation')"/>
                            <i class="customInput-display"></i>
                            <span>
                                Egg Donation
                            </span>

                        </label>

                        <label class="t-label-top" style="padding: 0;">

                            <input type="checkbox" class="custom-check"  [checked]="ps.pregForm.get('IVFType').value.includes('ICSI')"
                           (change)="ivftoggle('ICSI')"/>
                            <i class="customInput-display"></i>
                            <span>
                                ICSI
                            </span>

                        </label>

                        <label class="t-label-top" style="padding: 0;">

                            <input type="checkbox" class="custom-check"  [checked]="ps.pregForm.get('IVFType').value.includes('IVF')"
                           (change)="ivftoggle('IVF')"/>
                            <i class="customInput-display"></i>
                            <span>
                                IVF
                            </span>

                        </label>

                    </div>


                </div>
            </section>
            <!-- box (end) -->

            <!-- box (start) -->
            <section class="m-box" *ngIf="es.ExamType != 'Follicles'" [ngClass]="(es.ExamType == 'Amniocentesis' || es.ExamType == 'CVS' )? 'w65' : ''">

                <h2>
                    Number of Fetuses
                </h2>

                <div class="pregnancy-fetuses">

                    <!-- row (start) -->
                    <div class="l-flex-c fetuses-row">

                        <span class="l-flex-c">
                            <input id="fetuses_number_1" type="radio" name="NumFetuses" formControlName="NumFetuses"
                                [value]="1" class="custom-check">
                            <i class="customInput-display"></i>
                            <label for="fetuses_number_1" class="fetuses-count menstural-field">1</label>
                        </span>
                        <span *ngIf="ps.pregForm.value.NumFetuses>1">
                            <label for="gest_sacs_number" class="t-select-label">Gest. Sacs</label>

                            <select class="t-select t-field menstural-field" id="gest_sacs_number"
                                formControlName="NumberOfSacs">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </span>
                    </div>
                    <!-- row (end) -->

                    <!-- row (start) -->
                    <div class="l-flex-c fetuses-row">

                        <span class="l-flex-c">
                            <input id="fetuses_number_2" type="radio" name="NumFetuses" formControlName="NumFetuses"
                                [value]="2" class="custom-check">
                            <i class="customInput-display"></i>
                            <label for="fetuses_number_2" class="fetuses-count menstural-field">2</label>
                        </span>

                        <span class="l-fg1 l-flex-c fetus-row-2-fetus-checkSet" *ngIf="ps.pregForm.value.NumFetuses>1">

                            <label class="l-flex-c t-select-label fetuses-label">

                                <input type="radio" name="ChorioAmino" value="DiChorio - Di Amnio" class="custom-check"
                                    formControlName="ChorioAmino">
                                <i class="customInput-display"></i>
                                <span>
                                    DiChorio - Di Amnio
                                </span>

                            </label>

                            <label class="l-flex-c t-select-label fetuses-label">

                                <input type="radio" name="ChorioAmino" value="MonoChorio - DiAmnio" class="custom-check"
                                    formControlName="ChorioAmino">
                                <i class="customInput-display"></i>
                                <span>
                                    MonoChorio - DiAmnio
                                </span>

                            </label>

                            <label class="l-flex-c t-select-label fetuses-label">

                                <input type="radio" name="ChorioAmino" value="MonoChorio - MonoAmnio"
                                    class="custom-check" formControlName="ChorioAmino">
                                <i class="customInput-display"></i>
                                <span>
                                    MonoChorio - MonoAmnio
                                </span>

                            </label>

                        </span>

                    </div>
                    <!-- row (end) -->

                    <!-- row (start) -->
                    <div class="l-flex-c fetuses-row">

                        <span class="l-flex-c">

                            <input type="radio" name="NumFetuses" class="custom-check" formControlName="NumFetuses"
                                [value]="3" aria-label="custom fetus number">
                            <i class="customInput-display"></i>
                            <span class="fetuses-count fetuses-count--has-select menstural-field">
                                <select class="t-select" aria-label="select fetus number"
                                    *ngIf="ps.pregForm.get('NumFetuses').value < 3">
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                </select>
                                <select class="t-select" aria-label="select fetus number" formControlName="NumFetuses"
                                    *ngIf="ps.pregForm.get('NumFetuses').value > 2">
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                </select>
                            </span>

                        </span>

                    </div>
                    <!-- row (end) -->


                </div>

            </section>
            <section class="m-box" *ngIf="es.ExamType == 'Amniocentesis' || es.ExamType == 'CVS' || es.ExamType =='Follicles'" [ngClass]="(es.ExamType == 'Amniocentesis' || es.ExamType == 'CVS' || es.ExamType =='Follicles') ? 'w35Marg' : ''">
                <h2>Blood Type</h2>
                <label style="margin-inline-end: 1em;">
                    Blood Type
                </label>
                <select class="t-select t-field menstural-field" formControlName="BloodType">
                    <option value="A positive">A positive</option>
                    <option value="B positive">B positive</option>
                    <option value="AB positive">AB positive</option>
                    <option value="O positive">O positive</option>
                    <option value="A negative">A negative</option>
                    <option value="B negative">B negative</option>
                    <option value="AB negative">AB negative</option>
                    <option value="O negative">O negative</option>

                </select>
            </section>
            <!-- box (end) -->

        </div>
        <!-- PREGNANCY TABLE - WIDE CELL (END) -->

        <!-- PREGNANCY TABLE - NARROW CELL (START) -->
        <div class="l-flex l-column pregnancy-table-sidebar" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Amniocentesis' && es.ExamType !== 'CVS' && es.ExamType !='Follicles'">

            <section class="l-fg1 m-box">

                <h2>
                    Cervical Measurements
                </h2>

                <div class="l-flex l-column">

                    <!-- row (start) -->
                    <div class="l-flex-c cervical-row">
                        <label for="cevical_length" class="l-fg1 cervical-label">
                            Cervical Length
                        </label>
                        <span class="t-field t-field--number">
                            <input class="t-field-text" type="number" min="0" id="cevical_length"
                                formControlName="Measure" />
                            <span class="t-field-desc">
                                mm
                            </span>
                        </span>
                    </div>
                    <!-- row (end) -->

                    <!-- row (start) -->
                    <div class="l-flex-c cervical-row" (dblclick)="ps.pregForm.get('Funneling').setValue(-1)">

                        <label class="l-fg1 cervical-label" id="funneling">
                            Funneling
                        </label>

                        <div class="l-flex-c t-field t-twin-fieldset">

                            <label class="gestation-label">

                                <input type="radio" name="Funneling" class="custom-check" formControlName="Funneling"
                                    aria-labelledby="funneling" value="1" checked />
                                <i class="customInput-display"></i>
                                <span class="gestation-title-text">
                                    Yes
                                </span>

                            </label>

                            <label class="gestation-label">

                                <input type="radio" name="Funneling" class="custom-check" formControlName="Funneling"
                                    aria-labelledby="funneling" value="0" />
                                <i class="customInput-display"></i>
                                <span class="gestation-title-text">
                                    No
                                </span>

                            </label>

                        </div>

                    </div>
                    <!-- row (end) -->

                    <!-- row (start) -->
                    <div class="cervical-row">

                        <label class="cervical-label">
                            History
                        </label>

                        <div class="l-table-auto cervical-table">

                            <!-- row - head - (start) -->
                            <div class="l-row cervical-thead">
                                <div class="l-td-m">
                                    Length
                                </div>
                                <div class="l-td-m">
                                    Funneling
                                </div>
                                <div class="l-td-m">
                                    GA
                                </div>
                                <div class="l-td-m">
                                    Date
                                </div>
                            </div>
                            <!-- row - head - (end) -->

                            <!-- row (start) -->
                            <div class="l-row cervical-tbody" *ngFor="let cer of ps.CervicalMeasures">
                                <div class="l-td-m" *ngIf="cer.Measure">
                                    {{cer.Measure}} mm
                                </div>
                                <div class="l-td-m">
                                    {{cer.Funneling == "1"? "Yes": "No"}}
                                </div>
                                <div class="l-td-m">
                                    {{ps.getGA(cer.MeasureDate)}}
                                </div>
                                <div class="l-td-m cervical-table-date">
                                    {{"on " + (cer.MeasureDate | date:"dd.MM.yyyy")}}

                                </div>
                            </div>
                            <!-- row (end) -->


                        </div>

                    </div>
                    <!-- row (end) -->
                    <!-- row (start) -->

                    <div class="cervical-row">
                        <textarea class="l-textarea t-field--limitRad w100h100" aria-label="Additional comment"
                        placeholder="Cervical length Comment" formControlName="cervicalComment"></textarea>
                    </div>
                    <!-- row (end) -->

                </div>

            </section>

        </div>
        <!-- PREGNANCY TABLE - NARROW CELL (END) -->

    </div><!-- // table -->

    <div class="l-flex pregnancy-table">

        <!-- PREGNANCY TABLE - WIDE CELL (START) -->
        <div class="l-fg1 pregnancy-table-wide"  *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Tech Exam' && es.ExamType !== 'Amniocentesis' && es.ExamType !== 'CVS' && es.ExamType !='Follicles'">

            <section class="m-box">
                <h2>
                    MFPR Management
                </h2>
                <div class="l-flex t-boxMargin mfrp-panel" (dblclick)="ps.pregForm.get('MFPR').setValue(null); ps.pregForm.get('MFPRfetus').setValue(null) ">

                    <div class="l-fg1">
                        <div class="l-flex-c mfrp-row">
                            <label class="l-flex-c mfrp-label">
                                <input type="radio" name="MFPR" formControlName="MFPR" class="custom-check"
                                    value="Selective reduction" />
                                <i class="customInput-display"></i>
                                <span>
                                    Selective reduction
                                </span>
                            </label>
                            <div class="t-rowitems-sep"></div>

                            <label for="select_fetus" class="l-flex-c t-select-label">Select Fetus</label>
                            <select id="select_fetus" class="t-field t-select" formControlName="MFPRfetus">
                                <option default [ngValue]="null" ></option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </select>                           
                        </div>
                    </div>

                    <div class="l-fg1">
                        <textarea class="l-textarea t-field--limitRad w100h100" aria-label="Additional comment"
                            placeholder="Method of Reduction" formControlName="MFPRNotes"></textarea>
                    </div>
                    <div *ngIf="ps.pregForm.value.MFPR && !ps.pregForm.value.MFPRfetus" class="update-fetus-message">
                        <span>
                            Select Fetus Number
                        </span>
                    </div>
                </div>
            </section>
           

        </div>
        <!-- PREGNANCY TABLE - WIDE CELL (END) -->
        

        <!-- PREGNANCY TABLE - NARROW CELL (START) -->
        <div class="l-flex pregnancy-table-sidebar" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Amniocentesis' && es.ExamType !== 'Tech Exam' && es.ExamType !== 'CVS' && es.ExamType !='Follicles'">
            <button class="close_pregnancy" (click)="showclose=true">
                {{ ps.pregForm.get('ClosedDate').value? 'See Closing Remarks' : 'Close Pregnancy'}}
            </button>
        </div>
        <div class="l-flex pregnancy-table-sidebar" *ngIf="es.showDefaultButton()">
            <button class="defaults"  (click)="setDefaults()">
                Apply Defaults
            </button>
        </div>
        <!-- PREGNANCY TABLE - NARROW CELL (END) -->

    </div><!-- // table -->

    <div class="t-popup popup-patientDetails" *ngIf="awlLMPwarn" style='display: block'>

        <div class="t-popup-close"></div>

        <div class="t-popup-frame">
            <div class="t-popup-body">

                <button class="t-btn-popup-close" aria-label="close popup" (click)="awlLMPwarn=false"></button>

                <h2 style='color: D7A95B'>
                    The LMP from the AWL {{es.AWLlmp | date: 'd.M.yyyy' }} does not match the LMP from the Pregnancy
                    {{ps.pregForm.get('LMP').value | date: 'd.M.yyyy'}}
                </h2>
                <!-- box (end) -->

                <div class="l-flex l-flex-w t-fieldmargin patient-details">

                    <button class="save_patieont" (click)="awlLMPwarn=false;mwl()">
                        Keep LMP from Pregnancy
                    </button>
                    <button class="save_patieont" (click)="ps.pregForm.get('LMP').setValue(es.AWLlmp);awlLMPwarn=false;mwl()">
                        Use AWL LMP
                    </button>
                </div>



            </div>
        </div>

    </div>

    <app-closepregnancy *ngIf="showclose" (closed)="showclose=false"></app-closepregnancy>