import { Component, OnInit,SecurityContext,Output,EventEmitter,Input } from '@angular/core';
import {ExamsService} from '../exams.service';
import {PatientsService} from '../patients.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {PdfprintService} from '../pdfprint.service';
import {LoginService} from '../login.service';
import { StatsService } from '../stats.service';


@Component({
  selector: 'app-printopts',
  templateUrl: './printopts.component.html',
  styleUrls: ['./printopts.component.scss']
})
export class PrintoptsComponent implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Input('showopts') ShowOptions:boolean;
  @Input('stats') stats:boolean = false;
  @Input('isReport') isReport:boolean = false;
  @Input('type') type:string = '';
  @Input('startdate') startdate:Date;
  @Input('enddate') enddate:Date;
  @Input('hmostype') hmostype:string = '';

  
  
  
  showlast:boolean;
  lang:string="en-US";
  link='';
  chameleon = false;
  
  pdfToShow:SafeResourceUrl=null;
  isPDFLoading=false;
  showGallery=true;
  
  lastLink: string = "";
  showGo=true;
  tabMemory:boolean;
  changeTitle=false;
  newTitle:any;


  constructor(public sts:StatsService,public examservice: ExamsService, private pat: PatientsService,private sanitizer:DomSanitizer, private translate:TranslateService, public pdf:PdfprintService,public login: LoginService) { 
    this.lang=this.translate.currentLang;
    if(this.login.printLang)
      this.pdf.Lang = this.login.printLang;
    if(this.login.printStyle)
      this.pdf.Style = this.login.printStyle;
    //this.getImageFromService();
    //this.link=this.sanitizer.sanitize(SecurityContext.URL,  '/api/printpdf/'+this.examservice.examID+'/'+this.pat.currentPatient.PID+'/'+this.lang);
  }

  ngOnInit() {
    this.pdf.printCharts = false;
    this.pdf.printImages = false;
    if(this.examservice.isClosed && this.login.hasChameleon)
      this.chameleon=true;
    if(! this.login.letterhead)
      this.pdf.letterhead = false;
    if(this.examservice.isClosed && (!this.ShowOptions || !this.examservice.PregCopied)){
      this.ShowOptions = false;
      this.showlast=true;
      this.tabMemory = this.pdf.newTab;
      this.pdf.newTab=false;
      this.getPDF();
    }
    if(! this.examservice.isClosed && this.login.ishospital)
      this.examservice.getCustomTitles();
    this.changeTitle = this.examservice.changedTitle;
    this.newTitle = this.examservice.newTitle;
    if(this.examservice.GASTRO.includes(this.examservice.ExamType)){
      this.pdf.perPage = 6;
    }

  }

  getImageFromService() {
    this.showGo=false;
    this.pdf.getPDF(this.examservice.examID,this.pat.currentPatient.PID).subscribe(data => {
      this.createPDFFromBlob(data);
      this.isPDFLoading = false;
      this.showGo=true;
    }, error => {
      this.isPDFLoading = false;
      console.log(error);
    });
    if(this.showlast){
      this.showlast=false;
      this.pdf.newTab=this.tabMemory;
      this.showGallery=true;
    }

  }

  getPDF(){
    if(! this.examservice.isClosed){
        this.examservice.changedTitle = this.changeTitle;
        this.examservice.changeTitle(this.newTitle);
    }
    this.pdf.returnval = this.examservice.isClosed? 'view':'y';
    if(this.showlast)
      this.pdf.returnval = 'last';
    this.isPDFLoading = true;
    this.showGallery=false;
    if(!this.stats && this.lastLink == this.examservice.examID+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n')+'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+'/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' + (this.pdf.letterhead ? 'y': 'n')+'/n')//to prevent trying to load mutiple times
      return;
    if(!this.stats)
      this.lastLink = this.examservice.examID+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n')+'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+'/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' + (this.pdf.letterhead ? 'y': 'n')+'/n';
    if(this.pdf.newTab){
      if(this.stats && !this.isReport){
        this.pdf.returnval = 'y'
        var temp = 'api/print/'+this.pdf.Lang  + '/'+ this.pdf.Style+ '/' + this.pdf.returnval + '/' +this.sts.Params.type
        if(this.sts.Params['date']){
          temp += '/months/' + this.sts.Params['date']  + '/n'+'/'+this.sts.Params['searchByType']
        }else{
          temp += '/dates/' + this.sts.Params['start'] + '/' + this.sts.Params['end']+'/'+this.sts.Params['searchByType']
        }
        // console.log(temp);
        
        window.open(temp);
      }else if(this.stats && this.isReport){
        this.pdf.returnval = 'y'
        var start = new Date(this.startdate.toISOString().split('T')[0])
        var end  = new Date(this.enddate.toISOString().split('T')[0])
        var temp = 'api/print/' + (this.isReport ? 'y':'n')  + '/' +this.type+ '/' + this.startdate.toISOString().split('T')[0] + '/' + this.enddate.toISOString().split('T')[0] +'/' + this.hmostype;
        window.open(temp);
        
      }
      else{        
        window.open('api/printpdf/'+this.examservice.examID+'/'+this.pat.currentPatient.PID+'/'+this.pdf.Lang+'/'+ (this.pdf.printCharts ? 'y':'n') +'/'+ (this.pdf.printImages? 'y': 'n') + '/'+ this.pdf.Style+ '/'+ this.pdf.perPage + '/' + this.pdf.returnval + '/' +(this.pdf.letterhead ? 'y': 'n')+'/n');
      }
      this.isPDFLoading=false;
      this.closed.emit();
    }else{
      this.getImageFromService();
    }
  }

  createPDFFromBlob(pdf: ArrayBuffer) {
    let file = new Blob([pdf], {type: 'application/pdf'});
    this.pdfToShow = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
    
 }

  setLink(){
    this.link='';
    this.link=this.sanitizer.sanitize(SecurityContext.URL,  '/api/printpdf/'+this.examservice.examID+'/'+this.pat.currentPatient.PID+'/'+this.lang);
  }

  resendChameleon(){
    this.pdf.resendChameleon(this.examservice.examID,this.pat.currentPatient.PID).subscribe((data)=>{},(error)=>{
      console.log(error);
    });
    this.chameleon = false;
  }

}
