<div class="t-popup popup-patientDetails"  style='display: block'>

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame" [ngClass]="export ? 'mrg' : ''" >
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="closed.emit()"></button>

            <div class="login-form">
                <div *ngIf="loginService.loginError" class="strong">{{loginService.loginError}}</div>
				<div class="login-form-row" *ngIf="! loginService.getCode && (loginService.requireExamLogon || ! loginService.localServer)">
					<input class="login-form-field focusOnParent" type="text" placeholder="User Name" aria-label="user name" [(ngModel)]="user" autocomplete="off" (keyup.enter)="checkLogin()" (keyup)="clean()"/>
					<i class="login-form-icon icon-userName"></i>
				</div>

				<div class="login-form-row" *ngIf="! loginService.getCode && (loginService.requireExamLogon || ! loginService.localServer)">
					<input class="login-form-field focusOnParent" [type]="hidepw ? 'password' : 'text'" placeholder="Password" aria-label="password" [(ngModel)]="pw" (keyup.enter)="checkLogin()" (keyup)="clean()" autocomplete="off" (focus)="typed=true"/>
					<i class="login-form-icon {{! typed ? 'icon-password' : (hidepw ? 'icon-openpw' : 'icon-closepw')}}" (click)="hidepw=!hidepw"></i>
				</div>


				<input type="submit" class="login-form-submit t-btn-grad" (keyup.enter)="checkLogin()" value="Login" (click)="checkLogin()" *ngIf="! hideButton && (loginService.requireExamLogon || ! loginService.localServer) && ! search"/>
				<input type="submit" class="login-form-submit t-btn-grad" (keyup.enter)="loginService.loginAsCurrentUser()" value="Edit as {{loginService.username}}" (click)="hideButton=true;loginService.loginAsCurrentUser()" *ngIf="! loginService.requireExamLogon && loginService.localServer && !export && ! hideButton"/>
				<br>
				<input *ngIf="! newexam && !export" type="submit" class="login-form-submit t-btn-grad" value="VIEW ONLY" (click)="viewonly.emit()" style='margin-top:1em'/>
				<div class="login-form-row" *ngIf="loginService.exportLoading">
					<span>Downloading...</span>
				</div>
				
			</div>

            

        </div>
    </div>

</div>