import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AutotextService } from '../autotext.service';
import { CalendarService } from '../calendar.service';
import { ExamsService } from '../exams.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-geninput',
  templateUrl: './geninput.component.html',
  styleUrls: ['./geninput.component.scss']
})
export class GeninputComponent implements OnInit, OnDestroy {
  @Input('form') form: UntypedFormControl;
  @Input('title') title;

  CARDIOOPTIONS = ['קצב סינוס תקין'];
  selectedOption$ = new Subject()


  search = new UntypedFormControl();
  selected;
  sub = new Subscription();
  onLoad = true;
  loadonce = false;
  @ViewChild('inputDiagnosis') inputDiagnosis: ElementRef;





  constructor(public ls:LoginService,private http:HttpClient, public es:ExamsService, public cf:CalendarService, public atS:AutotextService) { }

 async ngOnInit() {
    this.sub = this.cf.formLoaded$.subscribe(() =>{
      if(this.form.value)
        this.search.setValue(this.form.value, {emitEvent:false})
      else{
        this.search.setValue('', {emitEvent:false})
      }
      if(!this.ls.isCardio){
        this.atS.options = this.atS.GYNOPTIONSEXMAS;
      }
    })

    this.atS.loadData(this.form);

    this.atS.filteredOptions = this.search.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    // if(!this.onLoad){
      this.sub = this.search.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((data) => {
        this.form.setValue(data);
      })

    // }
    this.sub = this.selectedOption$.subscribe(() => {
      this.form.setValue(this.search.value);
      
    })
    this.sub = this.atS.loaded$.subscribe(() =>{
      if(this.form.value)
        this.search.setValue(this.form.value, {emitEvent:false})
      else
        this.search.setValue('', {emitEvent:false})

    })
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.atS.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  blur(): void {;
    setTimeout(() => this.inputDiagnosis.nativeElement.blur(), 0);
  }

  reset(){
    this.search.patchValue('');
    this.atS.filteredOptions = this.search.valueChanges.pipe(startWith(''),map(value => this._filter(value)));
  }


  saveNew(option, newItem){
    if(option =='')
      return;
    this.form.setValue(option);
    this.selected = option;
    if(newItem){
      this.http.post('/api/newExams/saveAutoInput',{Text: option}).subscribe((data) => {
        console.log(data);
      })
    }
    this.selectedOption$.next()
  }
  onRightClick(event, option) {
    event.preventDefault(); //this will disable default action of the context menu
    this.atS.deleteAuto(option);
    this.loadonce = false;
    this.load();
   }
  load(){
    if(! this.loadonce)
      this.atS.loadData(this.form)
    this.loadonce = true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
