<div style="display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="color: #015a6c">Manage {{ fieldName }} Options</h2>
  <button (click)="closeDialog()">
    <mat-icon matTooltip="Close" style="color: #a9b6b6">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div style="display: flex; justify-content: space-between">
    <mat-form-field>
      <input matInput [(ngModel)]="newOption" placeholder="Add new option" />
    </mat-form-field>
    <button mat-raised-button (click)="addOption()">
      <mat-icon matTooltip="Add option" style="color: #277382">add_circle_outline</mat-icon>
    </button>
  </div>

  <div *ngFor="let option of options; let i = index" style="display: flex; justify-content: space-between">
    <span>{{ option }}</span>
    <div>
      <button (click)="moveOptionUp(i)" matTooltip="Move up" [disabled]="i === 0">
        <mat-icon style="color: #a9b6b6">keyboard_arrow_up</mat-icon>
      </button>
      <button (click)="moveOptionDown(i)" matTooltip="Move down" [disabled]="i === options.length - 1">
        <mat-icon style="color: #a9b6b6">keyboard_arrow_down</mat-icon>
      </button>
      <button (click)="deleteOption(option)" matTooltip="Delete option">
        <mat-icon style="color: #277382">delete_outline</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
