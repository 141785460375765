import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { Patient } from 'src/app/patients/patient';
import {PatientsService} from '../../patients.service';

@Component({
  selector: 'app-surrogate',
  templateUrl: './surrogate.component.html',
  styleUrls: ['./surrogate.component.scss']
})
export class SurrogateComponent implements OnInit {
  @Output('closed') closed = new EventEmitter<null>();
  maxBD: Date;
  startBD: Date;
  Surrogate:any;
  idchanged = false;
  @Input('genMom') genMom:Patient;
  error: string;

  constructor(private ps:PatientsService) { 
    this.maxBD = new Date();
    this.maxBD.setFullYear(this.maxBD.getFullYear() - 12);
    this.startBD = new Date();
    this.startBD.setFullYear(this.startBD.getFullYear() - 22);
  }

  ngOnInit(): void {
    this.ps.getSurrogate(this.genMom.PID).subscribe((data)=>{
      if(Object.keys(data).length === 0){
        this.Surrogate = {
          ID:'',
          First: '',
          Last: '',
          Partner: '',
          OrgID: '',
          Birth: null,
          AI: null
        };
      }
      else
      this.Surrogate = data;
    },(error)=>{
      console.log(error);
    })
  }

  close(){
    this.closed.emit();
  }

  save(){
    this.error = null;
    if(! this.Surrogate['PID'])
      this.Surrogate.PID = this.genMom.PID;
    this.ps.saveSurrogate(this.Surrogate).subscribe((data)=>{
      this.closed.emit()
    },(err)=>{
      this.error = err;
      console.log(err);
    })
  }

  switch(){
    this.Surrogate = {
      ID:'',
      First: '',
      Last: '',
      Partner: '',
      OrgID: '',
      Birth: null,
      AI: null
    };
  }

}
