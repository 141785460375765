import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PregnancyService } from "./pregnancy.service";
import { PatientsService } from "./patients.service";
import {
  createFamilyHistoryNewData,
  createPregnanciesNewData,
  createDiseasesNewData,
  createSurgeriesNewData,
  createMedicationsNewData,
  createSensitivitiesNewData,
  createHabitsNewData,
  createTestsNewData,
} from "./follow-up-history/data/data-storage";
import { createVisitsNewData } from "./follow-up-visit/data/data-storage";
import { createScansNewData, createMeasurementsNewData } from "./follow-up-scans/data/data-storage";
import {
  createScreeningNTNewData,
  createScreening2ndNewData,
  createScreeningRisksNewData,
} from "./follow-up-screening/data/data-storage";
import {
  createLabSerologyTestNewData,
  createLabCoombsNewData,
  createLabAntiDNewData,
  createLabGlucose50NewData,
  createLabGlucose75NewData,
  createLabGlucose100NewData,
  createLabHBA1CNewData,
  createLabPPGNewData,
  createLabKaryotypeNewData,
} from "./follow-up-lab/data/data-storage";
import { createGeneticsMotherNewData, createGeneticsSpouseNewData } from "./follow-up-genetics/data/data-storage";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FollowUpService {
  familyHistoryNewData: {
    PID: number;
    PregIndex: number;
    Related: string;
    Disease: string;
    Comments: string;
    ID: string;
  };
  pregnanciesData: {
    PID: number;
    PregIndex: number;
    ID: string;
    PregnancyYear: string;
    ConceptionMethod: string;
    DeliveryDate: string;
    DeliveryWeek: string;
    WayOfDelivery: string;
    NumberOfFetuses: number;
    Comments: string;
    Fetus1Weight: string;
    Fetus1Gender: string;
    Fetus1Comments: string;
    Fetus2Weight: string;
    Fetus2Gender: string;
    Fetus2Comments: string;
    Fetus3Weight: string;
    Fetus3Gender: string;
    Fetus3Comments: string;
    Fetus4Weight: string;
    Fetus4Gender: string;
    Fetus4Comments: string;
  };
  diseasesNewData: { PID: number; PregIndex: number; Name: string; Comments: string; ID: string };
  surgeriesNewData: { PID: number; PregIndex: number; Year: string; Name: string; Description: string; ID: string };
  medicationsNewData: { PID: number; PregIndex: number; Name: string; Dosage: string; Comments: string; ID: string };
  sensitivitiesNewData: { PID: number; PregIndex: number; Name: string; Comments: string; ID: string };
  habitsNewData: { PID: number; PregIndex: number; Type: string; Comments: string; ID: string };
  testsNewData: { PID: number; PregIndex: number; Name: string; Comments: string; ID: string };
  visitsNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    Weight: string;
    BPressureSys: string;
    BPressureDia: string;
    Proteinuria: string;
    Glucosutia: string;
    Uterus: string;
    Cervical: string;
    PV: string;
    Comments: string;
    Fetus1Gender: string;
    Fetus1Present: string;
    Fetus1Movements: string;
    Fetus1Breathing: string;
    Fetus1Tonus: string;
    Fetus1Pulse: string;
    Fetus1FHR: string;
    Fetus1Weight: string;
    Fetus1Percentage: string;
    Fetus1NST: string;
    Fetus1Doppler: string;
    Fetus1AF: string;
    Fetus1Comments: string;
    Fetus2Gender: string;
    Fetus2Present: string;
    Fetus2Movements: string;
    Fetus2Breathing: string;
    Fetus2Tonus: string;
    Fetus2Pulse: string;
    Fetus2FHR: string;
    Fetus2Weight: string;
    Fetus2Percentage: string;
    Fetus2NST: string;
    Fetus2Doppler: string;
    Fetus2AF: string;
    Fetus2Comments: string;
    Fetus3Gender: string;
    Fetus3Present: string;
    Fetus3Movements: string;
    Fetus3Breathing: string;
    Fetus3Tonus: string;
    Fetus3Pulse: string;
    Fetus3FHR: string;
    Fetus3Weight: string;
    Fetus3Percentage: string;
    Fetus3NST: string;
    Fetus3Doppler: string;
    Fetus3AF: string;
    Fetus3Comments: string;
    Fetus4Gender: string;
    Fetus4Present: string;
    Fetus4Movements: string;
    Fetus4Breathing: string;
    Fetus4Tonus: string;
    Fetus4Pulse: string;
    Fetus4FHR: string;
    Fetus4Weight: string;
    Fetus4Percentage: string;
    Fetus4NST: string;
    Fetus4Doppler: string;
    Fetus4AF: string;
    Fetus4Comments: string;
  };

  scansNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Name: string;
    Date: Date;
    GA: string;
    Sonographer: string;
    Status: string;
    Comments: string;
  };

  measurementsNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    BPD: string;
    HC: string;
    AC: string;
    FL: string;
    InstanceNumber: number;
  };

  screeningNTNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    InstanceNumber: number;
    Date: Date;
    GA: string;
    NT: string;
    CRL: string;
    Risk: string;
    PAPPA: string;
    FreeBeta: string;
    CombinedRisk: string;
  };

  screening2ndNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    InstanceNumber: number;
    Date: Date;
    GA: string;
    AFP: string;
    FreeBeta: string;
    UE3: string;
    Inhibin: string;
    Risk: string;
  };

  screeningRisksNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    InstanceNumber: number;
    CalculatedBy: string;
    Risk: string;
    Comments: string;
  };

  labSerologyTestNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    GA: string;
    Name: string;
    Date: Date;
    IgM: string;
    IgG: string;
    Avidity: string;
    Comments: string;
  };

  labCoombsNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    Comments: string;
  };

  labAntiDNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    Comments: string;
  };

  labGlucose50NewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    GCT60_140: string;
    Comments: string;
  };

  labGlucose75NewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    GTT0_110: string;
    GTT120_140: string;
    Comments: string;
  };

  labGlucose100NewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    GTT0_95: string;
    GTT60_180: string;
    GTT120_150: string;
    GTT180_140: string;
    Comments: string;
  };

  labHBA1CNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    Value: string;
    Comments: string;
  };

  labPPGNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    Meal: string;
    Value: string;
    Hours: string;
    Comments: string;
  };

  labKaryotypeNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Date: Date;
    GA: string;
    Type: string;
    Status: string;
  };

  geneticsMotherNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Name: string;
    Carrier: string;
    Mutation: string;
  };

  geneticsSpouseNewData: {
    PID: number;
    PregIndex: number;
    ID: string;
    Name: string;
    Carrier: string;
    Mutation: string;
  };

  today: Date = new Date();

  constructor(
    private http: HttpClient,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService
  ) {}

  async saveData(table: string, values: object) {
    const data = {
      table,
      values,
    };
    return this.http.post(`/api/follow-up`, data).toPromise();
  }

  async updateData(table: string, values: object, AutoIndex: number) {
    const data = {
      table,
      values,
      AutoIndex,
    };
    return this.http.put(`/api/follow-up`, data).toPromise();
  }

  async deleteData(table: string, AutoIndex: number) {
    const params = { table, AutoIndex };
    return this.http.delete(`/api/follow-up`, { params }).toPromise();
  }

  async getData(table: string, pregIndex: number): Promise<any> {
    try {
      const params = { table, pregIndex };
      return await this.http.get("api/follow-up", { params }).toPromise();
    } catch (error) {
      console.error(`Error fetching followup data for table: ${table}:`, error);
      return [];
    }
  }

  initializeNewData() {
    const patient = this.patientsService.currentPatient;
    const pregID = this.pregnancyService.pregID;

    if (patient && pregID) {
      this.familyHistoryNewData = createFamilyHistoryNewData(patient.PID, pregID, patient.ID);
      this.pregnanciesData = createPregnanciesNewData(patient.PID, pregID, patient.ID);
      this.diseasesNewData = createDiseasesNewData(patient.PID, pregID, patient.ID);
      this.surgeriesNewData = createSurgeriesNewData(patient.PID, pregID, patient.ID);
      this.medicationsNewData = createMedicationsNewData(patient.PID, pregID, patient.ID);
      this.sensitivitiesNewData = createSensitivitiesNewData(patient.PID, pregID, patient.ID);
      this.habitsNewData = createHabitsNewData(patient.PID, pregID, patient.ID);
      this.testsNewData = createTestsNewData(patient.PID, pregID, patient.ID);
      this.visitsNewData = createVisitsNewData(patient.PID, pregID, patient.ID, patient?.getGA(), this.today);
      this.scansNewData = createScansNewData(patient.PID, pregID, patient.ID, patient?.getGA(), this.today);
      this.measurementsNewData = createMeasurementsNewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.screeningNTNewData = createScreeningNTNewData(patient.PID, pregID, patient.ID, patient?.getGA(), this.today);
      this.screening2ndNewData = createScreening2ndNewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.screeningRisksNewData = createScreeningRisksNewData(patient.PID, pregID, patient.ID);
      this.labSerologyTestNewData = createLabSerologyTestNewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.labCoombsNewData = createLabCoombsNewData(patient.PID, pregID, patient.ID, this.today);
      this.labAntiDNewData = createLabAntiDNewData(patient.PID, pregID, patient.ID, this.today);
      this.labGlucose50NewData = createLabGlucose50NewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.labGlucose75NewData = createLabGlucose75NewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.labGlucose100NewData = createLabGlucose100NewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.labHBA1CNewData = createLabHBA1CNewData(patient.PID, pregID, patient.ID, this.today);
      this.labPPGNewData = createLabPPGNewData(patient.PID, pregID, patient.ID, patient?.getGA(), this.today);
      this.labKaryotypeNewData = createLabKaryotypeNewData(
        patient.PID,
        pregID,
        patient.ID,
        patient?.getGA(),
        this.today
      );
      this.geneticsMotherNewData = createGeneticsMotherNewData(patient.PID, pregID, patient.ID);
      this.geneticsSpouseNewData = createGeneticsSpouseNewData(patient.PID, pregID, patient.ID);
    } else {
      console.error("Patient or pregnancy data is missing.");
    }
  }

  getFetusesNumber(pregIndex: number | null = null): Observable<number | null> {
    pregIndex = pregIndex || this.pregnancyService.pregID;
    if (!pregIndex) {
      return of(null);
    }

    const params = { pregIndex };
    return this.http.get<number>(`api/fetuses-number`, { params });
  }

  getObstetricalFormula(pregIndex: number | null = null): Observable<number | null> {
    pregIndex = pregIndex || this.pregnancyService.pregID;
    if (!pregIndex) {
      return of(null);
    }

    const params = { pregIndex };
    return this.http.get<number>(`api/obstetrical-formula`, { params });
  }

  newPregnancy(PID: number, patientID: string): any {
    const body = { PID, ID: patientID };
    return this.http.post<any>(`api/newpregnancy`, body);
  }

  getAllLetters(pergID,ID){
    return this.http.get('/api/newExams/letters/'+pergID+"/"+this.patientsService.getPat().PID+"/"+ID);
  }

  visitClosed(visitDate = null):boolean{
    let now = new Date();
    var closure = new Date();
    closure.setHours(3);
    closure.setMinutes(0);
    if(now.getHours() < 3){
        closure.setDate(closure.getDate() -1);
    }
    if(!visitDate) return false
    return closure > new Date(visitDate);
  }
}
