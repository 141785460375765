import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { AutotextService } from '../autotext.service';
import { ExamsService } from '../exams.service';
import { FetalechoService } from '../fetalecho.service';

@Component({
  selector: 'app-fetalecho',
  templateUrl: './fetalecho.component.html',
  styleUrls: ['./fetalecho.component.scss']
})
export class FetalechoComponent implements OnInit, OnDestroy,AfterViewInit {
  @Input('items') Items:any;
  @Input('group') Group = '';
  sub = new Subscription();
  showComment = false;
  comment;
  @ViewChild("CommentsExtra") set CommentsExtra(ref: ElementRef) {
    if (!!ref) {
      ref.nativeElement.focus() ;
    }
  }
  @ViewChild('Comment') Comment: ElementRef;


  


  constructor(public es: ExamsService,private fb:UntypedFormBuilder, public fes:FetalechoService, public atS:AutotextService) { }

  ngOnInit() {
    this.fes.resetForm();
    this.fes.getFetalEchoData();
    // this.fes.getFetalMeasure();
    this.sub = this.es.examLoaded$.subscribe((data) =>{
      this.fes.resetForm();
      this.fes.getFetalEchoData();
      // this.fes.getFetalMeasure();
    })
    this.fes.getFetalEchoData();
   }
  ngAfterViewInit(): void {
    this.sub = this.fes.fetalEchoForm.get('Comments').valueChanges.subscribe((data) => {
      if(data != null){
        this.showComment = true;
        this.Comment.nativeElement.blur()
        this.comment = data;
      }
    })
  }
  clear(grp:string, name: string){
    if(! this.es.isClosed && name=='IVSStatus'){
      this.fes.fetalEchoForm.get(name).setValue(-1);
    }
    else if(this.es.isClosed ){
      this.fes.fetalEchoForm.get(name).setValue(null);
    }
  }
  getClass(item):string{
    if(item !== null  && this.fes.fetalEchoForm.get(item+'Note')  && this.fes.fetalEchoForm.get(item+'Note').value )
      return 'type-v'
    return 'type-plus';
  }

  ngOnDestroy(){
    this.sub.unsubscribe()
  }
}
