<div class="no-scroll patient-ob">

    <div class="wrapper">

        <!-- ========================== header (start) ========================== -->
        <header class="l-header">

            <div class="on-mobile header-logo">
                <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
            </div>
            <div class="l-header-panel">

                <i class="off-mobile header-icon icon-fetus" *ngIf="false"></i>

                <div class="header-pagi" *ngIf="false">
                    <a href="#" class="header-pagi-item">1</a>
                    <a href="#" class="header-pagi-item is-active">2</a>
                    <a href="#" class="header-pagi-item">3</a>
                </div>

                <nav class="header-menu" *ngIf="false">

                    <a href="#" class="header-menu-link icon-book" aria-label="library"></a>
                    <a href="#" class="header-menu-link icon-print" aria-label="Print"></a>
                    <a href="#" class="header-menu-link icon-file" aria-label="file"></a>
                    <a href="#" class="header-menu-link icon-chart" aria-label="chart"></a>
                    <a href="#" class="header-menu-link icon-image" aria-label="image"></a>
                    <a href="#" class="header-menu-link icon-searchdoc" aria-label="image"></a>
                    <a href="#" class="header-menu-link icon-save" aria-label="save"></a>
                    <a href="#" class="header-menu-link icon-chat" aria-label="chat"></a>
                    <a href="#" class="header-menu-link icon-confirm" aria-label="confirm"></a>

                </nav>

                <nav class="on-mobile header-mobilePanel">
                    <button class="btn-mobileMenu" id="open-headerMenu" data-show=".header-menu"
                        aria-label="open mobile menu"></button>
                    <button class="btn-mobileMenu" id="open-mainNav" data-show=".l-sidebar"
                        aria-label="open mobile sidebar"></button>
                </nav>

            </div>

        </header>
        <!-- ========================== header (end) ========================== -->


        <!-- ========================== intro (start) ========================== -->

        <main class="l-deashboard">

            <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">

                <!-- history (start) -->
                <div class="w50 patient-ob-box">
                    <div class="h100 m-box">
                        <h2>Custom Header Title</h2>
                        <div class="h100 l-flex l-column" >
                            <section class="l-flex l-flex-w l-just-center">
                                <div class="anatomy-twin-radios w50" (dblclick)="ls.CustomHeader.setValue(-1)">

                                    <label class="observe">
                        
                                        <input type="radio" class="custom-check customInput" [formControl]="ls.CustomHeader" (change)="ls.getImageFromService();ls.chaneHeader()" [value]="true" />
                                        <i class="customInput-display"></i>
                                        <span>
                                            Custom Header
                                        </span>
                        
                                    </label>
                        
                                    <label class="not-observe">
                        
                                        <input type="radio" class="custom-check customInput" [formControl]="ls.CustomHeader" (change)="ls.getImageFromService();ls.chaneHeader()" [value]="false" />
                                        <i class="customInput-display"></i>
                                        <span>
                                            Default Header
                                        </span>
                        
                                    </label>
                        
                                </div>
                            </section>
                            <section>
                                <hr />
                                <div class="headerFrame">
                                    <img class="oversize" [src]="ls.headerToShow" [alt]="ls.picAlt" >
                                </div>
                            </section>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="customFile"
                                    (change)="onFileSelect($event)" #UploadFileInput>
                            </div>
                            <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">
                                <button class="patient-ob-btn -start_exam"
                                    (click)="onFormSubmit()" [disabled]="! canSave">
                                    Save Header
                                </button>
                                <a class="patient-ob-btn -exit_patient" routerLink="/patsearch">
                                    Exit
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- history (end) -->

                <!-- new exam (start) -->
                <div class="w50 patient-ob-box">
                    <div class="h100 m-box">

                    </div>
                </div>

            </div><!-- box margin -->

        </main>

        <!-- ========================== main sidebar (start) ========================== -->

        <aside class="l-sidebar l-flex l-column">

            <img class="off-mobile sidebar-logo" src="assets/icons/logo.svg" alt="Artisan Sonics" />

            <!-- user panel (start) -->

            <!-- user panel (end) -->

            <!-- main nav (start) -->
            <nav class="mainNav l-fg1 scroller">

            </nav>
            <!-- main nav (end) -->

            <app-docpanel></app-docpanel>

        </aside>

        <!-- ========================== main sidebar (end) ========================== -->

        <app-bottombar></app-bottombar>

    </div><!-- end wrapper -->
</div>