<div class="t-popup popup-pregnancyClose" [formGroup]="pregnancyService.pregForm">
  <div class="t-popup-close"></div>

  <div class="t-popup-frame" style="min-width: 65em">
    <!-- -------------------------------------------- -->

    <div class="t-popup-body">
      <button
        _ngcontent-enj-c5=""
        aria-label="close popup"
        class="t-btn-popup-close"
        (click)="isClosed.emit()"
      ></button>
      <h2 style="margin-bottom: 1.5em; font-size: 1.6em">New Pregnancy</h2>

      <!-- ================ Last Menstrual (Start) ================ -->
      <section class="m-box">
        <h2>Last Menstrual</h2>

        <div class="pregnancy-menstrual">
          <div class="l-flex-c menstrual-row menstrual-row--LMP">
            <label class="l-fg1 menstural-label" id="menstural_LMP"> L.M.P. </label>

            <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">
              <input
                #lmpField
                [matDatepicker]="LMPDatepicker"
                formControlName="LMP"
                (dateChange)="checkLMP()"
                class="t-field t-field--date menstural-field"
                aria-labelledby="menstural_LMP"
                [max]="maxLMP"
                [min]="minLMP"
                [ngClass]="invalidLMP"
              />
              <div class="t-validation-message" style="left: auto; top: 70%; right: 12em">
                <span>Invalid LMP</span>
              </div>
              <img src="../../assets/icons/date.svg" (click)="LMPDatepicker.open()" style="margin-left: -3em" />
              <mat-datepicker #LMPDatepicker></mat-datepicker>
              <img
                src="../../assets/icons/x.svg"
                class="clear-lmp"
                matTooltip="Clear"
                (click)="pregnancyService.pregForm.get('LMP').setValue(null)"
              />
              <span class="t-field menstural-field menstural-info">{{ pregnancyService.lmpGA(undefined, dateNow) }}</span>
            </span>
          </div>

          <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US">
            <label class="l-fg1 menstural-label" id="menstural_LMP_by_US"> L.M.P by US </label>

            <span class="l-flex t-boxMargin menstural-fields-set">
              <select
                class="t-select t-field menstural-field"
                aria-labelledby="menstural_LMP_by_US"
                formControlName="LMPUSweeks"
              >
                <option *ngFor="let i of weeksArray" [value]="i">{{ i }} W</option>
              </select>

              <select
                class="t-select t-field menstural-field"
                aria-labelledby="menstural_LMP_by_US"
                formControlName="LMPUSdays"
              >
                <option *ngFor="let i of daysArray" [value]="i">{{ i }} D</option>
              </select>

              <input
                [matDatepicker]="USDatepicker"
                class="t-field t-field--date menstural-field"
                aria-labelledby="menstural_LMP_by_US"
                max="{{ todayDate }}"
                min="{{ minLMP }}"
                formControlName="LMP_US"
              />
              <img src="../../assets/icons/date.svg" (click)="USDatepicker.open()" style="margin-left: -3em" />
              <mat-datepicker #USDatepicker></mat-datepicker>
              <img
                src="../../assets/icons/x.svg"
                class="clear-lmp"
                matTooltip="Clear"
                (click)="
                  pregnancyService.pregForm.get('LMP_US').setValue(null);
                  pregnancyService.pregForm.get('LMPUSweeks').setValue(null);
                  pregnancyService.pregForm.get('LMPUSdays').setValue(null)
                "
              />
              <span class="t-field menstural-field menstural-info">{{ pregnancyService.lmpUSGA(undefined, undefined, undefined, dateNow) }}</span>
            </span>
          </div>

          <div class="l-flex-c menstrual-row menstrual-row--ovulation">
            <label class="l-fg1 menstural-label" id="menstural_ovulation"> Ovulation </label>

            <span class="l-flex-c t-boxMargin menstural-fields-set">
              <input
                [matDatepicker]="OvDatepicker"
                class="t-field t-field--date menstural-field"
                aria-labelledby="menstural_ovulation"
                max="{{ todayDate }}"
                min="{{ minLMP }}"
                formControlName="Ovulation"
              />
              <img src="../../assets/icons/date.svg" (click)="OvDatepicker.open()" style="margin-left: -3em" />
              <mat-datepicker #OvDatepicker></mat-datepicker>
              <img
                src="../../assets/icons/x.svg"
                class="clear-lmp"
                matTooltip="Clear"
                (click)="pregnancyService.pregForm.get('Ovulation').setValue(null)"
              />
              <span class="t-field menstural-field menstural-info">{{ pregnancyService.ovulationGA(undefined, dateNow) }}</span>
            </span>
          </div>

          <div class="l-flex-c menstrual-row menstrual-row--EDD">
            <label class="l-fg1 menstural-label" id="menstural_LMP"> E.D.D </label>

            <span class="l-flex t-boxMargin menstural-fields-set">
              <span class="t-field menstural-field menstural-info">{{ pregnancyService.edd() }}</span>
            </span>
          </div>
        </div>
      </section>
      <!-- ================ Last Menstrual (End) ================ -->

      <!-- ================ Gestation Sac Location (Start) ================ -->
      <section class="l-fg1 m-box">
        <h2>Gestation Sac Location</h2>

        <div class="l-flex-c t-set">
          <div class="t-set-title" (dblclick)="pregnancyService.pregForm.get('Insitu').setValue(-1)">
            <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big">
              <label>
                <input type="radio" name="Insitu" class="custom-check" formControlName="Insitu" [value]="1" />
                <i class="customInput-display"></i>
                <span> IN SITU </span>
              </label>

              <label>
                <input type="radio" name="Insitu" class="custom-check" formControlName="Insitu" [value]="0" />
                <i class="customInput-display"></i>
                <span> ECTOPIC </span>
              </label>
            </div>
          </div>

          <!-- =========== ECTOPIC Options (Start) =========== -->
          <div
            *ngIf="!pregnancyService.pregForm.get('Insitu').value"
            class="l-flex-c l-fg1 t-set-table"
            (dblclick)="unselectECTOPIC()"
          >
            <label class="t-label-top">
              <input
                type="radio"
                formControlName="CervicalEctop"
                [value]="true"
                class="custom-check"
                (click)="toggleECTOPIC('CervicalEctop')"
              />
              <i class="customInput-display"></i>
              <span> Cervical </span>
            </label>

            <label class="t-label-top">
              <input
                type="radio"
                formControlName="TubalEctop"
                [value]="true"
                class="custom-check"
                (click)="toggleECTOPIC('TubalEctop')"
              />
              <i class="customInput-display"></i>
              <span> Tubal </span>
            </label>

            <label class="t-label-top">
              <input
                type="radio"
                formControlName="OvarianEctop"
                [value]="true"
                class="custom-check"
                (click)="toggleECTOPIC('OvarianEctop')"
              />
              <i class="customInput-display"></i>
              <span> Ovarian </span>
            </label>

            <label class="t-label-top">
              <input
                type="radio"
                formControlName="PeritonealEctop"
                [value]="true"
                class="custom-check"
                (click)="toggleECTOPIC('PeritonealEctop')"
              />
              <i class="customInput-display"></i>
              <span> Peritoneal </span>
            </label>

            <label class="t-label-top">
              <input
                type="radio"
                formControlName="AbdominalEctop"
                [value]="true"
                class="custom-check"
                (click)="toggleECTOPIC('AbdominalEctop')"
              />
              <i class="customInput-display"></i>
              <span> Abdominal </span>
            </label>
          </div>
          <!-- =========== ECTOPIC Options (End) =========== -->
        </div>
      </section>
      <!-- ================ Gestation Sac Location (End) ================ -->

      <!-- ================ Pregnancy Type (Start) ================ -->
      <section class="m-box pregnancy-type">
        <h2 style="margin: 0 0.35em 0 1.3em">Pregnancy Type</h2>
        <div class="l-flex-c t-set">
          <div class="t-set-title" (dblclick)="pregnancyService.pregForm.get('NaturalPregnancy').setValue(-1)">
            <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big">
              <label>
                <input
                  type="radio"
                  name="NaturalPregnancy"
                  class="custom-check"
                  formControlName="NaturalPregnancy"
                  [value]="1"
                />
                <i class="customInput-display"></i>
                <span> Natural </span>
              </label>

              <label>
                <input
                  type="radio"
                  name="NaturalPregnancy"
                  formControlName="NaturalPregnancy"
                  class="custom-check"
                  [value]="0"
                />
                <i class="customInput-display"></i>
                <span> Artificial Fertility Aid </span>
              </label>
            </div>
          </div>

          <div class="l-flex-c t-set-table" *ngIf="pregnancyService.pregForm.value.NaturalPregnancy == 0">
            <label class="t-label-top" style="padding: 0">
              <input
                type="checkbox"
                class="custom-check"
                [checked]="pregnancyService.pregForm.get('IVFType').value.includes('Insemination')"
                (change)="ivfToggle('Insemination')"
              />
              <i class="customInput-display"></i>
              <span> Insemination </span>
            </label>

            <label class="t-label-top" style="padding: 0">
              <input
                type="checkbox"
                class="custom-check"
                [checked]="pregnancyService.pregForm.get('IVFType').value.includes('Embrio Donation')"
                (change)="ivfToggle('Embrio Donation')"
              />
              <i class="customInput-display"></i>
              <span> Embrio Donation </span>
            </label>

            <label class="t-label-top" style="padding: 0">
              <input
                type="checkbox"
                class="custom-check"
                [checked]="pregnancyService.pregForm.get('IVFType').value.includes('Egg Donation')"
                (change)="ivfToggle('Egg Donation')"
              />
              <i class="customInput-display"></i>
              <span> Egg Donation </span>
            </label>

            <label class="t-label-top" style="padding: 0">
              <input
                type="checkbox"
                class="custom-check"
                [checked]="pregnancyService.pregForm.get('IVFType').value.includes('ICSI')"
                (change)="ivfToggle('ICSI')"
              />
              <i class="customInput-display"></i>
              <span> ICSI </span>
            </label>

            <label class="t-label-top" style="padding: 0">
              <input
                type="checkbox"
                class="custom-check"
                [checked]="pregnancyService.pregForm.get('IVFType').value.includes('IVF')"
                (change)="ivfToggle('IVF')"
              />
              <i class="customInput-display"></i>
              <span> IVF </span>
            </label>
          </div>
        </div>
      </section>
      <!-- ================  Pregnancy Type (End) ================ -->

      <!-- ================  Number of Fetuses (Start) ================ -->
      <section class="m-box">
        <h2>Number of Fetuses</h2>

        <div class="pregnancy-fetuses">
          <div class="l-flex-c fetuses-row">
            <span class="l-flex-c">
              <input
                id="fetuses_number_1"
                type="radio"
                name="NumFetuses"
                formControlName="NumFetuses"
                [value]="1"
                class="custom-check"
              />
              <i class="customInput-display"></i>
              <label for="fetuses_number_1" class="fetuses-count menstural-field">1</label>
            </span>
            <span *ngIf="pregnancyService.pregForm.value.NumFetuses > 1">
              <label for="gest_sacs_number" class="t-select-label">Gest. Sacs</label>

              <select class="t-select t-field menstural-field" id="gest_sacs_number" formControlName="NumberOfSacs">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </span>
          </div>

          <div class="l-flex-c fetuses-row">
            <span class="l-flex-c">
              <input
                id="fetuses_number_2"
                type="radio"
                name="NumFetuses"
                formControlName="NumFetuses"
                [value]="2"
                class="custom-check"
              />
              <i class="customInput-display"></i>
              <label for="fetuses_number_2" class="fetuses-count menstural-field">2</label>
            </span>

            <span
              class="l-fg1 l-flex-c fetus-row-2-fetus-checkSet"
              *ngIf="pregnancyService.pregForm.value.NumFetuses > 1"
            >
              <label class="l-flex-c t-select-label fetuses-label">
                <input
                  type="radio"
                  name="ChorioAmino"
                  value="DiChorio - Di Amnio"
                  class="custom-check"
                  formControlName="ChorioAmino"
                />
                <i class="customInput-display"></i>
                <span> DiChorio - Di Amnio </span>
              </label>

              <label class="l-flex-c t-select-label fetuses-label">
                <input
                  type="radio"
                  name="ChorioAmino"
                  value="MonoChorio - DiAmnio"
                  class="custom-check"
                  formControlName="ChorioAmino"
                />
                <i class="customInput-display"></i>
                <span> MonoChorio - DiAmnio </span>
              </label>

              <label class="l-flex-c t-select-label fetuses-label">
                <input
                  type="radio"
                  name="ChorioAmino"
                  value="MonoChorio - MonoAmnio"
                  class="custom-check"
                  formControlName="ChorioAmino"
                />
                <i class="customInput-display"></i>
                <span> MonoChorio - MonoAmnio </span>
              </label>
            </span>
          </div>

          <div class="l-flex-c fetuses-row">
            <span class="l-flex-c">
              <input
                type="radio"
                name="NumFetuses"
                class="custom-check"
                formControlName="NumFetuses"
                [value]="3"
                aria-label="custom fetus number"
              />
              <i class="customInput-display"></i>
              <span class="fetuses-count fetuses-count--has-select menstural-field">
                <select
                  class="t-select"
                  aria-label="select fetus number"
                  *ngIf="pregnancyService.pregForm.get('NumFetuses').value < 3"
                >
                  <option [value]="3">3</option>
                  <option [value]="4">4</option>
                </select>
                <select
                  class="t-select"
                  aria-label="select fetus number"
                  formControlName="NumFetuses"
                  *ngIf="pregnancyService.pregForm.get('NumFetuses').value > 2"
                >
                  <option [value]="3">3</option>
                  <option [value]="4">4</option>
                </select>
              </span>
            </span>
          </div>
        </div>
      </section>
      <!-- ================  Number of Fetuses (End) ================ -->

      <div class="m-box pregnancy-popup-moreDetails">
        <h2>More Details</h2>
        <textarea class="scroller fulltext" style="height: 100%" formControlName="Comment"></textarea>
      </div>

      <button class="close_pregnancy" (click)="isClosed.emit()">Open New Pregnancy</button>
    </div>
  </div>
</div>
