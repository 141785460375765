import { Component, OnInit,Output, EventEmitter,Input } from '@angular/core';
import {MeasgrowthService} from '../measgrowth.service';
import { ChartDataSets, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Input("page") page:string;
  loaded=false;
  keys:string[];
  chartlist:string[];
  chartData:ChartDataSets[][] = [];
  lineChartOptions: ChartOptions  = { responsive: true,scales: {
    xAxes: [{
        type: 'linear',
        position: 'bottom',
        scaleLabel: { display: true, labelString: 'weeks' }
    }],
    yAxes: [{
      type: 'linear',
      scaleLabel: { display: true, labelString: 'mm' }
    }],
    
  },
  aspectRatio: 1,
  };

  weightChartOptions: ChartOptions  = { responsive: true,scales: {
    xAxes: [{
        type: 'linear',
        position: 'bottom',
        scaleLabel: { display: true, labelString: 'weeks' }
    }],
    yAxes: [{
      type: 'linear',
      scaleLabel: { display: true, labelString: 'gm' }
    }],
    
  },
  aspectRatio: 1,
  };

  

  constructor(public ms: MeasgrowthService) { 
    this.ms.setGA();
  }

  ngOnInit() {
    this.ms.loaded.subscribe(()=>{
      this.getChartData();
      this.loaded=true;
    });
    if(this.page != "Measurements")
      this.ms.fetchValues();
    else{
      this.getChartData();
      this.loaded=true;
    }
    this.keys=Object.keys(this.ms.measref);
  }

  getName(name:string):string{
    if(name.indexOf("EFW") > -1){
      return "EFW by "+ this.ms.getMeas(name).WeightType;
    }
    return name;
  }

  getChartData(){
    this.chartlist=[];
    var NOSEARCH = ['VoPl',"PeefSet"]
    let mnames = Object.keys(this.ms.measref).filter((i) => ! NOSEARCH.includes(i));
    for(let name of mnames){
      let mymeas = this.ms.getMeas(name);
      let history = this.ms.getAllPoints(name)
      if(history.length == 0)
        continue;
      this.chartlist.push(name);
      let myValues: ChartDataSets = {data: history,
        pointStyle:'crossRot',
        hitRadius: 4,
        pointRadius: 10,
        borderWidth: 2,
        pointBorderColor:'#015B6F',
        pointHoverBorderColor: '#015B6F',
        backgroundColor:'#015B6F',
        borderColor: '#015B6F',
        label: 'Measured'
      };
      if(mymeas.GGfig && mymeas.GGfig != 'none')
        this.chartData[name]=mymeas.getBoundaries(mymeas.GGfig,myValues);
    }
    
  }

}
