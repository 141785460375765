<div style="display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="color: #015a6c">Fetuses Details</h2>
  <button (click)="closeDialog()">
    <mat-icon matTooltip="Close" style="color: #a9b6b6">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div style="display: flex; justify-content: space-between">
    <table>
      <tr>
        <th style="padding: 0 0.5em 0 0" class="th-clear"><span> # </span></th>
        <th class="th-clear"><span> Weight </span></th>
        <th class="th-clear"><span> Gender </span></th>
        <th class="th-clear"><span> Comments </span></th>
      </tr>

      <tr *ngFor="let fetus of fetusData; let i = index">
        <td style="padding: 0 0.5em 1.5em 0">{{ i + 1 }}</td>
        <td>
          <input
            class="clear-input w100"
            type="text"
            [(ngModel)]="fetus.weight"
            (ngModelChange)="updateFetusData()"
            placeholder="Weight {{ i + 1 }}"
          />
        </td>
        <td>
          <select [(ngModel)]="fetus.gender" class="t-select clear-t-select w100" (ngModelChange)="updateFetusData()">
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </td>
        <td>
          <input
            class="clear-input w100"
            type="text"
            [(ngModel)]="fetus.comments"
            (ngModelChange)="updateFetusData()"
            placeholder="Comments {{ i + 1 }}"
          />
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
