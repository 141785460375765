<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up" aria-label="Back to History" matTooltip="Back to History">Follow Up</button> /
        Laboratory
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close Laboratory"
          matTooltip="Close Laboratory"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->

  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <!-- ================ Serology Tests (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Serology Tests</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> IgM </span>
              </th>
              <th class="th-clear">
                <span> IgG </span>
              </th>
              <th class="th-clear">
                <span> Avidity </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr
              *ngFor="let serologyTest of labSerologyTestData"
              [ngClass]="serologyTest.isEditing ? 'tr-isActive' : ''"
            >
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.Name"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="scanDatePicker"
                      [min]="today"
                      [(ngModel)]="serologyTest.Date"
                      [disabled]="!serologyTest.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #scanDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!serologyTest.isEditing ? 'disabled' : ''"
                      (click)="scanDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.GA"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.IgM"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'IgM'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.IgG"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'IgG'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.Avidity"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'Avidity'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="serologyTest.Comments"
                  [disabled]="!serologyTest.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabSerologyTest'"
                  [isEditing]="serologyTest.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!serologyTest.isEditing" (click)="editRow(serologyTest)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="serologyTest.isEditing"
                    (click)="
                      updateRow('fuLabSerologyTest', serologyTest, [
                        'Name',
                        'Date',
                        'GA',
                        'IgM',
                        'IgG',
                        'Avidity',
                        'Comments'
                      ])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabSerologyTest', serologyTest)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labSerologyTestNewData.Date"
                      [matDatepicker]="testNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #testNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="testNewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.IgM"
                  [inputName]="'IgM'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.IgG"
                  [inputName]="'IgG'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.Avidity"
                  [inputName]="'Avidity'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labSerologyTestNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabSerologyTest'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labSerologyTestNewData.Name ||
                    !followUpService.labSerologyTestNewData.Date ||
                    !followUpService.labSerologyTestNewData.GA ||
                    !followUpService.labSerologyTestNewData.IgM ||
                    !followUpService.labSerologyTestNewData.IgG ||
                    !followUpService.labSerologyTestNewData.Avidity ||
                    !followUpService.labSerologyTestNewData.Comments
                  "
                  (click)="
                    saveNewRow('fuLabSerologyTest', followUpService.labSerologyTestNewData, labSerologyTestOriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Serology Tests (End) ================ -->

      <!-- ================ Coombs & Anti-D (Start) ================ -->
      <div class="w100 summary-recommendations" style="display: flex">
        <!-- ================ Coombs (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2 class="weightAssTable-title">Coombs</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Date </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>
              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let coomb of labCoombsData" [ngClass]="coomb.isEditing ? 'tr-isActive' : ''">
                <td>
                  <div class="search-patient-field t-field w100" style="border-radius: 20px">
                    <span class="t-validation-wrap l-fg1" style="display: flex">
                      <input
                        matInput
                        [matDatepicker]="coombDatePicker"
                        [min]="today"
                        [(ngModel)]="coomb.Date"
                        [disabled]="!coomb.isEditing"
                        class="t-field-text w100"
                      />
                      <mat-datepicker #coombDatePicker></mat-datepicker>
                      <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                      <img
                        class="calendar-icon"
                        src="../../assets/icons/date.svg"
                        [ngClass]="!coomb.isEditing ? 'disabled' : ''"
                        (click)="coombDatePicker.open()"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="coomb.Comments"
                    [disabled]="!coomb.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuLabCoombs'"
                    [isEditing]="coomb.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!coomb.isEditing" (click)="editRow(coomb)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="coomb.isEditing"
                      (click)="updateRow('fuLabCoombs', coomb, ['Date', 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuLabCoombs', coomb)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <div class="search-patient-field t-field w100" style="border-radius: 20px">
                    <span class="t-validation-wrap l-fg1" style="display: flex">
                      <input
                        matInput
                        [(ngModel)]="followUpService.labCoombsNewData.Date"
                        [matDatepicker]="coombNewDatePicker"
                        [min]="today"
                        class="t-field-text w100"
                      />
                      <mat-datepicker #coombNewDatePicker></mat-datepicker>
                      <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                      <img
                        class="calendar-icon"
                        src="../../assets/icons/date.svg"
                        (click)="coombNewDatePicker.open()"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.labCoombsNewData.Comments"
                    [inputName]="'Comments'"
                    [tableName]="'fuLabCoombs'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="!followUpService.labCoombsNewData.Date || !followUpService.labCoombsNewData.Comments"
                    (click)="saveNewRow('fuLabCoombs', followUpService.labCoombsNewData, labCoombsOriginalData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Coombs (End) ================ -->

        <!-- ================ Anti-D (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2 class="weightAssTable-title">Anti D</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Date </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>
              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let anitd of labAntiDData" [ngClass]="anitd.isEditing ? 'tr-isActive' : ''">
                <td>
                  <div class="search-patient-field t-field w100" style="border-radius: 20px">
                    <span class="t-validation-wrap l-fg1" style="display: flex">
                      <input
                        matInput
                        [matDatepicker]="anitDDatePicker"
                        [min]="today"
                        [(ngModel)]="anitd.Date"
                        [disabled]="!anitd.isEditing"
                        class="t-field-text w100"
                      />
                      <mat-datepicker #anitDDatePicker></mat-datepicker>
                      <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                      <img
                        class="calendar-icon"
                        src="../../assets/icons/date.svg"
                        [ngClass]="!anitd.isEditing ? 'disabled' : ''"
                        (click)="anitDDatePicker.open()"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="anitd.Comments"
                    [disabled]="!anitd.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuLabAntiD'"
                    [isEditing]="anitd.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!anitd.isEditing" (click)="editRow(anitd)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="anitd.isEditing"
                      (click)="updateRow('fuLabAntiD', anitd, ['Date', 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuLabAntiD', anitd)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <div class="search-patient-field t-field w100" style="border-radius: 20px">
                    <span class="t-validation-wrap l-fg1" style="display: flex">
                      <input
                        matInput
                        [(ngModel)]="followUpService.labAntiDNewData.Date"
                        [matDatepicker]="antiDNewDatePicker"
                        [min]="today"
                        class="t-field-text w100"
                      />
                      <mat-datepicker #antiDNewDatePicker></mat-datepicker>
                      <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                      <img
                        class="calendar-icon"
                        src="../../assets/icons/date.svg"
                        (click)="antiDNewDatePicker.open()"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.labAntiDNewData.Comments"
                    [inputName]="'Comments'"
                    [tableName]="'fuLabAntiD'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="!followUpService.labAntiDNewData.Date || !followUpService.labAntiDNewData.Comments"
                    (click)="saveNewRow('fuLabAntiD', followUpService.labAntiDNewData, labAntiDOriginalData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Anti-D (End) ================ -->
      </div>
      <!-- ================ Coombs & Anti-D (End) ================ -->

      <!-- ================ Glucose 50 (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Glucose 50</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> GCT 60 &#60; 140 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let glucose50 of labGlucose50Data" [ngClass]="glucose50.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="g50DatePicker"
                      [min]="today"
                      [(ngModel)]="glucose50.Date"
                      [disabled]="!glucose50.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g50DatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!glucose50.isEditing ? 'disabled' : ''"
                      (click)="g50DatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose50.GA"
                  [disabled]="!glucose50.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose50'"
                  [isEditing]="glucose50.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose50.GCT60_140"
                  [disabled]="!glucose50.isEditing"
                  [inputName]="'GCT60_140'"
                  [tableName]="'fuLabGlucose50'"
                  [isEditing]="glucose50.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="glucose50.Comments"
                  [disabled]="!glucose50.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose50'"
                  [isEditing]="glucose50.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!glucose50.isEditing" (click)="editRow(glucose50)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="glucose50.isEditing"
                    (click)="updateRow('fuLabGlucose50', glucose50, ['Date', 'GA', 'GCT60_140', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabGlucose50', glucose50)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labGlucose50NewData.Date"
                      [matDatepicker]="g50NewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g50NewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="g50NewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose50NewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose50'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose50NewData.GCT60_140"
                  [inputName]="'GCT60_140'"
                  [tableName]="'fuLabGlucose50'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose50NewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose50'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labGlucose50NewData.Date ||
                    !followUpService.labGlucose50NewData.GA ||
                    !followUpService.labGlucose50NewData.GCT60_140 ||
                    !followUpService.labGlucose50NewData.Comments
                  "
                  (click)="saveNewRow('fuLabGlucose50', followUpService.labGlucose50NewData, labGlucose50OriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Glucose 50 (End) ================ -->

      <!-- ================ Glucose 75 (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Glucose 75</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> GTT 0 &#60; 110 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> GTT 120 &#60; 140 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let glucose75 of labGlucose75Data" [ngClass]="glucose75.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="g50DatePicker"
                      [min]="today"
                      [(ngModel)]="glucose75.Date"
                      [disabled]="!glucose75.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g50DatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!glucose75.isEditing ? 'disabled' : ''"
                      (click)="g50DatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose75.GA"
                  [disabled]="!glucose75.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose75'"
                  [isEditing]="glucose75.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose75.GTT0_110"
                  [disabled]="!glucose75.isEditing"
                  [inputName]="'GTT0_110'"
                  [tableName]="'fuLabGlucose75'"
                  [isEditing]="glucose75.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose75.GTT120_140"
                  [disabled]="!glucose75.isEditing"
                  [inputName]="'GTT120_140'"
                  [tableName]="'fuLabGlucose75'"
                  [isEditing]="glucose75.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose75.Comments"
                  [disabled]="!glucose75.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose75'"
                  [isEditing]="glucose75.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!glucose75.isEditing" (click)="editRow(glucose75)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="glucose75.isEditing"
                    (click)="
                      updateRow('fuLabGlucose75', glucose75, ['Date', 'GA', 'GTT0_110', 'GTT120_140', 'Comments'])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabGlucose75', glucose75)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labGlucose75NewData.Date"
                      [matDatepicker]="g75NewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g75NewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="g75NewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose75NewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose75'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose75NewData.GTT0_110"
                  [inputName]="'GTT0_110'"
                  [tableName]="'fuLabGlucose75'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose75NewData.GTT120_140"
                  [inputName]="'GTT120_140'"
                  [tableName]="'fuLabGlucose75'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose75NewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose75'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labGlucose75NewData.Date ||
                    !followUpService.labGlucose75NewData.GA ||
                    !followUpService.labGlucose75NewData.GTT0_110 ||
                    !followUpService.labGlucose75NewData.GTT120_140 ||
                    !followUpService.labGlucose75NewData.Comments
                  "
                  (click)="saveNewRow('fuLabGlucose75', followUpService.labGlucose75NewData, labGlucose75OriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Glucose 75 (End) ================ -->

      <!-- ================ Glucose 100 (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Glucose 100</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> GTT 0 &#60; 95 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> GTT 60 &#60; 180 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> GTT 120 &#60; 150 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> GTT 180 &#60; 140 ml/dl </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let glucose100 of labGlucose100Data" [ngClass]="glucose100.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="g50DatePicker"
                      [min]="today"
                      [(ngModel)]="glucose100.Date"
                      [disabled]="!glucose100.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g50DatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!glucose100.isEditing ? 'disabled' : ''"
                      (click)="g50DatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.GA"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.GTT0_95"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'GTT0_95'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.GTT60_180"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'GTT60_180'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.GTT120_150"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'GTT120_150'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.GTT180_140"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'GTT180_140'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="glucose100.Comments"
                  [disabled]="!glucose100.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose100'"
                  [isEditing]="glucose100.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!glucose100.isEditing" (click)="editRow(glucose100)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="glucose100.isEditing"
                    (click)="
                      updateRow('fuLabGlucose100', glucose100, [
                        'Date',
                        'GA',
                        'GTT0_95',
                        'GTT60_180',
                        'GTT120_150',
                        'GTT180_140',
                        'Comments'
                      ])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabGlucose100', glucose100)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labGlucose100NewData.Date"
                      [matDatepicker]="g100NewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #g100NewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="g100NewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.GTT0_95"
                  [inputName]="'GTT0_95'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.GTT60_180"
                  [inputName]="'GTT60_180'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.GTT120_150"
                  [inputName]="'GTT120_150'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.GTT180_140"
                  [inputName]="'GTT180_140'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labGlucose100NewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabGlucose100'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labGlucose100NewData.Date ||
                    !followUpService.labGlucose100NewData.GA ||
                    !followUpService.labGlucose100NewData.GTT0_95 ||
                    !followUpService.labGlucose100NewData.GTT60_180 ||
                    !followUpService.labGlucose100NewData.GTT120_150 ||
                    !followUpService.labGlucose100NewData.GTT180_140 ||
                    !followUpService.labGlucose100NewData.Comments
                  "
                  (click)="
                    saveNewRow('fuLabGlucose100', followUpService.labGlucose100NewData, labGlucose100OriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Glucose 100 (End) ================ -->

      <!-- ================ HbA1C (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">HbA1C</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> Value </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let hba1c of labHBA1CData" [ngClass]="hba1c.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="hba1cDatePicker"
                      [min]="today"
                      [(ngModel)]="hba1c.Date"
                      [disabled]="!hba1c.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #hba1cDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!hba1c.isEditing ? 'disabled' : ''"
                      (click)="hba1cDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="hba1c.Value"
                  [disabled]="!hba1c.isEditing"
                  [inputName]="'Value'"
                  [tableName]="'fuLabHBA1C'"
                  [isEditing]="hba1c.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="hba1c.Comments"
                  [disabled]="!hba1c.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabHBA1C'"
                  [isEditing]="hba1c.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!hba1c.isEditing" (click)="editRow(hba1c)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="hba1c.isEditing"
                    (click)="updateRow('fuLabHBA1C', hba1c, ['Date', 'GA', 'Value', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabHBA1C', hba1c)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labHBA1CNewData.Date"
                      [matDatepicker]="hba1cNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #hba1cNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="hba1cNewDatePicker.open()" />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labHBA1CNewData.Value"
                  [inputName]="'Value'"
                  [tableName]="'fuLabHBA1C'"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labHBA1CNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabHBA1C'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labHBA1CNewData.Date ||
                    !followUpService.labHBA1CNewData.Value ||
                    !followUpService.labHBA1CNewData.Comments
                  "
                  (click)="saveNewRow('fuLabHBA1C', followUpService.labHBA1CNewData, labHBA1COriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ HbA1C (End) ================ -->

      <!-- ================ PPG (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">PPG</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> Meal </span>
              </th>
              <th class="th-clear">
                <span> Value </span>
              </th>
              <th class="th-clear">
                <span> Hours </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let ppg of labPPGData" [ngClass]="ppg.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="ppgDatePicker"
                      [min]="today"
                      [(ngModel)]="ppg.Date"
                      [disabled]="!ppg.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #ppgDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!ppg.isEditing ? 'disabled' : ''"
                      (click)="ppgDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ppg.GA"
                  [disabled]="!ppg.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabPPG'"
                  [isEditing]="ppg.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ppg.Meal"
                  [disabled]="!ppg.isEditing"
                  [inputName]="'Meal'"
                  [tableName]="'fuLabPPG'"
                  [isEditing]="ppg.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ppg.Value"
                  [disabled]="!ppg.isEditing"
                  [inputName]="'Value'"
                  [tableName]="'fuLabPPG'"
                  [isEditing]="ppg.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ppg.Hours"
                  [disabled]="!ppg.isEditing"
                  [inputName]="'Hours'"
                  [tableName]="'fuLabPPG'"
                  [isEditing]="ppg.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ppg.Comments"
                  [disabled]="!ppg.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabPPG'"
                  [isEditing]="ppg.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!ppg.isEditing" (click)="editRow(ppg)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="ppg.isEditing"
                    (click)="updateRow('fuLabPPG', ppg, ['Date', 'GA', 'Meal', 'Value', 'Hours', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabPPG', ppg)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labPPGNewData.Date"
                      [matDatepicker]="ppgNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #ppgNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="ppgNewDatePicker.open()" />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labPPGNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabPPG'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labPPGNewData.Meal"
                  [inputName]="'Meal'"
                  [tableName]="'fuLabPPG'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labPPGNewData.Value"
                  [inputName]="'Value'"
                  [tableName]="'fuLabPPG'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labPPGNewData.Hours"
                  [inputName]="'Hours'"
                  [tableName]="'fuLabPPG'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labPPGNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuLabPPG'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.labPPGNewData.Date ||
                    !followUpService.labPPGNewData.GA ||
                    !followUpService.labPPGNewData.Meal ||
                    !followUpService.labPPGNewData.Value ||
                    !followUpService.labPPGNewData.Hours ||
                    !followUpService.labPPGNewData.Comments
                  "
                  (click)="saveNewRow('fuLabPPG', followUpService.labPPGNewData, labPPGOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ PPG (End) ================ -->

      <!-- ================ Karyotype (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Karyotype</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> Type </span>
              </th>
              <th class="th-clear">
                <span> Status </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let karyotype of labKaryotypeData" [ngClass]="karyotype.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="karyotypeDatePicker"
                      [min]="today"
                      [(ngModel)]="karyotype.Date"
                      [disabled]="!karyotype.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #karyotypeDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!karyotype.isEditing ? 'disabled' : ''"
                      (click)="karyotypeDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="karyotype.GA"
                  [disabled]="!karyotype.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuLabKaryotype'"
                  [isEditing]="karyotype.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="karyotype.Type"
                  [disabled]="!karyotype.isEditing"
                  [inputName]="'Type'"
                  [tableName]="'fuLabKaryotype'"
                  [isEditing]="karyotype.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="karyotype.Status"
                  [disabled]="!karyotype.isEditing"
                  [inputName]="'Status'"
                  [tableName]="'fuLabKaryotype'"
                  [isEditing]="karyotype.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!karyotype.isEditing" (click)="editRow(karyotype)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="karyotype.isEditing"
                    (click)="updateRow('fuLabKaryotype', karyotype, ['Date', 'GA', 'Type', 'Status'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuLabKaryotype', karyotype)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.labKaryotypeNewData.Date"
                      [matDatepicker]="karyotypeNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #karyotypeNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      (click)="karyotypeNewDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labKaryotypeNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuLabKaryotype'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labKaryotypeNewData.Type"
                  [inputName]="'Type'"
                  [tableName]="'fuLabKaryotype'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.labKaryotypeNewData.Status"
                  [inputName]="'Status'"
                  [tableName]="'fuLabKaryotype'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  [disabled]="
                    !followUpService.labKaryotypeNewData.Date ||
                    !followUpService.labKaryotypeNewData.GA ||
                    !followUpService.labKaryotypeNewData.Type ||
                    !followUpService.labKaryotypeNewData.Status
                  "
                  (click)="saveNewRow('fuLabKaryotype', followUpService.labKaryotypeNewData, labKaryotypeOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Karyotype (End) ================ -->
    </div>
  </main>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
