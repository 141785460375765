import { Component, OnInit } from "@angular/core";
import { PrintService } from "../print.service";
import { SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ExamsService } from "../exams.service";

@Component({
  selector: "app-visit-print",
  templateUrl: "./visit-print.component.html",
  styleUrls: ["./visit-print.component.scss"],
})
export class VisitPrintComponent implements OnInit {
  now: Date;
  PID: number;
  visitIndex: number;

  typeOfPrint = "simple";
  Direction = "ltr";
  altHeader: SafeHtml;
  showHeader = true;
  loaded = false;
  code;
  fetuses: any[] = []; // Array to store processed fetus data

  constructor(
    public printService: PrintService,
    public translate: TranslateService,
    public examsService: ExamsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.now = new Date();
  }

  ngOnInit(): void {
    const paramLanguage = this.activatedRoute.snapshot.params.LANG;
    this.PID = this.activatedRoute.snapshot.params.PID;
    this.visitIndex = this.activatedRoute.snapshot.params.visitIndex;
    this.code = this.activatedRoute.snapshot.params.code;

    this.translate.use(paramLanguage);

    if (paramLanguage === "he-IL" || paramLanguage === "ar-JO") {
      this.Direction = "rtl";
    }

    this.printService.loadVisit(this.visitIndex, this.PID, this.code);

    this.printService.dataLoaded$.subscribe((visitData: any) => {
      if (visitData) {
        this.processFetusData(visitData);
        this.loaded = true;
      }
    });
  }

  processFetusData(visitData: any): void {
    this.fetuses = [];
    for (let i = 1; i <= 4; i++) {
      const fetus = {
        Gender: visitData[`Fetus${i}Gender`],
        Present: visitData[`Fetus${i}Present`],
        Movements: visitData[`Fetus${i}Movements`],
        Breathing: visitData[`Fetus${i}Breathing`],
        Tonus: visitData[`Fetus${i}Tonus`],
        Pulse: visitData[`Fetus${i}Pulse`],
        FHR: visitData[`Fetus${i}FHR`],
        Weight: visitData[`Fetus${i}Weight`],
        Percentage: visitData[`Fetus${i}Percentage`],
        NST: visitData[`Fetus${i}NST`],
        Doppler: visitData[`Fetus${i}Doppler`],
        AF: visitData[`Fetus${i}AF`],
        Comments: visitData[`Fetus${i}Comments`],
      };

      if (Object.values(fetus).some((value) => value)) {
        this.fetuses.push(fetus);
      }
    }
  }
}
