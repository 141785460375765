import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { GastroService } from 'src/app/gastro.service';
import { PathologyService } from 'src/app/pathology.service';

@Component({
  selector: 'app-pathologies',
  templateUrl: './pathologies.component.html',
  styleUrls: ['./pathologies.component.scss']
})
export class PathologiesComponent implements OnInit {
  sub = new Subscription();
  constructor(public pathologyS:PathologyService, public es:ExamsService, public gs:GastroService, public atS:AutotextService) { }

  ngOnInit() {
    this.pathologyS.getPathologies();
    this.sub = this.es.examLoaded$.subscribe(() => {
      this.pathologyS.getPathologies();
      if(this.es.isClosed){
        this.pathologyS.pathologyForm.disable({emitEvent:false})
      }
    })
  }



}
