import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "uniqueAutoIndex",
})
export class UniqueAutoIndexPipe implements PipeTransform {
  transform(array: any[]): any[] {
    const uniqueIndexes = new Set<number>();

    return array.filter((item) => {
      if (!uniqueIndexes.has(item.AutoIndex)) {
        uniqueIndexes.add(item.AutoIndex);
        return true;
      }
      return false;
    });
  }
}
