import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutotextService } from '../autotext.service';
import { ExamsService } from '../exams.service';
import { MedicationService } from '../medication.service';
import { ProcedureService } from '../procedure.service';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit, OnDestroy {
  sub = new Subscription()
  procedureForm;
  FolliclesForm;
  deleteItem;
  toDelete=false;
  readydelete =false;

  
  constructor(public medS:MedicationService,public atS:AutotextService, public es:ExamsService,private fb:UntypedFormBuilder,private http:HttpClient, public pr:ProcedureService ) {
    this.resetForm();
    this.FolliclesForm = this.fb.group({
      X:[''],
      Y:[''],
      Z:[''],
      Ovary:['']
    })
    if(this.es.ExamType != 'Follicles'){
      this.medS.getAllMedication()
    }
  }

  ngOnInit() {
    this.es.fillForm(this.procedureForm,"invasive", true, false);
    this.getOvaries();
    this.sub = this.es.fetusChange$.subscribe(() => {
      this.resetForm();
      if(this.es.ExamType == 'Follicles'){
        this.getOvaries()
      }else{
        this.es.fillForm(this.procedureForm,"invasive", true, false);
      }
    })
    this.medS.Medication.get('Unit').setValue('ml', {emitEvent:false})
  }

  resetForm(){
    this.procedureForm = this.fb.group({
      Needle:[''],
      Tries:[''],
      ThroughPlacenta:[''],
      USGiudance:[''],
      antiD:[''],
      FluidSample:[''],
      FluidColor:[''],
      preformedby:[''],
      Abdominal:[''],
      Vaginal:[''],
      Transperineal:[''],
      Rectal:[''],
      Type:['']
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  saveFolicals(ovary){
    this.FolliclesForm.get('Ovary').setValue(ovary);
    if(this.FolliclesForm.get('X').value && this.FolliclesForm.get('Y').value && this.FolliclesForm.get('Z').value ){
      var X = this.FolliclesForm.get('X').value;
      var Y = this.FolliclesForm.get('Y').value;
      var Z = this.FolliclesForm.get('Z').value;
      var Ovary = this.FolliclesForm.get('Ovary').value;
      var folliclesData = {
        X:X,
        Y:Y,
        Z:Z,
        Ovary:Ovary,
        ExamID:this.es.examID,
        ID:this.es.patServ.getPat().ID,
        instanceNumber:this.es.getFetus()
      }
      this.http.post('/api/newExams/addFolicels', {data:folliclesData}).subscribe((data) => {
        if(data["status"] == 'ok'){
          console.log(data);
          this.getOvaries();
          this.FolliclesForm.reset();
        }
      })
    }
  }

  getOvaries(item = null){
    this.pr.getOvariesData(item);
  }
  getAVG(item){
    return (item.Xindex +  item.Yindex + item.Zindex )/3
  }



}
