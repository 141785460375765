<!-- =============== Popup (Start) =============== -->
<div *ngIf="mts.toggleSuccessMessage" class="server-popup">
  <div class="server-popup-body">
    <button (click)="togglePopup()" class="server-popup-close" aria-label="close popup"></button>
    {{ mts.successMessage }}
  </div>
</div>
<!-- =============== Popup (End) =============== -->
<div class="wrapper">
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel" style="
        justify-content: space-between;
        background-color: #dcb16b;
        color: #015a6c;
      ">
      <h3>Support Center: Reallocation of M/M</h3>
      <div style="display: flex; flex-direction: row" class="buttons-container">
        <a class="header-menu-link icon-confirm" style="cursor: pointer" aria-label="Close Media Transfer"
          (click)="exit()" matTooltip="Close Media Transfer and Open AWL"></a>
      </div>
    </div>
  </header>

  <div style="display: flex; flex-direction: row">
    <!-- =============== Incorrect Data Inputs (Start) =============== -->
    <div class="search-wrapper">
      <h4>Patient With Incorrect Data:</h4>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Clinic: </span>
        <select [(ngModel)]="mts.selectedIncorrectClinic" class="t-select t-field">
          <option *ngFor="let clinic of mts.availableClinics" [value]="clinic.GID">
            {{ clinic.ClinicTitle }}
          </option>
        </select>
      </label>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Patient ID: &nbsp; </span>
        <span class="t-field t-field--number number-input">
          <input class="t-field-text number-input" [(ngModel)]="mts.selectedIncorrectID" type="text" />
        </span>
      </label>
      <!-- =============== From Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> From: </span>

        <span>
          <input [matDatepicker]="incorrectDate" [(ngModel)]="mts.selectedFromIncorrectDate"
            (ngModelChange)="mts.onIncorrectFromDateChange($event)" class="t-field field--date mmenstural-field"
            (click)="incorrectDate.open()" />
          <mat-datepicker #incorrectDate></mat-datepicker>
        </span>

        <span> Time: </span>

        <select [(ngModel)]="mts.selectedFromIncorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of mts.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select [(ngModel)]="mts.selectedFromIncorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of mts.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== From Date Input (End) =============== -->

      <!-- =============== To Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> To: </span>

        <span>
          <input [matDatepicker]="incorrectToDate" [(ngModel)]="mts.selectedToIncorrectDate"
            (ngModelChange)="mts.onIncorrectToDateChange($event)" class="t-field field--date mmenstural-field"
            (click)="incorrectToDate.open()" />
          <mat-datepicker #incorrectToDate></mat-datepicker>
        </span>

        <span> Time: </span>

        <select [(ngModel)]="mts.selectedToIncorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of mts.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select [(ngModel)]="mts.selectedToIncorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of mts.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== To Date Input (End) =============== -->

      <button matTooltip="Search" class="advanced-search-btn" type="button" (click)="mts.searchIncorrectExam()"
        [disabled]="
          !(
            mts.selectedIncorrectClinic &&
            mts.selectedIncorrectID &&
            mts.selectedFromIncorrectDate &&
            mts.selectedFromIncorrectHour &&
            mts.selectedFromIncorrectMinute
          )
        ">
        Search
      </button>
    </div>
    <!-- =============== Incorrect Data Inputs (End) =============== -->

    <!-- =============== Correct Data Inputs (Start) =============== -->
    <div class="search-wrapper">
      <h4>Patient To Move Data To:</h4>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Clinic: </span>

        <select [(ngModel)]="mts.selectedCorrectClinic" class="t-select t-field">
          <option *ngFor="let clinic of mts.availableClinics" [value]="clinic.GID">
            {{ clinic.ClinicTitle }}
          </option>
        </select>
      </label>

      <label class="t-label-top" style="padding: 0.5em">
        <span> Patient ID: &nbsp; </span>
        <span class="t-field t-field--number">
          <input class="t-field-text number-input" [(ngModel)]="mts.selectedCorrectID" type="text" />
        </span>
      </label>
      <!-- =============== From Date Input (Start) =============== -->

      <label class="t-label-top" style="padding: 0.5em">
        <span> From: </span>
        <span>
          <input [matDatepicker]="correctDate" [(ngModel)]="mts.selectedFromCorrectDate"
            (ngModelChange)="mts.onCorrectFromDateChange($event)" class="t-field field--date mmenstural-field"
            (click)="correctDate.open()" />
          <mat-datepicker #correctDate></mat-datepicker>
        </span>

        <span> Time: </span>
        <select [(ngModel)]="mts.selectedFromCorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of mts.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select [(ngModel)]="mts.selectedFromCorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of mts.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== From Date Input (End) =============== -->

      <!-- =============== To Date Input (Start) =============== -->
      <label class="t-label-top" style="padding: 0.5em">
        <span> To: </span>
        <span>
          <input [matDatepicker]="correctToDate" [(ngModel)]="mts.selectedToCorrectDate"
            (ngModelChange)="mts.onCorrectToDateChange($event)" class="t-field field--date mmenstural-field"
            (click)="correctToDate.open()" />
          <mat-datepicker #correctToDate></mat-datepicker>
        </span>

        <span> Time: </span>
        <select [(ngModel)]="mts.selectedToCorrectHour" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let hour of mts.hours" [value]="hour">
            {{ hour }}
          </option>
        </select>
        &nbsp; : &nbsp;
        <select [(ngModel)]="mts.selectedToCorrectMinute" class="t-select t-field">
          <option [value]=""></option>
          <option *ngFor="let minute of mts.minutes" [value]="minute">
            {{ minute }}
          </option>
        </select>
      </label>
      <!-- =============== To Date Input (End) =============== -->

      <button matTooltip="Search" class="advanced-search-btn" type="button" (click)="mts.searchCorrectExam()"
        [disabled]="
          !(
            mts.selectedCorrectClinic &&
            mts.selectedCorrectID &&
            mts.selectedFromCorrectDate &&
            mts.selectedFromCorrectHour &&
            mts.selectedFromCorrectMinute
          )
        ">
        Search
      </button>
    </div>
    <!-- =============== Correct Data Inputs (End) =============== -->
  </div>

  <!-- =============== Search Results (Start) =============== -->
  <div style="display: flex; flex-direction: row">
    <main class="search-results w50" style="padding: 1em">
      <ng-container *ngIf="mts.incorrectResults">
        <h4>Exam Results With Incorrect Data:</h4>
        <div *ngFor="let exam of mts.incorrectResults" (click)="mts.goToExamDetails(exam)">
          <div class="l-flex patient-item">
            <div class="w100 patient-item-link">
              <div class="l-table h100">
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>{{ exam.First }} {{ exam.Last }}</strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong> Patient ID: {{ exam.ID }} </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong>
                    Exam Date: {{ exam.CreationTime | date : "d/M/yy, HH:mm" }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong> Exam ID: {{ exam.AutoIndex }} </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong> Media Count: {{ exam.MediaCount }} </strong>
                </span>
                <span class="l-td-m patient-item-cell-check" style="width: auto" (click)="mts.handleRadioClick($event)">
                  <label class="t-label-top" (dblclick)="mts.selectedIncorrectExam = null">
                    <input type="radio" [(ngModel)]="mts.selectedIncorrectExam" (ngModelChange)="
                        mts.updateDateToSelected($event)
                      " [value]="exam" name="incorrectExam" class="custom-check -sq" />
                    <i class="customInput-display -sq"></i>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="mts.toggleErrorMessage1" class="not-found-error">
        <h3 style="text-align: center">
          {{ mts.errorMessage1 }}
        </h3>
      </div>
    </main>

    <main class="search-results w50" style="padding: 1em">
      <ng-container *ngIf="mts.correctResults">
        <h4>Exam Results To Move Data To:</h4>

        <div *ngFor="let exam of mts.correctResults" (click)="mts.goToExamDetails(exam)">
          <div class="l-flex patient-item">
            <div class="w100 patient-item-link">
              <div class="l-table h100">
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong>{{ exam.First }} {{ exam.Last }}</strong>
                </span>
                <span class="l-td-m patient-item-cell patient-name-first" style="width: auto">
                  <strong> Patient ID: {{ exam.ID }} </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong>
                    Exam Date: {{ exam.CreationTime | date : "d/M/yy, HH:mm" }}
                  </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong> Exam ID: {{ exam.AutoIndex }} </strong>
                </span>
                <span class="l-td-m patient-item-cell patient-id" style="width: auto">
                  <strong> Media Count: {{ exam.MediaCount }} </strong>
                </span>
                <span class="l-td-m patient-item-cell-check" style="width: auto" (click)="mts.handleRadioClick($event)">
                  <label class="t-label-top" (dblclick)="mts.selectedCorrectExam = null">
                    <input type="radio" [(ngModel)]="mts.selectedCorrectExam" [value]="exam" name="correctExam"
                      class="custom-check -sq" />
                    <i class="customInput-display -sq"></i>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="mts.toggleErrorMessage2" class="not-found-error">
        <h3 style="text-align: center">
          {{ mts.errorMessage2 }}
        </h3>
      </div>
    </main>
  </div>
  <!-- =============== Search Results (End) =============== -->

  <!-- =============== Moving Section (Start) =============== -->
  <div style="display: grid; align-items: center; justify-content: center">
    <div *ngIf="mts.toggleDataTransferMessage" class="not-found-error">
      <h3 style="text-align: center">
        {{ mts.dataTransferMessage }}
      </h3>
    </div>

    <div *ngIf="
        mts.selectedIncorrectExam &&
        (mts?.selectedIncorrectExam?.FirstMediaDate ||
          mts?.selectedIncorrectExam?.LastMediaDate)
      ">
      <span>
        <strong>First Media Date: </strong>
        {{
        mts?.selectedIncorrectExam?.FirstMediaDate
        | date : "d/M/yy HH:mm"
        }}
      </span>
      <span>
        <strong> Last Media Date: </strong>
        {{
        mts?.selectedIncorrectExam?.LastMediaDate
        | date : "d/M/yy HH:mm"
        }}
      </span>
    </div>

    <!-- =============== Move From Inputs (Start) =============== -->
    <label class="t-label-top" style="padding: 0.5em">
      <span> Move From: &nbsp; </span>

      <span>
        <input [matDatepicker]="dateToMoveFrom" [(ngModel)]="mts.dateToMoveFrom"
          (ngModelChange)="mts.onMoveFromDateChange($event)" class="t-field field--date mmenstural-field"
          (click)="dateToMoveFrom.open()" />
        <mat-datepicker #dateToMoveFrom></mat-datepicker>
      </span>
      <select [(ngModel)]="mts.toMoveFromCorrectHour" class="t-select t-field">
        <option [value]=""></option>
        <option *ngFor="let hour of mts.hours" [value]="hour">
          {{ hour }}
        </option>
      </select>
      &nbsp; : &nbsp;
      <select [(ngModel)]="mts.toMoveFromCorrectMinute" class="t-select t-field">
        <option [value]=""></option>
        <option *ngFor="let minute of mts.minutes" [value]="minute">
          {{ minute }}
        </option>
      </select>
    </label>
    <!-- =============== Move From Inputs (End) =============== -->

    <!-- =============== Move To Inputs (Start) =============== -->
    <label class="t-label-top" style="padding: 0.5em">
      <span> Move Until: &nbsp; </span>

      <span>
        <input [matDatepicker]="dateToMoveTo" [(ngModel)]="mts.dateToMoveTo"
          (ngModelChange)="mts.onMoveToDateChange($event)" class="t-field field--date mmenstural-field"
          (click)="dateToMoveTo.open()" />
        <mat-datepicker #dateToMoveTo></mat-datepicker>
      </span>
      <select [(ngModel)]="mts.toMoveToCorrectHour" class="t-select t-field">
        <option [value]=""></option>
        <option *ngFor="let hour of mts.hours" [value]="hour">
          {{ hour }}
        </option>
      </select>
      &nbsp; : &nbsp;
      <select [(ngModel)]="mts.toMoveToCorrectMinute" class="t-select t-field">
        <option [value]=""></option>
        <option *ngFor="let minute of mts.minutes" [value]="minute">
          {{ minute }}
        </option>
      </select>
    </label>
    <label class="t-label-top" style="padding: 0.5em">
      <span> File Type: </span>
      <select [(ngModel)]="mts.selectedFileType" class="t-select t-field">
        <option></option>
        <option [value]="'All'">All</option>
        <option [value]="'Full'">Full</option>
        <option [value]="'Clips'">Clips</option>
        <option [value]="'Pictures'">Pictures</option>
      </select>
    </label>
    <!-- =============== Move To Inputs (End) =============== -->

    <button matTooltip="Move Data" class="advanced-search-btn" type="button" [disabled]="
        !(
          mts.selectedCorrectExam &&
          mts.selectedIncorrectExam &&
          mts.toMoveFromCorrectHour &&
          mts.toMoveFromCorrectMinute &&
          mts.toMoveToCorrectHour &&
          mts.toMoveToCorrectMinute &&
          mts.dateToMoveFrom &&
          mts.dateToMoveTo &&
          mts.selectedFileType
        )
      " (click)="mts.moveDataByTime()">
      Move Data
    </button>
  </div>
  <!-- =============== Moving Section (End) =============== -->

  <!-- =============== Moving Result (Start) =============== -->
  <div *ngIf="mts.movingDataResult" style="display: grid; align-items: center; justify-content: center">
    <button matTooltip="Revert Data Move" class="advanced-search-btn" type="button" [disabled]="
        !(
          mts.selectedCorrectExam &&
          mts.selectedIncorrectExam &&
          mts.toMoveFromCorrectHour &&
          mts.toMoveFromCorrectMinute &&
          mts.toMoveToCorrectHour &&
          mts.toMoveToCorrectMinute &&
          mts.dateToMoveFrom &&
          mts.dateToMoveTo &&
          mts.movingDataResult
        )
      " (click)="mts.revertDataMove()">
      Revert Data Move
    </button>
  </div>

  <div *ngIf="mts.movingDataResult" style="display: grid; align-items: center; justify-content: center">
    <main class="search-results" style="padding: 1em">
      <h4>
        {{
        mts.revert
        ? "Reverted To Original Data:"
        : "Updated New Data:"
        }}
      </h4>
      <table>
        <thead>
          <tr>
            <th>Original Exam ID</th>
            <th>Original Patient ID</th>
            <th>New Exam ID</th>
            <th>New Patient ID</th>
            <th>File Creation Date</th>
            <th>File Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of mts.movingDataResult">
            <td>{{ data.old_examId }}</td>
            <td>{{ data.old_patient_id }}</td>
            <td>{{ data.new_examId }}</td>
            <td>{{ data.new_patient_id }}</td>
            <td>{{ data.old_date | date : "d/M/yy, HH:mm" }}</td>
            <td>{{ data.new_file_type }}</td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
  <!-- =============== Moving Result (End) =============== -->

  <!-- =============== Finish Section (Start) =============== -->
  <div style="display: grid; align-items: center; justify-content: center">
    <label class="t-label-top" style="padding: 0.5em">
      <span> Document Path: &nbsp; </span>
      <span class="t-field t-field--number number-input">
        <input class="t-field-text number-input" [(ngModel)]="mts.documentPath" type="text" />
      </span>
    </label>
    <button matTooltip="Finish Transfer" class="advanced-search-btn" type="button" (click)="mts.toggleValidationMsg()"
      [disabled]="
        !(
          mts.selectedCorrectExam &&
          mts.selectedIncorrectExam &&
          mts.toMoveFromCorrectHour &&
          mts.toMoveFromCorrectMinute &&
          mts.toMoveToCorrectHour &&
          mts.toMoveToCorrectMinute &&
          mts.dateToMoveFrom &&
          mts.dateToMoveTo &&
          mts.movingDataResult
        )
      ">
      Finish Transfer
    </button>
    <div *ngIf="mts.toggleValidation" class="t-validation-wrap">
      <button class="t-menu-item is-invalid"></button>

      <div class="t-validation-message" style="width: 20em; left: 1em; font-size: 1.5em; margin: -4em 12em">
        <span style="border-radius: 8px">
          <p style="margin-bottom: 1em">
            You are about to complete the transfer without saving the Transfer
            Document files by the user.
          </p>
          <div style="
              display: flex;
              justify-content: space-between;
              margin-left: 1vw;
              margin-right: 1vw;
            ">
            <button class="t-menu-item" (click)="mts.finishTransfer()">
              Yes
            </button>
            <button class="t-menu-item" (click)="mts.toggleValidation = false">
              No
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>

  <!-- =============== Finish Section (End) =============== -->

  <aside class="l-sidebar l-flex l-column">
    <img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />

    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>

    <app-docpanel></app-docpanel>
  </aside>

  <app-bottombar></app-bottombar>
</div>