import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../exams.service';
import {AutotextService} from '../autotext.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-uterus',
  templateUrl: './uterus.component.html',
  styleUrls: ['./uterus.component.scss']
})
export class UterusComponent implements OnInit,OnDestroy {

  UterusForm: UntypedFormGroup;
  Myomas: Myoma[];
  mx: number;
  my: number;
  mz: number;
  msort: string;
  floadSubscription$:Subscription;
  mediaSub: Subscription;

  constructor(private fb:UntypedFormBuilder, public es: ExamsService,public atS:AutotextService) { 
    this.UterusForm = this.fb.group({
      Position: [''],
      Size: [''],
      SizeX:  [''],
      SizeY:  [''],
      SizeZ:  [''],
      Cervical:  [''],
      Funneling:  [''],
      Texture:  [''],
      Endometrial:  [''],
      EndoStripe:  [''],
      Echogenicity:  [''],
      EndoPhase:  [''],
      Cysts:  [''],
      Adenomyosis:  [''],
      Comments: [''],
      MyomaStr: [''],
      DiagnosisCodes: [''],
      Diagnosis: ['']
  });
  }

  ngOnInit() {
    this.es.fillForm(this.UterusForm,"uterus",false,true);
    this.floadSubscription$ = this.es.formLoaded$.subscribe(()=>{
      this.Myomas = new Array();
      if(this.UterusForm.get('MyomaStr').value){
        let mylist = this.UterusForm.get('MyomaStr').value.split('|');
        for(let m of mylist){
          let xyz = m.split(',');
          this.Myomas.push({'x': xyz[0], 'y': xyz.length > 1 ?  xyz[1]: null,'z': xyz.length > 2 ? xyz[2]: null,sort: xyz[3],vol:xyz.length > 4? xyz[4]: null})

        }
      }
    });
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.es.fillForm(this.UterusForm,"uterus",false,true);
      });
  }

  ngOnDestroy(){
    this.floadSubscription$.unsubscribe();
    if(this.mediaSub)
      this.mediaSub.unsubscribe();
  }

  getVolume(){
    if(this.UterusForm.get('SizeX').value && this.UterusForm.get('SizeY').value && this.UterusForm.get('SizeZ').value)
    // prolate ellipsoid: V = 0.52 X (L X AP X T). 
      return Math.round(0.52 * this.UterusForm.get('SizeX').value * this.UterusForm.get('SizeY').value * this.UterusForm.get('SizeZ').value)/1000;
    return '';
  }

  addMyoma(){
    if(! this.mx && ! this.my && ! this.mx )
      return;
    this.Myomas.push({x: this.mx, y: this.my, z: this.mz, sort: this.msort,vol:null});
    this.setMyomas();
    this.mx=null;
    this.my=null;
    this.mz = null;
    this.msort=undefined
  }

  setMyomas(){
    let mstr = ""
    for(let m of this.Myomas){
      mstr += m.x+ "," + m.y + "," + m.z + ","+m.sort +","+m.vol+ "|"
    }
    mstr =  mstr.substr(0, mstr.length -1);
    this.UterusForm.get('MyomaStr').setValue(mstr);
  }
  updateSort(event: any, i: number) {
    if (i >= 0 && i < this.Myomas.length) {
      this.Myomas[i].sort = event.target.value;
      this.setMyomas(); // Update Myomas array in the form
  }
}


  remove(index:number){
    this.Myomas.splice(index,1);
    this.setMyomas();
  }

  reset(fname: string){
    if(! this.es.isClosed){
      this.UterusForm.get(fname).setValue(null);
    }
  }

}

export class Myoma{
  x:number;
  y: number;
  z: number;
  sort: string;
  vol:number
}
