<footer class="weightAssTable" [ngClass]="dir" *ngIf="IsWeightAss" [style]="forprint ? '':'width: 60%;'">

    <section [ngClass]="forprint ? 'weightAssTable-item summary':'weightAssTable-item summary weightAss'">
        <div [ngClass]="forprint ? 'printbox' : 'm-box'">
            <h2 class="weightAssTable-title">
                {{'measurements.titles.FetalGrowth' | translate}}
            </h2>
            <!-- row (start) -->
            <div class="weightAss-row">

                <div class="l-table-auto weightAss-table">

                    <!-- row - head - (start) -->
                    <div class="l-row weightAss-thead">
                        <div class="l-td-m">
                            {{'general.position' | translate}}
                        </div>
                        <div class="l-td-m">
                            {{'general.date' | translate}}
                        </div>
                        <div class="l-td-m">
                            {{'measurements.titles.GA'| translate}}
                        </div>
                        <div class="l-td-m">
                            EFW gm
                        </div>
                        <div class="l-td-m">
                        </div>
                    </div>
                    <!-- row - head - (end) -->

                    <!-- row (start) -->
                    <div class="l-row weightAss-tbody" *ngFor="let cer of dataSource" >
                        <div class="l-td-m"*ngIf="cer.EFW" style="font-weight: bolder;">
                            {{cer.position}}
                        </div>
                        <div class="l-td-m"*ngIf="cer.EFW" style="font-weight: bolder;">
                            {{cer.Date | date:"dd.MM.yyyy"}}
                        </div>
                        <div class="l-td-m"*ngIf="cer.EFW" style="font-weight: bolder;">
                            {{cer.GA}}
                        </div>
                        <div class="l-td-m"*ngIf="cer.EFW"style="font-weight: bolder;">
                            {{cer.EFW}}
                        </div>
                        <div class="l-td-m"*ngIf="cer.EFW" style="text-align: start;">
                            {{'general.By' | translate }}<span> {{cer.Headlock.replace('(','').replace(')','')}} </span>
                        </div>
                    </div>
                    <!-- row (end) -->


                </div>
            </div>
        </div>
    </section>

</footer>
