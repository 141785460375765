import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ExamsService } from './exams.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {
  LeftOvary = [];
  RightOvary = [];

  constructor(private http:HttpClient, public es:ExamsService) {
  }

  getOvariesData(item ){
    if(item){
      // item=item+"index"
      this.http.get(`/api/newExams/getOvaries/${this.es.examID}/${this.es.getFetus()}/${item}`).subscribe((data) => {
        this.LeftOvary = data['LeftOvary'];
        this.RightOvary = data['RightOvary'];
      })
    }else{
      this.http.get(`/api/newExams/getOvaries/${this.es.examID}/${this.es.getFetus()}`).subscribe((data) => {
        this.LeftOvary = data['LeftOvary'];
        this.RightOvary = data['RightOvary'];
      })
    }
  }

  delete(item){
    this.http.post('/api/newExams/deleteOvary', {index:item.AutoIndex}).subscribe((data) =>{
      console.log(data);
      this.getOvariesData(null)
    })
  }


}

