export const createVisitsNewData = (PID: number, PregIndex: number, ID: string, GA: string = "", Date: Date = null) => ({
  PID,
  PregIndex,
  ID,
  Date,
  GA,
  Weight: "",
  BPressureSys: "",
  BPressureDia: "",
  Proteinuria: "",
  Glucosutia: "",
  Uterus: "",
  Cervical: "",
  PV: "",
  Comments: "",

  Fetus1Gender: "",
  Fetus1Present: "",
  Fetus1Movements: "",
  Fetus1Breathing: "",
  Fetus1Tonus: "",
  Fetus1Pulse: "",
  Fetus1FHR: "",
  Fetus1Weight: "",
  Fetus1Percentage: "",
  Fetus1NST: "",
  Fetus1Doppler: "",
  Fetus1AF: "",
  Fetus1Comments: "",

  Fetus2Gender: "",
  Fetus2Present: "",
  Fetus2Movements: "",
  Fetus2Breathing: "",
  Fetus2Tonus: "",
  Fetus2Pulse: "",
  Fetus2FHR: "",
  Fetus2Weight: "",
  Fetus2Percentage: "",
  Fetus2NST: "",
  Fetus2Doppler: "",
  Fetus2AF: "",
  Fetus2Comments: "",

  Fetus3Gender: "",
  Fetus3Present: "",
  Fetus3Movements: "",
  Fetus3Breathing: "",
  Fetus3Tonus: "",
  Fetus3Pulse: "",
  Fetus3FHR: "",
  Fetus3Weight: "",
  Fetus3Percentage: "",
  Fetus3NST: "",
  Fetus3Doppler: "",
  Fetus3AF: "",
  Fetus3Comments: "",

  Fetus4Gender: "",
  Fetus4Present: "",
  Fetus4Movements: "",
  Fetus4Breathing: "",
  Fetus4Tonus: "",
  Fetus4Pulse: "",
  Fetus4FHR: "",
  Fetus4Weight: "",
  Fetus4Percentage: "",
  Fetus4NST: "",
  Fetus4Doppler: "",
  Fetus4AF: "",
  Fetus4Comments: "",
});

export const visitsOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  Date: "",
  GA: "",
  Weight: "",
  BPressureDia: "",
  Proteinuria: "",
  Glucosutia: "",
  Uterus: "",
  Cervical: "",
  PV: "",
  Comments: "",

  Fetus1Gender: "",
  Fetus1Present: "",
  Fetus1Movements: "",
  Fetus1Breathing: "",
  Fetus1Tonus: "",
  Fetus1Pulse: "",
  Fetus1FHR: "",
  Fetus1Weight: "",
  Fetus1Percentage: "",
  Fetus1NST: "",
  Fetus1Doppler: "",
  Fetus1AF: "",
  Fetus1Comments: "",

  Fetus2Gender: "",
  Fetus2Present: "",
  Fetus2Movements: "",
  Fetus2Breathing: "",
  Fetus2Tonus: "",
  Fetus2Pulse: "",
  Fetus2FHR: "",
  Fetus2Weight: "",
  Fetus2Percentage: "",
  Fetus2NST: "",
  Fetus2Doppler: "",
  Fetus2AF: "",
  Fetus2Comments: "",

  Fetus3Gender: "",
  Fetus3Present: "",
  Fetus3Movements: "",
  Fetus3Breathing: "",
  Fetus3Tonus: "",
  Fetus3Pulse: "",
  Fetus3FHR: "",
  Fetus3Weight: "",
  Fetus3Percentage: "",
  Fetus3NST: "",
  Fetus3Doppler: "",
  Fetus3AF: "",
  Fetus3Comments: "",

  Fetus4Gender: "",
  Fetus4Present: "",
  Fetus4Movements: "",
  Fetus4Breathing: "",
  Fetus4Tonus: "",
  Fetus4Pulse: "",
  Fetus4FHR: "",
  Fetus4Weight: "",
  Fetus4Percentage: "",
  Fetus4NST: "",
  Fetus4Doppler: "",
  Fetus4AF: "",
  Fetus4Comments: "",
};
