<div class="auto-text-item">
    <label *ngIf="! firstRow">
        <input type="checkbox" class="sr-only" [(ngModel)]="isChecked" (change)="addText()">
        <span class="auto-text-checkbox"></span>
    </label>
    <div class="auto-text-input" *ngIf="edit">
        <textarea type="text" [(ngModel)]="Text" placeholder="Enter a new text sentence"></textarea>
    </div>
    <div class="auto-text-txt" *ngIf="!edit" style="cursor: pointer;" (click)="isChecked = !isChecked;addText()">{{Text}}</div>
    <div class="auto-text-controls"> 
        <button class="auto-text-add-button" matTooltip="Move Autotext to User" *ngIf="! edit && !atS.filter && ls.isCardio && (ls.isAdmin || (!ls.isAdmin && !atS.filter))" (click)="save(true)">Add</button>
        <button class="auto-text-delete-button" *ngIf="! edit && (ls.isAdmin || (!ls.isAdmin && atS.filter))" (click)="delete()">Delete</button>
        <button class="auto-text-edit-button" *ngIf="! edit && (ls.isAdmin || (!ls.isAdmin && atS.filter))" (click)="setEdit()">Edit</button>
        <button class="auto-text-done-button" *ngIf="edit && Text != ''  && (ls.isAdmin || (!ls.isAdmin && atS.filter))" (click)="save()">Done</button>
    </div>
</div>
