<div *ngIf="showDeleteAlert" class="print-history-popup" style="z-index: 9999999">
  <div class="print-history-popup-body">
    <h2 class="popup-title">
      {{
        ("GUI.documents.labels.Delete" | translate) +
          toDeleteDocument.title +
          ("GUI.documents.labels.document from" | translate) +
          (toDeleteDocument.CreationTime | date : "d/M/yy, HH:mm a")
      }}?
    </h2>
    <div style="display: flex; justify-content: space-around">
      <button (click)="deleteDocument()">
        {{ "GUI.documents.labels.Yes" | translate }}
      </button>
      <button (click)="toDeleteDocument = null; showDeleteAlert = false" style="margin-left: 2em">
        {{ "GUI.documents.labels.No" | translate }}
      </button>
    </div>
    <button
      (click)="showDeleteAlert = false; toDeleteDocument = null"
      class="print-history-popup-close"
      aria-label="close popup"
    ></button>
  </div>
</div>
<div class="t-popup popup-patientDetails">
  <div class="t-popup-close"></div>

  <div class="t-popup-frame">
    <div class="t-popup-body">
      <button class="t-btn-popup-close" aria-label="close popup" (click)="close()"></button>

      <h2></h2>

      <!-- <div>
                <input class="custom-file-upload" type="file" (change)="onFileSelected($event)">
            </div> -->

      <!--  ================= Admin Files (Start) ================= -->
      <div class="file-input-container" style="margin: 1em 0">
        <input type="file" id="fileAdminInput" class="file-input" (change)="onAdminFileSelected($event)" />
        <label for="fileAdminInput" class="file-label" (click)="errorUploadAdminDocs = null; selectedAdminFile = null">
          {{ "GUI.documents.labels.Add Administrative File" | translate }}
        </label>
      </div>

      <div *ngIf="errorUploadAdminDocs">
        <h3>{{ errorUploadAdminDocs }}</h3>
      </div>

      <div *ngIf="selectedAdminFile" style="display: flex; justify-content: center; gap: 1em; margin-top: 2em">
        <p>
          <strong> {{ "GUI.documents.labels.File Name" | translate }}: </strong> {{ selectedAdminFile.name }}
        </p>
        <p>
          <strong> {{ "GUI.documents.labels.File Size" | translate }}: </strong>
          {{ selectedAdminFile.size | fileSize }}
        </p>
      </div>
      <div
        *ngIf="selectedAdminFile"
        style="display: flex; justify-content: center; align-items: center; gap: 1em; margin-top: 2em"
      >
        <label
          ><strong>{{ "GUI.documents.labels.Title" | translate }}:</strong></label
        >
        <div class="cervical-row" style="width: 30%">
          <textarea
            class="scroller"
            maxlength="33"
            style="
              background-image: linear-gradient(to right, rgb(225, 235, 235) 0%, rgb(237, 244, 244) 100%);
              border-radius: 0.75em;
              min-height: 2.5em;
            "
            [(ngModel)]="adminDocumentTitle"
            rows="1"
          ></textarea>
        </div>
        <label
          ><strong>{{ "GUI.documents.labels.Comments" | translate }}:</strong></label
        >
        <div class="cervical-row" style="width: 30%">
          <textarea
            class="scroller"
            maxlength="66"
            style="
              background-image: linear-gradient(to right, rgb(225, 235, 235) 0%, rgb(237, 244, 244) 100%);
              border-radius: 0.75em;
              min-height: 2.5em;
            "
            [(ngModel)]="adminDocumentComments"
            rows="1"
          ></textarea>
        </div>
        <button
          *ngIf="!errorUploadAdminDocs"
          class="confirm--btn"
          style="margin-left: 2em"
          (click)="saveAdminDocument()"
        >
          {{ "GUI.documents.labels.Save" | translate }}
        </button>
      </div>
      <div class="l-flex patient-item-n" *ngFor="let document of adminDocuments; index as i">
        <button class="w100" style="cursor: pointer">
          <div class="l-table h100">
            <span class="l-td-m patient-item-cell" style="width: 5em" (click)="openDocument(document.AutoIndex)">
              <strong>{{ i + 1 }}</strong>
            </span>
            <span class="l-td-m patient-item-cell">
              <strong> {{ "GUI.documents.labels.name" | translate }} :</strong> {{ document.title }}
            </span>

            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> file type: </strong>
              {{ document.fileType }}
            </span>
            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> comment: </strong>
              {{ document.comments }}
            </span>
            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> creation time: </strong>
              {{ document.CreationTime | date : "dd/MM/yyyy" }}
            </span>

            <span class="l-td-m patient-item-cell w5">
              <img
                style="cursor: pointer"
                matTooltip="{{ 'GUI.documents.tooltips.Delete Document' | translate }}"
                height="35em"
                src="../../assets/icons/delete-blue.svg"
                (click)="showDeleteAlert = true; toDeleteDocument = document"
              />
            </span>
          </div>
        </button>
      </div>
      <!--  ================= Admin Files (End) ================= -->

      <hr class="divider" />
      <!--  ================= Clinical Files (Start) ================= -->

      <div class="file-input-container" style="margin: 1em 0">
        <input type="file" id="fileInput" class="file-input" (change)="onFileSelected($event)" />
        <label for="fileInput" class="file-label" (click)="errorUploadDocs = null; selectedFile = null">
          {{ "GUI.documents.labels.Add Clinical File" | translate }}
        </label>
      </div>
      <div *ngIf="errorUploadDocs">
        <h3>{{ errorUploadDocs }}</h3>
      </div>

      <div *ngIf="selectedFile" style="display: flex; justify-content: center; gap: 1em; margin-top: 2em">
        <p>
          <strong> {{ "GUI.documents.labels.File Name" | translate }}: </strong> {{ selectedFile.name }}
        </p>
        <p>
          <strong> {{ "GUI.documents.labels.File Size" | translate }}: </strong>
          {{ selectedFile.size | fileSize }}
        </p>
      </div>
      <div
        *ngIf="selectedFile"
        style="display: flex; justify-content: center; align-items: center; gap: 1em; margin-top: 2em"
      >
        <label
          ><strong>{{ "GUI.documents.labels.Title" | translate }}:</strong></label
        >
        <div class="cervical-row" style="width: 30%">
          <textarea
            class="scroller"
            maxlength="33"
            style="
              background-image: linear-gradient(to right, rgb(225, 235, 235) 0%, rgb(237, 244, 244) 100%);
              border-radius: 0.75em;
              min-height: 2.5em;
            "
            [(ngModel)]="documentTitle"
            rows="1"
          ></textarea>
        </div>
        <label
          ><strong>{{ "GUI.documents.labels.Comments" | translate }}:</strong></label
        >
        <div class="cervical-row" style="width: 30%">
          <textarea
            class="scroller"
            maxlength="66"
            style="
              background-image: linear-gradient(to right, rgb(225, 235, 235) 0%, rgb(237, 244, 244) 100%);
              border-radius: 0.75em;
              min-height: 2.5em;
            "
            [(ngModel)]="documentComments"
            rows="1"
          ></textarea>
        </div>
        <button *ngIf="!errorUploadDocs" class="confirm--btn" style="margin-left: 2em" (click)="saveDocument()">
          {{ "GUI.documents.labels.Save" | translate }}
        </button>
      </div>

      <div class="l-flex patient-item-n" *ngFor="let document of documents; index as i">
        <button class="w100" style="cursor: pointer">
          <div class="l-table h100">
            <span class="l-td-m patient-item-cell" style="width: 5em" (click)="openDocument(document.AutoIndex)">
              <strong>{{ i + 1 }}</strong>
            </span>
            <span class="l-td-m patient-item-cell"> <strong> {{ "GUI.documents.labels.name" | translate }} :</strong> {{ document.title }} </span>

            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> {{ "GUI.documents.labels.file type" | translate }}: </strong>
              {{ document.fileType }}
            </span>
            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> {{ "GUI.documents.labels.comment" | translate }}: </strong>
              {{ document.comments }}
            </span>
            <span class="l-td-m patient-item-cell" (click)="openDocument(document.AutoIndex)">
              <strong> {{ "GUI.documents.labels.creation time" | translate }}: </strong>
              {{ document.CreationTime | date : "dd/MM/yyyy" }}
            </span>

            <span class="l-td-m patient-item-cell w5">
              <img
                style="cursor: pointer"
                matTooltip="{{ 'GUI.documents.tooltips.Delete Document' | translate }}"
                height="35em"
                src="../../assets/icons/delete-blue.svg"
                (click)="showDeleteAlert = true; toDeleteDocument = document"
              />
            </span>
          </div>
        </button>
      </div>
      <!--  ================= Clinical Files (Enb) ================= -->
    </div>
  </div>
</div>
