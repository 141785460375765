<aside class="l-sidebar l-flex l-column scroller mCustomScrollbar _mCS_3">
<img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />

		<!-- user panel (start) -->
		<div class="userPanel">
			
			<i class="userPanel-icon icon-female" *ngIf="pat.ID != 'default'">
				<span class="l-flex t-boxMargin menstural-fields-set">
					
					<button class="btn-user_data" aria-label="user data" (click)="showPatD()" matTooltip="Open Patient Details" [ngClass]="! patservice.getPat().Birth && (es.ExamType == 'NT' || es.ExamType =='1st') ? 'is-invalid':''"></button>
					<div *ngIf="es.ExamType === 'NT'" class="t-validation-message">
						<span>Add Birth day by clicking here</span>
					</div>
				</span> 

			</i>

			<div class="userPanel-userName" *ngIf="pat.ID != 'default'">
				{{pat.First}} {{pat.Last}}
			</div>

			<dl class="l-table userPanel-userInfo" *ngIf="pat.ID != 'default'">
				<div class="l-row">
					<dt class="l-td-m">ID Number</dt>
					<dd class="l-td-m">{{pat.ID}}</dd>
				</div>
				<div class="l-row">
					<dt class="l-td-m">Age</dt>
					<dd class="l-td-m">{{patservice.getAgeMo()}}</dd>
				</div>
				<div class="l-row" *ngIf="es.ExamType != 'Gynecology' && es.ExamType != 'Follicles' && !ls.isCardio && !ls.isGastro">
					<dt class="l-td-m" >GA (at exam)</dt>
					<dd class="l-td-m">{{es.examGA()}}</dd>
				</div>
				<div class="l-row" *ngIf="surrogate">
					<dt class="l-td-m">Surrogate</dt>
					<dd class="l-td-m">{{surrogate}}</dd>
				</div>
			</dl>

			<div class="userPanel-controls">
				<a  (click)="bookmark()" class="userPanel-controls-item icon-files" [ngClass]="isBookmark ? 'saved' : ''" aria-label="files" matTooltip="Show Previous Exams in a Window"></a>
				<a (click)="go(this.returnto?'/'+this.returnto:'/exams')" class="userPanel-controls-item icon-exit" aria-label="exit" matTooltip="Close Exam and Open Patient History"></a>
			</div>

		</div>
		<!-- user panel (end) -->

		<!-- main nav (start) -->
		<nav class="mainNav l-fg1">
			<ul class="mainNav-list">
				<ng-container *ngIf="es.ExamType != 'Gynecology' && ! es.isForm && es.ExamType != 'Emergency Room' && ! ls.isCardio && ! ls.isGastro">
				
				<li class="mainNav-item" (click)="setPage('Anamnesis')" *ngIf="es.ExamType === 'Pediatric Echo'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Anamnesis' ? 'is-active':''">
						Anamnesis
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Pregnancy')" *ngIf="pat.ID != 'default' && es.ExamType !== 'Pediatric Echo'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Pregnancy' ? 'is-active':''">
						{{es.ExamType == 'Follicles' ? 'Introduction':'Pregnancy'}}
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Placenta')" *ngIf="es.ExamType !='Fetal Echo' && es.ExamType !== 'Pediatric Echo' && es.ExamType != 'Follicles'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Placenta' ? 'is-active':''">
						Placenta & etc.
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('NT')" *ngIf="es.ExamType=='NT' || es.ExamType=='1st' || es.addNT">
					<span class="mainNav-link" [ngClass]="currentPage == 'NT' ? 'is-active':''">
						{{es.ExamType == "NT"? "NT" : ( es.switchNT ? "1ST & NT": (es.addNT ? 'NT' :"1ST"))}}
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Measurements')" *ngIf="es.ExamType!='NT' && es.ExamType!='1st' && es.ExamType != 'Follicles'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Measurements' ? 'is-active':''">
						{{es.ExamType ==='Weight Assessment' ||  es.ExamType ==='Tech Exam'?  "Fetal Growth" : ((es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo') ? 'Measurements & Doppler' : 'Measurements')}}
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Anatomy')" *ngIf="es.ExamType!= 'Follicles' && es.ExamType!='CVS' && es.ExamType!='Amniocentesis' && es.ExamType!='NT' && es.ExamType!='1st' && es.ExamType!='Followup' && es.ExamType !='Weight Assessment' && es.ExamType !='Tech Exam'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Anatomy' ? 'is-active':''">
						Anatomy
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Obs Doppler')" *ngIf="es.ExamType!= 'Follicles' && es.ExamType!='CVS' && es.ExamType!='Amniocentesis' && es.ExamType!='NT' && es.ExamType!='1st' && es.ExamType !='Weight Assessment' && es.ExamType !='Fetal Echo' && es.ExamType !== 'Pediatric Echo' && es.ExamType !== 'Pediatric Echo'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Obs Doppler' ? 'is-active':''">
						Obs Doppler
					</span>
				</li>
			</ng-container>
			<ng-container *ngIf="es.ExamType == 'Gynecology'">
				<li class="mainNav-item" (click)="setPage('Introduction')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Introduction' ? 'is-active':''">
						Introduction
					</span>
				</li>
			</ng-container>
			<ng-container *ngIf="(es.ExamType == 'Gynecology') || es.switchOvaries">
				<li class="mainNav-item" (click)="setPage('Uterus')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Uterus' ? 'is-active':''">
						Uterus
					</span>
				</li>
				<li class="mainNav-item"  (click)="setPage('Ovaries')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Ovaries' ? 'is-active':''">
						Ovaries
					</span>
				</li>
			</ng-container>

			<ng-container *ngIf="ls.isCardio && es.ExamType !='Consultation Letter'">
				<li class="mainNav-item" (click)="setPage('Introduction')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Introduction' ? 'is-active':''">
						Introduction
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('2D Measurements')" *ngIf="es.ExamType !='Stress' && es.ExamType !='Duplex Carotid'">
					<span class="mainNav-link" [ngClass]="currentPage == '2D Measurements' ? 'is-active':''">
						2D Measurements
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Doppler')" *ngIf="es.ExamType !='Stress' && es.ExamType!='Duplex Carotid'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Doppler' ? 'is-active':''">
						Valves and Doppler
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Tissue Doppler')" *ngIf="es.ExamType !='Stress' && es.ExamType!='Duplex Carotid'">
					<span class="mainNav-link" [ngClass]="currentPage == 'Tissue Doppler' ? 'is-active':''">
						Heart Function
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Findings')" *ngIf="es.ExamType != 'TTE Adults' && es.ExamType!='Duplex Carotid'" >
					<span class="mainNav-link" [ngClass]="currentPage == 'Findings' ? 'is-active':''">
						{{es.ExamType !='Stress-Echo' ? 'Findings' : 'Exercise'}}
					</span>
				</li>
			</ng-container>
			<ng-container *ngIf="ls.isGastro && es.ExamType !='Consultation Letter'">
				<li class="mainNav-item" (click)="setPage('Preparation')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Preparation' ? 'is-active':''">
						Preparation
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Findings')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Findings' ? 'is-active':''">
						Findings
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('Pathologies')">
					<span class="mainNav-link" [ngClass]="currentPage == 'Pathologies' ? 'is-active':''">
						Pathologies
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('ProcedureGastro')">
					<span class="mainNav-link" [ngClass]="currentPage == 'ProcedureGastro' ? 'is-active':''">
						Course of Exam
					</span>
				</li>
				<li class="mainNav-item" (click)="setPage('SummaryGastro')">
					<span class="mainNav-link" [ngClass]="currentPage == 'SummaryGastro' ? 'is-active':''">
						Summary
					</span>
				</li>
			</ng-container>
			<li class="mainNav-item" (click)="setPage('Procedure')" *ngIf="es.ExamType == 'Amniocentesis' || es.ExamType == 'CVS' || es.ExamType == 'Follicles'">
				<span class="mainNav-link" [ngClass]="currentPage == 'Procedure' ? 'is-active':''">
					Course of Exam
				</span>
			</li>	
				<li class="mainNav-item" (click)="setPage('Summary')" *ngIf="! es.isForm && es.ExamType != 'Emergency Room' && ! ls.isGastro">
					<span class="mainNav-link" [ngClass]="currentPage == 'Summary' ? 'is-active':''">
						{{es.ExamType == 'Duplex Carotid' ? 'Findings and Diagnosis': 'Summary'}}
					</span>
				</li>				
			</ul>
		</nav>
		<!-- main nav (end) -->

		<app-docpanel></app-docpanel>
		

