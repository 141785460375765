<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up" aria-label="Back to History" matTooltip="Back to History">Follow Up</button> /
        Genetics
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close Genetics"
          matTooltip="Close Genetics"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->
  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <!-- ================ Mother's Genetics (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Mother's Genetics</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Carrier </span>
              </th>
              <th class="th-clear">
                <span> Mutation </span>
              </th>

              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let mGenetics of geneticsMotherData" [ngClass]="mGenetics.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="mGenetics.Name"
                  [disabled]="!mGenetics.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsMother'"
                  [isEditing]="mGenetics.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="mGenetics.Carrier"
                  [disabled]="!mGenetics.isEditing"
                  [inputName]="'Carrier'"
                  [tableName]="'fuGeneticsMother'"
                  [isEditing]="mGenetics.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="mGenetics.Mutation"
                  [disabled]="!mGenetics.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsMother'"
                  [isEditing]="mGenetics.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!mGenetics.isEditing" (click)="editRow(mGenetics)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="mGenetics.isEditing"
                    (click)="updateRow('fuGeneticsMother', mGenetics, ['Name', 'Carrier', 'Mutation'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuGeneticsMother', mGenetics)">
                    <img src="../.mgeneticsns/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======= -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsMotherNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsMother'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsMotherNewData.Carrier"
                  [inputName]="'Carrier'"
                  [tableName]="'fuGeneticsMother'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsMotherNewData.Mutation"
                  [inputName]="'Mutation'"
                  [tableName]="'fuGeneticsMother'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  [disabled]="
                    !followUpService.geneticsMotherNewData.Name ||
                    !followUpService.geneticsMotherNewData.Carrier ||
                    !followUpService.geneticsMotherNewData.Mutation
                  "
                  (click)="
                    saveNewRow('fuGeneticsMother', followUpService.geneticsMotherNewData, geneticsMotherOriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Mother's Genetics (End) ================ -->

      <!-- ================ Spouse's Genetics (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Spouse's Genetics</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Carrier </span>
              </th>
              <th class="th-clear">
                <span> Mutation </span>
              </th>

              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let sGenetics of geneticsSpouseData" [ngClass]="sGenetics.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="sGenetics.Name"
                  [disabled]="!sGenetics.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsSpouse'"
                  [isEditing]="sGenetics.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="sGenetics.Carrier"
                  [disabled]="!sGenetics.isEditing"
                  [inputName]="'Carrier'"
                  [tableName]="'fuGeneticsSpouse'"
                  [isEditing]="sGenetics.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="sGenetics.Mutation"
                  [disabled]="!sGenetics.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsSpouse'"
                  [isEditing]="sGenetics.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!sGenetics.isEditing" (click)="editRow(sGenetics)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="sGenetics.isEditing"
                    (click)="updateRow('fuGeneticsSpouse', sGenetics, ['Name', 'Carrier', 'Mutation'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuGeneticsSpouse', sGenetics)">
                    <img src="../.mgeneticsns/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======= -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsSpouseNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuGeneticsSpouse'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsSpouseNewData.Carrier"
                  [inputName]="'Carrier'"
                  [tableName]="'fuGeneticsSpouse'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.geneticsSpouseNewData.Mutation"
                  [inputName]="'Mutation'"
                  [tableName]="'fuGeneticsSpouse'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  [disabled]="
                    !followUpService.geneticsSpouseNewData.Name ||
                    !followUpService.geneticsSpouseNewData.Carrier ||
                    !followUpService.geneticsSpouseNewData.Mutation
                  "
                  (click)="
                    saveNewRow('fuGeneticsSpouse', followUpService.geneticsSpouseNewData, geneticsSpouseOriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Spouse's Genetics (End) ================ -->
    </div>
  </main>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
