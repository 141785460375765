import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {InboxService} from '../inbox.service';
import {ExamsService} from '../exams.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Output() openg = new EventEmitter<number>();

  constructor(public inbox: InboxService, private es: ExamsService) { }

  start: Date;
  startb: Date;
  From: Date;
  To: Date;

  ngOnInit() {
    this.start = new Date(this.es.CreationTime);
    this.To = this.start;
    this.startb = new Date(this.start.getTime() -  (24*60*60*1000) * 7);
    this.From = this.startb;

    this.getList();
  }

  getList(){
    this.inbox.getList(this.From, this.To);
  }

  showBox(inbox:number){
    this.openg.emit(inbox);
  }

}
