<div class="wrapper">
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel support-header-panel">
      <h3>{{ getSupportCenterText() }}</h3>
      <div class="buttons-container">
        <a class="header-menu-link icon-confirm pointer" aria-label="Close Inbox Transfer " (click)="exit()"
          [matTooltip]="getTooltipText()"></a>
      </div>
    </div>
  </header>

  <!-- =============== History Table (Start) =============== -->

  <!-- ======= Inbox Hisotry (Start) ======= -->
  <main *ngIf="historyType === 'inbox'" style="padding: 1em">
    <table>
      <thead>
        <tr>
          <th>Patient Name</th>
          <th>Patient ID</th>
          <th>Exam ID</th>
          <th>Inbox ID</th>
          <th>Is Inbox</th>
          <th>Clinic Name</th>
          <th>Clinic GID</th>
          <th>Doctor UID</th>
          <th>Doctor Name</th>
          <th>Transfer Date</th>
          <th>Document Path</th>
          <th>Done By</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let inboxData of tranferHistoryData">
          <tr *ngIf="inboxData.IsInbox">
            <td>{{ inboxData.NW_PatientName }}</td>
            <td>{{ inboxData.NW_PatientId }}</td>
            <td>{{ inboxData.NW_ExamId }}</td>
            <td>{{ inboxData.InboxId }}</td>
            <td>{{ inboxData.IsInbox}}</td>
            <td>{{ inboxData.ClinicName }}</td>
            <td>{{ inboxData.ClinicGID }}</td>
            <td>{{ inboxData.DoctorUID }}</td>
            <td>{{ inboxData.DoctorName }}</td>
            <td>{{ inboxData.TransferDate | date: "d/M/yy, HH:mm" }}</td>
            <td>{{ inboxData.DocumentPath }}</td>
            <td>{{ inboxData.DoneBy }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </main>
  <!-- ======= Inbox Hisotry (End) ======= -->

  <!-- ======= Media Hisotry (Start) ======= -->
  <main *ngIf="historyType === 'media'" style="padding: 1em">
    <table>
      <thead>
        <tr>
          <th>OG Patient Name</th>
          <th>OG Patient ID</th>
          <th>OG Exam ID</th>
          <th>NW Patient Name</th>
          <th>NW Patient ID</th>
          <th>NW Exam ID</th>
          <th>Is Inbox</th>
          <th>Doctor UID</th>
          <th>Doctor Name</th>
          <th>Clinic GID</th>
          <th>Clinic Name</th>
          <th>Transfer Date</th>
          <th>Document Path</th>
          <th>Done By</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let mediaData of tranferHistoryData">
          <tr *ngIf="!mediaData.IsInbox">
            <td>{{ mediaData.OG_PatientName}}</td>
            <td>{{ mediaData.OG_PatientId }}</td>
            <td>{{ mediaData.OG_ExamId }}</td>
            <td>{{ mediaData.NW_PatientName}}</td>
            <td>{{ mediaData.NW_PatientId }}</td>
            <td>{{ mediaData.NW_ExamId }}</td>
            <td>{{ mediaData.IsInbox }}</td>
            <td>{{ mediaData.DoctorUID }}</td>
            <td>{{ mediaData.DoctorName }}</td>
            <td>{{ mediaData.ClinicGID }}</td>
            <td>{{ mediaData.ClinicName }}</td>
            <td>{{ mediaData.TransferDate | date: "d/M/yy, HH:mm" }}</td>
            <td>{{ mediaData.DoctorName }}</td>
            <td>{{ mediaData.DoneBy }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </main>
  <!-- ======= Media Hisotry (End) ======= -->

  <!-- =============== History Table (End) =============== -->

  <aside class="l-sidebar l-flex l-column">
    <img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />

    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>

    <app-docpanel></app-docpanel>
  </aside>

  <app-bottombar></app-bottombar>
</div>