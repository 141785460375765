<div class="h100 l-flex-w summary" style="margin-bottom: 1em;" [formGroup]="grp">
    <div class="w100 summary-anamnesis summary"  style="margin-bottom: 1em;">
        <div class="m-box">
            <h2>Left Ventrical</h2>
            <textarea class="scroller" formControlName="LeftVentrical"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(grp.get('LeftVentrical'),'Left Ventrical',627)">Auto Text</button>
        </div>
    </div>
    <div class="w100 summary-anamnesis summary" style="margin-bottom: 1em;">
        <div class="m-box">
            <h2>Right Ventrical</h2>
            <textarea class="scroller" formControlName="RightVentrical"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed"
            (click)="atS.showAuto(grp.get('RightVentrical'),'Right Ventrical',625)">Auto Text</button>
        </div>
    </div>
    <section class="m-box">
        <h2>
            LVOT
        </h2>
                
        <!-- set (start) -->
        <div class="l-flex-c l-flex-w l-justify placenta-row">

            <label class="t-label-top" style="margin-bottom: 0.2em;">
                <span>
                    Peak Velocity
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTPV"/>
                    <span class="t-field-desc">
                        m/s
                    </span>
                </span>
            </label>

            <label class="t-label-top">
                <span>
                    Peak Gradient
                </span>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTPG"/>
                    <span class="t-field-desc">
                        mm/Hg
                    </span>
                </span>
            </label>

            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Mean Gradient
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTMG"/>
                    <span class="t-field-desc">
                        mm/Hg
                    </span>
                </span>
                
            </div>

            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Valve Area
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTVA"/>
                    <span class="t-field-desc">
                        cm²
                    </span>
                </span>
                
            </div>
            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Reg Flow Velocity
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTRFV"/>
                    <span class="t-field-desc">
                        m/sec
                    </span>
                </span>
                
            </div>

            <div class="t-label-top" style="margin-bottom: 0.2em;">
                <label id="gestantional_sac_appearance">
                    Diamemeter
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="16" max="24" formControlName="LVOTDiam"/>
                    <span class="t-field-desc">
                        mm
                    </span>
                </span>
            </div>

            <div class="t-label-top" style="margin-bottom: 0.2em;">
                <label id="gestantional_sac_appearance">
                    Trace
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTTrace"/>
                    <span class="t-field-desc">
                    </span>
                </span>
            </div>

            <div class="t-label-top" style="margin-bottom: 0.2em;">
                <label id="gestantional_sac_appearance">
                    Envelope Time
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVOTEnv"/>
                    <span class="t-field-desc">
                    </span>
                </span>
            </div>

            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Velocity Time Integ
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="15" max="25" formControlName="LVOTVTI"/>
                    <span class="t-field-desc">
                        cm
                    </span>
                </span>
            </div>
            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Heart Rate
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="HR"/>
                    <span class="t-field-desc">
                        bps
                    </span>
                </span>
            </div>

            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    Stroke Volume
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVSV"/>
                    <span class="t-field-desc">
                        ml
                    </span>
                </span>
            </div>

            <div class="t-label-top">
                <label id="gestantional_sac_appearance">
                    LVCO
                </label>
                <span class="t-field t-field--number">
                    <input class="t-field-text" type="number" min="0" formControlName="LVCO"/>
                    <span class="t-field-desc">
                        l
                    </span>
                </span>
            </div>
        </div>
        <!-- <div class="l-flex-c menstrual-row menstrual-row--LMP_by_US" *ngIf="grep.get(group) && doppler.get(group).value" style="margin-top: 1em;">
            <span  *ngIf="doppler.get(group) && doppler.get(group).value" style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{doppler.get(group).value}}</span>
        </div> -->
        <!-- set (end) -->
        <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(grp.get('LVOT'),'LVOT',626);">Auto Text</button>

    </section>
</div>
<div class="h100 l-flex-w t-boxMargin--spacebyPadding summary" [formGroup]="grp">
    <div class="w100 placenta-box placenta-ammiotic summary" style='height:auto; margin-bottom: 1em;'>
        <section class="m-box">
            <h2>
                LV filling
            </h2>
            <div class="l-flex-c l-flex-w l-justify placenta-row">

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Peak E-wave
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="Ewave" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Peak A-wave
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="Awave" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        E/A ratio
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="EA" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Deceleration time
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="DecT" />
                        <span class="t-field-desc">ms</span>
                    </span>
                </label>
            </div>
            <div class="l-flex-c" *ngIf="grp.get('LV') && grp.get('LV').value"
                style="margin-top: 1em;border-top: 1px solid #bdd3d5;padding-top: 0.67em;">
                <span *ngIf="grp.get('LV').value"
                    style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('LV').value}}</span>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(grp.get('LV'),'LV',616);">Auto
                Text</button>
        </section>
    </div>
    <div class="w50 summary-anamnesis summary" style="height: 50%;">


        <section class="m-box">

            <h2>
                Septal
            </h2>

            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        S' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Ssep" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Esep" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E/e' ratio
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Eesep" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP" *ngIf="grp.get('Septal').value">
                    <span *ngIf="grp.get('Septal').value"
                        style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('Septal').value}}</span>
                </div>
            </div>

            <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(grp.get('Septal'),'Septal',620);">Auto Text</button>

        </section>
    </div>
    <div class="w50 summary-anamnesis summary" style="height: 50%;">


        <section class="m-box">

            <h2>
                Lateral
            </h2>

            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        S' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Slat" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Elat" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E/e' ratio
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="Eelat" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP" *ngIf="grp.get('Lateral').value">
                    <span *ngIf="grp.get('Lateral').value"
                        style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('Lateral').value}}</span>
                </div>
            </div>

            <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(grp.get('Lateral'),'Lateral',621);">Auto Text</button>

        </section>
    </div>
    <div class="w100 placenta-box placenta-ammiotic summary" style='height:auto; margin-bottom: 1em;' *ngIf="es.ExamType =='Stress-Echo'">
        <h2>Diastolic Function After Stress</h2>
        <section class="m-box" style="height: auto;">
            <h2>
                LV filling
            </h2>
            <div class="l-flex-c l-flex-w l-justify placenta-row">

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Peak E-wave
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="EwaveAfter" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Peak A-wave
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="AwaveAfter" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        E/A ratio
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="EAAfter" />
                        <span class="t-field-desc">
                        </span>
                    </span>
                </label>

                <label class="t-label-top" style="margin-bottom: 0.2em;">
                    <span>
                        Deceleration time
                    </span>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" formControlName="DecTAfter" />
                        <span class="t-field-desc">ms</span>
                    </span>
                </label>
            </div>
            <div class="l-flex-c" *ngIf="grp.get('LV') && grp.get('LVAfter').value"
                style="margin-top: 1em;border-top: 1px solid #bdd3d5;padding-top: 0.67em;">
                <span *ngIf="grp.get('LVAfter').value"
                    style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('LVAfter').value}}</span>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(grp.get('LVAfter'),'LV After Stress',622);">Auto
                Text</button>
        </section>
    </div>
    <div class="w50 summary-anamnesis summary" style="height: 50%;"  *ngIf="es.ExamType =='Stress-Echo'">


        <section class="m-box">

            <h2>
                Septal
            </h2>

            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        S' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="SsepAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="EsepAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E/e' ratio
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="EesepAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP" *ngIf="grp.get('SeptalAfter').value">
                    <span *ngIf="grp.get('SeptalAfter').value"
                        style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('SeptalAfter').value}}</span>
                </div>
            </div>

            <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(grp.get('SeptalAfter'),'Septal After Stress',623);">Auto Text</button>

        </section>
    </div>
    <div class="w50 summary-anamnesis summary" style="height: 50%;"  *ngIf="es.ExamType =='Stress-Echo'">


        <section class="m-box">

            <h2>
                Lateral
            </h2>

            <div class="pregnancy-menstrual">

                <!-- row (start) -->
                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        S' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="SlatAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E' velocity
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="ElatAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>

                <div class="l-flex-c menstrual-row menstrual-row--LMP">

                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        E/e' ratio
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">

                        <input formControlName="EelatAfter" type="number" min="0" class="t-field"
                            aria-labelledby="menstural_LMP">

                        <span class="t-field menstural-field menstural-info"></span>

                    </span>

                </div>
                <div class="l-flex-c menstrual-row menstrual-row--LMP" *ngIf="grp.get('LateralAfter').value">
                    <span *ngIf="grp.get('LateralAfter').value"
                        style="border: 1px solid #bdd3d5;white-space: pre-wrap;unicode-bidi: plaintext;width: 100%;padding: 0.3em;">{{grp.get('LateralAfter').value}}</span>
                </div>
            </div>

            <button class="t-btn-edit" *ngIf="! es.isClosed"
                (click)="atS.showAuto(grp.get('LateralAfter'),'Lateral After Stress',624);">Auto Text</button>

        </section>
    </div>
</div>