import { Subscription } from "rxjs";
import { Patient } from "../patients/patient";
import { ExamsService } from "../exams.service";
import { LoginService } from "../login.service";
import { PatientsService } from "../patients.service";
import { AutotextService } from "../autotext.service";
import { PregnancyService } from "../pregnancy.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-anamnesis",
  templateUrl: "./anamnesis.component.html",
  styleUrls: ["./anamnesis.component.scss"],
})
export class AnamnesisComponent implements OnInit {
  subscritpion = new Subscription();
  anamnesisForm: UntypedFormGroup;
  patient: Patient = new Patient();
  patientGender: string;

  options: any[] = [];
  filteredOptions: any[] = [];
  itemId: any;
  subscription = new Subscription();
  inputControl: UntypedFormControl = new UntypedFormControl();
  inputTypes: string[] = ["CVS", "PreMedical", "Drugs", "SurgBack"];

  constructor(
    public pregnancyService: PregnancyService,
    public examsService: ExamsService,
    public autoTextService: AutotextService,
    private loginService: LoginService,
    private patientService: PatientsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.anamnesisForm = this.formBuilder.group({
      MedicalHistory: [""],
      PreMedical: [""],
      PreMedicalText: [""],
      PreMedicalComment: [""],
      CVS: [""],
      CVSText: [""],
      CVSComment: [""],
      SurgBack: [""],
      SurgBackText: [""],
      SurgBackComment: [""],
      Drugs: [""],
      DrugsText: [""],
      DrugsComment: [""],
      PhysicalExamination: [""],
      IndicationsOfExam: [""],
    });
  }

  ngOnInit(): void {
    this.examsService.fillForm(this.anamnesisForm, "Anamnesis", false, true);

    const medicalHistoryControl = this.anamnesisForm.get("MedicalHistory");
    medicalHistoryControl.valueChanges.subscribe((value: string) => {});

    this.patient = this.patientService.getPat();
    this.patientGender = this.patient.Sex;

    this.subscription = this.examsService.examLoaded$.subscribe(() => {
      this.autoTextService.showAuto(
        this.anamnesisForm.get("CVSText"),
        "CVSText",
        97,
        false
      );
      this.getText();
      this.inputControl.patchValue(
        this.anamnesisForm.value["CVSText"]
          ? this.anamnesisForm.value["CVSText"]
          : ""
      );
    });
  }

  getText() {
    var res = this.autoTextService.getTexts();
    this.subscription = res.subscribe((data: any) => {
      data.forEach((d) => {
        this.options.push(d.Text);
      });
      this.inputControl.patchValue("");
    });
  }

  updateGender(event: Event) {
    const selectedGender = (event.target as HTMLInputElement).value;
    this.patient.Sex = selectedGender;

    this.patientService
      .savePatient(this.patient, this.patient.PID)
      .subscribe((data) => {});
  }

  checkIn(inputType: string) {
    this.filteredOptions = this.options.filter((option) =>
      option.includes(this.anamnesisForm.get(inputType + "Text").value)
    );
    return (
      this.options.filter((option) =>
        option.includes(this.anamnesisForm.get(inputType + "Text").value)
      ).length > 0
    );
  }

  reset(inputType: string) {
    this.anamnesisForm.get(inputType).setValue(-1);
    this.anamnesisForm.get(inputType + "Text").setValue("");
    this.anamnesisForm.get(inputType + "Comment").setValue("");
  }

  delete(item, inputType: string) {
    var res = this.autoTextService.getTexts(String(97));
    this.subscription = res.subscribe((data: any) => {
      if (data.length > 0) {
        var del = data.filter((i) => i.Text === item);
        var d = this.autoTextService.delete(del[0].ID);
        this.subscription = d.subscribe((da: any) => {
          if (da.status === "ok") {
            this.anamnesisForm.get(inputType + "Text").patchValue("");
            this.getText();
            this.filteredOptions = this.filteredOptions.filter(
              (i) => i !== item
            );
            this.options = this.filteredOptions;
          }
        });
      }
    });
  }

  addAndSave(option: any, inputType: string) {
    let inputTypeId: number;

    switch (inputType) {
      case "CVS":
        inputTypeId = 97;
        break;
      case "PreMedical":
        inputTypeId = 96;
        break;
      case "Drugs":
        inputTypeId = 99;
        break;
      case "SurgBack":
        inputTypeId = 98;
        break;
    }

    this.options.push(option);

    if (this.anamnesisForm.get(inputType + "Text").value !== "") {
      this.autoTextService.showAuto(
        this.anamnesisForm.get(inputType + "Text"),
        inputType + "Text",
        inputTypeId,
        false
      );

      const response = this.autoTextService.saveText(option, null);
      this.subscription = response.subscribe(
        (data) => {
          if (this.itemId === null) this.itemId = data["insertId"];
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getTitle(inputType: string): string {
    let title: string;

    switch (inputType) {
      case "CVS":
        title = "CVS and Diabetes Background";
        break;
      case "PreMedical":
        title = "Medical status";
        break;
      case "Drugs":
        title = "Drugs Usage";
        break;
      case "SurgBack":
        title = "Surgical Background";
        break;
    }

    return title;
  }

  getClass(inputType: string): string {
    if (
      inputType !== null &&
      this.anamnesisForm.get(inputType + "Comment") &&
      this.anamnesisForm.get(inputType + "Comment").value
    )
      return "type-v";
    return "type-plus";
  }
}
