import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../exams.service';
import {PregnancyService} from '../pregnancy.service';
import {AutotextService} from '../autotext.service';

@Component({
  selector: 'app-placenta',
  templateUrl: './placenta.component.html',
  styleUrls: ['./placenta.component.scss']
})
export class PlacentaComponent implements OnInit, OnDestroy{

  Form:UntypedFormGroup;
  scores = [{nm: "Amniotic F.", fc:"ScoreAFI"},{nm:"Tonus",fc:"ScoreTonus"},{nm:"Movements",fc:"ScoreMove"},{nm:"Breathing",fc:"ScoreBreath"}];
  fetusSub;
  formSub;

  // position = {Right:false,Left:false,Anterior:false,Posterior:false,Fundal:false,Previa:false,Marginal:false};

  position = {Right:false,Left:false,Anterior:false,Posterior:false,Fundal:false,Previa:false,Marginal:false, Circumvallate:false};


  constructor(private fb:UntypedFormBuilder, public es: ExamsService, public ps: PregnancyService, public atS: AutotextService) {
    this.Form = this.fb.group({
      SacLoc: [''],
      SacApear: [''],
      LieAndPresent:  [''],
      Position:  [''],//left right
      PositionVertical: [''], //top bottom
      BloodVessels:  [''],
      Grading:  [''],
      AmniFluidVolume:  [''],
      AmniFluidIndex:  [''],
      MaxPocket:  [''],
      ScoreAFI:  [''],
      ScoreMove:  [''],
      ScoreTonus:  [''],
      ScoreBreath:  [''],
      Comments:  [''],
      Movements: [''],
      Heartbeat: [''],
      Heartrate:  [''],
    });
   }

  ngOnInit() {
    this.es.fillForm(this.Form,"placenta");
    this.fetusSub = this.es.fetusChange$.subscribe(()=>{
      this.Form.reset(false,{ emitEvent: false });
      this.es.fillForm(this.Form,"placenta");
      this.setPosition();
    });

    this.formSub = this.es.formLoaded$.subscribe(()=>{
      this.setPosition();
    });

  }

  setPosition(){
    let sloc:string = this.Form.get('SacLoc').value;
      // this.position = {Right:false,Left:false,Anterior:false,Posterior:false,Fundal:false,Previa:false,Marginal:false};

      this.position = {Right:false,Left:false,Anterior:false,Posterior:false,Fundal:false,Previa:false,Marginal:false, Circumvallate:false};
      if(sloc ){
        let svals = sloc.split(',');
        for(let val of svals){
          this.position[val] = true;
        }
      }
  }

  ngOnDestroy(): void {
    this.fetusSub.unsubscribe();
    this.formSub.unsubscribe();
  }

  getScore(){
    let score = 0;
    if(this.Form.get("ScoreAFI").value > 0)
      score += this.Form.get("ScoreAFI").value*1;
    if(this.Form.get("ScoreMove").value > 0)
     score += this.Form.get("ScoreMove").value*1;
     if(this.Form.get("ScoreTonus").value > 0)
      score += this.Form.get("ScoreTonus").value*1;
    if(this.Form.get("ScoreBreath").value > 0)
      score += this.Form.get("ScoreBreath").value*1;
    return score;
  }

  scorePosible(){
    return this.counted("ScoreAFI")+ this.counted("ScoreMove") + this.counted("ScoreTonus")+ this.counted("ScoreBreath");
  }

  counted(field:string){
    if(this.Form.get(field).value !== "" && this.Form.get(field).value !== -1 && this.Form.get(field).value!==null)
      return 2;
    return 0;
  }

  resetGrp(item:string){
    if(! this.es.isClosed)
      this.Form.get(item).setValue(null);
  }

  updateSac(){
    if(!this.es.isClosed){
      let value = '';
      for(let key in this.position){
        if(this.position[key])
          value += key + ',';
      }
      if(value != '')
        value = value.substr(0,value.length -1);
      this.Form.get('SacLoc').setValue(value);
    }

  }

}
