import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OptionService {
  constructor(private http: HttpClient) {}

  getOptions(tableName: string, fieldName: string): Observable<string[]> {
    return this.http
      .get<any>(`/api/auto-select-options`, {
        params: { tableName, fieldName },
      })
      .pipe(map((response) => response.data || []));
  }

  addOption(tableName: string, fieldName: string, newOption: string): Observable<any> {
    return this.http.post(`/api/auto-select-options`, { tableName, fieldName, newOption });
  }

  deleteOption(tableName: string, fieldName: string, optionToDelete: string): Observable<any> {
    return this.http.put(`/api/auto-select-options`, { tableName, fieldName, optionToDelete });
  }

  changeOrder(tableName: string, fieldName: string, updatedOptions: string[]): Observable<any> {
    return this.http.put(`/api/auto-select-options/order`, { tableName, fieldName, updatedOptions });
  }
}
