import { Component, OnInit,Input } from '@angular/core';
import {DopplerService} from '../doppler.service';
import {PrintService} from '../print.service'

@Component({
  selector: 'app-dopplerprint',
  templateUrl: './dopplerprint.component.html',
  styleUrls: ['./dopplerprint.component.scss']
})
export class DopplerprintComponent implements OnInit {
  @Input('Name') Name:string;
  @Input('key') Key:string;
  @Input('fetus') fetus:number;
  //value:number;
  gg:number;
  ggfig:string;
  value:number;
  saveGG=['CPR','RARI','RAPI','LARI','LAPI','URI','UPI','PSV','MRI','MPI']
  public isEitherLMPDataMissing: boolean = false;

  constructor(public dop: DopplerService, private ps: PrintService) { }

  ngOnInit() {
    this.value = this.ps.doppler[this.fetus][this.Key];
    this.dop.setValue(this.Key,this.ps.doppler[this.fetus][this.Key],this.ps.doppler[this.fetus][this.Key+"_GAfig"],this.ps.doppler[this.fetus][this.Key+"_GGfig"]);
    if(this.dop.Items[this.Key].hasOwnProperty('GG')){
      this.gg = this.dop.Items[this.Key].GG;
      if(this.saveGG.includes(this.Key) && !this.isEitherLMPDataMissing) {
        this.dop.saveGGdoppler(`${this.Key}_GGcalc`,this.gg,this.fetus+1);
      }
    }
    if(this.dop.Items[this.Key].hasOwnProperty('GGfig'))
      this.ggfig = this.dop.Items[this.Key].GGfig;

    const LMP = this.ps.pregnancy.LMP;
    const LMP_US = this.ps.pregnancy.LMP_ULTRASOUND;

    if (LMP || LMP_US) {
      this.isEitherLMPDataMissing = false;
    } else {
      this.isEitherLMPDataMissing = true;
    }
  }
  getOK(){
    try{
      if(this.ps.doppler[this.fetus][this.Key+'_OK'] === 0)
        return 'OK';
      if(this.ps.doppler[this.fetus][this.Key+'_OK'] === 1)
        return 'Not OK';
      return '';
    }
    catch{
      return '';
    }
  }

}
