import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutotextService } from 'src/app/autotext.service';
import { ExamsService } from 'src/app/exams.service';
import { GastroService } from 'src/app/gastro.service';
import { LoginService } from 'src/app/login.service';
import { PathologyService } from 'src/app/pathology.service';
import  gastroDiagnosisList  from '../../../assets/tables/gastroDiagnosis.json'

@Component({
  selector: 'app-gastro-summary',
  templateUrl: './gastro-summary.component.html',
  styleUrls: ['./gastro-summary.component.scss']
})
export class GastroSummaryComponent implements OnInit {
  gastroList:any;
  // search:any;
  @ViewChild('inputDiagnosis') inputDiagnosis: ElementRef;
  filteredOptions: Observable<string[]>;
  options: any[] = [];
  search = new UntypedFormControl();
  resetS = new UntypedFormControl();
  sub = new Subscription();
  showlock = false;
  signed:Date;
  signer:String;
  itemId;
  recoverySTR:string =`המטופל משתחרר כשהוא מתמצא בזמן ובמקום, רפלקס בליעה ושיעול שמורים\nמצב המודינמי יציב, ללא בחילות הקאות או כאבי בטן משמעותיים.`
  dissmissionSTR=`יש לחזור הביתה בליווי, למנוחה של מספר שעות עקב קבלת הטשטוש חל איסור מוחלט על נהיגה בשמונה השעות הקרובות\nבמידה ויופיעו כאבי בטן,חום,הקאות או דימום רקטלי משמעותי ביממה שלאחר הבדיקה יש לפנות לרופא המבצע בטל: 04-6191885\nבמידה ולא ניתן ליצור קשר יש לפנות לחדר מיון עם מכתב זה`
  
  constructor(public gs:GastroService, public atS:AutotextService, public es:ExamsService, public ls:LoginService) {}

  ngOnInit() {
    if(this.gs.gastroForm.get('Recovery').value == null || this.gs.gastroForm.get('Recovery').value == '' ){
      this.gs.gastroForm.get('Recovery').setValue(this.recoverySTR, {emitEvent:false})
    }
    if(this.gs.gastroForm.get('Dissmission').value == null || this.gs.gastroForm.get('Dissmission').value == ''){
      this.gs.gastroForm.get('Dissmission').setValue(this.dissmissionSTR, {emitEvent:false})
    }
    this.gastroList = gastroDiagnosisList;
    this.filteredOptions = this.search.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
      );
      this.gs.buildList();
      this.atS.showAuto(this.search, 'Diagnosis',89, false);
      this.getText();
      this.sub = this.gs.diagnosisChange$.subscribe(() => {
        this.gs.buildList();
        this.getText();

      })
    if(this.es.isClosed){
      this.search.disable({emitEvent:false})
      this.gs.getClosing().subscribe((d:any) => {
        this.signed = d.ClosedDate;
        this.signer = this.ls.getUserName();
      })
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.gastroList.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  private _reset(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.gastroList.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  reset(){
    this.search.patchValue('');
    // this.resetS.patchValue('');
    this.filteredOptions = this.search.valueChanges.pipe(startWith(''),map(value => this._filter(value)));
  }

  blur(): void {;
    setTimeout(() => this.inputDiagnosis.nativeElement.blur(), 0);
  }

  signClose(){
    this.gs.signExam(this.ls.getUserName()).subscribe((d)=>{
      this.signer = this.ls.getUserName();
      this.signed = new Date();
      this.gs.gastroForm.disable({emitEvent: false});
      this.search.disable({emitEvent:false})
      this.es.isClosed = true;
    },(error)=>{
      console.log(error);
    })
  }
  getText(){
    var res = this.atS.getTexts();
      this.sub = res.subscribe((data:any) => {
        this.atS.summaryDiagnosisAT = data;
        if((this.gastroList.length - data.length) != 130)
          data.forEach((d) => {
            this.gastroList.push({value: d.Text})
          })
          this.search.patchValue('');
      })
  }

  addToList(option, inATS){
    if(inATS){
      if(this.search.value)
      this.atS.showAuto(this.search, 'Diagnosis', 89,false)
      var res = this.atS.saveText(this.search.value, null)
      this.sub = res.subscribe((data)=>{
        if(this.itemId == null)
          this.itemId = data['insertId'];
          this.gs.saveDiagnosis(data, inATS)
          // gs.saveDiagnosis(option);
      },(error)=>{
        console.log(error);
      });
    }else{
      this.gs.saveDiagnosis(option)
    }
    
  }
  checkIn(){

    if(this.search.value){
      this.filteredOptions = this.gastroList.filter((option) => {
        if(option.value.includes(this.search.value)){
          this.options.push(option.value)
        }
        return option.value.includes(this.search.value)
     
      });
      
      
      return this.gastroList.filter((option) => option.value.includes(this.search.value)).length > 0;
    }
  }


}
