import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutotextService } from '../autotext.service';
import { DiagnosisService } from '../diagnosis.service';
import { ExamsService } from '../exams.service';
import { FetalechoService } from '../fetalecho.service';
import { PatientsService } from '../patients.service';

@Component({
  selector: 'app-fetalechoitem',
  templateUrl: './fetalechoitem.component.html',
  styleUrls: ['./fetalechoitem.component.scss']
})
export class FetalechoitemComponent implements OnInit,OnDestroy, AfterContentChecked {
  @Input('group') Group;
  @Input('name') name="";
  unitForm: UntypedFormGroup;
  dbName:any;
  showComment = false;
  ATiD;
  // comment = new FormControl();
  valueData = new UntypedFormControl();
  value:any;
  sub = new Subscription();
  noShow=true;
  DisplayName = "";
  haveComment=false;
  link;

  constructor(public atS:AutotextService,private ds:DiagnosisService, private pat:PatientsService,public fes:FetalechoService,private fb:UntypedFormBuilder, public es:ExamsService) { }

  ngOnInit() {
    const search = ' ';
    const replaceWith = '';
    this.dbName= this.name.split(search).join(replaceWith);    
    if(this.name === 'SVR' || this.name === 'PVR' || this.name === 'AVC' || this.name === 'VAC'){
      switch (this.name) {
        case 'PVR':
          this.DisplayName = 'Pulmonary Venous Return'
          this.noShow=false;
          this.ATiD=301;
          break;
        case 'SVR':
          this.DisplayName = 'Systemic Venous Return'
          this.noShow=false;
          this.ATiD=302;
          break;
        case 'AVC':
          this.DisplayName = 'AV Concordance'
          this.noShow=false;
          this.ATiD=303;
          break;
        case 'VAC':
          this.DisplayName = 'VA Concordance'
          this.noShow=false;
          this.ATiD=304;
          break;
        default:
          break;
      }
    }
  }
  ngAfterContentChecked(): void {
    if(this.name == 'PE')
    this.dbName='PE'
  if(this.name !== null  && this.fes.fetalEchoForm.get(this.dbName+'Note')  && this.fes.fetalEchoForm.get(this.dbName+'Note').value ){
    this.haveComment = true;
    this.link ='../../assets/icons/anatomy-v.svg'
  }
  this.haveComment = false;
  this.link = '../../assets/icons/anatomy-plus.svg';
      
  }

  clear(grp:string, name: string){
    if(! this.es.isClosed)
    this.fes.fetalEchoForm.get(this.dbName).setValue(-1);
  }

  getClass(item: string): string {
    const noteControl = this.fes.fetalEchoForm.get(this.dbName + 'Note');
    const specialInputs = ['AVC', 'VAC', 'SVR', 'PVR'];
  
    const isSpecialInput = specialInputs.includes(item);
    const isNoteControlValid = item !== null && noteControl && noteControl.value;
  
    if (isNoteControlValid) {
      return isSpecialInput ? 'type-v p-bottom1' : 'type-v p-bottom2';
    } else {
      return isSpecialInput ? 'type-plus p-bottom1' : 'type-plus p-bottom2';
    }
  }

  getLink(item){
    if(item == 'PE')
      this.dbName='PE'
    if(item !== null  && this.fes.fetalEchoForm.get(this.dbName+'Note')  && this.fes.fetalEchoForm.get(this.dbName+'Note').value ){
      this.haveComment = true;
      this.link ='../../assets/icons/anatomy-v.svg'
      return this.link;
    }
    this.haveComment = false;
    this.link = '../../assets/icons/anatomy-plus.svg';
    return this.link;
  }
  ngOnDestroy(){
    this.sub.unsubscribe()
  }

}
