<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up">Follow Up</button>
        / History
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close History"
          matTooltip="Close History"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->

  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <!-- ================ Family History (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Family History</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Related </span>
              </th>
              <th class="th-clear">
                <span> Disease </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>

            <!-- ======== Existing Data Row (Start) ======== -->
            <tr
              *ngFor="let familyHistory of familyHistoryData"
              [ngClass]="familyHistory.isEditing ? 'tr-isActive' : ''"
            >
              <td>
                <app-free-text-select
                  [(inputValue)]="familyHistory.Related"
                  [disabled]="!familyHistory.isEditing"
                  [inputName]="'Related'"
                  [tableName]="'fuFamilyHistory'"
                  [isEditing]="familyHistory.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="familyHistory.Disease"
                  [disabled]="!familyHistory.isEditing"
                  [inputName]="'Disease'"
                  [tableName]="'fuFamilyHistory'"
                  [isEditing]="familyHistory.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="familyHistory.Comments"
                  [disabled]="!familyHistory.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuFamilyHistory'"
                  [isEditing]="familyHistory.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!familyHistory.isEditing" (click)="editRow(familyHistory)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="familyHistory.isEditing"
                    (click)="updateRow('fuFamilyHistory', familyHistory, ['Related', 'Disease', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuFamilyHistory', familyHistory)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.familyHistoryNewData.Related"
                  [inputName]="'Related'"
                  [tableName]="'fuFamilyHistory'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.familyHistoryNewData.Disease"
                  [inputName]="'Disease'"
                  [tableName]="'fuFamilyHistory'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.familyHistoryNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuFamilyHistory'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.familyHistoryNewData.Related ||
                    !followUpService.familyHistoryNewData.Disease ||
                    !followUpService.familyHistoryNewData.Comments
                  "
                  (click)="
                    saveNewRow('fuFamilyHistory', followUpService.familyHistoryNewData, familyHistoryOriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Family History (End) ================ -->

      <!-- ================ Previous Pregnancies (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Pregnancies</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Pregnancy Year </span>
              </th>
              <th class="th-clear">
                <span> Conception Method </span>
              </th>
              <th class="th-clear">
                <span> Delivery Date </span>
              </th>
              <th class="th-clear">
                <span> Delivery Week </span>
              </th>
              <th class="th-clear">
                <span> Way Of Delivery </span>
              </th>
              <th class="th-clear">
                <span> Number Of Fetuses </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>

            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let pregnancy of pregnanciesData" [ngClass]="pregnancy.isEditing ? 'tr-isActive' : ''">
              <td>
                <select
                  class="t-select t-field w100"
                  [(ngModel)]="pregnancy.PregnancyYear"
                  [disabled]="!pregnancy.isEditing"
                >
                  <option *ngFor="let year of getYears()" [value]="year">
                    {{ year }}
                  </option>
                </select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="pregnancy.ConceptionMethod"
                  [disabled]="!pregnancy.isEditing"
                  [inputName]="'ConceptionMethod'"
                  [tableName]="'fuPregnancies'"
                  [isEditing]="pregnancy.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [(ngModel)]="pregnancy.DeliveryDate"
                      [disabled]="!pregnancy.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!pregnancy.isEditing ? 'disabled' : ''"
                      (click)="picker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <select
                  class="t-select t-field w100"
                  [(ngModel)]="pregnancy.DeliveryWeek"
                  [disabled]="!pregnancy.isEditing"
                >
                  <option *ngFor="let week of getDeliveryWeek()" [value]="week">
                    {{ week }}
                  </option>
                </select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="pregnancy.WayOfDelivery"
                  [disabled]="!pregnancy.isEditing"
                  [inputName]="'WayOfDelivery'"
                  [tableName]="'fuPregnancies'"
                  [isEditing]="pregnancy.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <div style="display: flex; justify-content: center; align-items: center">
                  <select
                    class="t-select t-field w100"
                    [(ngModel)]="pregnancy.NumberOfFetuses"
                    [disabled]="!pregnancy.isEditing"
                  >
                    <option [value]="1">1</option>
                    <option [value]="2">2</option>
                    <option [value]="3">3</option>
                    <option [value]="4">4</option>
                  </select>
                  <button
                    [disabled]="!pregnancy.isEditing || !pregnancy.NumberOfFetuses"
                    (click)="openFetusDetails(pregnancy.NumberOfFetuses, pregnancy)"
                    class="options-manager-icon"
                    [ngClass]="!pregnancy.isEditing || !pregnancy.NumberOfFetuses ? 'disabled' : ''"
                    [matTooltip]="!pregnancy.NumberOfFetuses ? 'Please select fetuses number' : 'Fetuses details'"
                  >
                    <mat-icon style="color: #428592">playlist_add</mat-icon>
                  </button>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="pregnancy.Comments"
                  [disabled]="!pregnancy.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuPregnancies'"
                  [isEditing]="pregnancy.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!pregnancy.isEditing" (click)="editRow(pregnancy)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="pregnancy.isEditing"
                    (click)="
                      updateRow('fuPregnancies', pregnancy, [
                        'PregnancyYear',
                        'ConceptionMethod',
                        'DeliveryDate',
                        'DeliveryWeek',
                        'WayOfDelivery',
                        'NumberOfFetuses',
                        'Comments',
                        'Fetus1Weight',
                        'Fetus1Gender',
                        'Fetus1Comments',
                        'Fetus2Weight',
                        'Fetus2Gender',
                        'Fetus2Comments',
                        'Fetus3Weight',
                        'Fetus3Gender',
                        'Fetus3Comments',
                        'Fetus4Weight',
                        'Fetus4Gender',
                        'Fetus4Comments'
                      ])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuPregnancies', pregnancy)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <select class="t-select t-field w100" [(ngModel)]="followUpService.pregnanciesData.PregnancyYear">
                  <option *ngFor="let year of getYears()" [value]="year">
                    {{ year }}
                  </option>
                </select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.pregnanciesData.ConceptionMethod"
                  [inputName]="'ConceptionMethod'"
                  [tableName]="'fuPregnancies'"
                ></app-free-text-select>
              </td>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.pregnanciesData.DeliveryDate"
                      [matDatepicker]="picker"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="picker.open()" />
                  </span>
                </div>
              </td>
              <td>
                <select [(ngModel)]="followUpService.pregnanciesData.DeliveryWeek" class="t-select t-field w100">
                  <option *ngFor="let week of getDeliveryWeek()" [value]="week">
                    {{ week }}
                  </option>
                </select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.pregnanciesData.WayOfDelivery"
                  [inputName]="'WayOfDelivery'"
                  [tableName]="'fuPregnancies'"
                ></app-free-text-select>
              </td>
              <td>
                <div style="display: flex; justify-content: center; align-items: center">
                  <select [(ngModel)]="followUpService.pregnanciesData.NumberOfFetuses" class="t-select t-field w100">
                    <option selected [value]="1">1</option>
                    <option [value]="2">2</option>
                    <option [value]="3">3</option>
                    <option [value]="4">4</option>
                  </select>
                  <button
                    (click)="
                      openFetusDetails(followUpService.pregnanciesData.NumberOfFetuses, followUpService.pregnanciesData)
                    "
                    class="options-manager-icon"
                    [matTooltip]="
                      !followUpService.pregnanciesData.NumberOfFetuses
                        ? 'Please select fetuses number'
                        : 'Fetuses details'
                    "
                  >
                    <mat-icon style="color: #428592">playlist_add</mat-icon>
                  </button>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.pregnanciesData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuPregnancies'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  (click)="saveNewRow('fuPregnancies', followUpService.pregnanciesData, pregnanciesOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Previous Pregnancies (End) ================ -->

      <!-- ================ Diseases & Surgeries (Start) ================ -->
      <div class="w100 summary-anamnesis" style="display: flex">
        <!-- ================ Diseases (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2>Diseases</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Name </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear"></th>
              </tr>

              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let diseases of diseasesData" [ngClass]="diseases.isEditing ? 'tr-isActive' : ''">
                <td>
                  <app-free-text-select
                    [(inputValue)]="diseases.Name"
                    [disabled]="!diseases.isEditing"
                    [inputName]="'Name'"
                    [tableName]="'fuDiseases'"
                    [isEditing]="diseases.isEditing"
                  ></app-free-text-select>
                </td>

                <td>
                  <app-free-text-select
                    [(inputValue)]="diseases.Comments"
                    [disabled]="!diseases.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuDiseases'"
                    [isEditing]="diseases.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!diseases.isEditing" (click)="editRow(diseases)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="diseases.isEditing"
                      (click)="updateRow('fuDiseases', diseases, ['Name', 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuDiseases', diseases)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.diseasesNewData.Name"
                    [inputName]="'Name'"
                    [tableName]="'fuDiseases'"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.diseasesNewData.Comments"
                    [inputName]="'Comments'"
                    [tableName]="'fuDiseases'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="!followUpService.diseasesNewData.Comments || !followUpService.diseasesNewData.Name"
                    (click)="saveNewRow('fuDiseases', followUpService.diseasesNewData, diseasesOriginalData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Diseases (End) ================ -->

        <!-- ================ Surgeries (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2 class="weightAssTable-title">Surgeries</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Year </span>
                </th>
                <th class="th-clear">
                  <span> Name </span>
                </th>
                <th class="th-clear">
                  <span> Description </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>
              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let surgery of surgeriesData" [ngClass]="surgery.isEditing ? 'tr-isActive' : ''">
                <td>
                  <select class="t-select t-field w100" [(ngModel)]="surgery.Year" [disabled]="!surgery.isEditing">
                    <option *ngFor="let year of getYears()" [value]="year">
                      {{ year }}
                    </option>
                  </select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="surgery.Name"
                    [disabled]="!surgery.isEditing"
                    [inputName]="'Name'"
                    [tableName]="'fuSurgeries'"
                    [isEditing]="surgery.isEditing"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="surgery.Description"
                    [disabled]="!surgery.isEditing"
                    [inputName]="'Description'"
                    [tableName]="'fuSurgeries'"
                    [isEditing]="surgery.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!surgery.isEditing" (click)="editRow(surgery)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="surgery.isEditing"
                      (click)="updateRow('fuSurgeries', surgery, ['Year', 'Name', 'Description'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuSurgeries', surgery)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <select class="t-select t-field w100" [(ngModel)]="followUpService.surgeriesNewData.Year">
                    <option *ngFor="let year of getYears()" [value]="year">
                      {{ year }}
                    </option>
                  </select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.surgeriesNewData.Name"
                    [inputName]="'Name'"
                    [tableName]="'fuSurgeries'"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.surgeriesNewData.Description"
                    [inputName]="'Description'"
                    [tableName]="'fuSurgeries'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="
                      !followUpService.surgeriesNewData.Description ||
                      !followUpService.surgeriesNewData.Name ||
                      !followUpService.surgeriesNewData.Year
                    "
                    (click)="saveNewRow('fuSurgeries', followUpService.surgeriesNewData, surgeriesOriginalData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Surgeries (End) ================ -->
      </div>
      <!-- ================ Diseases & Surgeries (End) ================ -->

      <!-- ================ Medications (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Medications</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Dosage </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>

            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let medication of medicationsData" [ngClass]="medication.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="medication.Name"
                  [disabled]="!medication.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuMedications'"
                  [isEditing]="medication.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="medication.Dosage"
                  [disabled]="!medication.isEditing"
                  [inputName]="'Dosage'"
                  [tableName]="'fuMedications'"
                  [isEditing]="medication.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="medication.Comments"
                  [disabled]="!medication.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuMedications'"
                  [isEditing]="medication.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!medication.isEditing" (click)="editRow(medication)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="medication.isEditing"
                    (click)="updateRow('fuMedications', medication, ['Name', 'Dosage', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuMedications', medication)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.medicationsNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuMedications'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.medicationsNewData.Dosage"
                  [inputName]="'Dosage'"
                  [tableName]="'fuMedications'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.medicationsNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuMedications'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.medicationsNewData.Name ||
                    !followUpService.medicationsNewData.Dosage ||
                    !followUpService.medicationsNewData.Comments
                  "
                  (click)="saveNewRow('fuMedications', followUpService.medicationsNewData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Medications (End) ================ -->
      <!-- ================ Sensitivities (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Sensitivities</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Name </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>

              <th class="th-clear">
                <span> </span>
              </th>
            </tr>

            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let sensitivity of sensitivitiesData" [ngClass]="sensitivity.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="sensitivity.Name"
                  [disabled]="!sensitivity.isEditing"
                  [inputName]="'Name'"
                  [tableName]="'fuSensitivities'"
                  [isEditing]="sensitivity.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="sensitivity.Comments"
                  [disabled]="!sensitivity.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuSensitivities'"
                  [isEditing]="sensitivity.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!sensitivity.isEditing" (click)="editRow(sensitivity)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="sensitivity.isEditing"
                    (click)="updateRow('fuSensitivities', sensitivity, ['Name', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuSensitivities', sensitivity)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.sensitivitiesNewData.Name"
                  [inputName]="'Name'"
                  [tableName]="'fuSensitivities'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.sensitivitiesNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuSensitivities'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.sensitivitiesNewData.Name || !followUpService.sensitivitiesNewData.Comments
                  "
                  (click)="saveNewRow('fuSensitivities', followUpService.sensitivitiesNewData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
        <!-- ================ Sensitivities (End) ================ -->
      </div>

      <!-- ================ Habits & Tests (Start) ================ -->
      <div class="w100 summary-anamnesis" style="display: flex">
        <!-- ================ Habits (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2 class="weightAssTable-title">Habits</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Type </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>

              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let habit of habitsData" [ngClass]="habit.isEditing ? 'tr-isActive' : ''">
                <td>
                  <app-free-text-select
                    [(inputValue)]="habit.Type"
                    [disabled]="!habit.isEditing"
                    [inputName]="'Type'"
                    [tableName]="'fuHabits'"
                    [isEditing]="habit.isEditing"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="habit.Comments"
                    [disabled]="!habit.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuHabits'"
                    [isEditing]="habit.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!habit.isEditing" (click)="editRow(habit)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="habit.isEditing"
                      (click)="updateRow('fuHabits', habit, ['Type', 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuHabits', habit)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.habitsNewData.Type"
                    [inputName]="'Type'"
                    [tableName]="'fuHabits'"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.habitsNewData.Comments"
                    [inputName]="'Comments'"
                    [tableName]="'fuHabits'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="!followUpService.habitsNewData.Type || !followUpService.habitsNewData.Comments"
                    (click)="saveNewRow('fuHabits', followUpService.habitsNewData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Habits (End) ================ -->

        <!-- ================ Tests (Start) ================ -->
        <div class="w50 summary-recommendations">
          <section class="m-box fulltext" style="height: 100%">
            <h2 class="weightAssTable-title">Tests</h2>
            <table>
              <tr>
                <th class="th-clear">
                  <span> Name </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>

              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let test of testsData" [ngClass]="test.isEditing ? 'tr-isActive' : ''">
                <td>
                  <app-free-text-select
                    [(inputValue)]="test.Name"
                    [disabled]="!test.isEditing"
                    [inputName]="'Name'"
                    [tableName]="'fuTests'"
                    [isEditing]="test.isEditing"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="test.Comments"
                    [disabled]="!test.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuTests'"
                    [isEditing]="test.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!test.isEditing" (click)="editRow(test)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="test.isEditing"
                      (click)="updateRow('fuTests', test, ['Name', 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuTests', test)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->

              <!-- ======== Add New Row (Start) ======== -->
              <tr>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.testsNewData.Name"
                    [inputName]="'Name'"
                    [tableName]="'fuTests'"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="followUpService.testsNewData.Comments"
                    [inputName]="'Comments'"
                    [tableName]="'fuTests'"
                  ></app-free-text-select>
                </td>

                <td>
                  <button
                    [disabled]="!followUpService.testsNewData.Name || !followUpService.testsNewData.Comments"
                    (click)="saveNewRow('fuTests', followUpService.testsNewData)"
                    style="width: fit-content; padding: 0 1em"
                    class="mat-tooltip-trigger search-btn"
                  >
                    Add
                  </button>
                </td>
              </tr>
              <!-- ======== Add New Row (End) ======== -->
            </table>
          </section>
        </div>
        <!-- ================ Tests (End) ================ -->
      </div>
      <!-- ================ Habits & Tests (Start) ================ -->
    </div>
  </main>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
