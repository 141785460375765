import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";
import { DiagnosisService } from "./diagnosis.service";

@Injectable({
  providedIn: "root",
})
export class AdvancedSearch {
  matchExams = [];
  searchCategory = [];
  selectedCategory: { name: string; values: number[]; table: string };
  selectedCategory2;
  selectedCategory3;
  selectedCategory4;
  selectedCategory5;
  selectedCategory6;
  valueForSearch: number;
  searchText = "";
  examTypeCounts: { [key: string]: number } = {};

  constructor(private ls: LoginService, public ds: DiagnosisService) {
    this.searchCategory = ls.isSheba
      ? [
          {
            name: "U/S",
            values: [1020, 1033, 1087, 1339, 1389, 1390],
            table: "summary",
          },
          {
            name: "MFM",
            values: [
              726, 754, 761, 762, 763, 770, 781, 782, 790, 801, 810, 815, 824,
              832, 864, 877,
            ],
            table: "summary",
          },
          {
            name: "NEURAL",
            values: [
              887, 888, 889, 890, 891, 892, 893, 894, 895, 896, 897, 898, 899,
              900, 901,
            ],
            table: "summary",
          },
          {
            name: "Gynaecology",
            values: [1408, 1433, 1445, 1467, 1489, 1509],
            table: "summary",
          },
        ]
      : [
          { name: "CNS", values: [18, 161], table: "cns" },
          { name: "Face&Neck", values: [113, 222], table: "face" },
          { name: "Chest&Cardio", values: [170, 286], table: "chest" },
          { name: "GIT", values: [1, 141], table: "digest" },
          { name: "GenitoUrinary", values: [137, 191], table: "urology" },
          { name: "Skeleton", values: [42, 233, 244], table: "skeleton" },
          { name: "Summary", values: [315, 321], table: "summary" },
        ];
  }

  getList(array: number[]) {
    let list = [];

    for (
      let i = 0;
      this.ds.diagnosis_list && i < this.ds.diagnosis_list.length;
      i++
    ) {
      if (array.includes(this.ds.diagnosis_list[i][0]))
        list.push(this.ds.diagnosis_list[i]);
    }

    return list;
  }

  setMatchExams(matchExams) {
    this.matchExams = matchExams;
  }

  clearLogout() {
    this.selectedCategory = null;
    this.selectedCategory2 = null;
    this.selectedCategory3 = null;
    this.selectedCategory4 = null;
    this.selectedCategory5 = null;
    this.selectedCategory6 = null;
    this.valueForSearch = null;
    this.searchText = "";
    this.matchExams = [];
    this.examTypeCounts = {};
  }
}
