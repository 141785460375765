<div class="wrapper">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">Follow Up</h1>
      <nav class="header-menu">
        <span
          (click)="showPrintOpts = true; showPrint = true"
          class="header-menu-link icon-print"
          aria-label="Print"
          matTooltip="Print"
        ></span>
        <a
          class="header-menu-link icon-confirm"
          routerLink="/patsearch"
          aria-label="Close Follow Up"
          matTooltip="Close Follow Up"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->

  <!-- ============ New Circles (Start) ============ -->
  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <div class="w50 patient-ob-box">
        <div class="h100 m-box" *ngIf="patient?.ID != 'default'">
          <h2>Patient History</h2>
          <!-- ==================== Pregnancy with LMP  (Start) ================== -->
          <div class="linked-radios-list linked-radios-set" *ngFor="let preg of pregnancies">
            <strong class="linked-radios-row">
              Pregnancy with LMP {{ preg.LMP | date : "dd/MM/yyyy" }}
              <span
                class="linked-radios-date"
                style="position: absolute; right: 0.5em; font-size: 11pt; cursor: pointer"
                (click)="closePreg = preg.AutoIndex; pregExams = preg.exams"
                *ngIf="
                  loginService.userType == 'doctor' ||
                  loginService.userType == 'tech' ||
                  loginService.userType == 'visitingdoc'
                "
              >
                {{ preg.ClosedDate ? "Closed on " + (preg.ClosedDate | date : "dd/MM/yyyy") : "Close" }}
              </span>
            </strong>
            <ul *ngIf="preg.visits">
              <li class="l-flex l-flex-c">
                <span style="width: 50%; margin-right: 5em">
                  <strong> Visit Date </strong>
                </span>
                <span style="width: 50%">
                  <strong> Report </strong>
                </span>
              </li>
            </ul>

            <ul>
              <li class="l-flex l-flex-c" *ngFor="let visit of preg.visits">
                <span
                  class="examlink"
                  style="display: flex; position: relative; width: 100%; align-items: center; cursor: default"
                >
                  <span style="width: 50%; white-space: nowrap; margin-right: 5em" class="linked-radios-date" [style]="!preg.ClosedDate ? {'cursor':'pointer'} : null" (click)="!preg.ClosedDate ? navigateToVisit() : null">
                    {{ visit.CreationTime | date : "dd/MM/yyyy" }} at
                    {{ visit.CreationTime | date : "HH:mm" }}
                  </span>
                  <span style="width: 50%; white-space: nowrap">
                    <img
                      class="print-history-icon"
                      src="../../assets/icons/print-history.svg"
                      matTooltip="Report"
                      (click)="printVisit(visit?.AutoIndex)"
                    />
                  </span>
                </span>
              </li>
            </ul>
            <div style="margin-top: 1em" *ngIf="preg.calendarForms && preg.calendarForms.length > 0">
              <!-- <strong class="linked-radios-row">Timeline </strong> -->

              <ul>
                <li
                  class="l-flex l-flex-c"
                  style="border-bottom: 1px solid #bdd3d5; margin-bottom: 1em"
                  *ngFor="let form of preg.calendarForms"
                >
                  <span (click)="loadCalendarForm(form)" class="l-fg1 examlink"> Pregnancy Timeline </span>
                  <span style="margin-right: 2em">by {{ form.Opener }}</span>
                  <span class="linked-radios-date"
                    >{{ form.CreationTime | date : "dd/MM/yyyy" }} at {{ form.CreationTime | date : "HH:mm" }}</span
                  >
                </li>
              </ul>
            </div>
            <div style="margin-top: 1em" *ngIf="preg.Letters && preg.Letters.length > 0">
              <strong class="linked-radios-row">
                {{ "GUI.Patient History.ExamTypes.Letters and Certificates" | translate }}
              </strong>
              <ul>
                <li class="l-flex l-flex-c" *ngFor="let letter of preg.Letters | uniqueAutoIndex">
                  <ng-container *ngIf="FORMS.includes(letter.ExamType)">
                    <span
                      class="l-fg1 examlink"
                      (click)="goLetter(null, letter, letter.ExamType, letter.CreationTime)"
                      (contextmenu)="askDelete(letter)"
                    >
                      {{
                        ("GUI.Letters." + letter.ChangedExamTitle | translate) ||
                          ("GUI.Patient History.ExamTypes.Letter" | translate)
                      }}
                      <ng-container *ngIf="letter.Locked">
                        <img src="../../assets/icons/login/password.svg" style="height: 1em" />{{
                          letter.LockedBy
                        }}</ng-container
                      >
                    </span>
                    <span style="margin-right: 2em">by {{ letter.Opener }}</span>
                    <span class="linked-radios-date"
                      >{{ letter.CreationTime | date : "dd/MM/yyyy" }} at
                      {{ letter.CreationTime | date : "HH:mm" }}</span
                    >
                  </ng-container>
                </li>
              </ul>
            </div>
            <ng-container *ngIf="loginService.premissions && loginService.premissions?.uploadDocuments">
              <div class="linked-radios-list linked-radios-set" *ngIf="preg.Documents && preg.Documents?.length > 0">
                <strong class="linked-radios-row" style="margin-bottom: 1em">
                  {{ "GUI.documents.labels.Attached Clinical Documents" | translate }}
                </strong>

                <ul>
                  <li
                    class="l-flex l-flex-c"
                    *ngFor="let document of preg.Documents | sortCreationTime : 'CreationTime'"
                  >
                    <span
                      class="l-fg1 examlink"
                      (click)="openDocument(document.AutoIndex)"
                      (contextmenu)="askDelete(document, false, false, false, false, true)"
                    >
                      {{ document.title }}
                    </span>

                    <span style="margin-right: 2em"> {{ document.comments }}</span>
                    <span class="linked-radios-date"
                      >{{ document.CreationTime | date : "dd/MM/yyyy" }} at
                      {{ document.CreationTime | date : "HH:mm" }}
                    </span>
                  </li>
                </ul>
              </div>
              <!-- ========================== Patient Clinial Documents (End) ========================== -->

              <!-- ========================== Patient Administrative Documents (Start) ========================== -->
              <div
                class="linked-radios-list linked-radios-set"
                *ngIf="preg.AdminDocuments && preg.AdminDocuments?.length > 0"
              >
                <strong class="linked-radios-row" style="margin-bottom: 1em">
                  {{ "GUI.documents.labels.Attached Administrative Documents" | translate }}
                </strong>

                <ul>
                  <li
                    class="l-flex l-flex-c"
                    *ngFor="let document of preg.AdminDocuments | sortCreationTime : 'CreationTime'"
                  >
                    <span
                      class="l-fg1 examlink"
                      (click)="openDocument(document.AutoIndex)"
                      (contextmenu)="askDelete(document, false, false, false, false, true)"
                    >
                      {{ document.title }}
                    </span>

                    <span style="margin-right: 2em"> {{ document.comments }}</span>
                    <span class="linked-radios-date"
                      >{{ document.CreationTime | date : "dd/MM/yyyy" }} at
                      {{ document.CreationTime | date : "HH:mm" }}
                    </span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
          <!-- ==================== Pregnancy with LMP  (End) ================== -->
        </div>
      </div>

      <!-- ==================== Pregnancy Categories Navigation (Start) ================== -->

      <div class="w50 patient-ob-box">
        <div class="h100 m-box">
          <h2>Create New</h2>

          <div *ngIf="!isPregClosed" style="width: 35%">
            <button
              class="search-btn"
              style="padding: 0 1em 0 1em; white-space: nowrap; min-width: fit-content"
              *ngIf="!isPregClosed"
              (click)="newPregnancy()"
            >
              <!-- (click)="openNewPregnancy = true" -->
              Create New Pregnancy
            </button>
          </div>
          <div class="h100 l-flex l-column circle-container" style="position: relative">
            <button
              class="circle center circle-title"
              routerLink="/follow-up/visit"
              matTooltip="Go to Visit"
              [disabled]="!isPregClosed"
            >
              <a class="circle-text" aria-label="Go to Visit"> Visit </a>
            </button>

            <button
              class="circle top"
              [disabled]="!isPregClosed"
              matTooltip="Create a new document"
              (click)="newLetter()"
            >
              New Documents
            </button>
            <button
              class="circle bottom"
              routerLink="/follow-up/genetics"
              matTooltip="Go to Lab"
              [disabled]="!isPregClosed"
            >
              Genetics
            </button>
            <button
              disabled
              class="circle left"
              routerLink="/follow-up/scans"
              matTooltip="Go to U/S Scans"
              [disabled]="!isPregClosed"
            >
              U/S Scans
            </button>
            <button
              class="circle right"
              routerLink="/follow-up/laboratory"
              matTooltip="Go to Lab"
              [disabled]="!isPregClosed"
            >
              Lab
            </button>
            <button
              (click)="calendarForm = true; selectedForm = null; cf.selcForm = null"
              [disabled]="!isPregClosed"
              class="circle top-left"
            >
              Timeline
            </button>
            <button
              class="circle top-right"
              [disabled]="!isPregClosed"
              matTooltip="Upload a new file"
              (click)="newFile()"
            >
              New Files
            </button>
            <button
              class="circle bottom-left"
              routerLink="/follow-up/history"
              matTooltip="Go to History"
              [disabled]="!isPregClosed"
            >
              <a class="circle-text" aria-label="Go to History"> History </a>
            </button>
            <button
              class="circle bottom-right"
              routerLink="/follow-up/screening"
              matTooltip="Go to Screening"
              [disabled]="!isPregClosed"
            >
              Screening
            </button>
          </div>
        </div>
      </div>

      <!-- ==================== Pregnancy Categories Navigation (End) ================== -->
    </div>
    <!-- box margin -->
  </main>
  <!-- ============ New Circles (End) ============ -->

  <!-- ============ Header (End) ============ -->

  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
    (attachedFile)="openDocumentsAdd = true"
  ></app-patdetails>
  <app-closepregnancy
    *ngIf="closePreg"
    [pregtoload]="closePreg"
    [exams]="pregExams"
    (examClosed)="pregClosed()"
    (closed)="closePreg = null"
    [isFollowUp]="true"
  ></app-closepregnancy>
  <app-pat-documents
    *ngIf="openDocumentsAdd"
    [showDocuments]="false"
    [PregIndex]="pregnancyService.pregID"
    (closed)="openDocumentsAdd = false; resetPregs()"
  ></app-pat-documents>
</div>
<app-calexams *ngIf="calendarForm" (closed)="closCalForm($event)" [selectedForm]="selectedForm"></app-calexams>
<app-commerror></app-commerror>
<app-open-pregnancy
  *ngIf="openNewPregnancy"
  [pregtoload]="newPregIndex"
  (isClosed)="openNewPregnancy = false; resetPregs()"
></app-open-pregnancy>
