export const createFamilyHistoryNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Related: "",
  Disease: "",
  Comments: "",
  ID,
});

export const familyHistoryOriginalData = {
  PID: null,
  PregIndex: null,
  Related: "",
  Disease: "",
  Comments: "",
  ID: null,
};

export const createPregnanciesNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  ID,
  PregnancyYear: "",
  ConceptionMethod: "",
  DeliveryDate: "",
  DeliveryWeek: "",
  WayOfDelivery: "",
  NumberOfFetuses: 1,
  Comments: "",
  Fetus1Weight: "",
  Fetus1Gender: "",
  Fetus1Comments: "",
  Fetus2Weight: "",
  Fetus2Gender: "",
  Fetus2Comments: "",
  Fetus3Weight: "",
  Fetus3Gender: "",
  Fetus3Comments: "",
  Fetus4Weight: "",
  Fetus4Gender: "",
  Fetus4Comments: "",
});

export const pregnanciesOriginalData = {
  PID: null,
  PregIndex: null,
  ID: null,
  PregnancyYear: "",
  ConceptionMethod: "",
  DeliveryDate: "",
  DeliveryWeek: "",
  WayOfDelivery: "",
  NumberOfFetuses: "",
  Comments: "",
  Fetus1Weight: "",
  Fetus1Gender: "",
  Fetus1Comments: "",
  Fetus2Weight: "",
  Fetus2Gender: "",
  Fetus2Comments: "",
  Fetus3Weight: "",
  Fetus3Gender: "",
  Fetus3Comments: "",
  Fetus4Weight: "",
  Fetus4Gender: "",
  Fetus4Comments: "",
};

export const createDiseasesNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Name: "",
  Comments: "",
  ID,
});

export const diseasesOriginalData = {
  PID: null,
  PregIndex: null,
  Name: "",
  Comments: "",
  ID: null,
};

export const createSurgeriesNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Year: "",
  Name: "",
  Description: "",
  ID,
});

export const surgeriesOriginalData = {
  PID: null,
  PregIndex: null,
  Year: "",
  Name: "",
  Description: "",
  ID: null,
};

export const createMedicationsNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Name: "",
  Dosage: "",
  Comments: "",
  ID,
});

export const medicationsOriginalData = {
  PID: null,
  PregIndex: null,
  Name: "",
  Dosage: "",
  Comments: "",
  ID: null,
};

export const createSensitivitiesNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Name: "",
  Comments: "",
  ID,
});

export const sensitivitiesOriginalData = {
  PID: null,
  PregIndex: null,
  Name: "",
  Comments: "",
  ID: null,
};

export const createHabitsNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Type: "",
  Comments: "",
  ID,
});

export const habitsOriginalData = {
  PID: null,
  PregIndex: null,
  Type: "",
  Comments: "",
  ID: null,
};

export const createTestsNewData = (PID: number, PregIndex: number, ID: string) => ({
  PID,
  PregIndex,
  Name: "",
  Comments: "",
  ID,
});

export const testsOriginalData = {
  PID: null,
  PregIndex: null,
  Name: "",
  Comments: "",
  ID: null,
};
