<div class="t-popup popup-patientDetails" style='display: block;'>
    <div *ngIf="showPopup" class="t-popup popup-patientDetails" style='display: block'>
        <div class="t-popup-frame error-w">
            <div class="t-popup-body">

                <button class="t-btn-popup-close" style="position: absolute; right: 0; top: 0; margin: 0.4em;"
                    aria-label="close popup" (click)="togglePrintPopup()">
                </button>
                <div style="display:flex; align-items: center; justify-content: space-between; margin-top: 1">
                    <button class="print-all-btn" style="min-width:7.9em;" matTooltip="Print All"
                        (click)="printCalForm(1); togglePrintPopup();">
                        Print All
                    </button>
                    <button class="print-filled-btn" style="min-width:7.9em; margin: 0" matTooltip="Print Non Empty"
                        (click)="printCalForm(0); togglePrintPopup();">
                        Print Non Empty
                    </button>
                    <button class="print-filled-btn" style="min-width:7.9em; margin: 0" matTooltip="Cancel"
                        (click)="togglePrintPopup();">
                        Cancel
                    </button>
                </div>

            </div>
        </div>

    </div>

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame" style="max-width: 60em;">
        <div class="t-popup-body" style="padding: 1.12em 1.06em 1.12em 1.06em;">
            <header class="l-header" style="position: initial;padding: 0;height: fit-content;width: 100%;display: flex;flex-direction: row-reverse;justify-content: space-between;">
                <button class="t-btn-popup-close" aria-label="close popup" style="position: relative;top: auto;right: auto;" (click)="closed.emit();"></button>
                <div class="l-header-panel" style="padding: 0;background: transparent;height: inherit;">
                    <nav class="header-menu" style="align-items: center;">
                        <span (click)="togglePrintPopup()" class="header-menu-link icon-print" aria-label="Print" matTooltip="Print" style="
                        margin-left: 0.5em; cursor: pointer;filter: invert(39%) sepia(8%) saturate(4116%) hue-rotate(143deg) brightness(60%) contrast(78%);" ></span>
                       <button (click)="ce.saveCalendar$.next(true);" matTooltip="Save" class="advanced-search-btn" type="button">
                           Save
                       </button>
                    </nav>
                    <button class='login-form-submit t-btn-grad' style="width: 30%;height: 2em;" (click)="cf.setDefaults()" *ngIf="! cf.calForm.get('copied').value && cf.Pat.ID !='default' && cf.Defaults ">Apply Defaults</button>

                </div>
            </header>
            <section class="m-box" style="margin-top: 10px;padding-top: 0;" *ngIf="cf.Pat.ID !='default'">
                        
                <div class="pregnancy-menstrual">
        
                    <!-- row (start) -->
                    <div class="l-flex-c menstrual-row menstrual-row--LMP">
        
                        <label class="l-fg1 menstural-label" id="menstural_LMP">
                            L.M.P.
                        </label>
        
                        <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">
        
                            <input [matDatepicker]="LMPDatepicker" [(ngModel)]="cf.LMP" (dateChange)="checklmp()"
                                class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP" style="height: auto;background-color:#e4eded"
                                [max]="lmpmax" [min]="lmpmin" [ngClass]="invalidlmp">
                            <div class="t-validation-message" style="right:12em;top:70%;left: auto;">
                                <span>Invalid LMP</span>
                            </div>
                            <img src="../../assets/icons/date.svg" (click)="LMPDatepicker.open()"
                                style='margin-left: -3em'>
                            <mat-datepicker #LMPDatepicker></mat-datepicker>
                            <img src='../../assets/icons/x.svg' class='clear-lmp' matTooltip="Clear"
                                (click)="resetLMP()" *ngIf="! es.isClosed">
                            <span class="t-field menstural-field menstural-info">{{getLmpGA()}}</span>
                        </span>
        
                    </div>
                    <!-- row (end) -->
                    <div class="l-flex-c menstrual-row menstrual-row--EDD">

                        <label class="l-fg1 menstural-label" id="menstural_LMP">
                            E.D.D
                        </label>
        
                        <span class="l-flex t-boxMargin menstural-fields-set">
        
                            <span  class="t-field menstural-field menstural-info">{{cf.edd(false, '', (getLmpGA() == ''))}}</span>
                        </span>
        
                    </div>
        
                </div>
        
            </section>
            <div class="calendar-box w100 m-box summary" [formGroup]="cf.calForm">
                <div class="d-calender w100">
                    <div class="d-calender-box w100">
                        <span class="d-select" (click)="trimester = 'first'" [ngClass]="trimester == 'first' ? 'selected' : ''">
                            <strong>
                                1st Trimester
                            </strong>
                        </span>
                        <span class="d-select" (click)="trimester = 'second'" [ngClass]="trimester == 'second' ? 'selected' : ''">
                            <strong>
                                2nd Trimester
                            </strong>
                            </span>
                        <span class="d-select" (click)="trimester = 'third'" [ngClass]="trimester == 'third' ? 'selected' : ''">
                            <strong>
                                3rd Trimester
                            </strong>
                        </span>
                    </div>
                </div>
                <div class="d-calender w100" >
                    <div class="w100" *ngIf="trimester == 'first'">
                        <div class="w100">
                            <div class="d-calendar w100">
                                <span class="d-sp-calendar" style="width: 10%;">Month</span>
                                <span class="d-sp-calendar" style="width: 12%;">Week</span>
                                <span class="d-sp-calendar"
                                    style="width: 28%; display: flex; justify-content: center; align-items: center;">
                                    Exam Name &nbsp;
                                    <div class="search-help custom-tooltip" style="margin-top: 0;">
                                        <div class="tooltip-text">
                                            The pull down menu has a permanent list of all available obstetric exams.
                                            <br>
                                            To add a new line at the bottom just write a new text which we automatically
                                            save.
                                            <br>
                                            To delete an added line just press right click after line selection.
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">Comments</span>
                            </div>
                            <div class="d-calendar w100" *ngFor="let month of cf.triArray[0].months;index as i">
                                <span class="d-sp-calendar" style="width: 10%;">
                                    <div class="h100 d-sp-w-cal" style="border: none;" >
                                        <div class="h100">{{month.name}}</div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 12%;display: grid">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as j" [ngClass]="j+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <div style="place-self: center;">
                                                {{cf.getWeek(month.name, j, 'first')}}
                                            </div>
                                            <div style="place-self: center;" *ngIf="(es.pregnancies && es.pregnancies.length > 0 && es.pregnancies[0].LMP ) || cf.LMP">
                                                {{cf.getDateByWeek(cf.getWeek(month.name, j, 'first'), cf.LMP) |  date:"dd/MM/yyyy"}}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 28%;display: grid;">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <app-geninput [form]="cf.calForm.get('week'+cf.getWeek(month.name, k))" style="width: 12em; transform: translateX(-0.5em);"></app-geninput>
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">
                                    <div class="t-td-calendar" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="cervical-row" style="margin: 5px;">
                                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed" (click)="atS.showAuto(
                                                cf.calForm.get('week'+cf.getWeek(month.name, k)+'Comment'),
                                                'week '+cf.getWeek(month.name, k)+' Comment', 
                                                firstTriID)">
                                                Auto Text
                                            </button>
                                            <textarea class="l-textarea t-field--limitRad w100 h100" [formControlName]="'week'+cf.getWeek(month.name, k)+'Comment'"  aria-label="Additional comment"
                                            placeholder="Comment"></textarea>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="cervical-row" style="margin: 5px 0;">
                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed"
                            (click)="atS.showAuto(cf.calForm.get('firstTri'),'First Trimester ',1200)">Auto
                            Text</button>
                            <textarea class="l-textarea t-field--limitRad w100 h100" aria-label="Additional comment" formControlName="firstTri"
                            placeholder="Comment for 1st Trimester"></textarea>
                        </div>
                    </div>
                    <div class="w100" *ngIf="trimester == 'second'">                
                        <div class="w100">
                            <div class="d-calendar w100">
                                <span class="d-sp-calendar" style="width: 10%;">Month</span>
                                <span class="d-sp-calendar" style="width: 12%;">Week</span>
                                <span class="d-sp-calendar"
                                    style="width: 28%; display: flex; justify-content: center; align-items: center;">
                                    Exam Name &nbsp;
                                    <div class="search-help custom-tooltip" style="margin-top: 0;">
                                        <div class="tooltip-text">
                                            The pull down menu has a permanent list of all available obstetric exams.
                                            <br>
                                            To add a new line at the bottom just write a new text which we automatically
                                            save.
                                            <br>
                                            To delete an added line just press right click after line selection.
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">Comments</span>
                            </div>
                            <div class="d-calendar w100" *ngFor="let month of cf.triArray[1].months;index as i">
                                <span class="d-sp-calendar" style="width: 10%;">
                                    <div class="h100 d-sp-w-cal" style="border: none;" >
                                        <div class="h100">{{month.name}}</div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 12%;display: grid">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as j" [ngClass]="j+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <div style="place-self: center;">
                                                {{cf.getWeek(month.name, j, 'second')}}
                                            </div>
                                            <div style="place-self: center;" *ngIf="(es.pregnancies && es.pregnancies.length > 0 && es.pregnancies[0].LMP) || cf.LMP ">
                                                {{cf.getDateByWeek(cf.getWeek(month.name, j,'second'), cf.LMP) |  date:"dd/MM/yyyy"}}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 28%;display: grid;">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <app-geninput [form]="cf.calForm.get('week'+cf.getWeek(month.name, k))"
                                                style="width: 12em; transform: translateX(-0.5em);"></app-geninput>
                                            <!-- <select class="t-field t-select cald-select" style="align-self: center;height: 30%;text-align-last: center;" [formControlName]="'week'+cf.getWeek(month.name, k)" >
                                                <option></option>
                                                <option *ngFor="let exam of OB" [value]="exam">{{exam}}</option>
                                            </select> -->
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">
                                    <div class="t-td-calendar" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="cervical-row" style="margin: 5px;">
                                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed" (click)="atS.showAuto(
                                                cf.calForm.get('week'+cf.getWeek(month.name, k)+'Comment'),
                                                'week '+cf.getWeek(month.name, k)+' Comment',
                                                secondTriID)">
                                                Auto Text
                                            </button>
                                            <textarea class="l-textarea t-field--limitRad w100 h100" [formControlName]="'week'+cf.getWeek(month.name, k)+'Comment'"  aria-label="Additional comment"
                                            placeholder="Comment"></textarea>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="cervical-row" style="margin: 5px 0;">
                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed"
                            (click)="atS.showAuto(cf.calForm.get('secondTri'),'Second Trimester ',1100)">Auto
                            Text</button>
                            <textarea class="l-textarea t-field--limitRad w100 h100" aria-label="Additional comment" formControlName="secondTri"
                            placeholder="Comment for 2nd Trimester"></textarea>
                        </div>
                    </div>
                    <div class="w100" *ngIf="trimester == 'third'">                        
                        <div class="w100">
                            <div class="d-calendar w100">
                                <span class="d-sp-calendar" style="width: 10%;">Month</span>
                                <span class="d-sp-calendar" style="width: 12%;">Week</span>
                                <span class="d-sp-calendar"
                                    style="width: 28%; display: flex; justify-content: center; align-items: center;">
                                    Exam Name &nbsp;
                                    <div class="search-help custom-tooltip" style="margin-top: 0;">
                                        <div class="tooltip-text">
                                            The pull down menu has a permanent list of all available obstetric exams.
                                            <br>
                                            To add a new line at the bottom just write a new text which we automatically
                                            save.
                                            <br>
                                            To delete an added line just press right click after line selection.
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">Comments</span>
                            </div>
                            <div class="d-calendar w100" *ngFor="let month of cf.triArray[2].months;index as i">
                                <span class="d-sp-calendar" style="width: 10%;">
                                    <div class="h100 d-sp-w-cal" style="border: none;" >
                                        <div class="h100">{{month.name}}</div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 12%;display: grid">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as j" [ngClass]="j+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <div style="place-self: center;">
                                                {{cf.getWeek(month.name, j, 'third')}}
                                            </div>
                                            <div style="place-self: center;" *ngIf="(es.pregnancies && es.pregnancies.length > 0 && es.pregnancies[0].LMP) || cf.LMP ">
                                                {{cf.getDateByWeek(cf.getWeek(month.name, j,'third'), cf.LMP) |  date:"dd/MM/yyyy"}}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 28%;display: grid;">
                                    <div class="d-sp-w-cal" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="h100">
                                            <app-geninput [form]="cf.calForm.get('week'+cf.getWeek(month.name, k))" style="width: 12em; transform: translateX(-0.5em);"></app-geninput>
                                            <!-- <select class="t-field t-select cald-select" style="align-self: center;height: 30%;text-align-last: center;" [formControlName]="'week'+cf.getWeek(month.name, k)" >
                                                <option></option>
                                                <option *ngFor="let exam of OB" [value]="exam">{{exam}}</option>
                                            </select> -->
                                        </div>
                                    </div>
                                </span>
                                <span class="d-sp-calendar" style="width: 50%;">
                                    <div class="t-td-calendar" *ngFor="let _ of [].constructor(month.weeks);index as k" [ngClass]="k+1 == month.weeks ? 'brd-none' : ''">
                                        <div class="cervical-row" style="margin: 5px;">
                                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed" (click)="atS.showAuto(
                                                cf.calForm.get('week'+cf.getWeek(month.name, k)+'Comment'),
                                                'week '+cf.getWeek(month.name, k)+' Comment',
                                                thirdTriID)">
                                                Auto Text
                                            </button>
                                            <textarea class="l-textarea t-field--limitRad w100 h100" [formControlName]="'week'+cf.getWeek(month.name, k)+'Comment'"  aria-label="Additional comment"
                                            placeholder="Comment"></textarea>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="cervical-row" style="margin: 5px 0;">
                            <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed"
                            (click)="atS.showAuto(cf.calForm.get('thirdTri'),'Third Trimester ',1000)">Auto
                            Text</button>
                            <textarea class="l-textarea t-field--limitRad w100 h100" aria-label="Additional comment" formControlName="thirdTri"
                            placeholder="Comment for 3rd Trimester"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cervical-row" style="margin: 5px 0;" [formGroup]="cf.calForm">
                <button class="t-btn-edit btn-calendar" *ngIf="! es.isClosed"
                (click)="atS.showAuto(cf.calForm.get('plannerComment'),'Planner Comment ',1000)">Auto
                Text</button>
                <textarea class="l-textarea t-field--limitRad w100 h100" aria-label="Additional comment" formControlName="plannerComment"
                placeholder="Comments"></textarea>
            </div>
        </div>
    </div>
</div>
<app-autotext *ngIf="atS.Display"></app-autotext>
