<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up" aria-label="Back to History" matTooltip="Back to History">Follow Up</button> /
        Screening
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close Screening"
          matTooltip="Close Screening"
        ></a>
      </nav>
    </div>
  </header>
  <!-- ============ Header (End) ============ -->

  <main class="l-deashboard">
    <div class="h100 l-flex l-flex-w t-boxMargin--spacebyPadding">
      <div style="display: flex; gap: 1em" class="fetus-selector">
        <button
          *ngFor="let num of getFetusesToRender()"
          (click)="selectedInstanceNumber = num"
          [class.selected]="selectedInstanceNumber === num"
        >
          Fetus {{ num }}
        </button>
      </div>

      <!-- ================ NT (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">NT</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> NT </span>
              </th>
              <th class="th-clear">
                <span> CRL </span>
              </th>
              <th class="th-clear">
                <span> Risk </span>
              </th>
              <th class="th-clear">
                <span> PAPP-A </span>
              </th>
              <th class="th-clear">
                <span> Free Beta </span>
              </th>
              <th class="th-clear">
                <span> Combined Risk </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let ntScan of getFilteredScreeningNT()" [ngClass]="ntScan.isEditing ? 'tr-isActive' : ''">
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="ntScanDatePicker"
                      [min]="today"
                      [(ngModel)]="ntScan.Date"
                      [disabled]="!ntScan.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #ntScanDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!ntScan.isEditing ? 'disabled' : ''"
                      (click)="ntScanDatePicker.open()"
                    />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.GA"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.NT"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'NT'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.CRL"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'CRL'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.Risk"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'Risk'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.PAPPA"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'PAPPA'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.FreeBeta"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'FreeBeta'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="ntScan.CombinedRisk"
                  [disabled]="!ntScan.isEditing"
                  [inputName]="'CombinedRisk'"
                  [tableName]="'fuScreeningNT'"
                  [isEditing]="ntScan.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!ntScan.isEditing" (click)="editRow(ntScan)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="ntScan.isEditing"
                    (click)="
                      updateRow('fuScreeningNT', ntScan, [
                        'Date',
                        'GA',
                        'NT',
                        'CRL',
                        'Risk',
                        'PAPPA',
                        'FreeBeta',
                        'CombinedRisk'
                      ])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuScreeningNT', ntScan)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.screeningNTNewData.Date"
                      [matDatepicker]="scanNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #scanNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="scanNewDatePicker.open()" />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.NT"
                  [inputName]="'NT'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.CRL"
                  [inputName]="'CRL'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.Risk"
                  [inputName]="'Risk'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.PAPPA"
                  [inputName]="'PAPPA'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.FreeBeta"
                  [inputName]="'FreeBeta'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningNTNewData.CombinedRisk"
                  [inputName]="'CombinedRisk'"
                  [tableName]="'fuScreeningNT'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.screeningNTNewData.Date ||
                    !followUpService.screeningNTNewData.GA ||
                    !followUpService.screeningNTNewData.NT ||
                    !followUpService.screeningNTNewData.CRL ||
                    !followUpService.screeningNTNewData.Risk ||
                    !followUpService.screeningNTNewData.PAPPA ||
                    !followUpService.screeningNTNewData.FreeBeta ||
                    !followUpService.screeningNTNewData.CombinedRisk
                  "
                  (click)="saveNewRow('fuScreeningNT', followUpService.screeningNTNewData, screeningNTOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================  NT (End) ================ -->

      <!-- ================ 2nd Trimester Screening (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">2nd Trimester Screening</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> AFP </span>
              </th>
              <th class="th-clear">
                <span> Free Beta </span>
              </th>
              <th class="th-clear">
                <span> uE3 </span>
              </th>
              <th class="th-clear">
                <span> Inhibin </span>
              </th>
              <th class="th-clear">
                <span> Risk </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr
              *ngFor="let secondScan of getFilteredScreening2nd()"
              [ngClass]="secondScan.isEditing ? 'tr-isActive' : ''"
            >
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [matDatepicker]="secondScanDatePicker"
                      [(ngModel)]="secondScan.Date"
                      [min]="today"
                      [disabled]="!secondScan.isEditing"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #secondScanDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      [ngClass]="!secondScan.isEditing ? 'disabled' : ''"
                      (click)="secondScanDatePicker.open()"
                    />
                  </span>
                </div>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.GA"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'GA'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.AFP"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'BPD'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.FreeBeta"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'HC'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.UE3"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'AC'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.Inhibin"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'FL'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="secondScan.Risk"
                  [disabled]="!secondScan.isEditing"
                  [inputName]="'FL'"
                  [tableName]="'fuScreening2nd'"
                  [isEditing]="secondScan.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!secondScan.isEditing" (click)="editRow(secondScan)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="secondScan.isEditing"
                    (click)="
                      updateRow('fuScreening2nd', secondScan, [
                        'Date',
                        'GA',
                        'AFP',
                        'FreeBeta',
                        'UE3',
                        'Inhibin',
                        'Risk'
                      ])
                    "
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuScreening2nd', secondScan)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <div class="search-patient-field t-field w100" style="border-radius: 20px">
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      [(ngModel)]="followUpService.measurementsNewData.Date"
                      [matDatepicker]="secondScanNewDatePicker"
                      [min]="today"
                      class="t-field-text w100"
                    />
                    <mat-datepicker #secondScanNewDatePicker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img
                      class="calendar-icon"
                      src="../../assets/icons/date.svg"
                      (click)="secondScanNewDatePicker.open()"
                    />
                  </span>
                </div>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.GA"
                  [inputName]="'GA'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.AFP"
                  [inputName]="'AFP'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.FreeBeta"
                  [inputName]="'FreeBeta'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.UE3"
                  [inputName]="'UE3'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.Inhibin"
                  [inputName]="'Inhibin'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screening2ndNewData.Risk"
                  [inputName]="'Risk'"
                  [tableName]="'fuScreening2nd'"
                ></app-free-text-select>
              </td>

              <td>
                <button
                  [disabled]="
                    !followUpService.screening2ndNewData.Date ||
                    !followUpService.screening2ndNewData.GA ||
                    !followUpService.screening2ndNewData.AFP ||
                    !followUpService.screening2ndNewData.FreeBeta ||
                    !followUpService.screening2ndNewData.UE3 ||
                    !followUpService.screening2ndNewData.Inhibin ||
                    !followUpService.screening2ndNewData.Risk
                  "
                  (click)="saveNewRow('fuScreening2nd', followUpService.screening2ndNewData, screening2ndOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ 2nd Trimester Screening (End) ================ -->

      <!-- ================ Risk (Start) ================ -->
      <div class="w100 summary-recommendations" style="height: 100%">
        <section class="m-box fulltext" style="height: 100%">
          <h2 class="weightAssTable-title">Risks</h2>
          <table>
            <tr>
              <th class="th-clear">
                <span> Calculated By </span>
              </th>
              <th class="th-clear">
                <span> Risk </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>

              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <!-- ======== Existing Data Row (Start) ======== -->
            <tr *ngFor="let risk of getFilteredScreeningRisks()" [ngClass]="risk.isEditing ? 'tr-isActive' : ''">
              <td>
                <app-free-text-select
                  [(inputValue)]="risk.CalculatedBy"
                  [disabled]="!risk.isEditing"
                  [inputName]="'CalculatedBy'"
                  [tableName]="'fuScreeningRisks'"
                  [isEditing]="risk.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="risk.Risk"
                  [disabled]="!risk.isEditing"
                  [inputName]="'Risk'"
                  [tableName]="'fuScreeningRisks'"
                  [isEditing]="risk.isEditing"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="risk.Comments"
                  [disabled]="!risk.isEditing"
                  [inputName]="'Comments'"
                  [tableName]="'fuScreeningRisks'"
                  [isEditing]="risk.isEditing"
                ></app-free-text-select>
              </td>

              <td class="edit-column">
                <div style="display: flex; justify-content: center; gap: 1em">
                  <button *ngIf="!risk.isEditing" (click)="editRow(risk)">
                    <img src="../../assets/icons/edit.svg" class="edit-icon" />
                  </button>
                  <button
                    class="orange-btn"
                    *ngIf="risk.isEditing"
                    (click)="updateRow('fuScreeningRisks', risk, ['CalculatedBy', 'Risk', 'Comments'])"
                  >
                    <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                  </button>
                  <button (click)="deleteRow('fuScreeningRisks', risk)">
                    <img src="../../assets/icons/del.svg" class="edit-icon" />
                  </button>
                </div>
              </td>
            </tr>
            <!-- ======== Existing Data Row (End) ======== -->

            <!-- ======== Add New Row (Start) ======== -->
            <tr>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningRisksNewData.CalculatedBy"
                  [inputName]="'CalculatedBy'"
                  [tableName]="'fuScreeningRisks'"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningRisksNewData.Risk"
                  [inputName]="'Risk'"
                  [tableName]="'fuScreeningRisks'"
                ></app-free-text-select>
              </td>

              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.screeningRisksNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuScreeningRisks'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  [disabled]="
                    !followUpService.screeningRisksNewData.CalculatedBy ||
                    !followUpService.screeningRisksNewData.Risk ||
                    !followUpService.screeningRisksNewData.Comments
                  "
                  (click)="
                    saveNewRow('fuScreeningRisks', followUpService.screeningRisksNewData, screeningRisksOriginalData)
                  "
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
            <!-- ======== Add New Row (End) ======== -->
          </table>
        </section>
      </div>
      <!-- ================ Risk (End) ================ -->
    </div>
  </main>
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
