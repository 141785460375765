import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { PatientsService } from '../../patients.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input('tz') tz: string;
  @Input('awlid') awlid: number;
  @Input('idtype') idtype: string;
  @Input('exp') exp:string;
  @Output() closed = new EventEmitter<null>();
  name: string;
  approved: boolean;
  payment: number;
  macabipay: boolean;
  note: string;
  code: string;
  show=false;
  
  constructor(private ps: PatientsService) { }

  ngOnInit() {
    this.ps.getPayment(this.awlid).subscribe((data)=>{
      this.name = data['SurnameHeb']+ ' ' + data['FamilyNameHeb'];
      if(data["HMOcovered"] !== null){
        this.show=true;
        this.approved = data['HMOcovered'] == 1;
        this.payment = data['PatPayment'];
        this.macabipay = data['CollectAtClinic'] === 0;
      }
      else{
        this.ps.macabiLookup(this.tz,this.idtype, this.awlid, this.exp).subscribe((data)=>{
          this.show=true;
          if(data['Eligibility'][0].hasOwnProperty('IsEligible'))
            this.approved = data['Eligibility'][0].IsEligible[0] != "false";
          if(data['EligibilityMessages'] && data['EligibilityMessages'][0]["Message"] && data['EligibilityMessages'][0]["Message"][0]["ServiceProviderMessage"]){
            this.note = data['EligibilityMessages'][0]["Message"][0]["ServiceProviderMessage"][0];
            if(data['EligibilityMessages'][0]["Message"][0]["EligibilityResultCode"])
              this.code = data['EligibilityMessages'][0]["Message"][0]["EligibilityResultCode"][0];
          }
          if(data["Member"] && data["Member"][0]){
            this.name = data["Member"][0]["FirstName"][0] + " " + data["Member"][0]["LastName"][0];
            this.macabipay = data["Member"][0]["PaymentType"] == "2";
          }
          if(data["Deductible"])
            this.payment = data["Deductible"][0]["Amount"][0];
          this.ps.savePaymentAWL(this.awlid,{'approved': this.approved,payment: this.payment, 'macabipay': this.macabipay, note: this.note}).subscribe((data)=>{},
          (error)=>{
            console.log(error);
          })

        },(error)=>{
          console.log(error);
        })
      }
    },(error)=>{
      console.log(error);
    });
  }

}
