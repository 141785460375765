import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutotextService } from '../autotext.service';
import { ExamsService } from '../exams.service';
import { FetalechoService } from '../fetalecho.service';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit, OnDestroy {
  
  comment;
  @Input('data') data;
  @Input('ATiD') ATiD;
  @Input('form') form:UntypedFormGroup;
  @Input('type') type;
  @Output() closed = new EventEmitter<string>();
  @ViewChild("CommentsExtra") ref: ElementRef;
  myField:UntypedFormControl;
  destroy$: Subject<null> = new Subject();

  constructor(public fes:FetalechoService, public atS:AutotextService, public es:ExamsService) { 
    this.myField = new UntypedFormControl()
  }

  ngOnInit() {
    this.myField.setValue(this.data)
    
    this.myField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(()=>{
      this.form.get(this.type).patchValue(this.myField.value)
    })
  }
  ngAfterViewInit() : void {
    setTimeout(()=>{this.ref.nativeElement.focus()},1);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  close(toclose){
    this.closed.emit(toclose ? '1': null);
  }

  focusF(editor){
    console.log(this);
    
    editor.focus();
  }

}
