<!-- measurment unit (start) TEMPORARILY CLONED BY JS TO SAVE MAINTANANCE TIME see script below -->
<section [ngClass]="mvClass" class="measurment-unit-h">
    <div *ngIf="noSexWarn" style="position: absolute;color: #DCB16B; font-size: large;">You must set the fetal sex in Urology in the Anatomy section before using Dolberg</div>
    <div class="measurment-unit-in" [ngClass]="es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo' ? 'fetal-unit' : '' ">
        
        <div class="mearurment-unit-vim">
            
            <header class="measurment-unit-top" [ngClass]="es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo' ? (MyMeasure.name ==='PEEFF'? 'peef' :'padd') : ''">
                <div class="measurment-unit-dim" *ngIf="MyMeasure.name ==='PEEFF'" style="margin-left: -1em;">
                    <span>
                        <small>Min</small>
                        <input *ngIf="Editable && !es.isClosed" type="number" class="nval" min="{{MyMeasure.GAmin}}" max="{{MyMeasure.GAmax}}" (focus)="editing=true" [formControl]="measureCMIN" (blur)="finishEdit()">
                        {{Editable && !es.isClosed ? '': MyMeasure.value}}
                        <small>{{Editable? 'cm': ''}}</small>
                    </span>
                </div>
                <div *ngIf="editing && Editable && this.es.ExamType !=='Fetal Echo' && es.ExamType !== 'Pediatric Echo'" > <span [ngClass]="minWarn" title="The minimal value on the {{MyMeasure.GAfig}} GA table is {{MyMeasure.GAmin}}">min:{{MyMeasure.GAmin}}</span> | 
                    <span [ngClass]="maxWarn" title="The maximal value on the {{MyMeasure.GAfig}} GA table is {{MyMeasure.GAmax}}">max:{{MyMeasure.GAmax}}</span></div>
                <h2 class="measurment-unit-title" [ngClass]=" es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo' ? 'fetal-title' : ''">{{getBaseName()}}</h2>
                <dt class="l-td-m measurment-cell measurment-unit-key" *ngIf="MyMeasure.name.indexOf('EFW') > -1">
                    by {{MyMeasure.WeightType}}
                    <div class='measurment-switch' >
                            <div class="on-hoverPanel measurment-listHold">
                                <div class="on-hoverPanel-body">
                                    <ul class="ul-norm measurment-list">
                                            <li *ngFor="let fwfig of weightopts" (click)="setEFW(fwfig.name+' '+fwfig.desc)" class="measurment-list-item">
                                                    {{fwfig.name+" "+fwfig.desc}} <img *ngIf="fwfig==MyMeasure.WeightType" src="../../assets/icons/v.svg">
                                            </li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                </dt>
                <div class="measurment-unit-dim" [ngClass]="MyMeasure.name ==='PEEFF' ? '' :(es.ExamType === 'Fetal Echo' ||  es.ExamType === 'Pediatric Echo' ? 'elipse': '')">
                    <span [ngStyle]="{'color': different ? 'white' : 'red'}">
                        <small *ngIf="MyMeasure.name ==='PEEFF'">Max</small>
                        <input [ngStyle]="{'color': different ? 'white' : 'red'}" *ngIf="Editable && !es.isClosed" [ngClass]="outOfRange() ? 'is-invalid' : '' " type="number" class="nval" min="{{MyMeasure.GAmin}}" max="{{MyMeasure.GAmax}}" (focus)="editing=true" [formControl]="measureC" (blur)="finishEdit()"  #value>
                        {{Editable && !es.isClosed ? '': (MyMeasure.name.indexOf('EFW') > -1 ? rnd(MyMeasure.value ) :MyMeasure.value)}}
                        <div *ngIf="outOfRange()" class="t-validation-message">
                            <span style="font-size: 0.60em;">Out of range</span>
                        </div>
                        <small *ngIf="MyMeasure.name !=='IFI'">{{Editable ?  getMeasureStand() : (MyMeasure.name == "HCAC"? '': "g")}}</small>
                    </span>
                </div>
            </header>
            <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big peefCheck" *ngIf="MyMeasure.name ==='PEEFF'">

                <label>
    
                    <input type="radio" class="custom-check" [formControl]="peefSet"
                         [value]="1"/>
                    <i class="customInput-display"></i>
                    <span>
                        yes
                    </span>
    
                </label>
    
                <label>
    
                    <input type="radio" 
                        class="custom-check" [formControl]="peefSet" [value]="0"/>
                    <i class="customInput-display"></i>
                    <span>
                        no
                    </span>
    
                </label>
    
            </div>

            <!-- <dl class="l-table measurment-unit-data" *ngIf="es.ExamType === 'Fetal Echo'">
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                        <span [ngClass]="minWarn+maxWarn" *ngIf="haveZscore"> Z. Score</span>
                    </dt>
                </div>
            </dl> -->
            <dl class="l-table measurment-unit-data" *ngIf="es.ExamType !== 'Fetal Echo' && es.ExamType !== 'Pediatric Echo'">
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span [ngClass]="minWarn+maxWarn" >GA by {{MyMeasure.GAfig}}</span>

                        <div [ngClass]="MyMeasure.GA_opts.length > 1? 'measurment-switch' : ''">
                            <div class="on-hoverPanel measurment-listHold">
                                <div class="on-hoverPanel-body">
                                    <ul class="ul-norm measurment-list">
                                            <li *ngFor="let gafig of MyMeasure.GA_opts" (click)=setGA(gafig) [ngClass]="gafig==MyMeasure.GAfig?'is-checked':''" class="measurment-list-item">
                                                    {{gafig}}
                                             </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value">
                        <span class="measurment-unit-value-bubble" *ngIf="MyMeasure.GA"><i></i>
                            <span>
                                {{MyMeasure.GA}}
                            </span>

                        </span>
                    </dd>
                </div>
                <div class="l-row measurment-unit-row">
                    <dt class="l-td-m measurment-cell measurment-unit-key">
                            <span [ngClass]="ggwarn" [attr.title]="ggwarn ? 'The GA from LMP is outside of the bounds of this GG table' : undefined"> GG by {{MyMeasure.GGfig}} {{GG}}</span>

                        <div [ngClass]="MyMeasure.GG_opts.length > 1? 'measurment-switch' : ''">
                            <div class="on-hoverPanel measurment-listHold">
                                <div class="on-hoverPanel-body">
                                    <ul class="ul-norm measurment-list">
                                            <li *ngFor="let ggfig of ggFigun()" (click)=setGG(ggfig) class="measurment-list-item" [ngClass]="ggfig==MyMeasure.GGfig?'is-checked':''">
                                                    {{ggfig}}
                                             </li>
                                             <li (click)="setGG('none')" class="measurment-list-item" [ngClass]="'none'==MyMeasure.GGfig?'is-checked':''">
                                                none
                                             </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </dt>
                    <dd class="l-td-m measurment-cell measurment-unit-value" (mouseover)='toggleChart()'>
                        <div class="measurment-unit-value-bubble" *ngIf="(MyMeasure.GG || MyMeasure.GG === 0) && !es.isEitherLMPDataMissing"><i></i>
                            <span>
                                    {{MyMeasure.GG}}{{MyMeasure.showSD? 'SD': '%'}}
                            </span>

                            <div class="on-hoverPanel measurment-chartHold">
                                <div class="on-hoverPanel-body" width="300" height="300">
                                        <canvas *ngIf="showChart" baseChart [datasets]="chartData"
                                        [options]="lineChartOptions"
                                        [legend]="true"
                                        [chartType]="'scatter'"
                                    ></canvas>
                                </div>
                            </div>
                                
                        </div>
                    </dd>
                </div>
            </dl>
        </div>

    </div>

</section>