<div class="t-popup popup-graphs" style='display: block;height:100%'>

    <div class="popup-close"></div>
    
    <div class="popup-frame" [ngClass]="ls.isCardio ? 'boxWidth' :''">
        <div class="popup-body">
            <h2>{{gs.showSelect ? 'Select Images' : 'Gallery'}}</h2>
            <div class="graphs-container">
                <app-gallery style='width: 100%' [forprint]="false" [inbox]="inbox" (closed)="closeall.emit()"></app-gallery>
            </div>
            <button type="button" class="popup-close-button" (click)="closed.emit()">Close Gallery</button>
        </div>
    </div>
</div>