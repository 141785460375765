<!-- ==================================== POPUP : Consultation Letter (STARTS) ============================================== -->

<div *ngIf="!ls.ishospital">
  <form [formGroup]="consultation">
    <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
      <div class="w50">
        <div class="l-flex l-flex-c t-field t-field-pad">
          <label> Select title </label>

          <i class="t-sep"></i>

          <span class="t-validation-wrap l-fg1">
            <select
              class="t-select w100"
              #Title
              formControlName="Title"
              (change)="changeTitle()"
              [ngClass]="!consultation.get('Title').value ? 'is-invalid' : ''"
            >
              <option value="" >Please select a title</option>
              <option value="Consultation Letter">Consultation Letter</option>
              <option value="Gynecology Consult Letter">
                Gynecology Consult Letter
              </option>
              <option value="Obstetrics Consult Letter">
                Obstetrics Consult Letter
              </option>
              <option value="Visit Confirmation">Visit Confirmation</option>
              <option value="Visit Summary">Visit Summary</option>
              <option value="Summary And Visit Recommendation">
                Summary And Visit Recommendation
              </option>
              <option value="Medical Prescription">Medical Prescription</option>
            </select>
            <div class="t-validation-message">
              <span>Please select a title</span>
            </div>
          </span>
        </div>
      </div>
      <div class="w50">
        <div class="l-flex l-flex-c t-field t-field-pad">
          <label> Select template </label>

          <i class="t-sep"></i>

          <span class="t-validation-wrap l-fg1">
            <select
              class="t-select w100"
              name="selectedTemplate"
              #selected
              (change)="setTemplate(selected.value);showEdit=false"
            >
              <option  value="" >Select a template</option>
              <option
                *ngFor="let template of templates"
                [value]="template.TemplateID"
              >
                {{ template.Title }}

                <div></div>
              </option>
            </select>
          </span>
          
          <button *ngIf="selected.value && showEdit" (click)="showPopup=true" class="customInput-display w10"></button>
          <button *ngIf="selected.value" (click)="deleteTemplate(selected.value)" class="auto-text-delete-button">Delete</button>
      
        </div>
      </div>

      <div class="w100 h100 summary-anamnesis">
        <section
          class="m-box fulltext"
          style="padding-bottom: 0; padding-top: 1.5em"
        >
          <h2 class="title">{{ es.ExamType }}</h2>
          <div class="w40 edit-template">
            <input
              
              #titleValue
              name="titleValue"
              class="w20 template search-patient-field template-input"
              style="background-color: #e5eef0; color: #015a6c"
              placeholder="enter template title"
              type="text"
              (input)="checkTextChanged(selected.value,titleValue.value)"
            />
            <button
              [disabled]="checkTemplate(titleValue.value)"
              (click)="createTemplate(titleValue.value)"
              class="w20 template search-btn search-btn--newPatient"
            >
              Add Template
            </button>
          </div>
          <angular-editor
            class="scroller scroller-intro fulltext"
            formControlName="FormContent"
            style="width: 100%"
            [config]="editorConfig"
            (input)="checkTextChanged(selected.value)"
          >
          </angular-editor>
          <button
            class="t-btn-edit"
            (click)="
              atS.showAuto(
                consultation.get('FormContent'),
                'Consultation Letter',
                91
              )
            "
          >
            Auto Text
          </button>
        </section>
      </div>
    </div>
  </form>
  <div *ngIf="showPopup" class="t-popup popup-patientDetails" style='display: block;'>
    <div class="popup-frame">
        <div class="popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="showPopup=false"></button>

            <h2 style='color: D7A95B'>
                You are about to override your current template.
                <br>
                Are you sure you want to continue?
            </h2>
            <div>
                <button (click)="editTemplate(selected.value)">yes</button> 
                <button style='margin-left: 2em' (click)="showPopup=false">no</button>
            </div>

        </div>
    </div>

</div>
</div>

<div class="second-layer-popup">
  <div class="second-layer-popup-frame">
    <div class="second-layer-popup-body"></div>
  </div>
</div>
<app-autotext *ngIf="atS.Display"></app-autotext>

<!-- ===================================== POPUP : Consultation Letter (END)  =============================================== -->


<div *ngIf="ls.ishospital">

  <form [formGroup]="consultation">

      <div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">
          <div class="w100">

              <div class="l-flex l-flex-c t-field t-field-pad">

                  <label>
                      Type of Letter
                  </label>

                  <i class="t-sep"></i>

                  <span class="t-validation-wrap l-fg1">
                     
                      <select class="t-select w100" #Title formControlName="Title" [ngClass]="! consultation.get('Title').value? 'is-invalid':''">
                          <option [ngValue]="" disabled>Please select a title</option>
                          <option value="Consultation Letter">Consultation Letter</option>
                          <option value="Gynecology Consult Letter">Gynecology Consult Letter</option>
                          <option value="Obstetrics Consult Letter">Obstetrics Consult Letter</option>
                          <option value="Visit Confirmation">Visit Confirmation</option>
                          <option value="Visit Summary">Visit Summary</option>
                          <option value="Summary And Visit Recommendation">Summary And Visit Recommendation</option>
                          <option value="Medical Prescription">Medical Prescription</option>
                          
                      </select>
                      <div class="t-validation-message">
                          <span>Please select a title</span>
                      </div>
                  </span>

              </div>

          </div>
          <div class="w100 h100 summary-anamnesis">


              <section class="m-box fulltext" style="padding-bottom: 0;">
                  <h2>{{es.ExamType}}</h2>
                  <angular-editor class="scroller scroller-intro fulltext" formControlName="FormContent" style="width: 100%;" [config]="editorConfig">
                  </angular-editor>
                  <button class="t-btn-edit"
                      (click)="atS.showAuto(consultation.get('FormContent'),'Consultation Letter',91)">Auto
                      Text</button>
              </section>


          </div>
      </div>
  </form>
</div>

