import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Measure } from '../measurements/meaure';
import { MeasgrowthService } from '../measgrowth.service';
import { ExamsService } from '../exams.service';
import { Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/internal/operators";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { UntypedFormControl } from '@angular/forms';
import { FetalechoService } from '../fetalecho.service';
import { NtService } from '../nt.service';
import { LoginService } from '../login.service';
import { PregnancyService } from '../pregnancy.service';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeasureComponent implements OnInit, OnDestroy {

  @Input('MyMeasure') Mname: string;
  @Input('DisplayName') displayName: string;
  @Input () different=true
  MyMeasure: Measure;
  PeefSetMeasure: Measure;

  @Input("Editable") Editable: boolean;
  @Input("updated") updated: Observable<void>;
  @Input("isminmax") minmax: boolean = false; 
  @ViewChild('value') value: ElementRef;
  isCm:boolean;
  isFetalMeasure:boolean;
  mclass: string;
  mvClass: string;
  minWarn: string;
  maxWarn: string;
  ggwarn: string;
  noSexWarn = false;
  valMessage: string;
  GA: string;
  GG: number;
  GAfigure: string;
  GGfigure: string;
  showGA: boolean = false;
  showGG: boolean = false;
  showChart: boolean = false;
  is397: boolean = false;
  showEFW = false;
  haveZscore = false;
  chartData: ChartDataSets[] = [];
  chartColors: Color[];
  measureC: UntypedFormControl;
  measureCMIN: UntypedFormControl;
  measureCMAX: UntypedFormControl;
  peefSet: UntypedFormControl;
  isPEEFF: boolean = false;
  msubscription$: Subscription;
  sub :Subscription;
  updatesub$: Subscription;
  weightsub$: Subscription;
  peefCheck = -1;
  lineChartOptions: ChartOptions = {
    responsive: true, scales: {
      xAxes: [{
        type: 'linear',
        position: 'bottom',
        scaleLabel: { display: true, labelString: 'weeks' }
      }],
      yAxes: [{
        type: 'linear',
        scaleLabel: { display: true, labelString: 'mm' }
      }],

    },
    aspectRatio: 1,
  };


  //template doesn't have access if static - so putting it here
  public weightopts = [
    { name: "Hadlock1", "desc": "(AC,FL)" },
    { name: "Hadlock2", "desc": "(BPD,AC,FL)" },
    { name: "Hadlock3", "desc": "(HC,AC,FL)" },
    { name: "Hadlock4", "desc": "(BPD,HC,AC,FL)" },
  ];
  public cmMeasure = ["AOFlow","PULFlow","DASYSFlow","DADIASTFLOW","ISTHMUS"];
  public fetalEchoMeasure = ["PEEFF","TRC","MIT","AOR","PulmonaryV","MPASYS","MPADYAST","LPA","RPA","ASCAO","TrasArch","AOFlow","PULFlow","DASYSFlow","DADIASTFLOW"]
  public zScore = ["TrasArch","MPASYS","MPADYAST","LPA","RPA","AOFlow","PULFlow","DASYSFlow","DADIASTFLOW"]


  @ViewChild("inputToFocus", { static: true }) inputElement: ElementRef;
  editing = false;
  constructor(
    private measService: MeasgrowthService,
    public es: ExamsService,
    private fes: FetalechoService,
    public nt: NtService,
    private ls: LoginService,
    private pregnancyService: PregnancyService,
  ) {
    this.mclass = "mainNormal";
    this.mvClass = "is-stat3";
    this.maxWarn = "";
    this.minWarn = "";
    this.valMessage = "click to set/edit";
    this.measureC = new UntypedFormControl("");
    this.measureCMIN = new UntypedFormControl();
    this.measureCMAX = new UntypedFormControl();
    this.peefSet = new UntypedFormControl();

    this.msubscription$ = this.measService.loaded.subscribe(() => {
      try{this.value.nativeElement.blur();}
      catch{}//1st load
      this.measureC.setValue(this.measService.getMeas(this.Mname).value, { emitEvent: false });
      if(this.Mname === 'PEEFF')
        this.peefSet.setValue(this.measService.getMeas('PeefSet').value, {emitEvent: false});
      if(this.minmax)
        this.measureCMIN.setValue(this.measService.getMeasMin(this.Mname).valuemin, {emitEvent:false});
    });
    this.weightopts.push({name:'none', desc: ''})
  }

  ngOnInit() {
    this.sub = this.es.fetusChange$.subscribe(
      (data) => {
        this.measureC.setValue("", {emitEvent:false});
        this.measureCMIN.setValue("", {emitEvent:false});
        this.peefSet.setValue(-1, {emitEvent: false});
        
      }
    )
    this.MyMeasure = this.measService.getMeas(this.Mname);
    if(this.minmax)
      this.MyMeasure.hasMinMax = true;
    this.MyMeasure.setCompnent(this);
    this.isCm = this.cmMeasure.includes(this.Mname);
    this.haveZscore = this.zScore.includes(this.Mname)
    this.isFetalMeasure = this.fetalEchoMeasure.includes(this.Mname);
    if(this.MyMeasure.hasMinMax){
      this.PeefSetMeasure = this.measService.getMeas('PeefSet');
      this.measureCMIN.setValue(this.MyMeasure.valuemin, { emitEvent: false });
      
      // this.peefSet()
      this.measureCMIN.valueChanges.pipe(
        debounceTime(400))
        .subscribe(model => {
          this.MyMeasure.valuemin = this.measureCMIN.value;
          this.measService.saveMeas(this.MyMeasure.name + 'MIN', this.MyMeasure.valuemin, this.MyMeasure.Table);
          this.checkNum();
        });
        this.peefSet.valueChanges.pipe(debounceTime(400)).subscribe(model => {
          this.PeefSetMeasure.value = this.peefSet.value
          this.measService.saveMeas('PeefSet', this.PeefSetMeasure.value, 'meas')
          this.checkNum();
        })
    }
    // this.peefSet.setValue(this.PeefSetMeasure.value, {emitEvent:false})
    this.measureC.setValue(this.MyMeasure.value, { emitEvent: false });
    try { this.MyMeasure.GGfig = this.MyMeasure.GG_opts[0]; }//to start with
    catch {
      this.MyMeasure.GGfig = ""
    }
    try { this.MyMeasure.GAfig = this.MyMeasure.GA_opts[0]; }
    catch { this.MyMeasure.GAfig = "" }
    if (!this.Editable) {
      this.weightsub$ = this.measService.weightUpdated.subscribe(()=>{
        this.MyMeasure.calcGGTable(this.measService.getGALMP(), this.MyMeasure.GGfig);
        this.SDClass();
      });
    }
    this.measureC.valueChanges.pipe(
      debounceTime(400))
      .subscribe(model => {
        if((this.ls?.usMode|| this.ls.premissions?.usMode) && this.es.examsUsMode.includes(this.es.ExamType)){
        if (this.MyMeasure.value!= this.measureC.value ){
          this.different=false
        }
        else{
          this.different=true
        }
      }
        this.MyMeasure.value = this.measureC.value;
        this.measService.saveMeas(this.MyMeasure.name, this.MyMeasure.value, this.MyMeasure.Table);
        this.checkNum();
      });
    if (this.updated) {
      this.updatesub$ = this.updated.subscribe(() => {
        this.measureC.setValue(this.MyMeasure.value, { emitEvent: false });
        if(this.es.ExamType =='NT' || this.es.switchNT || this.es.addNT){
          if(this.MyMeasure.name == 'CRL'){
            this.MyMeasure.GAmin = 40
            this.MyMeasure.GAmax = 84
          }
          if(this.MyMeasure.name == 'NT')
          {
            this.MyMeasure.GAmin = 0.6
            this.MyMeasure.GAmax = 5
          }
        }
        
        if(this.MyMeasure.hasMinMax){
          this.measureCMIN.setValue(this.MyMeasure.valuemin, { emitEvent: false });
          this.peefSet.setValue(this.PeefSetMeasure.value, {emitEvent:false})
        }
      });
    }
    this.msubscription$ = this.es.titleChange.subscribe((data) =>{
      if(this.es.ExamType =='NT' || this.es.switchNT || this.es.addNT){
        if(this.MyMeasure.name == 'CRL'){
          this.MyMeasure.GAmin = 40
          this.MyMeasure.GAmax = 84
        }
        if(this.MyMeasure.name == 'NT')
        {
          this.MyMeasure.GAmin = 0.6
          this.MyMeasure.GAmax = 5
        }
      }
      
    })

    const LMP = this.pregnancyService.pregForm.get('LMP').value;
    const LMP_US = this.pregnancyService.pregForm.get('LMP_US').value;

    if (LMP || LMP_US) {
      this.es.isEitherLMPDataMissing = false;
      this.es.setEitherLMPDataMissing(false);
    } else {
      this.es.isEitherLMPDataMissing = true;
      this.es.setEitherLMPDataMissing(true);
    }
  }

  ngOnDestroy() {
    this.msubscription$.unsubscribe();
    // this.sub.unsubscribe()
    if (this.updatesub$)
      this.updatesub$.unsubscribe();
    if(this.weightsub$)
      this.weightsub$.unsubscribe();
  }

  toggleChart() {
    if(! this.MyMeasure.GG_tables || this.MyMeasure.GGfig == 'none')
      return;
    //for 3-10-50-90-97 tables
    if (this.MyMeasure.GG_tables[this.MyMeasure.GGfig].hasOwnProperty("type") && this.MyMeasure.GG_tables[this.MyMeasure.GGfig].type == "5-col")
      this.is397 = true;

    let myValues: ChartDataSets = {
      data: this.measService.getAllPoints(this.MyMeasure.name)/*[{
        x: Math.round((Measure.WeekToDays(this.measService.getGALMP())/7)*10)/10,
        y: this.MyMeasure.value
      }]*/,
      pointStyle: 'crossRot',
      hitRadius: 4,
      pointRadius: 10,
      borderWidth: 2,
      pointBorderColor: '#015B6F',
      pointHoverBorderColor: '#015B6F',
      backgroundColor: '#015B6F',
      borderColor: '#015B6F',
      label: 'Measured'
    };
    this.chartData = this.MyMeasure.getBoundaries(this.MyMeasure.GGfig, myValues);

    this.showChart = true;
    this.showGG = false;
  }

  typeMe(): void {
    if (!this.Editable)
      return;
    this.editing = true;
  };



  finishEdit(): void {
    this.editing = false;
  }

  getBaseName(): string {
    if (this.MyMeasure.displayName)
      return this.MyMeasure.displayName;
    let parts = this.MyMeasure.name.split(" ");
    return parts[0];
  }

  checkNum(): void {
    if (this.MyMeasure.value < this.MyMeasure.GAmin)
      this.minWarn = "warning";
    else
      this.minWarn = "";
    if (this.MyMeasure.value > this.MyMeasure.GAmax)
      this.maxWarn = "warning";
    else
      this.maxWarn = "";
    if (this.minWarn == "" && this.maxWarn == "")
      this.MyMeasure.calcGATable(this.MyMeasure.GAfig);
    else
      this.MyMeasure.GA = null;
    //this.MyMeasure.calcGGTable(this.GAlmp,this.MyMeasure.GGfig);
    this.measService.calcGGTable(this.MyMeasure.name, this.MyMeasure.GGfig);

    this.SDClass();

  }

  SDClass(): void {
    const LMP = this.pregnancyService.pregForm.get('LMP').value;
    const LMP_US = this.pregnancyService.pregForm.get('LMP_US').value;
    let isLMPMissing: boolean;
    if (LMP || LMP_US) {
      isLMPMissing = false;
    } else {
      isLMPMissing = true;
    }

    if (!this.MyMeasure.value) {
      this.mvClass = "is-stat3"
      return
    }
  
    if (!this.MyMeasure.GG && isLMPMissing) {
      return;
    }

    if (this.MyMeasure.GG && !isLMPMissing) {
      if (this.MyMeasure.GG > 96) {
        this.mvClass = "is-stat5";
        return;
      }
      if (this.MyMeasure.GG > 68) {
        this.mvClass = "is-stat4";
        return;
      }
      if (this.MyMeasure.GG > 32) {
        this.mvClass = "is-stat3";
        return;
      }
      if (this.MyMeasure.GG > 6) {
        this.mvClass = "is-stat2";
        return;
      }
      this.mvClass = "is-stat1";
    }
  }

  setGA(afig: string): void {
    try {
      this.MyMeasure.GAfig = afig;
      this.measService.saveMeas(this.MyMeasure.name.replace(' ','') + '_GAfig', afig, this.MyMeasure.Table);
      if(afig == 'none'){
        this.MyMeasure.GA = null;
        return;
      }
      if(this.MyMeasure.name.includes('EFW'))
        this.measService.calcGGTable('EFW',this.MyMeasure.GAfig);
      this.MyMeasure.GAmin = this.MyMeasure.GA_tables[afig].data[0][0] / 10;
      this.MyMeasure.GAmax = this.MyMeasure.GA_tables[afig].data[this.MyMeasure.GA_tables[afig].data.length - 1][0] / 10;
      this.checkNum();
    }
    catch { }//incase the tables don't exist
    setTimeout(() => this.showGA = false, 400);
  }

  setEFW(fig: string) {
    this.MyMeasure.WeightType = fig
    setTimeout(() => this.showEFW = false, 400);
    if(! fig.includes('none'))
      this.measService.calcGGTable("EFW", "");
    else
      this.MyMeasure.value = null;
    this.measService.saveMeas(this.MyMeasure.name.replace('EFW ', 'Weight') + 'Type', fig);
  }

  setGG(gfig: string): void {
    if (gfig.indexOf('fetal sex') > -1) {
      this.noSexWarn = true;
      setTimeout(() => this.noSexWarn = false, 4000);
      return;
    }
    this.MyMeasure.GGfig = gfig;
    this.measService.saveMeas(this.MyMeasure.name.replace(' ','') + '_GGfig', gfig, this.MyMeasure.Table);//replace to remove space in efw 1 and efw 2
    if(gfig == 'none'){
      this.MyMeasure.GG = null;
      this.ggwarn = null;
      return;
    }
    if(gfig == 'Spiegel-Yagel' && this.MyMeasure.name.includes('EFW'))
      this.setEFW('Hadlock3 (HC,AC,FL)');//Spiegel-yagel uses hadlock 3
    setTimeout(() => this.showGG = false, 400);
    try {//incase the table doesn't exist
      var isdeci = this.MyMeasure.GG_tables[gfig].hasOwnProperty("weeks") && this.MyMeasure.GG_tables[gfig].weeks == "decimal"
      if ((this.MyMeasure.GG_tables[gfig].hasOwnProperty('min_weeks') && (Measure.WeekToDays(this.measService.getGALMP()) / 7 < this.MyMeasure.GG_tables[gfig].min_weeks || Measure.WeekToDays(this.measService.getGALMP()) / 7 > this.MyMeasure.GG_tables[gfig].max_weeks))
        || ! this.MyMeasure.GG_tables[gfig].hasOwnProperty('min_weeks') && (Measure.WeekToDays(this.measService.getGALMP()) < (isdeci ? this.MyMeasure.GG_tables[gfig].data[0][0] * 7 : Measure.WeekToDays(this.MyMeasure.GG_tables[gfig].data[0][0])) ||
        Measure.WeekToDays(this.measService.getGALMP()) > (isdeci ? this.MyMeasure.GG_tables[gfig].data[this.MyMeasure.GG_tables[gfig].data.length - 1][0] * 7 : Measure.WeekToDays(this.MyMeasure.GG_tables[gfig].data[this.MyMeasure.GG_tables[gfig].data.length - 1][0])))) {
        this.ggwarn = "warning";
        this.MyMeasure.GG = null;
      }
      else {
        this.ggwarn = null;
        this.measService.calcGGTable(this.MyMeasure.name, this.MyMeasure.GGfig);
        this.SDClass();
      }
      
    }
    catch(e) {
      console.log(e); 
    }
  }

  getMeasureStand(){
    // console.log(this.Mname);
    
    if(this.Mname == 'DASYSFlow' || this.Mname == 'DADIASTFLOW')
      return 'm/s'
    if(this.isFetalMeasure){
      return this.isCm ? 'cm/s' : 'cm'
    }else{
      return 'mm'
    }

  }

  outOfRange(){
    if((this.es.ExamType == 'NT' || this.es.switchNT || this.es.addNT) && this.measureC.value){
      switch (this.Mname) {
        case 'CRL': case 'NT':
          if(this.measureC.value < this.MyMeasure.GAmin || this.measureC.value > this.MyMeasure.GAmax)
          return true;
          else
          return false;
          
          default:
            return false;
          }
    }else
      return false;
  }

  ggFigun(){
    let gg = [];
    for(let g of this.MyMeasure.GG_opts){
      if(g != 'none')
        gg.push(g);
    }
    return gg;
  }

  rnd(n:number){
    if(n)
      return Math.round(n);
    return n;
  }

}
