import { Component, OnInit } from "@angular/core";
import { FollowUpService } from "../follow-up.service";
import { PregnancyService } from "../pregnancy.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "../login.service";
import { PatientsService } from "../patients.service";
import { Patient } from "../patients/patient";
import { scansOriginalData, measurementsOriginalData } from "./data/data-storage";
import { Subscription } from "rxjs";

@Component({
  selector: "app-follow-up-scans",
  templateUrl: "./follow-up-scans.component.html",
  styleUrls: ["./follow-up-scans.component.scss"],
})
export class FollowUpScansComponent implements OnInit {
  public showPatientDetailsForm: boolean = false;
  public patient: Patient;
  // Is selected row in edit mode:
  public isEditing: boolean = false;

  private pregID: number;

  // Data structures: (For Resetting):
  public scansOriginalData = { ...scansOriginalData };
  public measurementsOriginalData = { ...measurementsOriginalData };

  // Data:
  public scansData = [];
  public measurementsData = [];

  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;

  today: Date = new Date();

  selectedInstanceNumber: number = 1;

  constructor(
    public followUpService: FollowUpService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
    this.followUpService.initializeNewData();

    this.patient = this.patientsService.getPat();
    this.pregID = this.pregnancyService.pregID;

    // Get the number of fetuses for the latest pregnancy:
    this.fetusNumberSub = this.followUpService.getFetusesNumber(this.pregID).subscribe((data) => {
      this.fetusesNumber = data;
    });

    // Get the obstetrical formula for the latest pregnancy:
    this.obsFormulaSub = this.followUpService.getObstetricalFormula(this.pregID).subscribe((data) => {
      this.obstetricalFormula = data;
    });

    this.getData("fuScans", "scansData");
    this.getData("fuMeasurements", "measurementsData");
  }

  ngOnDestroy() {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }

    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  async updateRow(table: string, rowData: any, columnsToUpdate: string[]) {
    try {
      const dataToUpdate = columnsToUpdate.reduce((obj, col) => {
        obj[col] = rowData[col];
        return obj;
      }, {});

      const result = await this.followUpService.updateData(table, dataToUpdate, rowData.AutoIndex);
      if (result["status"] === "ok") {
        rowData.isEditing = false;
      }
    } catch (error) {
      console.error("Error updating the row", error);
    }
  }

  async deleteRow(table: string, rowData: any) {
    try {
      const result = await this.followUpService.deleteData(table, rowData.AutoIndex);
      if (result["status"] === "ok") {
        switch (table) {
          case "fuScans":
            this.scansData = this.scansData.filter((scan) => scan !== rowData);
            break;
          case "fuMeasurements":
            this.measurementsData = this.measurementsData.filter((measurement) => measurement !== rowData);
            break;
          default:
            console.error(`Table ${table} not handled.`);
            break;
        }
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  }

  async saveNewRow(table: string = "", rowData: any, originalData: any = null) {
    try {
      rowData.InstanceNumber = this.selectedInstanceNumber;

      const result = await this.followUpService.saveData(table, rowData);

      if (result["status"] === "ok") {
        this.resetNewData(rowData, originalData);
        await this.handleDataFetch(table);
      }
    } catch (error) {
      console.error("Error during the save new row", error);
    }
  }

  async handleDataFetch(table: string) {
    switch (table) {
      case "fuScans":
        await this.getData("fuScans", "scansData");
        break;
      case "fuMeasurements":
        await this.getData("fuMeasurements", "measurementsData");
        break;
      default:
        console.error("Unknown table:", table);
    }
  }

  async getData(table: string, placementVariable: string, pregIndex: number = this.pregID) {
    const data = await this.followUpService.getData(table, pregIndex);
    this[placementVariable] = data["data"];
  }

  editRow(rowData: any) {
    rowData.isEditing = true;
  }

  resetNewData(newData: any, originalData: any) {
    const fieldsToPreserve = ["PID", "PregIndex", "ID"];

    for (const key in newData) {
      if (!fieldsToPreserve.includes(key)) {
        if (key === "Date") {
          newData[key] = new Date();
        } else if (key === "GA") {
          newData[key] = this.patient?.getGA() || "";
        } else {
          newData[key] = originalData && originalData[key] !== undefined ? originalData[key] : "";
        }
      }
    }
  }

  getFilteredScans(): any[] {
    return this.scansData.filter((scan) => scan.InstanceNumber === this.selectedInstanceNumber);
  }

  getFilteredMeasurements(): any[] {
    return this.measurementsData.filter((measure) => measure.InstanceNumber === this.selectedInstanceNumber);
  }

  getFetusesToRender(): number[] {
    const instanceNumbersFromData = [
      ...new Set([
        ...this.scansData.map((scan) => scan.InstanceNumber),
        ...this.measurementsData.map((measurement) => measurement.InstanceNumber),
      ]),
    ];

    const maxInstanceNumber = Math.max(this.fetusesNumber, ...instanceNumbersFromData);

    return Array.from({ length: maxInstanceNumber }, (_, i) => i + 1);
  }
}
