import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GastroFormsService } from 'src/app/gastro-forms.service';
import { GastroService } from 'src/app/gastro.service';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-patforms',
  templateUrl: './patforms.component.html',
  styleUrls: ['./patforms.component.scss']
})
export class PatformsComponent implements OnInit {
  @Output() closed = new EventEmitter<string>();
  showprintopts=false;
  showprint=false
  constructor(public gfs:GastroFormsService, private ls:LoginService) { }

  ngOnInit() {
  }

  close(toclose: boolean) {
    this.closed.emit(toclose ? '1': null);
  }
  getClass(item):string{
    if(item !== null  && this.gfs.Form.get(item+'Note')  && this.gfs.Form.get(item+'Note').value )
      return 'type-v'
    return 'type-plus';
  }

}
