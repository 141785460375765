<div class="wrapper" style="margin-top: 0; padding-right: 0.5em">
  <!-- ============ Header (Start) ============ -->
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel">
      <h1 class="t-title-main" style="margin-right: 32px">
        <button routerLink="/follow-up">Follow Up</button>
        / Visit
      </h1>
      <nav class="header-menu">
        <a
          class="header-menu-link icon-confirm"
          routerLink="/follow-up"
          aria-label="Close History"
          matTooltip="Close History"
        ></a>
      </nav>
    </div>
  </header>

  <main class="l-deashboard">
    <div class="w100 summary-recommendations" style="height: 100%">
      <section class="m-box fulltext" style="height: 100%">
        <h2 class="weightAssTable-title">Visits</h2>

        <!-- ================ Toggle Visits/Fetuses (Start) ================ -->
        <div class="t-set-title" (dblclick)="(false)" style="display: flex; justify-content: flex-end">
          <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big">
            <label>
              <input type="radio" name="toggleVisits" class="custom-check" [(ngModel)]="toggleVisits" [value]="true" />
              <i class="customInput-display"></i>
              <span> Visits </span>
            </label>

            <label>
              <input type="radio" name="toggleVisits" class="custom-check" [(ngModel)]="toggleVisits" [value]="false" />
              <i class="customInput-display"></i>
              <span> Fetuses Details </span>
            </label>
          </div>
        </div>
        <!-- ================ Toggle Visits/Fetuses (End) ================ -->

        <ng-container *ngIf="toggleVisits">
          <div *ngIf="isObjectEmpty(visitsData)" style="display: flex; justify-content: center; align-items: center">
            <h4>No previous visits available</h4>
          </div>
          <!-- ================ Previous Visits (Start) ================ -->
          <ng-container *ngIf="visitsData && !isObjectEmpty(visitsData)">
            <table>
              <tr>
                <th class="th-clear">
                  <span> Date </span>
                </th>
                <th class="th-clear">
                  <span> GA </span>
                </th>
                <th class="th-clear">
                  <span> Weight kg</span>
                </th>
                <th class="th-clear">
                  <span> B. Pressure Sys. </span>
                </th>
                <th class="th-clear">
                  <span> B. Pressure Dia. </span>
                </th>
                <th class="th-clear">
                  <span> Proteinuria mg/day </span>
                </th>
                <th class="th-clear">
                  <span> Glucosutia mg/ml </span>
                </th>
                <th class="th-clear">
                  <span> Uterus cm </span>
                </th>
                <th class="th-clear">
                  <span> Cervical mm </span>
                </th>
                <th class="th-clear">
                  <span> PV </span>
                </th>
                <th class="th-clear">
                  <span> Comments </span>
                </th>
                <th class="th-clear">
                  <span> </span>
                </th>
              </tr>

              <!-- ======== Existing Data Row (Start) ======== -->
              <tr *ngFor="let visit of visitsData" [ngClass]="visit.isEditing ? 'tr-isActive' : ''">
                <td style="width: 11em">
                  <div
                    class="search-patient-field t-field w100"
                    style="border-radius: 20px; height: 2.5em; margin-bottom: 0.5em"
                  >
                    <span class="t-validation-wrap l-fg1" style="display: flex">
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [(ngModel)]="visit.Date"
                        [disabled]="true"
                        class="t-field w100"
                        style="padding: 0 0 0 1em"
                      />
                      <mat-datepicker #picker></mat-datepicker>
                      <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                      <img
                        class="calendar-icon"
                        src="../../assets/icons/date.svg"
                        [ngClass]="!visit.isEditing ? 'disabled' : ''"
                        (click)="picker.open()"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.GA"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.Weight"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.BPressureSys"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.BPressureDia"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.Proteinuria"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.Glucosutia"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.Uterus"
                  />
                </td>
                <td>
                  <input
                    [disabled]="!visit.isEditing"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                    [(ngModel)]="visit.Cervical"
                  />
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="visit.PV"
                    [disabled]="!visit.isEditing"
                    [inputName]="'PV'"
                    [tableName]="'fuVisits'"
                    [isEditing]="visit.isEditing"
                  ></app-free-text-select>
                </td>
                <td>
                  <app-free-text-select
                    [(inputValue)]="visit.Comments"
                    [disabled]="!visit.isEditing"
                    [inputName]="'Comments'"
                    [tableName]="'fuVisits'"
                    [isEditing]="visit.isEditing"
                  ></app-free-text-select>
                </td>

                <td class="edit-column" style="width: 3em">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!visit.isEditing  && !followUpService.visitClosed(visit.CreationTime)" (click)="editRow(visit)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="visit.isEditing  && !followUpService.visitClosed(visit.CreationTime)"
                      (click)="updateRow('fuVisits', visit, ['GA', 'Weight', 'BPressureSys','BPressureDia','Proteinuria','Glucosutia','Uterus','Cervical','PV','Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                    <button (click)="deleteRow('fuVisits', visit)">
                      <img src="../../assets/icons/del.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </tr>
              <!-- ======== Existing Data Row (End) ======== -->
            </table>
            <!-- ================ Previous Visits (End) ================ -->
          </ng-container>
          <hr class="divider" />
          <!-- ================ Add Visits & Fetus Data (Start) ================ -->
          <!-- New Visit (Start) -->
          <table>
            <tr>
              <th class="th-clear">
                <span> Date </span>
              </th>
              <th class="th-clear">
                <span> GA </span>
              </th>
              <th class="th-clear">
                <span> Weight kg </span>
              </th>
              <th class="th-clear">
                <span> B. Pressure Sys. </span>
              </th>
              <th class="th-clear">
                <span> B. Pressure Dia. </span>
              </th>
              <th class="th-clear">
                <span> Proteinuria  mg/day </span>
              </th>
              <th class="th-clear">
                <span> Glucosutia mg/ml </span>
              </th>
              <th class="th-clear">
                <span> Uterus cm </span>
              </th>
              <th class="th-clear">
                <span> Cervical mm</span>
              </th>
              <th class="th-clear">
                <span> PV </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
              <th class="th-clear">
                <span> </span>
              </th>
            </tr>
            <tr>
              <td style="width: 11em">
                <div
                  class="search-patient-field t-field w100"
                  style="border-radius: 20px; height: 2.5em; margin-bottom: 0.5em"
                >
                  <span class="t-validation-wrap l-fg1" style="display: flex">
                    <input
                      matInput
                      readonly
                      [(ngModel)]="followUpService.visitsNewData.Date"
                      [matDatepicker]="picker"
                      [min]="today"
                      [max]="today"
                      class="t-field w100"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <i class="t-sep" style="margin: 0 2.5em 0 0.5em"></i>
                    <img class="calendar-icon" src="../../assets/icons/date.svg" (click)="picker.open()" />
                  </span>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.GA"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.Weight"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.BPressureSys"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.BPressureDia"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.Proteinuria"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.Glucosutia"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.Uterus"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData.Cervical"
                />
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.visitsNewData.PV"
                  [inputName]="'PV'"
                  [tableName]="'fuVisits'"
                ></app-free-text-select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.visitsNewData.Comments"
                  [inputName]="'Comments'"
                  [tableName]="'fuVisits'"
                ></app-free-text-select>
              </td>
              <td>
                <button
                  [disabled]="!followUpService.visitsNewData.Date"
                  (click)="saveNewRow('fuVisits', followUpService.visitsNewData, visitsOriginalData)"
                  style="width: fit-content; padding: 0 1em"
                  class="mat-tooltip-trigger search-btn"
                >
                  Add
                </button>
              </td>
            </tr>
          </table>
          <!-- New Visit (End) -->
          <!-- Fetus Data (Start) -->
          <table style="margin-top: 0">
            <tr>
              <th class="th-clear">
                <span> # </span>
              </th>
              <th class="th-clear">
                <span> Gender </span>
              </th>
              <th class="th-clear">
                <span> Present. </span>
              </th>
              <th class="th-clear">
                <span> Movements </span>
              </th>
              <th class="th-clear">
                <span> Breathing </span>
              </th>
              <th class="th-clear">
                <span> Tonus </span>
              </th>
              <th class="th-clear">
                <span> Pulse </span>
              </th>
              <th class="th-clear">
                <span> FHR bpm </span>
              </th>
              <th class="th-clear">
                <span> Weight gm </span>
              </th>
              <th class="th-clear">
                <span> Percentage </span>
              </th>
              <th class="th-clear">
                <span> NST </span>
              </th>
              <th class="th-clear">
                <span> Doppler </span>
              </th>
              <th class="th-clear">
                <span> A.F. </span>
              </th>
              <th class="th-clear">
                <span> Comments </span>
              </th>
            </tr>
            <!-- ======== Add New Row (Fetuses) (Start) ======== -->
            <tr *ngFor="let fetus of fetuses; let i = index">
              <td>{{ i + 1 }}</td>
              <td style="width: 7em">
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Gender']"
                >
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Present']"
                >
                  <option value="אלכסוני">אלכסוני</option>
                  <option value="עכוז">עכוז</option>
                  <option value="רוחבי">רוחבי</option>
                  <option value="ראש">ראש</option>
                </select>
              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Movements']"
                >
                  <option value="הודגם">הודגם</option>
                  <option value="לא הודגם">לא הודגם</option>
                </select>
              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Breathing']"
                >
                  <option value="הודגם">הודגם</option>
                  <option value="לא הודגם">לא הודגם</option>
                </select>
              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Tonus']"
                >
                  <option value="הודגם">הודגם</option>
                  <option value="לא הודגם">לא הודגם</option>
                </select>
              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Pulse']"
                >
                  <option value="הודגם">לא הודגם</option>
                  <option value="לא הודגם">לא הודגם</option>
                </select>
              </td>
              <td style="width: 6em">
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'FHR']"
                />
              </td>
              <td style="width: 6em">
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Weight']"
                />
              </td>
              <td style="width: 6em">
                <input
                  type="text"
                  class="t-field w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Percentage']"
                />
              </td>
              <td style="width: 6em">
                <select class="t-field t-select w100"     [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'NST']">
                  <option value="OK">OK</option>
                  <option value="Not">Not OK</option>
                </select>
              </td>
              <td style="width: 6em">
                <select class="t-field t-select w100"     [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Doppler']">
                  <option value="OK">OK</option>
                  <option value="Not">Not OK</option>
                </select>

              </td>
              <td>
                <select
                  class="t-field t-select w100"
                  style="height: 2em; padding: 0 0 0 1em; margin-bottom: 0.5em"
                  [(ngModel)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'AF']"
                >
                <option value="OK">OK</option>
                <option value="Not">Not OK</option>
                </select>
              </td>
              <td>
                <app-free-text-select
                  [(inputValue)]="followUpService.visitsNewData['Fetus' + (i + 1) + 'Comments']"
                  [inputName]="'Fetus' + (i + 1) + 'Comments'"
                  [tableName]="'fuVisits'"
                ></app-free-text-select>
              </td>
            </tr>
            <!-- ======== Add New Row (Fetuses) (End) ======== -->
          </table>
          <!-- Fetus Data (End) -->
          <!-- ================ Add Visits  (End) ================ -->
        </ng-container>

        <!-- ================ Fetuses Previous Data (Clinical Exams) (Start) ================ -->
        <div
          *ngIf="!toggleVisits && isObjectEmpty(visitsData)"
          style="display: flex; justify-content: center; align-items: center"
        >
          <h4>No fetus data available</h4>
        </div>
        <table *ngIf="!toggleVisits && !isObjectEmpty(visitsData)">
          <tr>
            <th class="th-clear">
              <span> # </span>
            </th>
            <th class="th-clear">
              <span> Gender </span>
            </th>
            <th class="th-clear">
              <span> Present. </span>
            </th>
            <th class="th-clear">
              <span> Movements </span>
            </th>
            <th class="th-clear">
              <span> Breathing </span>
            </th>
            <th class="th-clear">
              <span> Tonus </span>
            </th>
            <th class="th-clear">
              <span> Pulse </span>
            </th>
            <th class="th-clear">
              <span> FHR bpm </span>
            </th>
            <th class="th-clear">
              <span> Weight gm </span>
            </th>
            <th class="th-clear">
              <span> Percentage </span>
            </th>
            <th class="th-clear">
              <span> NST </span>
            </th>
            <th class="th-clear">
              <span> Doppler </span>
            </th>
            <th class="th-clear">
              <span> A.F. </span>
            </th>
            <th class="th-clear">
              <span> Comments </span>
            </th>
            <th class="th-clear">
              <span> </span>
            </th>
          </tr>

          <!-- ======== Existing Data Row (Start) ======== -->
          <ng-container *ngFor="let visit of visitsData">
            <tr style="background-color: #f8f9fa; font-weight: bold">
              <td colspan="14">
                <div style="padding: 10px; display: flex; align-items: center">
                  <input
                    matInput
                    [disabled]="!visit.isEditing"
                    [matDatepicker]="picker"
                    [(ngModel)]="visit.Date"
                    class="t-field w100"
                    style="width: 100%; border: none; background: transparent; margin-bottom: 1em"
                  />
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </td>
            </tr>

            <tr *ngFor="let fetus of [1, 2, 3, 4]">
              <ng-container *ngIf="visit['Fetus' + fetus + 'Gender']">
                <td>{{ fetus }}</td>
                <td style="width: 7em">
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Gender']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                  </select>
                </td>
                <td>
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Present']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="אלכסוני">אלכסוני</option>
                    <option value="עכוז">עכוז</option>
                    <option value="רוחבי">רוחבי</option>
                    <option value="ראש">ראש</option>
                  </select>
                </td>
                <td>
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Movements']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="הודגם">הודגם</option>
                    <option value="לא הודגם">לא הודגם</option>
                  </select>
                </td>
                <td>
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Breathing']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="הודגם">הודגם</option>
                    <option value="לא הודגם">לא הודגם</option>
                  </select>
                </td>
                <td>
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Tonus']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="הודגם">הודגם</option>
                    <option value="לא הודגם">לא הודגם</option>
                  </select>
                </td>
                <td>
                  <select
                    class="t-field t-select w100"
                    [(ngModel)]="visit['Fetus' + fetus + 'Pulse']"
                    [disabled]="!visit.isEditing"
                  >
                    <option value="הודגם">הודגם</option>
                    <option value="לא הודגם">לא הודגם</option>
                  </select>
                </td>
                <td style="width: 6em">
                  <input
                    [(ngModel)]="visit['Fetus' + fetus + 'FHR']"
                    type="text"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em"
                    [disabled]="!visit.isEditing"
                  />
                </td>
                <td style="width: 6em">
                  <input
                    [(ngModel)]="visit['Fetus' + fetus + 'Weight']"
                    type="text"
                    class="t-field w100"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em"
                    [disabled]="!visit.isEditing"
                  />
                </td>
                <td style="width: 6em">
                  <input
                    [(ngModel)]="visit['Fetus' + fetus + 'Percentage']"
                    type="text"
                    class="t-field w100"
                    class="t-field w100"
                    style="height: 2em; padding: 0 0 0 1em"
                    [disabled]="!visit.isEditing"
                  />
                </td>
                <td style="width: 6em">
                  <select class="t-field t-select w100"   [(ngModel)]="visit['Fetus' + fetus + 'NST']"  [disabled]="!visit.isEditing">
                    <option value="OK">OK</option>
                    <option value="Not OK">Not OK</option>
                  </select>
                <td style="width: 6em">
                  <select class="t-field t-select w100"   [(ngModel)]="visit['Fetus' + fetus + 'Doppler']"  [disabled]="!visit.isEditing">
                    <option value="OK">OK</option>
                    <option value="Not OK">Not OK</option>
                  </select>
                </td>
                <td>
                  <select class="t-field t-select w100" [(ngModel)]="visit['Fetus' + fetus + 'AF']" [disabled]="!visit.isEditing">
                    <option value="OK">OK</option>
                    <option value="Not">Not OK</option>
                  </select>
                </td>
                <td>
                  <app-free-text-select
                    [disabled]="!visit.isEditing"
                    [(inputValue)]="visit['Fetus' + fetus + 'Comments']"
                    [inputName]="'FetusComments'"
                    [tableName]="'fuVisits'"
                  ></app-free-text-select>
                </td>
                <td class="edit-column" style="width: 3em">
                  <div style="display: flex; justify-content: center; gap: 1em">
                    <button *ngIf="!visit.isEditing && !followUpService.visitClosed(visit.CreationTime)" (click)="editRow(visit)">
                      <img src="../../assets/icons/edit.svg" class="edit-icon" />
                    </button>
                    <button
                      class="orange-btn"
                      *ngIf="visit.isEditing  && !followUpService.visitClosed(visit.CreationTime)"
                      (click)="updateRow('fuVisits', visit, ['Fetus' + fetus + 'Gender', 'Fetus' + fetus + 'Present', 'Fetus' + fetus + 'Movements','Fetus' + fetus + 'Breathing','Fetus' + fetus + 'Tonus','Fetus' + fetus + 'Pulse','Fetus' + fetus + 'FHR','Fetus' + fetus + 'Weight','Fetus' + fetus + 'Percentage','Fetus' + fetus + 'NST','Fetus' + fetus + 'Doppler','Fetus' + fetus + 'AF','Fetus' + fetus + 'Comments'])"
                    >
                      <img src="../../assets/icons/checked-oragne.svg" class="edit-icon" />
                    </button>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <!-- ======== Existing Data Row (End) ======== -->
        </table>
        <!-- ================ Fetuses Previous Data (Clinical Exams) (End) ================ -->
      </section>
    </div>
    <!-- ================ Previous Pregnancies (End) ================ -->
  </main>
  <!-- ============ Header (End) ============ -->
  <app-patdetails
    *ngIf="showPatientDetailsForm"
    [pat]="patient"
    (closed)="showPatientDetailsForm = false"
  ></app-patdetails>
  <app-follow-up-bar
    (showPatDetails)="showPatientDetailsForm = true"
    (patient)="(patient)"
    [fetusesNumber]="fetusesNumber"
    [obstetricalFormula]="obstetricalFormula"
  ></app-follow-up-bar>
</div>
<app-commerror></app-commerror>
