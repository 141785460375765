import { Component, OnInit, Output,EventEmitter,Input, OnDestroy } from '@angular/core';
import {LoginService} from '../login.service';
import {Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommerrorService } from '../commerror.service';

@Component({
  selector: 'app-loginbox',
  templateUrl: './loginbox.component.html',
  styleUrls: ['./loginbox.component.scss']
})
export class LoginboxComponent implements OnInit, OnDestroy {

  user: string;
  pw: string;
  error: string;
  code: string;
  hideButton=false;
  @Output() closed = new EventEmitter<null>();
  @Output() viewonly = new EventEmitter<null>();
  @Input('isnew') newexam:boolean;
  @Input('isExport') export:boolean = false;
  @Input('search') search:boolean = false;


  private destroy$ = new Subject();
  hidepw=true;
  typed=false;

  constructor(public loginService: LoginService, public errorMessage:CommerrorService) { }

  ngOnInit() {
    this.loginService.onFailedLogin.pipe(takeUntil(this.destroy$)).subscribe(()=>{
      this.hideButton = false;
    });
  }

  checkLogin(){
    if(! this.loginService.getCode){
      if(this.loginService.loginname != this.user && ! this.loginService.contLogin)
        this.errorMessage.error("you are trying to start a new exam with another user’s credentials, the exam report will be signed with the other user’s name,  do you want to continue? if not please log out from the app session and log in again",true);
      else{
        this.hideButton = true;
        this.loginService.login(this.user,this.pw,false, false,this.export);
      }
    }
    else
      this.loginService.sendCode(this.code);
  }

  clean(){
    try{
      this.user = this.user.replace(/[^\x00-\x7F]+/, '')
      this.pw = this.pw.replace(/[^\x00-\x7F]+/, '')
    }
    catch{}
  }

  ngOnDestroy(){
    this.destroy$.next();
  }

}
