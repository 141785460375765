import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';
import {PregnancyService} from '../pregnancy.service';


@Component({
  selector: 'app-closepregnancy',
  templateUrl: './closepregnancy.component.html',
  styleUrls: ['./closepregnancy.component.scss']
})
export class ClosepregnancyComponent implements OnInit {
  @Output() closed = new EventEmitter<null>();
  @Output() examClosed = new EventEmitter<null>();
  @Input('pregtoload') pregIDtoLoad:number;
  @Input('exams') exams:any;
  @Input('isFollowUp') isFollowUp: any;

  
  isclosed = false;
  constructor(public ps: PregnancyService) { }

  ngOnInit() {
    if(this.exams && this.exams.length >0)
      this.ps.setExamID(this.exams[0].AutoIndex,this.exams[0].CreationTime )
    if(this.pregIDtoLoad)
      this.ps.loadPreg(this.pregIDtoLoad, this.isFollowUp);
  }

  closeMax():Date{
    let d = new Date();
    let dt = Date.parse(this.ps.getLMP());
    d.setTime(dt + (48 * 7 * 24 * 60 * 60 * 1000) );
    return d;
  }

  closePreg(){
    this.ps.close();
    this.examClosed.emit();
    this.isclosed=true;
  }

}
