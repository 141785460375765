import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { PatientsService } from '../patients.service';
import {Patient} from '../patients/patient';
import { LoginService } from '../login.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-patdetails',
  templateUrl: './patdetails.component.html',
  styleUrls: ['./patdetails.component.scss'],
})
export class PatdetailsComponent implements OnInit {
  @Input('pat') myPat: Patient;
  @Input('awl') awl:number;
  @Input('awlTime') awlTime: string;
  @Input('awlExam') awlExam: string;
  @Input('awlLMP')  awlLMP:string;
  @Input('awlComment') awlComment: string;
  @Input('openWAWL') open_w_awl: boolean;
  @Output() closed = new EventEmitter<string>();
  @Output() attachedFile = new EventEmitter<any>();
  @Output() showpay = new EventEmitter<null>();
  @Output() show_surrogate = new EventEmitter<null>();
  warning: string;

  minLMP:Date;
  maxLMP:Date;
  maxBD:Date;
  startBD:Date;
  invalidlmp='';
  LMP;
  Birth;
  SpouseBirth;
  openAWL:boolean;
  prnerr:string = null;
  treatmenterr:string = null;
  PRNVerified = false;
  iderr:string=null;
  idchanged=false;
  hideSave=false;
  ecode:number;
  ecodes:any[];
  ecodeTxt: string[];
  copyTo:number;
  tryCopy = false;
  copyNote:string;
  codesChanged = false;
  showSpouse = false;
  
  examTypes = ['1st','NT','Early Anatomy','2nd/3rd Trimester','Late Anatomy','Specialized','Fetal Echo', 'Pediatric Echo', 'Weight Assessment','Tech Exam','Gynecology','Follicles', 'Consultation Visit'];
  cardioTodayListExams=['אקו', 'אקו לב', 'אקו מכבי', 'אקו לאומית', 'אקו מאוחדת', 'אקו לב במאמץ', 'אקו במאמץ מכבי', 'אקו במאמץ לאומית', 'אקו במאמץ מאוחדת', 'אקו במאמץ פרטי', 'ארגומטריה', 'ארגומטריה מכבי','ארגומטריה לאומית', 'ארגומטריה מאוחדת', 'הולטר קצב לב', 'בדיקת אקג', 'קרוטיד US עורקי צואר', 'ייעוץ קרדיולוגי', 'יעוץ רפואי מכבי', 'יעוץ רפואי לאומית', 'יעוץ רפואי מאוחדת', 'יעוץ רפואי כללית מושלם']

  GA;

  constructor(public patservice: PatientsService, public ls: LoginService, private http: HttpClient) { 
    this.maxLMP = new Date();
    this.maxLMP.setMonth(this.maxLMP.getMonth() -1);
    this.minLMP = new Date();
    this.minLMP.setMonth(this.minLMP.getMonth() -10);
    this.maxBD = new Date();
    this.maxBD.setFullYear(this.maxBD.getFullYear() - 12);
  }

  ngOnInit() {
    this.LMP = this.myPat.LMP;
    if(this.awlLMP){
      this.GA = this.lmpGA(this.awlLMP)
    }
    this.Birth = this.myPat.Birth;
    this.SpouseBirth = this.myPat?.SpouseBirth;
    // if(this.myPat.Birth.split(" ").length > 0)
    if(this.myPat.Birth && this.myPat.Birth != ""){
      this.myPat.Birth = this.myPat.Birth.split(" ")[0];
      this.startBD = new Date();
      this.startBD.setFullYear(this.startBD.getFullYear() - 22);
    }
    if(this.myPat?.SpouseBirth && this.myPat?.SpouseBirth != ""){
      this.myPat.SpouseBirth = this.myPat?.SpouseBirth.split(" ")[0];
      this.startBD = new Date();
      this.startBD.setFullYear(this.startBD.getFullYear() - 22);
    }
    this.clearSpace();
    this.PRNVerified = ! this.ls.PRNcheck;//don't check prn if diabled
    if(this.open_w_awl)
      this.openAWL = true;
    if(this.myPat['PaymentCodes'] && this.ls.ExamCodes){//for belinson
      this.ecodes = this.myPat['PaymentCodes'].split(',');
      this.ecodeTxt = [];
      for(let code of this.ecodes){
        for(let ec of this.ls.ExamCodes){
          if(code == ec[0])
            this.ecodeTxt.push(ec[1]);
        }
      }
    } 
    this.patservice.getZimunfeedID();
  }



  savePatient(cb = null, noclose = false, openWEB = false) {
    this.hideSave = true;
    if(!this.myPat.UID && this.ls.doctor)
      this.myPat.UID = this.ls.doctor;
    this.patservice.savePatient(this.myPat,this.myPat.PID).subscribe((data) => {
      if(data.hasOwnProperty("error")){
        this.hideSave = false;
        this.warning = data["error"]
        if(cb)
          cb(data["error"])
      }
      else{
        if(! noclose)
          this.closed.emit('1');
        if(cb) {
          cb(false);
        }

        if (!this.ls.ishospital && this.patservice.zimunFeedID && openWEB) {
          this.goOldWebPatientPage(this.myPat.ID, this.patservice.zimunFeedID)
            .then((url) => {
              window.open(url, "_blank");
            })
            .catch((error) => {
             console.log(error);
            });
        }
      }
    }, // success path
    (error) => { // error path(data)=> 
      this.hideSave = false;
      this.warning = error.error.error
      if(cb)
          cb(error);
    });
    
  }  

  dateChanged(itemName:string){
    try{
      this.myPat[itemName] = this[itemName].format("YYYY-MM-DD");
    }
    catch{}
    //this.myPat['LMP'] = this[itemName].format("YYYY-MM-DD");
    //this.GA = this.lmpGA(this.awlLMP);
  }
  
  lmpGA(lmp){
    if(! lmp)
      return "";
    let dt = new Date();
    let days = Math.floor((dt.getTime() - Date.parse(lmp))/(1000 * 60 * 60 *24));
    if(Math.floor(days/7) < 1 || Math.floor(days/7) >50)
      return ""
    return Math.floor(days/7) + "w " + (Math.floor(days) % 7) + "d"
  }

  close(toclose: boolean) {
    this.closed.emit(toclose ? '1': null);
  }

  getBMI(){
    if(!this.myPat.Height || ! this.myPat.Weight)
      return "";
    return Math.round(10 * this.myPat.Weight / Math.pow(this.myPat.Height/100,2))/10;
  }

  getBSA(){
    if(this.myPat.BSAus)
      return this.myPat.BSAus;
    if(!this.myPat.Height || ! this.myPat.Weight)
      return "";
    return (Math.round(100 * Math.sqrt((this.myPat.Height * this.myPat.Weight)/3600)) / 100) + 'm²';
  }

  clearSpace(){
    try{this.myPat.ID = this.myPat.ID.replace(/\s+/,'');}
    catch(e){}
  }

  saveAWL(cb = null){
    if(this.idchanged){
      this.savePatient((err)=>{
        if(! err)
          this.patservice.saveAWL(this.awl,this.awlTime,this.awlLMP,this.awlExam,this.awlComment, this.myPat.PID,this.myPat.PRN,this.myPat.Code,null, ((this.ls.isAdmin || this.ls.userType == 'secretary') ? (this.myPat.UID? this.myPat.UID: this.ls.doctor) : null)).subscribe((data)=>{
            this.closed.emit('awl');
          },(error)=>{
            console.log(error);
          });
      },true)
    }
    else//only save awl
      this.patservice.saveAWL(this.awl,this.awlTime,this.awlLMP,this.awlExam,this.awlComment, this.myPat.PID,this.myPat.PRN,this.myPat.Code,this.ecodes,  ((this.ls.isAdmin || this.ls.userType == 'secretary') ? (this.myPat.UID? this.myPat.UID: this.ls.doctor) : null)).subscribe((data)=>{
          this.closed.emit('awl');
      },(error)=>{
        console.log(error);
      });
  }

  deleteAWL(){
    this.patservice.deleteAWL(this.awl).subscribe((data)=>{
      this.closed.emit('awl');
    },(err)=>{
      console.log(err);
    });
  }

  prepareAWL(){
    if(! this.myPat.PID){//save the patient and then open
      this.hideSave = true;
      this.patservice.savePatient(this.myPat).subscribe((data) => {
        if(data.hasOwnProperty("error")){
          this.warning = data["error"]
          this.hideSave = false
        }
        else{
          if(data['PID']){
            this.myPat.PID = data['PID'];
            this.openAWL=true;
          }
        }
      }, // success path
      (error) => { // error path(data)=> 
        this.warning = error.error.error;
        this.hideSave = false;
      });
    }
    else
      this.openAWL=true;
  }
  
  checklmp(){
    if(this.awlLMP){
      let dt = new Date(this.awlLMP);
      if(dt < this.minLMP || dt > this.maxLMP)
        this.invalidlmp = 'is-invalid';
      else
      this.invalidlmp = '';
    }
  }

  checkPRN(){
    if(this.myPat.Code && this.myPat.PRN && this.myPat.PRN.length > 8 && this.myPat.Code.length > 4){
      this.patservice.checkPRN(this.myPat.ID,this.myPat.PRN,this.myPat.Code).subscribe((data)=>{
        if(data['response'] == ''){//verified
          this.PRNVerified = true;
          this.prnerr = null;
          this.iderr = null;
        }
        else{
          this.iderr = null;
          if(data['response'].indexOf('התחייבות לא שייך למבוטח') > -1)
            this.iderr = data['response'];
          this.prnerr = data['response'];
        }
      },(err)=>{
        this.prnerr = err['error'];
      })
    }
  }

  checkLength(){
    if(! this.ls.PRNcheck)
      return;
    this.PRNVerified = false;
    if(this.myPat.PRN && this.myPat.PRN.length > 0 && this.myPat.PRN.length < 9)
      this.prnerr = "PRN invalid";
    else
      this.prnerr = null;
    if(this.myPat.Code && this.myPat.Code.length > 0 && this.myPat.Code.length < 5)
      this.treatmenterr = 'is-invalid';
    else
      this.treatmenterr = null;
  }

  IDValidator()
  {
    if(this.myPat.IsID == 0)
      return true;
    let id:any = this.myPat.ID.replace('-','');
    if (id.length < 9 || isNaN(id)) {  // Make sure ID is formatted properly
          return false;
      }
      let sum = 0, incNum;
      for (let i=0; i < id.length; i++) {
          incNum = Number(id[i]) * ((i % 2) + 1);  // Multiply number by 1 or 2
          sum += (incNum > 9) ? incNum - 9 : incNum;  // Sum the digits up and add to total
      }
      return (sum % 10 === 0);
  }
  
  receive(){//mark as recived in db
    this.patservice.receivePat(this.awl).subscribe((data)=>{
      this.closed.next('awl');
    },(err)=>{
      console.log(err);
    }
    )
  }

  getExamTypes(){
    if(this.ls.isGastro){
      return ['Colonoscopy', 'Gastroscopy', 'Gastro-Colonoscopy', 'Sigmoidoscopy']
    }
    if(this.ls.isCardio)
      return this.cardioTodayListExams
    if(this.ls.premissions?.thirdScan && !this.examTypes.includes('Third Scan')){
      this.examTypes.push('Third Scan')
    }
    return this.examTypes;
  }

  addCode(){
    if(! this.ecodes){
      this.ecodes = [];
      this.ecodeTxt = [];
    }
    if(! this.ecodes.includes(this.ecode)){
      this.ecodes.push(this.ecode);
      for(let ec of this.ls.ExamCodes){
        if(this.ecode == ec[0])
          this.ecodeTxt.push(ec[1] + '('+ ec[0] + ')');
      }
      this.codesChanged = true;
    }
    setTimeout(()=>{this.ecode = 0},1);
    
  }

  removeCode(index:number){
    this.ecodes.splice(index,1);
    this.ecodeTxt.splice(index,1);
    this.codesChanged = true;
  }

  copyPat(){
    this.copyNote=null;
    if(! this.copyTo)
      return;
    this.tryCopy = true;
    this.patservice.copyPat(this.myPat.PID,this.copyTo).subscribe((d)=>{
      this.copyNote = 'Patient copied';
      this.tryCopy = false;
    },(error)=>{
      this.tryCopy = false;
      this.copyNote = error.error.error;
    })
  }

  // Redirects to the patient's page in old web:
  goOldWebPatientPage(patientID: string, zimunFeedID: number): Promise<string> {
    return new Promise((resolve, reject) => {
        if (!this.ls.ishospital) {
          try {
            this.http
              .get(`/api/old-web/${patientID}/${zimunFeedID}`)
              .subscribe((data) => {
                const url = `https://artisanmedical.co.il/Patient/viewGeneralPatient.php?PatSysID=${data}`;
                resolve(url);
              }, (error) => {
                  reject(error);
              });
          } catch (error) {
            console.error("Error fetching patient data: ", error);
            reject(error);
          }
        }
        else {
            reject('Cannot get patSysID.');
        }
    });
  }

  sendATD(){
    this.myPat.ATDNotify = new Date();
    this.patservice.notifyATD(this.myPat.AWLID).subscribe(()=>{
      this.closed.emit('awl');
    },(err)=>{
      console.log(err);
      this.myPat.ATDNotify = null;
    });
  }

}
