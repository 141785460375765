import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from '../autotext.service';
import { CalendarService } from '../calendar.service';
import { CommerrorService } from '../commerror.service';
import { ExamsService } from '../exams.service';
import { PregnancyService } from '../pregnancy.service';

@Component({
  selector: 'app-calexams',
  templateUrl: './calexams.component.html',
  styleUrls: ['./calexams.component.scss']
})
export class CalexamsComponent implements OnInit,OnDestroy {
  @Output() closed = new EventEmitter<null>();
  @Input('selectedForm') selectedForm;
  comment ='';
  examSelected;
  OB =[ '1st', 'NT', 'Early', '2nd/3rd','Weight Assessment', 'Late','Third Scan','Specialized' ,'Fetal Echo'];
  loaded;
  trimester ='first';
  showLMP = false;
  invalidlmp='';
  lmpmin: string;
  lmpmax: string;
  weeks=[];
  days=[];
  now;
  floaded$: Subscription;
  sub: Subscription
  showPopup: boolean = false;
  firstTriID: number = 10010;
  secondTriID: number = 10020;
  thirdTriID: number = 10030;

  constructor(
    public ce: CommerrorService,
    public atS: AutotextService,
    public cf: CalendarService,
    public es: ExamsService,
    public pregS: PregnancyService,
    public fb: UntypedFormBuilder,
    public http: HttpClient,
  ) {
    this.now = new Date().toISOString()
    for(let i=2;i < 45; i++){
      this.weeks.push(i);
    }
    for(let i =0; i < 7; i++){
      this.days.push(i);
    }
    let date = new Date();
    let lmpd = Date.now() - 2*7*24*60*60000;//2 weeks ago
    date.setTime(lmpd)
    this.lmpmax = date.getUTCFullYear() + '-'+CalexamsComponent.add0(date.getMonth()+1)+'-'+CalexamsComponent.add0(date.getDate());
    lmpd = Date.now() - 44*7*24*60*60000;//44 weeks ago
    date.setTime(lmpd)
    this.lmpmin = date.getUTCFullYear() + '-'+CalexamsComponent.add0(date.getMonth()+1)+'-'+CalexamsComponent.add0(date.getDate());
   }
   static add0(val: number): string{
    if(val > 9)
      return val.toString();
    return "0"+ val.toString();
  }

  async ngOnInit() {
    this.cf.Pat = this.cf.ps.getPat()
    this.cf.resetForm(this.selectedForm);
    this.floaded$ = this.cf.formLoaded$.subscribe(() => {
      this.loaded = true;
      if(this.cf.es.patServ.getPat().LMP)
        this.cf.LMP = this.cf.es.patServ.getPat().LMP
      if(this.cf.calForm.get('LMP').value)
        this.cf.LMP = this.cf.calForm.get('LMP').value;
      
    })

    this.sub = this.ce.saveCalendar$.subscribe((save) => {
      if(!save && this.cf.Pat.ID !='default')
        this.cf.deleteClaendar();
        this.closed.emit();

    })
      
  }
  ngOnDestroy(): void {
    this.floaded$.unsubscribe();
    this.sub.unsubscribe();
  }

  checklmp(){
    if(this.cf.LMP < new Date(this.lmpmin)  || this.cf.LMP > new Date(this.lmpmax))
      this.invalidlmp = 'is-invalid';
    else{
      this.invalidlmp='';
      const date = new Date(this.cf.LMP);
      this.cf.calForm.get('LMP').setValue(date.getUTCFullYear() + '-'+CalexamsComponent.add0(date.getMonth()+1)+'-'+CalexamsComponent.add0(date.getDate()));
      
    }
  }

  printCalForm(value: number) {
    this.cf.calForm.get('printAllFields').setValue(value);
    const temp = `api/newExams/printCalendar/${this.cf.calForm.value['pregID']}`;
    window.open(temp);
  }

  getLmpGA(){
    return this.pregS.lmpGA(this.cf.LMP, this.now)
  }

  resetLMP(){
    this.cf.LMP=null;
    this.cf.calForm.get('LMP').setValue(null)  
    
  }

  getNumber(num){
    return Number(num)
  }

  close(){
    this.ce.errorCalendar("")
  }

  togglePrintPopup() {
    this.showPopup = !this.showPopup;
  } 
}
