import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { MeasureComponent } from './measure/measure.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { HttpClientModule,HttpClient,HTTP_INTERCEPTORS }    from '@angular/common/http';
import { ExamsbarComponent } from './examsbar/examsbar.component';
import { ExamComponent } from './exam/exam.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule } from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { CUSTOM_DATE_FORMATS } from './utils/custom-date-formats';
import { MatNativeDateModule } from '@angular/material/core';

import { LoginComponent } from './login/login.component';
import { PatientsComponent } from './patients/patients.component';
import { BottombarComponent } from './bottombar/bottombar.component';
import { PatdetailsComponent } from './patdetails/patdetails.component';
import { PatexamsComponent } from './patexams/patexams.component';
import { DocpanelComponent } from './docpanel/docpanel.component';
import { PregnancyComponent } from './pregnancy/pregnancy.component';
import { PlacentaComponent } from './placenta/placenta.component';
import { AnatomyComponent } from './anatomy/anatomy.component';
import { SummaryComponent } from './summary/summary.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { DopplerComponent } from './doppler/doppler.component';
import { DoppleritemComponent } from './doppleritem/doppleritem.component';
import { NTComponent } from './nt/nt.component';
import { PrintComponent } from './print/print.component';
import { PrintmeasureComponent } from './printmeasure/printmeasure.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LightboxModule } from 'ngx-lightbox';
import { ChartsComponent } from './charts/charts.component';
import { SystemprintComponent } from './systemprint/systemprint.component';
import { DopplerprintComponent } from './dopplerprint/dopplerprint.component';
import { PrintoptsComponent } from './printopts/printopts.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AutotextComponent } from './autotext/autotext.component';
import { AutotextRowComponent } from './autotext-row/autotext-row.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {DateAdapter,MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {  MAT_MOMENT_DATE_FORMATS,  MomentDateAdapter,  MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { GalleryboxComponent } from './gallerybox/gallerybox.component';
import { GenradioComponent } from './genradio/genradio.component';
import { InboxComponent } from './inbox/inbox.component';
import { InterceptorService } from './interceptor.service';
import { CommerrorComponent } from './commerror/commerror.component';
import { DiagnosesComponent } from './diagnoses/diagnoses.component';
import { UterusComponent } from './uterus/uterus.component';
import { OvariesComponent } from './ovaries/ovaries.component';
import { RemotevideoComponent } from './remotevideo/remotevideo.component';
import { LoginboxComponent } from './loginbox/loginbox.component';
import { GYNgeneralComponent } from './gyngeneral/gyngeneral.component';
import { ClosepregnancyComponent } from './closepregnancy/closepregnancy.component';
import { SendLinkComponent } from './send-link/send-link.component';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { WeightAssessmentComponent } from './weight-assessment/weight-assessment.component';
import { CardioGeneralComponent } from './cardio/cardio-general/cardio-general.component';
import { TwodComponent } from './cardio/twod/twod.component';
import { CardioDopplerComponent } from './cardio/cardio-doppler/cardio-doppler.component';
import { CardioPrintComponent } from './cardio/cardio-print/cardio-print.component';
import { CardioTissueDopComponent } from './cardio/cardio-tissue-dop/cardio-tissue-dop.component';
import { FetalechoComponent } from './fetalecho/fetalecho.component';
import { FetalechoitemComponent } from './fetalechoitem/fetalechoitem.component';
import { FetalmeasureComponent } from './fetalmeasure/fetalmeasure.component';
import { MedicalhistoryComponent } from './medicalhistory/medicalhistory.component';
import { MedicalhistoryitemComponent } from './medicalhistoryitem/medicalhistoryitem.component';
import { PreparationComponent } from './Gastro/preparation/preparation.component';
import { FindingsComponent } from './Gastro/findings/findings.component';
import { PathologiesComponent } from './Gastro/pathologies/pathologies.component';
import { GastroSummaryComponent } from './Gastro/gastro-summary/gastro-summary.component';
import { GastroPrintComponent } from './Gastro/gastro-print/gastro-print.component';
import { PatformsComponent } from './Gastro/patforms/patforms.component';
import { DetailFindingComponent } from './Gastro/findings/detail-finding/detail-finding.component';
import { PatformsitemComponent } from './Gastro/patforms/patformsitem/patformsitem.component';
import { GastroPrintoptsComponent } from './Gastro/gastro-printopts/gastro-printopts.component';
import { GsprintFormComponent } from './Gastro/gsprint-form/gsprint-form.component';
import { CustomheaderComponent } from './customheader/customheader.component';
import { CardioSummaryComponent } from './cardio/cardio-summary/cardio-summary.component';
import { TextBoxComponent } from './text-box/text-box.component';
import { CardioFindingsComponent } from './cardio/cardio-findings/cardio-findings.component';
import { PaymentComponent } from './misc/payment/payment.component';
import { StatsComponent } from './stats/stats.component';
import { PrintstatsComponent } from './Gastro/printstats/printstats.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { GeninputComponent } from './geninput/geninput.component';
import { TotpboxComponent } from './totpbox/totpbox.component';
import { SearchtextComponent } from './searchtext/searchtext.component';
import { TimeoutComponent } from './Gastro/patforms/timeout/timeout.component';
import { AdmissionComponent } from './Gastro/patforms/admission/admission.component';
import { GastroProcedureComponent } from './Gastro/gastro-procedure/gastro-procedure.component';
import { CalexamsComponent } from './calexams/calexams.component';
import { PrintreportComponent } from './printreport/printreport.component';
import { PrintcalendarComponent } from './printcalendar/printcalendar.component';
import { InformconcentComponent } from './informconcent/informconcent.component';
import { CardioFlowMeasComponent } from './cardio/cardio-flow-meas/cardio-flow-meas.component';
import { LimitsComponent } from './limits/limits.component';
import { SurrogateComponent } from './custom/surrogate/surrogate.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { MapPipe } from './pipes/map.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SetPipe } from './pipes/set.pipe';
import { ToArrayPipe } from './pipes/to-array.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdminLeumitReportComponent } from './admin-leumit-report/admin-leumit-report.component';
import { MediaTransferComponent } from './media-transfer/media-transfer.component';
import { MatSelectModule } from "@angular/material/select";

import { UniqueAutoIndexPipe } from './pipes/unique-auto-index.pipe';
import { SortCreationTimePipe } from './pipes/sort-creation-time.pipe';
import { PrintConsentComponent } from './print-consent/print-consent.component';
import { InboxTransferComponent } from './inbox-transfer/inbox-transfer.component';
import { TransferHistoryComponent } from './transfer-history/transfer-history.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { MesureUsComponent } from './mesure-us/mesure-us.component';
import { WeeksDaysPipe } from './pipes/weeks-days.pipe';
import { UniqueExamsPipe } from './pipes/unique-exams.pipe';
import { DatePipe } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { PatientRedirectComponent } from './patient-redirect/patient-redirect.component';
import { FollowUpBarComponent } from './follow-up-bar/follow-up-bar.component';
import { FollowUpComponent } from './follow-up/follow-up.component';
import { FollowUpHistoryComponent } from './follow-up-history/follow-up-history.component';
import { PatDocumentsComponent } from './pat-documents/pat-documents.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FreeTextSelectComponent } from './free-text-select/free-text-select.component';
import { OptionsManagerComponent } from './options-manager/options-manager.component';
import { FetusDetailsComponent } from './fetus-details/fetus-details.component';
import { FollowUpVisitComponent } from './follow-up-visit/follow-up-visit.component';
import { VisitPrintComponent } from './visit-print/visit-print.component';
import { FollowUpScansComponent } from './follow-up-scans/follow-up-scans.component';
import { FollowUpScreeningComponent } from './follow-up-screening/follow-up-screening.component';
import { FollowUpLabComponent } from './follow-up-lab/follow-up-lab.component';
import { FollowUpGeneticsComponent } from './follow-up-genetics/follow-up-genetics.component';
import { OpenPregnancyComponent } from './open-pregnanacy/open-pregnancy.component';

//import { ServiceWorkerModule } from '@angular/service-worker';
//import { environment } from '../environments/environment';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    MeasurementsComponent,
    MeasureComponent,
    ExamsbarComponent,
    ExamComponent,
    LoginComponent,
    PatientsComponent,
    BottombarComponent,
    PatdetailsComponent,
    PatexamsComponent,
    DocpanelComponent,
    PregnancyComponent,
    PlacentaComponent,
    AnatomyComponent,
    SummaryComponent,
    DopplerComponent,
    DoppleritemComponent,
    NTComponent,
    PrintComponent,
    PrintmeasureComponent,
    GalleryComponent,
    ChartsComponent,
    SystemprintComponent,
    DopplerprintComponent,
    PrintoptsComponent,
    AutotextComponent,
    AutotextRowComponent,
    GalleryboxComponent,
    GenradioComponent,
    InboxComponent,
    CommerrorComponent,
    DiagnosesComponent,
    UterusComponent,
    OvariesComponent,
    RemotevideoComponent,
    LoginboxComponent,
    GYNgeneralComponent,
    ClosepregnancyComponent,
    SendLinkComponent,
    SearchboxComponent,
    ConsultationComponent,
    WeightAssessmentComponent,
    CardioGeneralComponent,
    TwodComponent,
    CardioDopplerComponent,
    CardioPrintComponent,
    CardioTissueDopComponent,
    FetalechoComponent,
    FetalechoitemComponent,
    FetalmeasureComponent,
    MedicalhistoryComponent,
    MedicalhistoryitemComponent,
    PreparationComponent,
    FindingsComponent,
    PathologiesComponent,
    GastroSummaryComponent,
    GastroPrintComponent,
    PatformsComponent,
    DetailFindingComponent,
    PatformsitemComponent,
    GastroPrintoptsComponent,
    GsprintFormComponent,
    CustomheaderComponent,
    CardioSummaryComponent,
    TextBoxComponent,
    CardioFindingsComponent,
    PaymentComponent,
    StatsComponent,
    PrintstatsComponent,
    ProcedureComponent,
    GeninputComponent,
    TotpboxComponent,
    SearchtextComponent,
    TimeoutComponent,
    AdmissionComponent,
    GastroProcedureComponent,
    CalexamsComponent,
    PrintreportComponent,
    PrintcalendarComponent,
    InformconcentComponent,
    CardioFlowMeasComponent,
    LimitsComponent,
    SurrogateComponent,
    AdvancedSearchComponent,
    MapPipe,
    FilterPipe,
    SetPipe,
    ToArrayPipe,
    AdminLeumitReportComponent,
    MediaTransferComponent,
    UniqueAutoIndexPipe,
    SortCreationTimePipe,
    PrintConsentComponent,
    InboxTransferComponent,
    TransferHistoryComponent,
    AnamnesisComponent,
    MesureUsComponent,
    WeeksDaysPipe,
    UniqueExamsPipe,
    NavComponent,
    PatientRedirectComponent,
    PatDocumentsComponent,
    FileSizePipe,
    FollowUpBarComponent,
    FollowUpComponent,
    FollowUpHistoryComponent,
    FreeTextSelectComponent,
    OptionsManagerComponent,
    FetusDetailsComponent,
    FollowUpVisitComponent,
    VisitPrintComponent,
    FollowUpScansComponent,
    FollowUpScreeningComponent,
    FollowUpLabComponent,
    FollowUpGeneticsComponent,
    OpenPregnancyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    LightboxModule,
    MatDatepickerModule,
    MatTableModule,
    MatIconModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    AngularEditorModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  }),
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports:[
  ],
  providers: [
    MatDatepickerModule, 
    MatTooltipModule,
    ThemeService,
    UniqueExamsPipe,
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'he-IL'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
