import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup,FormControl } from '@angular/forms';
import {ExamsService} from '../exams.service';
import {AutotextService} from '../autotext.service';

@Component({
  selector: 'app-gyngeneral',
  templateUrl: './gyngeneral.component.html',
  styleUrls: ['./gyngeneral.component.scss']
})
export class GYNgeneralComponent implements OnInit {
  GenForm: UntypedFormGroup;
  lastMed: string;

  constructor(private fb:UntypedFormBuilder, public es: ExamsService,public atS:AutotextService) {
    this.GenForm = this.fb.group({
      MedicalHistory: [''],
      Purpose: [''],
      LMP:  [''],
      Gravida:[''],
      Para:[''],
      Abortion:[''],

    });
    if(! this.es.isClosed){
      this.es.getLastHistory(true).subscribe((data)=>{
        this.lastMed = data['history'];
      },(err)=>{
        console.log(err);
      });
    }
   }

  ngOnInit() {
    this.es.fillForm(this.GenForm,"GYNgeneral",false,true);
  }

  days():number{
    if(! this.GenForm.get('LMP').value)
      return null;
    let now  = new Date();
    let lmp  = new Date(this.GenForm.get('LMP').value)
    return Math.floor((now.getTime() - lmp.getTime()) / (24*60 * 60 *1000));
  }

  addLastMed(){
    if(this.GenForm.get("MedicalHistory").value)
      this.GenForm.get("MedicalHistory").setValue(this.GenForm.get("MedicalHistory").value + this.lastMed);
    else
      this.GenForm.get("MedicalHistory").setValue(this.lastMed);
  }

  setDefaults(){
    this.es.Defaults = 1;
    this.es.setDefaults().subscribe((data)=>{
    },(err)=>{
      console.log(err);
      this.es.Defaults=null;
    });
  }

}
