import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OptionService } from "../option.service";

@Component({
  selector: "app-options-manager",
  templateUrl: "./options-manager.component.html",
  styleUrls: ["./options-manager.component.scss"],
})
export class OptionsManagerComponent {
  newOption = "";
  options: string[];
  tableName: string;
  fieldName: string;

  constructor(
    public dialogRef: MatDialogRef<OptionsManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private optionService: OptionService
  ) {
    this.options = data.options || [];
    this.tableName = data.tableName;
    this.fieldName = data.fieldName;
  }

  addOption() {
    if (this.newOption && !this.options.includes(this.newOption.trim())) {
      this.optionService.addOption(this.tableName, this.fieldName, this.newOption.trim()).subscribe(() => {
        this.options.push(this.newOption.trim());
        this.newOption = "";
        this.saveOrder();
      });
    }
  }

  deleteOption(option: string) {
    this.optionService.deleteOption(this.tableName, this.fieldName, option).subscribe(() => {
      this.options = this.options.filter((o) => o !== option);
      this.saveOrder();
    });
  }

  moveOptionUp(index: number) {
    if (index > 0) {
      const temp = this.options[index - 1];
      this.options[index - 1] = this.options[index];
      this.options[index] = temp;
      this.saveOrder();
    }
  }

  moveOptionDown(index: number) {
    if (index < this.options.length - 1) {
      const temp = this.options[index + 1];
      this.options[index + 1] = this.options[index];
      this.options[index] = temp;
      this.saveOrder();
    }
  }

  saveOrder() {
    this.optionService.changeOrder(this.tableName, this.fieldName, this.options).subscribe(() => {});
  }

  closeDialog() {
    this.dialogRef.close(this.options);
  }
}
