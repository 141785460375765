<aside class="l-sidebar l-flex l-column scroller mCustomScrollbar _mCS_3">
  <img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />

  <!-- ============ Patient Information (Start) ============ -->
  <div class="userPanel">
    <i class="userPanel-icon icon-female">
      <span class="l-flex t-boxMargin menstural-fields-set">
        <button
          class="btn-user_data"
          aria-label="user data"
          (click)="showPatientDetails()"
          matTooltip="Open Patient Details"
        ></button>
      </span>
    </i>

    <div class="userPanel-userName">{{ patient?.First }} {{ patient?.Last }}</div>
    <dl class="l-table userPanel-userInfo">
      <div class="l-row">
        <dt class="l-td-m">ID Number</dt>
        <dd class="l-td-m">
          {{ patient?.ID }}
        </dd>
      </div>
      <div class="l-row">
        <dt class="l-td-m">Age</dt>
        <dd class="l-td-m">
          {{ patientsService.getAgeMo() }}
        </dd>
      </div>
      <div class="l-row">
        <dt class="l-td-m">GA</dt>
        <dd class="l-td-m">
          {{ patient?.getGA() }}
        </dd>
      </div>
      <div class="l-row">
        <dt class="l-td-m">EDD</dt>
        <dd class="l-td-m">
          {{ patientEDD | date : "dd.MM.yyyy" }}
        </dd>
      </div>
      <div *ngIf="fetusesNumber" class="l-row">
        <dt class="l-td-m">Fetuses</dt>
        <dd class="l-td-m" style="display: flex; align-items: center; justify-content: space-around; gap: 2em">
          {{ fetusesNumber }}
          <button (click)="(false)">
            <mat-icon matTooltip="Edit" style="color: #dcb16b">edit</mat-icon>
          </button>
        </dd>
      </div>
      <div *ngIf="obstetricalFormula" class="l-row">
        <dt class="l-td-m">Obstetric Formula</dt>
        <dd
          *ngIf="obstetricalFormula.Gravida || obstetricalFormula.Para || obstetricalFormula || obstetricalFormula.Abortion"
          class="l-td-m"
        >
          G({{ obstetricalFormula?.Gravida }}) P({{ obstetricalFormula?.Para }}) AB({{ obstetricalFormula?.Abortion }})
        </dd>
      </div>
    </dl>
  </div>
  <!-- ============ Patient Information (End) ============ -->

  <app-docpanel></app-docpanel>
</aside>
