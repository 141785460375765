import { Component, OnInit } from "@angular/core";
import { Patient } from "../patients/patient";
import { PatientsService } from "../patients.service";
import { FollowUpService } from "../follow-up.service";
import { PregnancyService } from "../pregnancy.service";
import { visitsOriginalData } from "./data/data-storage";
import { LoginService } from "../login.service";
import { Subscription } from "rxjs";
import { isObjectEmpty } from "../utils/helpers";
@Component({
  selector: "app-follow-up-visit",
  templateUrl: "./follow-up-visit.component.html",
  styleUrls: ["./follow-up-visit.component.scss"],
})
export class FollowUpVisitComponent implements OnInit {
  isObjectEmpty = isObjectEmpty;
  showPatientDetailsForm: boolean = false;
  patient: Patient;
  pregID: number;

  // Data structures: (For Resetting):
  visitsOriginalData = { ...visitsOriginalData };

  // Data:
  visitsData = [];

  toggleVisits: boolean = true;
  fetuses: any[] = [];
  fetusesNumber: number;
  obstetricalFormula: Object;
  fetusNumberSub: Subscription;
  obsFormulaSub: Subscription;

  today: Date = new Date();

  constructor(
    public patientsService: PatientsService,
    public followUpService: FollowUpService,
    public pregnancyService: PregnancyService,
    public loginService: LoginService
  ) {
    this.loginService.loginMissing();
  }

  ngOnInit(): void {
    this.followUpService.initializeNewData();

    this.patient = this.patientsService.getPat();
    this.pregID = this.pregnancyService.pregID;

    // Get the number of fetuses for the latest pregnancy:
    this.fetusNumberSub = this.followUpService.getFetusesNumber(this.pregID).subscribe((data) => {
      this.fetusesNumber = data;
      this.loadFetuses(data);
    });

    // Get the obstetrical formula for the latest pregnancy:
    this.obsFormulaSub = this.followUpService.getObstetricalFormula(this.pregID).subscribe((data) => {
      this.obstetricalFormula = data;
    });

    this.getData("fuVisits", "visitsData");
  }

  ngOnDestroy() {
    if (this.fetusNumberSub) {
      this.fetusNumberSub.unsubscribe();
    }

    if (this.obsFormulaSub) {
      this.obsFormulaSub.unsubscribe();
    }
  }

  async loadFetuses(fetusNumber: number) {
    try {
      this.fetuses = Array.from({ length: fetusNumber }, (_, index) => ({
        index: index + 1,
        gender: "",
        present: "",
        movements: "",
        breathing: "",
        tonus: "",
        pulse: "",
        fhr: "",
        weight: "",
        percentage: "",
        nst: "",
        doppler: "",
        af: false,
        comments: "",
      }));
    } catch (error) {
      console.error("Error loading fetuses:", error);
    }
  }

  async saveNewRow(table: string = "", rowData: any, originalData: any = null) {
    try {
      const result = await this.followUpService.saveData(table, rowData);

      if (result["status"] === "ok") {
        this.resetNewData(rowData, originalData);
        await this.handleDataFetch(table);
      }
    } catch (error) {
      console.error("Error during the save new row", error);
    }
  }

  resetNewData(newData: any, originalData: any) {
    const fieldsToPreserve = ["PID", "PregIndex", "ID"];

    for (const key in newData) {
      if (!fieldsToPreserve.includes(key)) {
        if (key === "Date") {
          newData[key] = new Date();
        } else if (key === "GA") {
          newData[key] = this.patient?.getGA() || "";
        } else {
          newData[key] = originalData && originalData[key] !== undefined ? originalData[key] : "";
        }
      }
    }
  }

  editRow(rowData: any) {
    rowData.isEditing = true;
  }

  async updateRow(table: string, rowData: any, columnsToUpdate: string[]) {
    try {
      const dataToUpdate = columnsToUpdate.reduce((obj, col) => {
        obj[col] = rowData[col];
        return obj;
      }, {});

      const result = await this.followUpService.updateData(table, dataToUpdate, rowData.AutoIndex);
      if (result["status"] === "ok") {
        rowData.isEditing = false;
      }
    } catch (error) {
      console.error("Error updating the row", error);
    }
  }

  async handleDataFetch(table: string) {
    switch (table) {
      case "fuVisits":
        await this.getData("fuVisits", "visitsData");
        break;
      default:
        console.error("Unknown table:", table);
    }
  }

  async getData(table: string, placementVariable: string, pregIndex: number = this.pregID) {
    const data = await this.followUpService.getData(table, pregIndex);
    this[placementVariable] = data["data"];
  }

  async deleteRow(table: string, rowData: any) {
    try {
      const result = await this.followUpService.deleteData(table, rowData.AutoIndex);
      if (result["status"] === "ok") {
        switch (table) {
          case "fuVisits":
            this.visitsData = this.visitsData.filter((visit) => visit !== rowData);
            break;

          default:
            console.error(`Table ${table} not handled.`);
            break;
        }
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  }
}
