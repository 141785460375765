import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ExamsService } from 'src/app/exams.service';
import { GastroService } from 'src/app/gastro.service';

@Component({
  selector: 'app-gastro-procedure',
  templateUrl: './gastro-procedure.component.html',
  styleUrls: ['./gastro-procedure.component.scss']
})
export class GastroProcedureComponent implements OnInit, OnDestroy {
  ProcedureForm;
  SignForm;
  Signs=[];
  sub = new Subscription();
  constructor(private fb:UntypedFormBuilder, public es:ExamsService, public gs:GastroService) { }

  ngOnInit() {
    this.resetSignForm();
    this.resetProcedureForm();
    this.loadSigns();
    this.sub = this.es.examLoaded$.subscribe(() => {
    this.resetProcedureForm();
    // this.es.fillForm(this.ProcedureForm, 'gastroProcedure', )
    })
  }

  resetSignForm(){
    this.SignForm = this.fb.group({
      CreationTime:[''],
      Blood:[''],
      Pulse:[''],
      Saturation:[''],
      Oxygen:[''],
      bpSystolic:[''],
      bpDyastolic:[''],
    })
  }
  resetProcedureForm(){
    this.ProcedureForm = this.fb.group({
      PainLevel:[''],
      Byopsy:[''],
      Plate:[''],
      PlateText:[''],
      SkinBefore:[''],
      SkinAfter:[''],
      DuringProc:[''],
      DuringProcText:[''],
      EndTime:[''],
      Medication:[''],
      MedicationText:['']
    })
    this.es.fillForm(this.ProcedureForm, 'gastroProcedure', true, true, [] )

  }
  loadSigns(){
    var res = this.gs.getAllSigns();
    res.subscribe((data:any) =>{
      this.Signs = data;
    })
  }
  saveSigns(){
    if(this.SignForm.touched){
      this.SignForm.get('CreationTime').setValue(new Date().toISOString().replace('T', ' ').replace('Z', ''))
      this.gs.saveSign(this.SignForm.value) //(this.SignForm, 'signs', false, true)
      // this.Signs.push(this.SignForm.value)
      this.loadSigns()
      this.resetSignForm();
    }
  }
  setTime(){
    var now = new Date()
    this.ProcedureForm.get('EndTime').setValue(now)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
