<div class="body-wrapper" *ngIf="printService.loaded" [ngClass]="Direction" style="padding-top: 0">
  <header class="header">
    <div
      class="justify-between items-center"
      [innerHtml]="altHeader"
      *ngIf="altHeader && showHeader"
      style="width: 100%"
    ></div>
    <div class="justify-between items-center" style="margin-bottom: 5px">
      <h1 class="header-title">Visit Report</h1>

      <img
        [src]="!printService.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'"
        alt="Logo"
        class="header-logo"
        *ngIf="printService.env && !altHeader && printService.env['haslogo']"
      />
    </div>
    <div class="header-details">
      <div style="display: inline-block; padding-left: 1em; padding-right: 1em">
        {{ (printService.isForm ? "general.date" : "general.ExaminationDate") | translate }}:&nbsp;
        <strong style="direction: ltr">
          {{ printService.visitData.CreationTime | date : "dd.MM.yyyy HH:mm" }}
        </strong>
      </div>
      <div style="display: inline-block">
        {{ "general.Printing_Date" | translate }}:&nbsp;<strong style="direction: ltr">
          {{ now | date : "dd.MM.yyyy HH:mm" }}
        </strong>
      </div>
    </div>
  </header>
  <main class="main simple">
    <section class="section" *ngIf="printService.Pat">
      <h4 class="text-20 text-gold">
        {{ "general.Patient.Patient_Details" | translate }}
      </h4>
      <div style="width: 100%">
        <div style="display: inline-flex">
          <table>
            <tr>
              <td>
                <dt>{{ "general.Patient.first_name" | translate }}</dt>
                <dd style="font-size: 20px">
                  <strong>{{ printService.Pat.First }}</strong>
                </dd>
              </td>
              <td>
                <dt>{{ "general.Patient.last_name" | translate }}</dt>
                <dd style="font-size: 20px">
                  <strong>{{ printService.Pat.Last }}</strong>
                </dd>
              </td>
              <td>
                <dt>{{ "general.Patient.ID" | translate }}</dt>
                <dd>{{ printService.Pat.ID }}</dd>
              </td>
              <td *ngIf="printService.Pat.Birth">
                <dt>{{ "general.Patient.birth_date" | translate }}</dt>
                <dd>{{ printService.Pat.Birth | date : "dd.MM.yyyy" }}</dd>
              </td>
              <td *ngIf="printService.Pat.Birth">
                <dt>{{ "general.Patient.Age" | translate }}</dt>
                <dd>{{ printService.Pat.getAgeMo() }}</dd>
              </td>
              <td *ngIf="printService.Pat.Mobile">
                <dt>{{ "general.Patient.mobile" | translate }}</dt>
                <dd>{{ printService.Pat.Mobile }}</dd>
              </td>
              <td *ngIf="printService.Pat.Phone">
                <dt>{{ "general.Patient.home_phone" | translate }}</dt>
                <dd>{{ printService.Pat.Mobile }}</dd>
              </td>
              <td *ngIf="printService.Pat.Address">
                <dt>{{ "general.Patient.address" | translate }}</dt>
                <dd>{{ printService.Pat.Address }}</dd>
              </td>
              <td *ngIf="printService.Pat.City">
                <dt>{{ "general.Patient.town" | translate }}</dt>
                <dd>{{ printService.Pat.City }}</dd>
              </td>
              <td *ngIf="printService.Pat.Zip">
                <dt>{{ "general.Patient.zip" | translate }}</dt>
                <dd>{{ printService.Pat.Zip }}</dd>
              </td>
              <td *ngIf="printService.Pat.Email">
                <dt>{{ "general.Patient.email" | translate }}</dt>
                <dd>{{ printService.Pat.Email }}</dd>
              </td>
              <td *ngIf="printService.Pat.Weight">
                <dt>{{ "general.Patient.weight" | translate }}</dt>
                <dd>{{ printService.Pat.Weight }}</dd>
              </td>
              <td *ngIf="printService.Pat.Height">
                <dt>{{ "general.Patient.height" | translate }}</dt>
                <dd>{{ printService.Pat.Height }}</dd>
              </td>
              <td *ngIf="printService.Pat.Height && printService.Pat.Weight">
                <dt>{{ "general.Patient.bmi" | translate }}</dt>
                <!-- <dd>{{ getBMI(printService.Pat.Height, printService.Pat.Weight) }}</dd> -->
              </td>
              <td *ngIf="printService.Pat.BloodType">
                <dt>{{ "general.Patient.blood_type" | translate }}</dt>
                <dd>{{ printService.Pat.BloodType }}</dd>
              </td>
              <td *ngIf="printService.Pat.HMO">
                <dt>{{ "general.Patient.hmo" | translate }}</dt>
                <dd>{{ printService.Pat.HMO }}</dd>
              </td>
              <td *ngIf="printService.Pat.RefferDoc">
                <dt>{{ "general.Patient.referring_doctor" | translate }}</dt>
                <dd>{{ printService.Pat.RefferDoc }}</dd>
              </td>
            </tr>
            <tr *ngIf="printService.surrogate">
              <td colspan="2">
                <dt>{{ "surrogates.name" | translate }}</dt>
                <dd>
                  <strong>{{ printService.surrogate.First }} {{ printService.surrogate.Last }}</strong>
                </dd>
              </td>
              <td>
                <dt>{{ "surrogates.ID" | translate }}</dt>
                <dd>
                  <strong>{{ printService.surrogate.ID }}</strong>
                </dd>
              </td>
              <td *ngIf="printService.surrogate.Birth">
                <dt>{{ "surrogates.birth" | translate }}</dt>
                <dd>{{ printService.surrogate.Birth | date : "dd.MM.yyyy" }}</dd>
              </td>
              <td *ngIf="printService.surrogate.OrgID">
                <dt>{{ "surrogates.orgid" | translate }}</dt>
                <dd>{{ printService.surrogate.OrgID }}</dd>
              </td>
            </tr>
          </table>
          <div *ngIf="typeOfPrint === 'fancy'" class="flex justify-center items-center ps-24" style="width: 20%">
            <img src="../../assets/images/girl.svg" width="111" height="154" alt="Patient Icon" />
          </div>
        </div>
      </div>
    </section>

    <section class="section marginBottom">
      <h4 class="text-20 text-gold">Visit</h4>
      <section class="section">
        <h4 class="text-20 text-gold">Mother Details</h4>
      <table class="l-table-auto weightAss-table">
        <tr class="l-row weightAss-thead">
          <td class="l-td-m" style="width: 10%; font-weight: 700">Date</td>
          <td class="l-td-m" style="width: 5%; font-weight: 700">GA</td>
          <td class="l-td-m" style="width: 5%; font-weight: 700">Weight</td>
          <td class="l-td-m" style="width: 12%; font-weight: 700">B. Pressure Sys.</td>
          <td class="l-td-m" style="width: 12%; font-weight: 700">B. Pressure Dia.</td>
          <td class="l-td-m" style="width: 8%; font-weight: 700">Proteinuria</td>
          <td class="l-td-m" style="width: 8%; font-weight: 700">Glucosutia</td>
          <td class="l-td-m" style="width: 8%; font-weight: 700">Uterus</td>
          <td class="l-td-m" style="width: 8%; font-weight: 700">Cervical</td>
        </tr>
        <tr class="l-row weightAss-tbody">
          <td class="l-td-m" style="width: 10%">{{ printService.visitData.CreationTime | date : "dd/MM/yyyy" }}</td>
          <td class="l-td-m" style="width: 5%">{{ printService.visitData.GA }}</td>
          <td class="l-td-m" style="width: 5%">{{ printService.visitData.Weight }}</td>
          <td class="l-td-m" style="width: 12%">{{ printService.visitData.BPressureSys }}</td>
          <td class="l-td-m" style="width: 12%">{{ printService.visitData.BPressureDia }}</td>
          <td class="l-td-m" style="width: 8%">{{ printService.visitData.Proteinuria }}aa</td>
          <td class="l-td-m" style="width: 8%">{{ printService.visitData.Glucosutia }}bb</td>
          <td class="l-td-m" style="width: 8%">{{ printService.visitData.Uterus }}cc</td>
          <td class="l-td-m" style="width: 8%">{{ printService.visitData.Cervical }}</td>
        </tr>
      </table>
      <table class="l-table-auto weightAss-table">
        <tr class="l-row weightAss-thead">
          <td class="l-td-m" style="width: 7%; font-weight: 700">PV</td>
          <td class="l-td-m" style="width: 12%; font-weight: 700">Comments</td>
        </tr>
        <tr class="l-row weightAss-tbody">
          <td class="l-td-m" style="width: 7%">{{ printService.visitData.PV }}</td>
          <td class="l-td-m" style="width: 12%">{{ printService.visitData.Comments }}</td>
        </tr>
      </table>
    </section>
      <section class="section">
        <h4>Fetus Details</h4>
        <div style="width: 100%">
          <div *ngFor="let fetus of fetuses; let i = index" style="margin-bottom: 20px">
            <table class="l-table-auto weightAss-table">
              <h5>Fetus {{ i + 1 }}</h5>
              <tr class="l-row weightAss-thead">
                <td class="l-td-m" style="width: 5%; font-weight: 700">Gender</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Present</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Movements</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Breathing</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Tonus</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Pulse</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">FHR</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Weight</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Percentage</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">NST</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Doppler</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">A.F.</td>
                <td class="l-td-m" style="width: 5%; font-weight: 700">Comments</td>
              </tr>
              <tr class="l-row weightAss-tbody">
                <td class="l-td-m" style="width: 5%">{{ fetus.Gender }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Present }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Movements }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Breathing }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Tonus }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Pulse }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.FHR }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Weight }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Percentage }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.NST }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Doppler }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.AF }}</td>
                <td class="l-td-m" style="width: 5%">{{ fetus.Comments }}</td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </section>

    <section *ngIf="printService.visitDocuments && printService.visitDocuments.length" class="section marginBottom">
      <h4 class="text-20 text-gold">Files</h4>
      <table class="l-table-auto weightAss-table">
        <tr class="l-row weightAss-thead">
          <td class="l-td-m" style="width: 5%">#</td>
          <td class="l-td-m" style="width: 15%; font-weight: 700">File Name</td>
          <td class="l-td-m" style="width: 15%; font-weight: 700">Creation Time</td>
          <td class="l-td-m" style="width: 15%; font-weight: 700">Comments</td>
        </tr>
        <br />
        <ng-container *ngFor="let document of printService.visitDocuments; index as i">
          <tr class="l-row weightAss-tbody">
            <td class="l-td-m" style="width: 5%">{{ i + 1 }}</td>
            <td class="l-td-m" style="width: 15%">{{ document.title }}</td>
            <td class="l-td-m" style="width: 15%">{{ document.CreationTime | date : "dd/MM/yyyy" }}</td>
            <td class="l-td-m" style="width: 15%">{{ document.comments }}</td>
          </tr>
        </ng-container>
      </table>
    </section>

    <section *ngIf="printService.visitLettersData && printService.visitLettersData.length" class="section marginBottom">
      <h4 class="text-20 text-gold">Documents</h4>
      <table class="l-table-auto weightAss-table">
        <tr class="l-row weightAss-thead">
          <td class="l-td-m" style="width: 5%">#</td>
          <td class="l-td-m" style="width: 15%; font-weight: 700">Document Name</td>
          <td class="l-td-m" style="width: 15%; font-weight: 700">Creation Time</td>
        </tr>
        <br />
        <ng-container *ngFor="let letter of printService.visitLettersData; index as i">
          <tr class="l-row weightAss-tbody">
            <td class="l-td-m" style="width: 5%">{{ i + 1 }}</td>
            <td class="l-td-m" style="width: 15%">
              {{ letter.ChangedExamTitle ? letter.ChangedExamTitle : letter.ExamType }}
            </td>
            <td class="l-td-m" style="width: 15%">{{ letter.CreationTime | date : "dd/MM/yyyy" }}</td>
          </tr>
        </ng-container>
      </table>
    </section>
  </main>
</div>
